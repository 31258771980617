import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ChangeEvent } from 'react';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { Driver, Packer, PageSaleState, Sales, SalesState, Shipment } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { Dialog, Classes, MenuItem, Checkbox } from "@blueprintjs/core";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import { AppToaster } from "../shared/toaster"
import { debug } from 'console';
import QRCode from "react-qr-code";

type MassShipmentsProps =
	UserState
	& SalesState
	& typeof commonActionCreators.actionCreators
	& typeof salesActionCreators.actionCreators
	& RouteComponentProps<{ idSale: string }>
	& { showNavBar: boolean };


class MassShipments extends React.Component<MassShipmentsProps> {
	state: {
		idSale: number,
		showData: boolean,
		showConfirmationModal: boolean,
		showConfirmationModalDeliveryChange: boolean,
		showConfirmationModalDriverChange: boolean,
		deliveryType: string,
		deliveryTypeNext: string,
		currentShipments: Array<Shipment>,
		blankSale: Array<Sales>,
		driverForAll?: Driver,
		driverForAllNext?: Driver,
		toPrint: React.PureComponent | null
	} = {
			idSale: 0,
			showData: true,
			showConfirmationModal: false,
			showConfirmationModalDeliveryChange: false,
			showConfirmationModalDriverChange: false,
			deliveryType: "",
			deliveryTypeNext: "",
			currentShipments: Array<Shipment>(),
			blankSale: Array<Sales>(),
			driverForAll: undefined,
			driverForAllNext: undefined,
			toPrint: null
		};


	constructor(props: MassShipmentsProps) {
		super(props);
	}

	componentDidMount() {
		var bs = Array<Sales>();
		var s = ({} as Sales);
		s.id = 0;
		bs.push(s);
		this.setState({ blankSale: bs });
		this.cleanData();

		if (this.props.packers.length <= 0) {
			this.props.loadPackers(false);
		}
		
		if (this.props.sales.length <= 0) {
		//this.props.getSalesInState("", PageSaleState.ReadyForPacking);
		this.props.getSalesForMassShipments();
		}

		if (this.props.drivers.length <= 0) {
			this.props.getDrivers();
		}
	}

	private cleanData() {
		const cs = Array<Shipment>();
		cs.push({} as Shipment);
		this.setState({ currentShipments: cs, driverForAll: undefined });
	}

	//private onFormSubmit = (evt: any) => {
	//	evt.preventDefault();
	//	//this.search();
	//}

	private updateDeliveryType(value: string) {
		if (this.state.deliveryType != "" && this.state.currentShipments.filter(i => i.sale != null).length > 0) {
			this.setState({ deliveryTypeNext: value, showConfirmationModalDeliveryChange: true });
		}
		else
			this.setState({ deliveryType: value, deliveryTypeNext: "" });
	}
	private updateSale(venta: Sales, index: number) {
		var cs = this.state.currentShipments;
		if (venta == null || venta.id == 0)
			cs[index] = ({} as Shipment);
		else {
			cs[index].sale = venta;
			cs[index].bulks = venta != null && cs[index].bulks == undefined ? 1 : cs[index].bulks;
		}
		this.verifyLines(cs);
		this.setState({ currentShipments: cs });
		setTimeout(() => {
			var x = this.refs[this.state.deliveryType == "Flex" ? "B" + index : "P" + index + "-" + 0];
			if (this.state.deliveryType == "Flex") {
				(x as HTMLInputElement).select();
			}
			else
				(x as HTMLInputElement).focus();
		}, 50);
	}

	private updateBulks(bultos: string, index: number) {
		var cs = this.state.currentShipments;
		cs[index].bulks = bultos != "" ? parseInt(bultos) : 0;
		this.verifyLines(cs);
		this.setState({ currentShipments: cs });
		let r = this.refs;
	}
	private updatePackers(packer: Packer, index: number, event: React.ChangeEvent<HTMLInputElement>) {
		var cs = this.state.currentShipments;
		var checkbox = event.currentTarget;

		if (cs[index].packers == undefined)
			cs[index].packers = Array<Packer>();

		var ix = cs[index].packers.findIndex(i => i == packer);
		if (ix >= 0 && !checkbox.checked)
			cs[index].packers.splice(ix, 1);
		else if (ix < 0 && checkbox.checked) {
			var p = this.props.packers.find(i => i == packer);
			if (p)
				cs[index].packers.push(p);
		}

		this.verifyLines(cs);
		this.setState({ currentShipments: cs });
	}
	private updateDriver(driver: Driver, index: number) {
		var cs = this.state.currentShipments;
		cs[index].driver = driver;
		this.verifyLines(cs);
		this.setState({ currentShipments: cs });
		setTimeout(() => {
			var x = (this.refs["S" + (index + 1)] as any);
			if (x && x.inputEl)
				(x.inputEl as HTMLInputElement).focus();
		}, 50);
	}

	private updateDriverForAll(driver?: Driver) {
		console.debug();
		if (driver && driver.id > 0) {
			if (this.state.currentShipments.length > 1 && this.state.driverForAll && this.state.driverForAll != null)
				this.setState({ driverForAllNext: driver, showConfirmationModalDriverChange: true });
			else if (this.state.currentShipments.find(it => it.driver && it.driver != null && it.driver != driver) != null)
				this.setState({ driverForAllNext: driver, showConfirmationModalDriverChange: true });
			else
				this.setState({ driverForAll: driver });
		}
		else
			this.setState({ driverForAll: undefined });
	}

	private verifyLines(cs: Array<Shipment>) {
		var agregar = true;
		var vacias = [];
		for (var i = 0; i < cs.length; i++) {
			if (cs[i].sale == undefined || cs[i].sale == null) {
				agregar = false;
				vacias.push(i)
			}
		}
		if (agregar) {
			const cs = this.state.currentShipments;
			cs.push({} as Shipment);
		}
		if (vacias.length > 1) {
			for (var i = 0; i < vacias.length - 1; i++) {
				cs = cs.splice(vacias[i], 1);
			}
		}
		this.setState({ currentShipments: cs });
	}

	componentWillReceiveProps(nextProps: MassShipmentsProps) {
		if (nextProps.packedSucess) {
			let cant = this.state.currentShipments.filter(i => i.sale != null).length;
			AppToaster.show({ intent: "success", message: cant + (cant == 1 ? " venta guardada" : " ventas guardadas") + " con éxito." });
			//this.props.history.push(`/ventasListasParaEmpaquetar`);
			this.props.dismissPackedSucess();
			this.cleanData();
			this.handleCloseConfirmation();
			if (this.state.deliveryTypeNext != "")
				this.setState({ deliveryType: this.state.deliveryTypeNext, deliveryTypeNext: "" });

			this.props.getSalesForMassShipments();
		}

		if (nextProps.salesToPrint) {
			let baseUrlQR = nextProps.customMessage;
			let toPrint = <div id='printToPDF'>
				{nextProps.salesToPrint.map((sale, ix) => {
					let urlQR = `${baseUrlQR}${sale.id}`;

					return <table style={{ border: "solid", marginBottom: "20px", width: 600 }} key={ix}>
						<tbody>
							<tr>
								<td>Numero de Venta</td>
								<td colSpan={5}># {sale.id}</td>
								<td rowSpan={true ? 7 : 5} style={{ width: 128 }}><QRCode value={urlQR} size={128} /></td>
							</tr>
							<tr>
								<td style={{ width: 120 }}>Cliente</td>
								<td colSpan={2}>{sale.client}</td>
								<td style={{ width: 50 }}>DNI/CUIT</td>
								<td style={{ width: 50 }}></td>
								<td style={{ width: 50 }}></td>
							</tr>
							<tr>
								<td>Dirección</td>
							</tr>
							<tr>
								<td>Localidad</td>
								<td style={{ width: 50 }}></td>
								<td>Provincia</td>
								<td></td>
								<td>C.P.</td>
								<td style={{ width: 50 }}></td>
							</tr>
							<tr>
								<td>Teléfono</td>
							</tr>
							{
								true ?      //sale. es Expreso
									<>
										<tr>
											<td>Expreso</td>
										</tr>
										<tr>
											<td colSpan={2}>Cantidad de bultos</td>
											<td ></td>
											<td colSpan={2}>Nro. de bulto/s</td>
										</tr>
									</> :
									<></>
							}
						</tbody>
					</table>
				})}
			</div>

			this.setState({ salesToPrint: null, toPrint: toPrint, customMessage: "" });
		}
	}

	private escapeRegExpChars(text: string) {
		return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}

	private highlightText(text: string, query: string) {
		let lastIndex = 0;
		const words = query
			.split(/\s+/)
			.filter(word => word.length > 0)
			.map(this.escapeRegExpChars);
		if (words.length === 0) {
			return [text];
		}
		const regexp = new RegExp(words.join("|"), "gi");
		const tokens: React.ReactNode[] = [];
		while (true) {
			const match = regexp.exec(text);
			if (!match) {
				break;
			}
			const length = match[0].length;
			const before = text.slice(lastIndex, regexp.lastIndex - length);
			if (before.length > 0) {
				tokens.push(before);
			}
			lastIndex = regexp.lastIndex;
			tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
		}
		const rest = text.slice(lastIndex);
		if (rest.length > 0) {
			tokens.push(rest);
		}
		return tokens;
	}

	private renderProductDrivers: ItemRenderer<Driver> = (item, { handleClick, modifiers, query }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}
		const text = item.name;
		const maxLength = 15;
		return (
			<MenuItem
				active={false}
				disabled={modifiers.disabled}
				label={`${(item.logistic != null && item.logistic != undefined) ? (item.logistic.name.length > (maxLength + 1) ? item.logistic.name.substring(0, maxLength) + "..." : item.logistic.name) : ""}`}
				key={"dri" + item.id}
				labelClassName="labelSuggestMS driver"
				onClick={handleClick}
				text={this.highlightText(text, query)}
			/>
		);
	};


	private renderProductSales: ItemRenderer<Sales> = (item, { handleClick, modifiers, query }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}
		const text = item.id.toString();
		const maxLength = 12;
		var label = item.user;
		if (this.state.deliveryType == "ToBeAgreed" && !item.hasMeLiOrder) {
			label = item.client;
        }
		return (
			<MenuItem
				active={false}
				disabled={modifiers.disabled}
				//BACK 180 label={`${item.user}`}
				label={`${label}`}
				labelClassName="labelSuggestMS"
				key={"sal" + item.id}
				onClick={handleClick}
				text={text == "0" ? "Eliminar fila" : this.highlightText(text, query)}
			/>
		);
	};

	private handleCloseConfirmation = () => {
		this.setState({ showConfirmationModal: false, showConfirmationModalDeliveryChange: false, showConfirmationModalDriverChange: false });
	}

	private verifyAndConfirm = () => {
		let mensajesError: string[] = [];
		let cs = this.state.currentShipments.filter(it => it.sale != null);
		if (cs.length > 0) {
			let isFlex = this.state.deliveryType == "Flex";

			for (var i = 0; i < cs.length; i++) {
				if (cs[i].sale && cs[i] != null) {
					if (!cs[i].packers || cs[i].packers.length == 0) {
						mensajesError.push("Seleccione algún responsable para la Venta # " + cs[i].sale.id);
					}
					if (isFlex) {
						if (!(cs[i].bulks > 0))
							mensajesError.push("Ingrese los bultos para la Venta # " + cs[i].sale.id);
						if ((!this.state.driverForAll || this.state.driverForAll == null) && (!cs[i].driver || cs[i].driver == null))
							mensajesError.push("Seleccione un conductor para la Venta # " + cs[i].sale.id);
					}
				}
			}
		}
		else
			mensajesError.push("Debe cargar al menos un registro para guardar.");

		if (mensajesError.length == 0) {
			this.setState({ showConfirmationModal: true });
		}
		else {
			let mensajes = <>
				{mensajesError.map((val, it) => { return <span key={it}>{val}<br /></span>; })}
			</>;
			AppToaster.show({ intent: "warning", message: mensajes });
		}
	}

	private confirmationDialog = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showConfirmationModal}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>¿Confirmas guardar la información de empaque de {this.state.currentShipments.length - 1} ventas?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.save();
					}}>Guardar</Button>
				</div>
			</div>
		</Dialog>
	}

	private confirmationDialogChangeDelivery = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showConfirmationModalDeliveryChange}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Existen datos sin gardar para esta Forma de Entrega.</p>
				<p>¿Desea guardar los datos?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.save();
						this.handleCloseConfirmation();
					}}>Guardar datos y cambiar Forma de Entrega</Button>
					<Button color="secondary" className="ml-2" onClick={() => {
						this.cleanData();
						this.setState({ deliveryType: this.state.deliveryTypeNext, deliveryTypeNext: "" });
						this.handleCloseConfirmation();
					}}>Cambiar forma de Entrega sin guardar los datos</Button>
				</div>
			</div>
		</Dialog>
	}

	private confirmationDialogChangeDriver = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showConfirmationModalDriverChange}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>¿Está seguro que desea cambiar la Logística para todas las filas?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.setState({ driverForAll: this.state.driverForAllNext, driverForAllNext: undefined })
						this.handleCloseConfirmation();
					}}>Cambiar</Button>
				</div>
			</div>
		</Dialog>
	}


	private save = () => {
		console.debug();
		var cs = this.state.currentShipments;
		//if (this.state.deliveryType == "Flex" && this.state.driverForAll && this.state.driverForAll != null) {
		if (this.state.driverForAll && this.state.driverForAll != null) {
			for (var i = 0; i < cs.length; i++) {
				cs[i].driver = this.state.driverForAll;
			}
		}
		this.props.saveMassShipment(cs, this.state.deliveryType);
	}

	private renderHeader() {
		return <Card>
			<Card.Body>
				<h4 className="card-title">
					<i className="mdi mdi-package-variant icon-title mr-2"></i>Información de empaque
                </h4>
				<p className="card-description"> Registrar envíos masivamente </p>
				<Form>
					<Row>
						<Col className="form-group" md={4}>
							<Label>
								Forma de entrega
                            </Label>
							<select className="form-control" onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.updateDeliveryType(evt.currentTarget.value)}
								value={this.state.deliveryType}>
								<option value="" disabled={this.state.deliveryType != "" ? true : false}>Seleccione una opción...</option>
								{/*<option value="Collect">Colecta</option>*/}
								<option value="Flex">Flex</option>
								<option value="ToBeAgreed">Expreso/Moto</option>
							</select>
						</Col>
						{
							this.state.deliveryType != "" ?
								<Col className="form-group" md={3} style={{ paddingTop: "34px", paddingLeft: "20px" }}>
									<Label>Pendientes: {this.props.sales.filter((value: Sales, index1: number) => {

										if (value.saleInformation != null)
											return (this.state.deliveryType == 'ToBeAgreed'  && (value.saleInformation.deliveryMethod == 'Expreso' || value.saleInformation.deliveryMethod == 'Moto'))
										else
											return (value.meLiSaleDetails != null && (value.meLiSaleDetails.find(it => it.deliveryType == this.state.deliveryType) != null)) 
										
									}).length}
									</Label>
								</Col> :
								<></>
						}
						{
							(this.state.deliveryType == "Flex" || this.state.deliveryType == 'ToBeAgreed')?
								<Col className="form-group" md={3} style={{ paddingTop: "22px" }}>
									<Suggest
										key={"Driver"}
										items={[{ id: 0, name: "Ninguno ", logistic: undefined }, ...this.props.drivers]}
										popoverProps={{ minimal: true, popoverClassName: "popoverSuggestMS" }}
										noResults={<MenuItem disabled={true} text="Conductor inexistente" />}
										onItemSelect={(value: Driver, event?: React.SyntheticEvent<HTMLElement>) => {
											this.updateDriverForAll(value);
										}
										}
										itemsEqual={(iteamA: Driver, itemB: Driver) => iteamA.id == itemB.id}
										inputValueRenderer={(value: Driver) => value.name}
										itemRenderer={this.renderProductDrivers}
										itemPredicate={(query, item, _index, exactMatch) => { return item.name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
										inputProps={{ placeholder: "Carga múltiple para un conductor" }}
										selectedItem={(this.state.deliveryType == "Flex" || this.state.deliveryType == 'ToBeAgreed') && this.state.driverForAll != undefined ? this.state.driverForAll : null}
										resetOnClose={true}
									/>
							</Col>
							:
							<></>
						}
					</Row>
				</Form>
			</Card.Body>
		</Card>
	}

	updateActiveItemSales(activeItem: Sales) {
		this.setState({ activeItemSale: activeItem })
	}

	private renderBody = () => {
		const styleChecks = {
			display: "inline-block",
			paddingRight: "20px"
		};

		return <Card>
			<Card.Body>
				<Row className="table-responsive">
					<Col md={12}>
						<table className="table table-hover">
							<thead>
								<tr>
									<th style={{ width: 130 }} ># Venta</th>
									<th style={{ width: 80 }}>Bultos</th>
									<th>Responsables</th>
									<th style={{ width: 200 }}>Logística</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.currentShipments.map((shipment: Shipment, index: number) => {
										return <tr key={index}>
											<td>
												<div style={{ textAlign: "right" }}>
													{shipment.sale != null ? <span> {index + 1} </span> : ""}
													<Suggest
														key={"S" + index}
														ref={"S" + index}
														items={
															this.state.blankSale.concat(this.props.sales).filter((value: Sales, index1: number) => {
																var ids = this.state.currentShipments.map((sh: Shipment) => { return sh.sale != null ? sh.sale.id : null; });
																if (index == this.state.currentShipments.length - 1 && value.id == 0)
																	return false;
																else
																	return ids.indexOf(value.id) < 0 ?
																		((value.saleInformation != null && this.state.deliveryType == 'ToBeAgreed' && (value.saleInformation.deliveryMethod == 'Expreso' || value.saleInformation.deliveryMethod == 'Moto')) ||
																		(value.meLiSaleDetails != null && (value.meLiSaleDetails.find(it => it.deliveryType == this.state.deliveryType) != null))) :
																		false;
															})}
														popoverProps={{ minimal: true, popoverClassName: "popoverSuggestMS" }}
														noResults={<MenuItem disabled={true} text="Venta inexistente" />}
														onItemSelect={(value: Sales, event?: React.SyntheticEvent<HTMLElement>) => {
															this.updateSale(value, index);
														}
														}
														itemsEqual={(iteamA: Sales, itemB: Sales) => iteamA.id == itemB.id}
														inputValueRenderer={(value: Sales) => { return value.id != 0 ? value.id.toString() : ""; }}
														itemRenderer={this.renderProductSales}
														itemPredicate={(query, item, _index, exactMatch) => { return item.id.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
														inputProps={{ placeholder: "Seleccione una venta" }}
														selectedItem={this.state.currentShipments[index].sale == null ? null : this.state.currentShipments[index].sale}  /*Raro pero así funcionó*/
														resetOnClose={true}
														className="MSSuggest"
													/>
												</div>
											</td>
											<td>
												<input
													type="number"
													key={"B" + index}
													ref={"B" + index}
													className="form-control"
													disabled={this.state.deliveryType != "Flex"}
													min={1}
													value={this.state.deliveryType == "Flex" && shipment.bulks > 0 ? shipment.bulks.toString() : ""}
													onChange={(evt: React.ChangeEvent<HTMLInputElement>) => this.updateBulks((evt.currentTarget.value ? evt.currentTarget.value : ""), index)}
												/>
											</td>
											<td>
												{this.props.packers.map((packer: Packer, intexResp: number) => {
													return (
														<div key={intexResp} className="form-check" style={styleChecks}>
															<label className="form-check-label text-muted">
																<input
																	type="checkbox"
																	key={"P" + index + "-" + intexResp}
																	ref={"P" + index + "-" + intexResp}
																	className="form-check-input highlightSelected"
																	checked={shipment.packers != undefined ? shipment.packers.findIndex(p => p.name == packer.name) >= 0 : false}
																	onChange={(evt: React.ChangeEvent<HTMLInputElement>) => this.updatePackers(packer, index, evt)}
																/>
																<i className="input-helper"></i>{packer.nickname}
															</label>
														</div>);
												})}
											</td>
											<td>
												<Suggest
													disabled={(this.state.deliveryType == "Flex" || this.state.deliveryType == 'ToBeAgreed' )&& this.state.driverForAll != undefined}
													key={"D" + index}
													ref={"D" + index}
													items={this.props.drivers}
													popoverProps={{ minimal: true, popoverClassName: "popoverSuggestMS" }}
													noResults={<MenuItem disabled={true} text="Conductor inexistente" />}
													onItemSelect={(value: Driver, event?: React.SyntheticEvent<HTMLElement>) => {
														this.updateDriver(value, index);
													}
													}
													itemsEqual={(iteamA: Driver, itemB: Driver) => iteamA.id == itemB.id}
													inputValueRenderer={(value: Driver) => value.name}
													itemRenderer={this.renderProductDrivers}
													itemPredicate={(query, item, _index, exactMatch) => { return item.name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
													inputProps={{ placeholder: "Seleccione un conductor" }}
													
													selectedItem={this.state.driverForAll != undefined ? this.state.driverForAll :
																	this.state.currentShipments[index].driver != null ? this.state.currentShipments[index].driver : null
																	}
													resetOnClose={true}
												/>
											</td>
										</tr>;
									})
								}
							</tbody>
						</table>
						<br /><br /><br />
						<Button color="primary" className="ml-2" onClick={() => { this.verifyAndConfirm(); }}>Guardar masivamente</Button>
						<br /><br /><br /><br />
					</Col>
				</Row>
			</Card.Body>
		</Card>;
	}

	private printPDF(salesIds: number[] | null) {
		if (salesIds != null) {
			this.props.printPDF((salesIds as number[]));
			setTimeout(function () {
				var btn = document.getElementById("btnPrintToPDF");
				btn!.click();
			}, 200);
		}
		else {
			if (document != null) {
				var div = document.getElementById("printToPDF");
				if (div != null) {
					var divContents = div.outerHTML;
					var a = window.open('', '', 'height=700, width=1000');
					if (a != null) {
						a.document.write(`<html>
	<head>
		<style type="text/css" media="print">
			@page {
				size: auto;
				margin: 0mm;');
				margin-left: 5mm;');
			}
		</style>
		<script>
			setTimeout(function () {
				window.close();
			}, 1000);
		</script>
	</head>
	<body>
		${divContents}
	</body>
</html>`);
						a.document.close();
						a.print();
					}
					//this.props.printSales(this.state.selectedSales.map((sale: Sales) => sale.id));
				}
				this.setState({ salesToPrint: null, toPrint: null });
			}
		}
	}

				//<Button color="primary" className="ml-2" onClick={() => { this.printPDF([55, 56]); }}>Prueba Impresion PDF</Button>
				//{this.state.toPrint ?
				//	<div style={{ display: "none" }}>
				//		{this.state.toPrint}
				//		<Button color="primary" className="ml-2" id="btnPrintToPDF" onClick={() => { this.printPDF(null); }}>Imp</Button>
				//	</div> :
				//	<></>
				//}
	render() {
		return (
			<>

				<div className="grid-margin">
					{this.renderHeader()}
				</div>
				<div className="grid-margin">
					{
						this.state.deliveryType != "" ?
							this.renderBody() :
							<></>
					}
				</div>
				{this.confirmationDialog()}
				{this.confirmationDialogChangeDelivery()}
				{this.confirmationDialogChangeDriver()}

			</>
		);
	}
}

export default connect(
	(state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
	(dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(MassShipments as any);