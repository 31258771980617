import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../..';
import { KnownAction, MeLiOrder, Sales, Product, SelectedProduct, EditingSaleProduct, Message, PageSaleState, Packer, MessageConfiguration, AuditLog, SaleMessage, ClaimStatus, Driver, Shipment, Holidays, Province, City, Company, DeliveryMethod, Logistic } from '../types';
import { configuration } from '../../configureStore';
import { handleError, requestHelper } from '../../common';
import download from 'downloadjs';
import Reports from '../../../app/sales/Reports';
import ReviewSales from '../../../app/sales/ReviewSales';
import { debug } from 'console';



const getSale = (idSale: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
	const appState = getState();
	if (appState) {
		let url = `${configuration.baseApiUrl}/Sales/${idSale}`;

		let onSuccess = (response: Response) => {
			(response.json() as Promise<Sales>)
				.then(data => {
					if (data != null) {
						dispatch({ type: "RECEIVE_SALE", sale: data });
					}
				})
		}
		requestHelper(url, 'GET', null, onSuccess, dispatch);
	};
}

const getClaimStatus = (saleId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
	const appState = getState();
	if (appState) {
		let url = `${configuration.baseApiUrl}/Sales/ClaimStatus/${saleId}`;

		let onSuccess = (response: Response) => {
			(response.json() as Promise<ClaimStatus>)
				.then(data => {
					if (data != null) {
						if (appState.salesState != null &&
							appState.salesState.currentSale != null) {
							dispatch({ type: "RECEIVE_CLAIM_ORDER", orders: data });
						}
					}
				})
		}
		requestHelper(url, 'GET', null, onSuccess, dispatch, false);
	};
};

const getMessageConfigurations = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
	const appState = getState();
	if (appState) {
		let url = `${configuration.baseApiUrl}/Message`;
		let onSuccess = (response: Response) => {
			(response.json() as Promise<MessageConfiguration[]>)
				.then(data => {
					if (data != null) {
						dispatch({ type: "RECEIVE_MESSAGE_CONFIGURATIONS", messageConfigurations: data });
					}
				})
		}
		requestHelper(url, 'GET', null, onSuccess, dispatch);
	}
}

const getMessages = (saleDetail: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
	const appState = getState();
	if (appState) {
		let url = `${configuration.baseApiUrl}/Message/${saleDetail}`;

		let onSuccess = (response: Response) => {
			(response.json() as Promise<Message[]>)
				.then(data => {
					if (data != null) {
						dispatch({ type: "RECEIVE_MESSAGES", messages: data });
					}
				})
		}
		requestHelper(url, 'GET', null, onSuccess, dispatch, false);
	}
};

const getOrders = (fromDate: Date, toDate: Date, userName: string, delivery: string, hasCard: boolean | undefined, orderNumber: number, onlyManufacturing: boolean = false, clearCache: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
	const appState = getState();
	if (appState) {
		let url = `${configuration.baseApiUrl}/MeLi?fromDate=${fromDate.toDateString()}&toDate=${toDate.toDateString()}`;
		if (userName != "") {
			url += `&userName=${userName}`;
		}
		if (delivery != "") {
			url += `&deliveryType=${delivery}`;
		}

		if (hasCard != undefined) {
			url += `&hasCard=${hasCard}`;
		}

		if (orderNumber != 0) {
			url += `&orderNumber=${orderNumber}`;
		}

		url += `&onlyManufacturing=${onlyManufacturing}`;

		url += `&clearCache=${clearCache}`;

		let onSuccess = (response: Response) => {
			(response.json() as Promise<MeLiOrder[]>)
				.then(data => {
					if (data != null) {
						dispatch({ type: "RECEIVE_MELI_ORDER", orders: data });
					}
				})
			dispatch({
				type: "UPDATE_FILTERS",
				pageSaleState: PageSaleState.MLSale,
				filters: {
					fromDate: fromDate,
					toDate: toDate,
					userName: userName,
					deliveryType: delivery,
					onlyReopenSales: false,
					excludeMeLiOrders: false,
					excludePaymentLink: false,
					excludeTransfers: false,
					ordersWithCard: hasCard,
					currentPage: 1,
					saleId: 0,
					orderNumber: orderNumber,
					pageSaleState: PageSaleState.MLSale,
					onlySalesWithoutAdjustments: false,
					isNotPrinted: false,
					flexZone: ""
				}
			});
		}

		requestHelper(url, 'GET', null, onSuccess, dispatch);
	}
};

const getFulljausOrders = (fromDate: Date, toDate: Date, userName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
	const appState = getState();
	if (appState) {
		let url = `${configuration.baseApiUrl}/Fulljaus?fromDate=${fromDate.toDateString()}&toDate=${toDate.toDateString()}`;

		if (userName != "") {
			url += `&userName=${userName}`;
		}
			

		let onSuccess = (response: Response) => {
			(response.json() as Promise<MeLiOrder[]>)
				.then(data => {
					if (data != null) {
						dispatch({ type: "RECEIVE_FULLJAUS_ORDER", orders: data });
					}
				})
			dispatch({
				type: "UPDATE_FILTERS",
				pageSaleState: PageSaleState.FJSale,
				filters: {
					fromDate: fromDate,
					toDate: toDate,
					userName: userName,
					deliveryType: "",
					onlyReopenSales: false,
					excludeMeLiOrders: false,
					excludePaymentLink: false,
					excludeTransfers: false,
					ordersWithCard: false,
					currentPage: 1,
					saleId: 0,
					orderNumber: 0,
					pageSaleState: PageSaleState.FJSale,
					onlySalesWithoutAdjustments: false,
					isNotPrinted: false,
					flexZone: ""
				}
			});
		}

		requestHelper(url, 'GET', null, onSuccess, dispatch);
	}
};

export const actionCreators = {
	getOrders: getOrders,
	getFulljausOrders: getFulljausOrders,
	cleanOrders: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			dispatch({ type: "CLEAN_ORDERS" });
		}
	},
	cleanSale: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			dispatch({ type: "CLEAN_CURRENT_SALE" });
		}
	},
	createSale: (orders: MeLiOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let ordersToSend = orders.map((order: MeLiOrder) => order.orderNumber);
			let url = `${configuration.baseApiUrl}/Sales`;

			let onSuccess = (response: Response) => {
				if (response.status == 206) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE", text: data.message });
						});
				}
				else if (response.status == 207) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
						});
				}
				else {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "RECEIVE_SALES_CREATED", amountSalesCreated: 1, saleId: data.id, containsmessages: data.containsMessages, loadedWitouthDifference: false, duplicatesales: 0 });
						})
				}
			}
			
			requestHelper(url, 'POST', ordersToSend, onSuccess, dispatch);
		}
	},
	createSaleFulljaus: (orders: MeLiOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let ordersToSend = orders.map((order: MeLiOrder) => order.referenceOrder);
			let url = `${configuration.baseApiUrl}/Sales/FulljausSimple`;

			let onSuccess = (response: Response) => {
				if (response.status == 206) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE", text: data.message });
						});
				}
				else if (response.status == 207) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
						});
				}
				else {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "RECEIVE_SALES_CREATED", amountSalesCreated: 1, saleId: data.id, containsmessages: data.containsMessages, loadedWitouthDifference: false, duplicatesales: 0 });
						})
				}
			}

			requestHelper(url, 'POST', ordersToSend, onSuccess, dispatch);
		}
	},
	dismissSuccessfullyCreated: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_SALES_CREATED" });
	},
	createMasiveSales: (orders: MeLiOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let ordersToSend = orders.map((order: MeLiOrder) => order.orderNumber);
			let url = `${configuration.baseApiUrl}/Sales/Massive`;

			let onSuccess = (response: Response) => {
				if (response.status == 206) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE", text: data.message });
						});
				}
				else if (response.status == 207) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
						});
				}
				else {
					(response.json() as Promise<any>)
						.then(data => {
							if (data != null) {
								dispatch({ type: "RECEIVE_SALES_CREATED", amountSalesCreated: data.createdOrder, saleId: 0, containsmessages: data.containsMessages, loadedWitouthDifference: false, duplicatesales: data.duplicateSales });
							}
						})
				}
			}
			requestHelper(url, 'POST', ordersToSend, onSuccess, dispatch);
		}
	},
	ruleOutMasiveSales: (orders: MeLiOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let ordersToSend = orders.map((order: MeLiOrder) => order.orderNumber);
			let url = `${configuration.baseApiUrl}/Sales/ruleOutMassive`;

			let onSuccess = (response: Response) => {
				if (response.status == 206) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE", text: data.message });
						});
				}
				else if (response.status == 207) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
						});
				}
				else {
					(response.json() as Promise<any>)
						.then(data => {
							if (data != null) {
								dispatch({ type: "RECEIVE_SALES_CREATED", amountSalesCreated: data.createdOrder, saleId: 0, containsmessages: data.containsMessages, loadedWitouthDifference: false, duplicatesales: data.duplicateSales });
							}
						})
				}
			}
			requestHelper(url, 'POST', ordersToSend, onSuccess, dispatch);
		}
	},
	getSales: (fromDate: Date, toDate: Date, userName: string,
		onlyReopenSales: boolean, excludeMeLiOrders: boolean,
		excludePaymentLink: boolean, excludeTransfers: boolean,
		deliveryType: string,
		saleId: number,
		orderNumber: number,
		saleState: PageSaleState,
		isInSearchAll: boolean,
		onlySalesWithoutAdjustments: boolean,
		isNotPrinted: boolean,
		ordersWithCard?: boolean,
		flexZone?: string,
		limitDispatchDate?: number,
		//BACK 175
		acMeliOther?: string,
		deliveryMethod?: string	): AppThunkAction<KnownAction> => (dispatch, getState) => {
			const appState = getState();

			let stateNew = saleState == PageSaleState.ReadyForPackingAcNoMeli ? PageSaleState.ReadyForPacking : saleState

			if (appState) {
				let url = `${configuration.baseApiUrl}/Sales?fromDate=${fromDate.toDateString()}&toDate=${toDate.toDateString()}&onlyReopenSales=${onlyReopenSales}&excludeMeLiOrders=${excludeMeLiOrders}&excludePaymentLink=${excludePaymentLink}&excludeTransfers=${excludeTransfers}&saleState=${stateNew}&isInSearchAll=${isInSearchAll}&deliveryType=${deliveryType}&saleId=${saleId}&orderNumber=${orderNumber}&onlySalesWithoutAdjustments=${onlySalesWithoutAdjustments}&isNotPrinted=${isNotPrinted}`;
				if (userName != "") {
					url += `&userName=${userName}`;
				}

				if (ordersWithCard != undefined) {
					url += `&ordersWithCard=${ordersWithCard}`;
				}
				url += `&flexZone=${flexZone ? flexZone : ""}`;
				url += `&limitDispatchDate=${limitDispatchDate ? limitDispatchDate.toString() : "0"}`;

				//BACK 175
				url += `&type=${acMeliOther}`;
				url += `&deliveryMethod=${(deliveryMethod == "Expreso" || deliveryMethod == "Moto") ? deliveryMethod : ""}`;
				
				
				let onSuccess = (response: Response) => {
					(response.json() as Promise<Sales[]>)
						.then(data => {
							if (data != null) {
								dispatch({ type: "RECEIVE_SALES", sales: data });
							}
						})
					dispatch({
						type: "UPDATE_FILTERS",
						pageSaleState: isInSearchAll ? PageSaleState.All : saleState,
						filters: {
							fromDate: fromDate,
							toDate: toDate,
							userName: userName,
							deliveryType: deliveryType,
							onlyReopenSales: onlyReopenSales,
							excludeMeLiOrders: excludeMeLiOrders,
							excludePaymentLink: excludePaymentLink,
							excludeTransfers: excludeTransfers,
							ordersWithCard: ordersWithCard,
							currentPage: 1,
							saleId: saleId,
							orderNumber: orderNumber,
							pageSaleState: saleState,
							onlySalesWithoutAdjustments: onlySalesWithoutAdjustments,
							isNotPrinted: isNotPrinted,
							flexZone: flexZone ? flexZone : "",
							limitDispatchDate: limitDispatchDate ? limitDispatchDate : 0
						}
					});
				}
				requestHelper(url, 'GET', null, onSuccess, dispatch);
			}
		},
	getSalesInState: (deliveryType: string, saleState: PageSaleState): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();

		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/GetInState?saleState=${saleState}&deliveryType=${deliveryType}`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Sales[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_SALES", sales: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	getSalesForMassShipments: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();

		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/GetSalesForMassShipments`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Sales[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_SALES", sales: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	getProducts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Product`;
			let onSuccess = (response: Response) => {
				(response.json() as Promise<Product[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_PRODUCTS", products: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	refreshAllProducts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Product`;
			let onSuccess = (response: Response) => {
				(response.json() as Promise<Product[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_PRODUCTS", products: data });
							//window.location.reload();
						}
					})
			}
			requestHelper(url, 'PUT', null, onSuccess, dispatch);
		}
	},
	refreshProduct: (id: string, name: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			var new_str = name.replace('/', '|');
			let url = `${configuration.baseApiUrl}/Product/${id}/${new_str}`;
			let onSuccess = (response: Response) => {
				(response.json() as Promise<Product>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_PRODUCT_REFRESH", product: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	//BACK 111
	refreshHolidays: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Holidays`;
			let onSuccess = (response: Response) => {
				(response.json() as Promise<Holidays[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_HOLIDAYS", holidays: data });
							//window.location.reload();
						}
					})
			}
			requestHelper(url, 'PUT', null, onSuccess, dispatch);
		}
	},
	dismissProductRefreshed: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			dispatch({ type: "DISMISS_PRODUCT_REFRESH" });
		}
	},
	createLinkSale: (products: SelectedProduct[], userName: string, detail: string, isLink: boolean, fullName: string, isCash:boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let objectToSend = {
				user: userName,
				detail: detail,
				fullName: fullName,
				products: products.map((selectedProduct: SelectedProduct) => {
					return {
						quantity: selectedProduct.quantity,
						price: selectedProduct.product.price,
						sku: selectedProduct.product.name
					}
				})
			};
			let url = '';

			if (isLink)
				url = `${configuration.baseApiUrl}/Sales/Link`
			else if (isCash)
				url = `${configuration.baseApiUrl}/Sales/Cash`
			else
				url = `${configuration.baseApiUrl}/Sales/Transfer`;


			let onSuccess = (response: Response) => {
				(response.json() as Promise<any>)
					.then(data => {
						dispatch({ type: "RECEIVE_SALES_CREATED", amountSalesCreated: 1, saleId: data.id, containsmessages: 0, loadedWitouthDifference: false, duplicatesales: 0 });
					})

			};

			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);

		}
	},
	getSale: getSale,
	addDifference: (saleId: number, saleDetailId: number, saleDetailType: string, products: EditingSaleProduct[], payType: string | null, skuOriginal: string | null, isBreakdown: boolean, ignoreDifference: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let objectToSend = {
				id: saleDetailId,
				saleDetailType: saleDetailType,
				//PACKS y COMBOS
				SkuOriginal: skuOriginal,
				isBreakdown: isBreakdown,
				IgnoreDifference: ignoreDifference,
				products: products.map((selectedProduct: EditingSaleProduct) => {
					return {
						quantity: selectedProduct.quantity,
						price: selectedProduct.price,
						sku: selectedProduct.sku
					}
				})
			};
			let url = null;

			if (payType == 'L')
				url = `${configuration.baseApiUrl}/Sales/Difference/Link/${saleId}`;
			else if (payType == 'T')
				url = `${configuration.baseApiUrl}/Sales/Difference/Transfer/${saleId}`;
			else if (payType == 'C')
				url = `${configuration.baseApiUrl}/Sales/Difference/Cash/${saleId}`;
			else
				url = `${configuration.baseApiUrl}/Sales/Difference/Empty/${saleId}`;
				
			let onSuccess = (response: Response) => {
				
				dispatch({ type: "RECEIVE_SALES_CREATED", amountSalesCreated: 1, saleId: saleId, containsmessages: 0, loadedWitouthDifference: ignoreDifference, duplicatesales: 0});
				const untypedDispatch = <any>dispatch;
				untypedDispatch(getSale(saleId));
			}

			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	addOrder: (idSale: Sales | null, orders: MeLiOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && idSale != null) {
			let ordersToSend = orders.map((order: MeLiOrder) => order.orderNumber);
			let url = `${configuration.baseApiUrl}/Sales/MeLi/${idSale.id}`;

			let onSuccess = (response: Response) => {
				dispatch({ type: "RECEIVE_SALES_ORDER_ADDED", amountSalesCreated: 0 })
			}
			requestHelper(url, 'PUT', ordersToSend, onSuccess, dispatch);
		}
	},
	addOrderLink: (idSale: number, products: SelectedProduct[], userName: string, detail: string, isLink: boolean, isCash: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let objectToSend = {
				user: userName,
				detail: detail,
				products: products.map((selectedProduct: SelectedProduct) => {
					return {
						quantity: selectedProduct.quantity,
						price: selectedProduct.product.price,
						sku: selectedProduct.product.name
					}
				})
			};
			let url = '';
			if (isLink)
				url = `${configuration.baseApiUrl}/Sales/Link/${idSale}`;
			else if (isCash)
				url = `${configuration.baseApiUrl}/Sales/Cash/${idSale}`;
			else
				url = `${configuration.baseApiUrl}/Sales/Transfer/${idSale}`;

			let onSuccess = (response: Response) => {
				dispatch({ type: "RECEIVE_SALES_ORDER_ADDED", amountSalesCreated: 0 });
			};

			requestHelper(url, 'PUT', objectToSend, onSuccess, dispatch);

		}
	},
	addVoucher: (saleId: number, idTransfer: number, file: any, transferType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/AddVoucher?idTransfer=${idTransfer}&transferType=${transferType}`;
			let formData = new FormData();
			formData.append("file", file);
			let onSuccess = (response: Response) => {
				const untypedDispatch = <any>dispatch;
				untypedDispatch(getSale(saleId));
			};
			let authorization = `Bearer ${localStorage.getItem('token')}`;
			fetch(url,
				{
					method: 'PUT',
					cache: 'no-cache',
					body: formData,
					headers: {
						'Authorization': authorization
					}
				})
				.then(response => {
					if (response.ok) {
						onSuccess(response);
					}
					else {
						handleError(dispatch, response);
					}
				});

		}
	},
	toggleTransfer: (idTransfer: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/Transfer/${idTransfer}`;

				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getSale(currentSale.id));
				};

				requestHelper(url, 'DELETE', null, onSuccess, dispatch);
			}
		}
	},
	toggleCash: (idCash: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/Cash/${idCash}`;

				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getSale(currentSale.id));
				};

				requestHelper(url, 'DELETE', null, onSuccess, dispatch);
			}
		}
	},
	toggleLink: (idTransfer: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/Link/${idTransfer}`;

				//BACK 142
				let onSuccess = (response: Response) => {
					if (response.status == 207) {
						(response.json() as Promise<any>)
							.then(data => {
								dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
							});

						const untypedDispatch = <any>dispatch;
						untypedDispatch(getSale(currentSale.id));
					}
                    else {
						const untypedDispatch = <any>dispatch;
						untypedDispatch(getSale(currentSale.id));
                    }
				}

				requestHelper(url, 'DELETE', null, onSuccess, dispatch);
			}
		}
	},
	toggleMeLiOrder: (idTransfer: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/MeLiOrder/${idTransfer}`;

				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getSale(currentSale.id));
				};

				requestHelper(url, 'DELETE', null, onSuccess, dispatch);
			}
		}
	},
	toggleDifference: (idDifference: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/Difference/${idDifference}`;

				let onSuccess = (response: Response) => {
					if (response.status == 207) {
						(response.json() as Promise<any>)
							.then(data => {
								dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
							});

						const untypedDispatch = <any>dispatch;
						untypedDispatch(getSale(currentSale.id));
					}
                    else {
						const untypedDispatch = <any>dispatch;
						untypedDispatch(getSale(currentSale.id));
                    }
				};

				requestHelper(url, 'DELETE', null, onSuccess, dispatch);
			}
		}
	},
	removeAttachment: (idSaleDate: number, password: string, transferType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/RemoveVoucher/`;
				let objectToSend = {
					id: idSaleDate,
					password: password,
					transferType: transferType
				};
				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getSale(currentSale.id));
				};

				requestHelper(url, 'DELETE', objectToSend, onSuccess, dispatch);
			}
		}
	},
	getMessages: getMessages,
	sendMessages: (saleDetail: number, message: string, file: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Message/${saleDetail}`;
			if (file != null) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					let objectToSend = {
						message: message,
						file: reader.result,
						fileName: file.name
					};
					let onSuccess = (response: Response) => {
						const untypedDispatch = <any>dispatch;
						untypedDispatch(getMessages(saleDetail));
						dispatch({ type: "RECEIVE_MESSAGE_SENT" });
					}
					requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
				};
			}
			else {
				let objectToSend = {
					message: message
				};
				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getMessages(saleDetail));
					dispatch({ type: "RECEIVE_MESSAGE_SENT" });
				}
				requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
			}

		}
	},
	sendSaleMessage: (saleDetail: number, message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Message`;

			let objectToSend = {
				saleId: saleDetail,
				message: message
			};

			let onSuccess = (response: Response) => {
				const untypedDispatch = <any>dispatch;
				untypedDispatch(getSale(saleDetail));
				untypedDispatch(getClaimStatus(saleDetail));
			}
			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	confirmSale: (saleId: number, cuit: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Confirm`;
			let objectToSend = {
				Id: saleId,
				Invoice: cuit,
				SaleInformation: null
			};

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "RECEIVE_CONFIRMED_SUCCESS", newState: data.state });
							const untypedDispatch = <any>dispatch;
							untypedDispatch(getSale(saleId));
						})

				}
			};
			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	confirmSales: (sales: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/ConfirmMassive`;
			let onSuccess = (response: Response) => {
				if (response.status == 207) {
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "SHOW_CUSTOM_MESSAGE_POPUP", text: data.message });
						});
				}
				else
					dispatch({ type: "RECEIVE_CONFIRMED_SUCCESS", newState: 'ReadyForPacking' });
			};

			requestHelper(url, 'POST', sales, onSuccess, dispatch);
		}
	},
	//BACK 145
	confirmSaleInformation: (saleId: number, salesChannel: string, deliveryMethod: string, companyId: number,
		newCompany: boolean, companyName: string, companyAddress: string, companyTriplicateRemittance: boolean,
		clientId: number, businessName: string, address: string, provinceId: number, cityId: number,
		postalCode: string, email: string, documentType: string, cuil: string, insurance: string, telephone: string,
		invoice: string	): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let objCompany = !newCompany ? null : {
				Name: companyName,
				Address: companyAddress,
				TriplicateRemittance: companyTriplicateRemittance
			};

			let objClient = {
				Id: clientId,
				EMail: email,
				Name: businessName,
				Address: address,
				ProvinceId: provinceId,
				LocationId: cityId,
				PostalCode: postalCode,
				Telephone: telephone,
				DocumentType: documentType,
				DocumentNumber: cuil,
				Insurance: insurance
			};
			let objectSaleInformation = {
				SaleId: saleId,
				SalesChannel: salesChannel,
				DeliveryMethod: (deliveryMethod == "Local" ? DeliveryMethod.Local :
								deliveryMethod == "Rodriguez" ? DeliveryMethod.Rodriguez :
								deliveryMethod == "Moto" ? DeliveryMethod.Moto :
								DeliveryMethod.Expreso)	,
				CompanyId: companyId,
				Company: objCompany,
				Client: objClient
			};

			let objectToSend = {
				Id: saleId,
				Invoice: invoice,
				SaleInformation: objectSaleInformation
			};

			let url = `${configuration.baseApiUrl}/Sales/ConfirmInformation`;

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "RECEIVE_CONFIRMED_SUCCESS", newState: data.state });
							const untypedDispatch = <any>dispatch;
							untypedDispatch(getSale(saleId));
						})

				}
			};

			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	markAsBilled: (saleId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsBilled/${saleId}`;

			let onSuccess = (response: Response) => {
				{
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getSale(saleId));
				}
			};

			requestHelper(url, 'POST', null, onSuccess, dispatch);
		}
	},
	markSalesAsBilled: (sales: Sales[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsBilled`;

			let objectToSend = {
				saleIds: sales.map((sale: Sales) => sale.id)
			};

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "RECEIVE_SALES_BILLED", amountSalesBilled: data.count });
						})
				}
			};

			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	refreshPaymentStatus: (id: number, paymentType: string, paymentLink: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/IsPaymentLinkPayed/${paymentLink}`;

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							if (data.isPayed) {
								dispatch({ type: "REFRESH_PAYMENT_STATUS", id: id, paymentType: paymentType });
							}
						})
				}
			};

			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	getCsv: (file: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/MeLi/Csv`;
			if (file != null) {
				let formData = new FormData();
				formData.append("file", file);
				let onSuccessResult = (response: Response) => {
					(response.json() as Promise<{ [id: string]: string[]; }>)
						.then(data => {
							dispatch({ type: "RECEIVE_CSV_RESULT", result: data });
						})
				}

				let onSuccess = (response: Response) => {
					response.blob().then(function (blob: any) {
						return download(blob, "file.csv");
					});

					requestHelper(`${configuration.baseApiUrl}/MeLi/CSVResult`, 'GET', null, onSuccessResult, dispatch);
				};
				let authorization = `Bearer ${localStorage.getItem('token')}`;
				dispatch({ type: "BEGIN_REQUEST" });
				fetch(url,
					{
						method: 'POST',
						cache: 'no-cache',
						body: formData,
						headers: {
							'Authorization': authorization
						}
					})
					.then(response => {
						dispatch({ type: "END_REQUEST" });
						if (response.ok) {
							onSuccess(response);
						}
						else {
							handleError(dispatch, response);
						}
					});
			}

		}
	},
	dismissSuccessfullyBilled: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_SALES_BILLED" });
	},
	dismissConfirmSuccess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_CONFIRMED_SUCCESS" });
	},
	//BACK 287
	dismissConfirmBackReviewSuccess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_CONFIRMED_BACK_REVIEW_SUCCESS" });
	},
	dismissMessageSent: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_MESSAGE_SENT" });
	},
	dismissloadedWitouthDifference: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_LOADED_WITHOUT_DIFF" });
	},
	
	loadPackers: (spinner: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Packers`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Packer[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_PACKERS", packers: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch, spinner);
		};
	},
	getClaimStatus: getClaimStatus,
	markAsPacked: (saleId: number, packers: string[], packetCount: { [id: string]: string; }, hasChanges: boolean, password: string, driver: Driver | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsPacked/${saleId}`;

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							const untypedDispatch = <any>dispatch;
							dispatch({ type: "RECEIVE_PACKED_SUCCESS" });
							untypedDispatch(getSale(saleId));
						})

				}
			};

			let objectToSend = {
				packers: packers,
				packetCount: packetCount,
				hasChanges: hasChanges,
				password: password,
				idDriver: driver && driver != null ? driver.id : -1
			};
			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	dismissPackedSucess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_RECEIVE_PACKED_SUCCESS" });
	},
	markAsShipped: (saleId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsShipped/${saleId}`;

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							const untypedDispatch = <any>dispatch;
							dispatch({ type: "RECEIVE_SHIPPED_SUCCESS" });
							untypedDispatch(getSale(saleId));
						})

				}
			};
			requestHelper(url, 'PUT', null, onSuccess, dispatch);
		}
	},
	dismissShippedSucess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_SHIPPED_SUCCESS" });
	},
	reopenSale: (idSaleDate: number, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/Reopen/`;
				let objectToSend = {
					id: idSaleDate,
					password: password
				};
				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					dispatch({ type: "RECEIVE_REOPEN_SUCCESS" });
					untypedDispatch(getSale(currentSale.id));
				};

				requestHelper(url, 'PUT', objectToSend, onSuccess, dispatch);
			}
		}
	},
	//BACK 287
	reopenSaleNoPass: (saleId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/ReopenNoPass/${saleId}`;

			let onSuccess = (response: Response) => {
				dispatch({ type: "RECEIVE_CONFIRMED_BACK_REVIEW_SUCCESS" });
			};

			requestHelper(url, 'PUT', null, onSuccess, dispatch);
		}
	},
	generatePackageNumbers: (idSale: number, amount: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let currentSale = appState.salesState.currentSale;
				let url = `${configuration.baseApiUrl}/Sales/GeneratePackageNumber/${idSale}/${amount}`;

				let onSuccess = (response: Response) => {
					const untypedDispatch = <any>dispatch;
					untypedDispatch(getSale(currentSale.id));
				};

				requestHelper(url, 'GET', null, onSuccess, dispatch);
			}
		}
	},
	dismissReopenSaleSuccess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_REOPEN_SUCCESS" });
	},
	getMessageConfigurations: getMessageConfigurations,
	saveMessageConfiguration: (messageConfiguration: MessageConfiguration): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Message`;

			let onSuccess = (response: Response) => {
				const untypedDispatch = <any>dispatch;
				dispatch({ type: "RECEIVE_MESSAGE_CONFIGURATION_UPDATE" });
				untypedDispatch(getMessageConfigurations());
			};

			requestHelper(url, 'PUT', messageConfiguration, onSuccess, dispatch);
		}
	},
	dismissMessageConfigurationSuccess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			dispatch({ type: "DISMISS_MESSAGE_CONFIGURATION_UPDATE" });
		}
	},
	getHistory: (idSale: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			let url = `${configuration.baseApiUrl}/Sales/History/${idSale}`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<AuditLog[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_AUDIT_LOGS", auditLogs: data });
						}
					})
			};

			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	confirmStock: (orders: MeLiOrder[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let ordersToSend = orders.map((order: MeLiOrder) => order.orderNumber);
			let url = `${configuration.baseApiUrl}/MeLi/ConfirmStock`;

			let onSuccess = (response: Response) => {
				dispatch({ type: "RECEIVE_STOCK_CONFIRMED", amountOrderConfirmed: ordersToSend.length });
				if (appState.salesState) {
					dispatch({ type: "RECEIVE_MELI_ORDER", orders: appState.salesState.orders.filter((value: MeLiOrder) => !orders.includes(value)) });
				}
			}
			requestHelper(url, 'POST', ordersToSend, onSuccess, dispatch);
		}
	},
	dismissMessageStockConfirmed: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			dispatch({ type: "DISMISS_STOCK_CONFIRMED" });
		}
	},
	toggleOrderNotification: (idOrder: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			let currentSale = appState.salesState.currentSale;
			let url = `${configuration.baseApiUrl}/MeLi/ToggleNotification/${idOrder}`;

			let onSuccess = (response: Response) => {
				dispatch({ type: "ORDER_NOTIFICATION_TOGGLE", orderId: idOrder });
			};

			requestHelper(url, 'POST', null, onSuccess, dispatch);
		}
	},
	getIternalMessages: (saleId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/InternalMessage/${saleId}`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<SaleMessage[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_INTERNAL_SALE_MESSAGES", messages: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch, false);
		}
	},
	deleteSale: (idSaleDate: number, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.salesState != null) {
			if (appState.salesState.currentSale != null) {
				let url = `${configuration.baseApiUrl}/Sales`;
				let objectToSend = {
					id: idSaleDate,
					password: password
				};
				let onSuccess = (response: Response) => {
					dispatch({ type: "RECEIVE_DELETE_SUCCESS" });
				};

				requestHelper(url, 'DELETE', objectToSend, onSuccess, dispatch);
			}
		}
	},
	dismissDeleteSale: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "RECEIVE_DELETE_DISMISS" });
	},
	dismissCustomMessage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_CUSTOM_MESSAGE" });
	},
	dismissCustomMessagePopUp: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_CUSTOM_MESSAGE_POPUP" });
	},
	getPrintableLabel: (orderNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/MeLi/PrintableLabel/${orderNumber}`;

			let onSuccess = (response: Response) => {
				response.blob().then(function (blob: any) {
					return download(blob, orderNumber + ".txt");
				});
			};

			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	getPrintableLabel2: (orderNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/MeLi/PrintableLabel2/${orderNumber}`;

			let onSuccess = (response: Response) => {
				response.blob().then(function (blob: any) {
					return download(blob, orderNumber + ".txt");
				});
			};

			requestHelper(url, 'GET', null, onSuccess, dispatch);
		}
	},
	printSales: (sales: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsPrinted`;

			//if (sales.length == 1 && appState.salesState != null
			//	&& appState.salesState.currentSale != null
			//	&& appState.salesState.currentSale.isPrinted &&
			//	appState.salesState.currentSale.id == sales[0]) {
			//	url = `${configuration.baseApiUrl}/Sales/unMarkAsPrinted`;
			//}

			let onSuccess = (response: Response) => {
				dispatch({ type: "SALES_PRINTED", sales: sales });
			}
			requestHelper(url, 'POST', sales, onSuccess, dispatch);
		}
	},
	printSalesChk: (sales: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsPrinted`;

			if (sales.length == 1 && appState.salesState != null
				&& appState.salesState.currentSale != null
				&& appState.salesState.currentSale.isPrinted &&
				appState.salesState.currentSale.id == sales[0]) {
				url = `${configuration.baseApiUrl}/Sales/unMarkAsPrinted`;
			}

			let onSuccess = (response: Response) => {
				dispatch({ type: "SALES_PRINTED_CHK", sales: sales });
			}
			requestHelper(url, 'POST', sales, onSuccess, dispatch);
		}
	},
	printTags: (sales: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/MarkAsTagPrinted`;

			//if (sales.length == 1 && appState.salesState != null
			//	&& appState.salesState.currentSale != null
			//	&& appState.salesState.currentSale.isPrinted &&
			//	appState.salesState.currentSale.id == sales[0]) {
			//	url = `${configuration.baseApiUrl}/Sales/unMarkAsPrinted`;
			//}

			let onSuccess = (response: Response) => {
				dispatch({ type: "SALES_TAG_PRINTED", printOk : true});
			}
			requestHelper(url, 'POST', sales, onSuccess, dispatch);
		}
	},
	//BACK 115
	printTagsMassive: (sales: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Meli/GetPrintableLableMasive`;

			//if (sales.length == 1 && appState.salesState != null
			//	&& appState.salesState.currentSale != null
			//	&& appState.salesState.currentSale.isPrinted &&
			//	appState.salesState.currentSale.id == sales[0]) {
			//	url = `${configuration.baseApiUrl}/Sales/unMarkAsPrinted`;
			//}

			//let onSuccess = (response: Response) => {
			//	dispatch({ type: "SALES_TAG_PRINTED", printOk: true });
			//}

			//Etiquetas_MES-DD_HH.MM.SS_hs
			let date = new Date();
			let longMonth = date.toLocaleString('es-AR', { month: 'long' }); /* June */
			let fileName = "Etiquetas_" + longMonth + "-" + date.getUTCDate() + "_" + date.toLocaleTimeString('es-AR').split(' ')[0].replace(":", ".").replace(":", ".") + "_hs.zip";

			let onSuccess = (response: Response) => {
				response.blob().then(function (blob: any) {
					return download(blob, fileName);
				});
			};

			requestHelper(url, 'POST', sales, onSuccess, dispatch);
		}
	},
	getDrivers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Drivers`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Driver[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_DRIVERS", drivers: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch);
		};
	},
	//BACK-193
	getLogistics: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Logistics`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Logistic[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_LOGISTICS", logistics: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch);
		};
	},
	//BACK145
	getProvinces: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Provinces`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Province[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_PROVINCES", provinces: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch, false);
		};
	},
	getCities: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Cities`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<City[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_CITIES", cities: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch, false);
		};
	},
	getCompanies: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/Companies`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Company[]>)
					.then(data => {
						if (data != null) {
							dispatch({ type: "RECEIVE_COMPANIES", companies: data });
						}
					})
			}
			requestHelper(url, 'GET', null, onSuccess, dispatch, false);
		};
	},
	saveMassShipment: (shipments: Shipment[], deliveryType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/SaveMassShipment/${deliveryType}`;
			let sh = [];
			for (var i = 0; i < shipments.length; i++) {
				if (shipments[i] != null && shipments[i].sale != null && shipments[i].sale.id != 0)
					sh.push(
						{
							saleId: shipments[i].sale.id,
							bulks: deliveryType == "Flex" ? shipments[i].bulks : 0,
							packersIds: shipments[i].packers.map((pakcer: Packer) => { return pakcer.name }),
							driverId: (deliveryType == "Flex" || deliveryType == "ToBeAgreed") ? shipments[i].driver.id : 0,
						});
			}

			let onSuccess = (response: Response) => {
				{
					dispatch({ type: "SAVE_MASS_SHIPMENT" });
				}
			};
			requestHelper(url, 'POST', sh, onSuccess, dispatch);
		}
	},
	//BACK 155
	getReport: (fromDate: Date, toDate: Date, reportType: string, logisticId: number,
		saleState: PageSaleState,
		isInSearchAll: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
			const appState = getState();

			if (appState) {
				let url = `${configuration.baseApiUrl}/Reports?fromDate=${fromDate.toDateString()}&toDate=${toDate.toDateString()}&reportType=${reportType}&logisticId=${logisticId}&saleState=${saleState}&isInSearchAll=${isInSearchAll}`;

				let onSuccess = (response: Response) => {
					response.blob().then(function (blob: any) {

						const todayDate = fromDate;
						var d = todayDate.getDate();
						var dd = "";
						var m = todayDate.getMonth() + 1;
						var mm = "";
						var yyyy = todayDate.getFullYear();
						if (d < 10) {
							dd = '0' + d;
						} else { dd = d.toString(); }
						if (m < 10) {
							mm = '0' + m;
						} else { mm = m.toString(); }
						var formatToday = yyyy + '-' + mm + '-' + dd;


						return download(blob, "Reporte_" + formatToday + "_" + reportType + ".xlsx");
					});
					
				};
				requestHelper(url, 'GET', null, onSuccess, dispatch);
				dispatch({
					type: "UPDATE_FILTERS",
					pageSaleState: PageSaleState.Report,
					filters: {
						fromDate: fromDate,
						toDate: toDate,
						reportType: reportType,
						logisticId: logisticId
						//pageSaleState: PageSaleState.Report
					}
				});
			}
		},
	printPDF: (salesIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/GetSalesForPDF`;

			let onSuccess = (response: Response) => {
				(response.json() as Promise<Sales[]>).then(data => {
					dispatch({
						type: "PRINT_PDF", Sales: data, urlBaseQR: `${configuration.baseApiUrl}/Sales/QR/` });
				});
			};
			requestHelper(url, 'Post', salesIds, onSuccess, dispatch);
		}
	},
	checkMessageAndConfirm: (sales: Sales[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState) {
			let url = `${configuration.baseApiUrl}/Sales/CheckMessageAndConfirm`;

			let objectToSend = {
				saleIds: sales.map((sale: Sales) => sale.id)
			};

			let onSuccess = (response: Response) => {
				{
					(response.json() as Promise<any>)
						.then(data => {
							dispatch({ type: "RECEIVE_CONFIRMED_SALES_WITHOUT_MESSAGE", countConfirmedSalesWithOutMessage: data.countConfirmedSalesWithOutMessage });
						})
				}
			};

			requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
		}
	},
	dismissMessageAndConfirm: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({ type: "DISMISS_CONFIRMED_SALES_WITHOUT_MESSAGE" });
	},
};
