import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { UserState, ReviewFilter  } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState, MeLiSaleDetailContainer, SaleProduct, SaleMessage, Packer, Shipment, Province, City, Company} from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster"
import { Position, Tooltip, Popover, Dialog, Classes } from '@blueprintjs/core';
import CommonSale from './commonSale';
import { debug } from 'console';
import ReactHtmlParser from 'react-html-parser';
import QRCode from "react-qr-code";
import { configuration } from '../../store/configureStore';

const coversorNumeroALetras = require('numero-a-letras');

declare var require: any;

type ReviewSalesProps =
	UserState
	& SalesState
	& typeof commonActionCreators.actionCreators
	& typeof salesActionCreators.actionCreators
	& RouteComponentProps<{ startDateIndex: string }>
	& { pageSaleState: PageSaleState };


class ReviewSales extends React.Component<ReviewSalesProps> {
	private getTwoWeeksAgo = () => {
		const date = new Date();
		date.setDate(date.getDate() - 15);
		return date;
	}

	private itemsPerPage = () => this.props.user.itemsPerPage;
	
	state: {
		fromDate: Date,
		toDate: Date,
		userName: string,
		deliveryType: string,
		onlyReopenSales: boolean,
		excludeMeLiOrders: boolean,
		onlySalesWithoutAdjustments: boolean,
		isNotPrinted: boolean,
		excludePaymentLink: boolean,
		excludeTransfers: boolean,
		ordersWithCard?: boolean,
		selectedSales: Sales[],
		currentPage: number,
		pageSaleState: PageSaleState,
		saleId: number,
		orderNumber: number,
		showConfirmationModal: boolean,
		flexZone: string,
		limitDispatchDate: number,
		showPopUp: boolean,
		messagePopup: string,
		//BACK 157
		package: number,
		currentShipments: Array<Shipment>,
		//BACK 287
		showReturnInReviewConfirmation: boolean,
		saleIdInReview: number
	} = {
			fromDate: this.getTwoWeeksAgo(),
			toDate: new Date(),
			userName: "",
			deliveryType: "",
			onlyReopenSales: false,
			excludeMeLiOrders: false,
			excludePaymentLink: false,
			excludeTransfers: false,
			selectedSales: [],
			currentPage: 1,
			ordersWithCard: undefined,
			pageSaleState: PageSaleState.All,
			saleId: 0,
			orderNumber: 0,
			onlySalesWithoutAdjustments: false,
			isNotPrinted: false,
			showConfirmationModal: false,
			flexZone: "",
			limitDispatchDate: 0,
			showPopUp: false,
			messagePopup: "",
			package: 0,
			currentShipments: Array<Shipment>(),
			showReturnInReviewConfirmation: false,
			saleIdInReview: 0
		};

	componentDidMount() {
		if (this.props.packers.length <= 0) {
			this.props.loadPackers(false);
		}

		if (this.props.provinces.length <= 0) {
			this.props.getProvinces();
		}

		if (this.props.cities.length <= 0) {
			this.props.getCities();
		}

		if (this.props.companies.length <= 0) {
			this.props.getCompanies();
		}

		let filters = this.props.filters[this.props.pageSaleState] as ReviewFilter;

		if (filters != undefined) {
			this.setState({
				fromDate: filters.fromDate,
				toDate: filters.toDate,
				userName: filters.userName,
				deliveryType: filters.deliveryType,
				onlyReopenSales: filters.onlyReopenSales,
				excludeMeLiOrders: filters.excludeMeLiOrders,
				excludePaymentLink: filters.excludePaymentLink,
				excludeTransfers: filters.excludeTransfers,
				onlySalesWithoutAdjustments: filters.onlySalesWithoutAdjustments,
				currentPage: filters.currentPage,
				ordersWithCard: filters.ordersWithCard,
				saleId: filters.saleId,
				orderNumber: filters.orderNumber,
				pageSaleState: filters.pageSaleState,
				isNotPrinted: filters.isNotPrinted,
				flexZone: filters.flexZone,
				limitDispatchDate: filters.limitDispatchDate
			}, () => {
				if (this.props.sales.length > 0) {
					this.search();
				}
			});
		}
		else {
			if (this.props.sales.length > 0) {
				this.search();
			}
		}
	}

	private updateBulks(bultos: string, sale: Sales, index: number) {
		var cs = this.state.currentShipments;
		if (cs.length < index) {
			for (var i = cs.length; i < index; i++) {
				cs.push({} as Shipment);
				//cs[i].sale = null;
			}
		}

		var agregar = true;
		for (var i = 0; i < cs.length; i++) {
			if (i == index) {
				if (typeof (cs[index].sale) !== 'undefined')  //cs[index].sale == null) {
				{
					if (cs[index].sale.id == sale.id) {
						agregar = false;
						cs[index].bulks = bultos != "" ? parseInt(bultos) : 0;
					}
				}
				else {
					agregar = false;
					cs[index].sale = sale;
					cs[index].bulks = bultos != "" ? parseInt(bultos) : 0;
					
				}
			}
		}
		if (agregar) {
			const cs = this.state.currentShipments;
			cs.push({} as Shipment);
			cs[index].sale = sale;
			cs[index].bulks = bultos != "" ? parseInt(bultos) : 0;
		}

		this.setState({ currentShipments: cs });
		let r = this.refs;
	}

	componentWillReceiveProps(nextProps: ReviewSalesProps) {
		if (nextProps.salesCreatedSuccessfully) {
			AppToaster.show({ intent: "success", message: "Venta/s creada/s exitosamente" });
			this.props.dismissSuccessfullyCreated();
		}

		if (nextProps.salesBilledSuccessfully) {
			AppToaster.show({ intent: "success", message: `Se han marcado ${nextProps.amountSalesBilled} venta/s como facturadas` });
			this.props.dismissSuccessfullyBilled();
		}

		if (nextProps.confirmedSuccess) {
			AppToaster.show({ intent: "success", message: "Ventas enviadas a empaquetar" });
			this.props.dismissConfirmSuccess();
			this.search();
		}

		//BACK 287
		if (nextProps.confirmedBackReviewSuccess) {
			AppToaster.show({ intent: "success", message: "Venta enviada a revisión" });
			this.props.dismissConfirmBackReviewSuccess();
			this.search();
		}

		//BACK
		if (nextProps.receiveSalesSuccessfully) {
			AppToaster.show({ intent: "success", message: `Se han enviado ${nextProps.countConfirmedSalesWithOutMessage} venta/s a administración` });
			this.props.dismissMessageAndConfirm();
			if (nextProps.countConfirmedSalesWithOutMessage > 0) {
				this.search();
            }
			
		}

		if (nextProps.customMessagePopUp.length > 0) {
			this.setState({
				showPopUp: true,
				messagePopup: nextProps.customMessagePopUp
			});
			this.props.dismissCustomMessagePopUp();
			this.search();
		}
	}
	//BACK 157

	private onFormSubmit = (evt: any) => {
		evt.preventDefault();
		
		this.search();
	}

	private search() {
		this.setState({ selectedSales: [], currentPage: 1 });
		let isInSearch = this.props.pageSaleState == PageSaleState.All;
		let state = this.state.pageSaleState;
		if (!isInSearch) {
			state = this.props.pageSaleState;
		}

		this.state.currentShipments = Array<Shipment>();

		//BACK 175
		let acMeliOther = (this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli') ? 'NC' : (this.props.location.pathname == '/ventasListasParaEmpaquetar') ? 'ML' : '';
		let deliveryMethod = this.state.deliveryType;
		
		
		this.props.getSales(this.state.fromDate,
			this.state.toDate,
			this.state.userName != null ? this.state.userName.trim() : this.state.userName ,
			this.state.onlyReopenSales,
			this.state.excludeMeLiOrders,
			this.state.excludePaymentLink,
			this.state.excludeTransfers,
			((this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli') ? "" : this.state.deliveryType),
			this.state.saleId,
			this.state.orderNumber,
			state,
			isInSearch,
			this.state.onlySalesWithoutAdjustments,
			this.state.isNotPrinted,
			this.state.ordersWithCard,
			this.state.flexZone,
			this.state.limitDispatchDate,
			acMeliOther,
			deliveryMethod
		);

		//if (this.state.pageSaleState) {
		//    if (this.state.pageSaleState == PageSaleState.All) {
		//        if (this.props.user.role == "Packer") {
		//            state = PageSaleState.ReadyForPacking;
		//        }
		//        else if (this.props.user.role == "Administrative") {
		//            state = PageSaleState.InAdministration;
		//        }
		//        else {
		//            state = PageSaleState.InReview;
		//        }
		//    }
		//    else {
		//        state = this.state.pageSaleState;
		//    }
		//}
		//else {
		//    state = this.props.pageSaleState;
		//}

		
	}

	private onDateChange = (input: string, date: Date) => {
		this.setState({ [input]: date })
	}

	private pushSelectedSales(sale: Sales) {
		let selectedSales = this.state.selectedSales;
		if (selectedSales.includes(sale)) {
			selectedSales = selectedSales.filter((value: Sales) => sale != value);
		}
		else {
			selectedSales.push(sale);
		}

		this.setState({ selectedSales: selectedSales });
	}

	private togglerSelectedAllSales() {
		if (this.state.selectedSales.length == this.props.sales.length) {
			this.setState({ selectedSales: [] });
		}
		else {
			this.setState({ selectedSales: [...this.props.sales] });
		}
	}

	private currentSales = () => this.props.sales.slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);


	private handleRadioChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		if (changeEvent.target.value == undefined || changeEvent.target.value == "") {
			this.setState({
				ordersWithCard: undefined
			});
		}
		else {
			this.setState({
				ordersWithCard: changeEvent.target.value == "true"
			});
		}

	}

	private handleRadioChangeFLD = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		if (changeEvent.target.value == undefined || changeEvent.target.value == "") {
			this.setState({
				limitDispatchDate: undefined
			});
		}
		else {
			this.setState({
				limitDispatchDate: parseInt(changeEvent.target.value)
			});
		}
	}

	private handleCloseConfirmation = () => {
		this.setState(
			{
				showConfirmationModal: false,
			});
	}

	private handleClosePopup = () => {
		this.setState({ showPopUp: false, messagePopup: "" });
	}

	private popupDialog = () => {
		return <Dialog
			//icon="info-sign"
			onClose={this.handleClosePopup}
			//title="Confirmación"
			isOpen={this.state.showPopUp}
			className="naranja"
		>
			<div className={Classes.DIALOG_BODY}>
				<p>{ReactHtmlParser(this.state.messagePopup)}</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ justifyContent: "center" }}>
					<Button onClick={this.handleClosePopup} color="primary">Aceptar</Button>
				</div>
			</div>
		</Dialog>
	}

	private getFilterOptions() {
		let result = [];

		//if (this.props.user.role == "Admin"
		//    || this.props.user.role == "Seller"
		//    || this.props.user.role == "SellerLead") {
		//    result.push(<option value={PageSaleState.InReview}>En revisión</option>);
		//    result.push(<option value={PageSaleState.InAdministration}>En administración</option>);
		//    result.push(<option value={PageSaleState.ReadyForPacking}>Lista para empaquetar</option>);
		//    result.push(<option value={PageSaleState.Packed}>Empaquetada</option>);
		//    result.push(<option value={PageSaleState.Dispatched}>Despachada</option>);
		//    result.push(<option value={PageSaleState.Cancelled}>Cancelada</option>);
		//}
		//else if (this.props.user.role == "Administrative") {
		//    result.push(<option value={PageSaleState.InAdministration}>En administración</option>);
		//    result.push(<option value={PageSaleState.ReadyForPacking}>Lista para empaquetar</option>);
		//    result.push(<option value={PageSaleState.Packed}>Empaquetada</option>);
		//    result.push(<option value={PageSaleState.Dispatched}>Despachada</option>);
		//}
		//else if (this.props.user.role == "Packer") {
		//    result.push(<option value={PageSaleState.ReadyForPacking}>Lista para empaquetar</option>);
		//    result.push(<option value={PageSaleState.Packed}>Empaquetada</option>);
		//    result.push(<option value={PageSaleState.Dispatched}>Despachada</option>);
		//}

		result.push(<option key={"sPSS" + PageSaleState.All} value={PageSaleState.All}>Seleccione una opción...</option>);
		result.push(<option key={"sPSS" + PageSaleState.InReview} value={PageSaleState.InReview}>En revisión (recién creada)</option>);
		result.push(<option key={"sPSS" + PageSaleState.InAdministration} value={PageSaleState.InAdministration}>En administración</option>);
		//BACK 77
		result.push(<option key={"sPSS" + PageSaleState.ReadyForPacking} value={PageSaleState.ReadyForPacking}>Lista para imprimir</option>);
		result.push(<option key={"sPSS" + PageSaleState.Packed} value={PageSaleState.Packed}>Empaquetada</option>);
		result.push(<option key={"sPSS" + PageSaleState.Dispatched} value={PageSaleState.Dispatched}>Despachada</option>);
		result.push(<option key={"sPSS" + PageSaleState.Cancelled} value={PageSaleState.Cancelled}>Cancelada</option>);
		return result;
	}

	getInt(valueString?: string) {
		if (valueString != undefined && valueString != null) {

			let value = valueString == "" ? 0 : parseInt(valueString);

			if (isNaN(value) || value < 0) {
				value = 0;
			}
			return value;
		}
		return 0;
	}

	private getIconTitle() {
		if (this.props.pageSaleState == PageSaleState.All) {
			return <><i className="mdi mdi-magnify icon-title mr-2"></i>Búsqueda de Ventas por Estado</>;
		}
		if (this.props.pageSaleState == PageSaleState.InReview) {
			return <><i className="mdi mdi-briefcase-check icon-title mr-2"></i>Revisión de Ventas</>;
		}
		if (this.props.pageSaleState == PageSaleState.InAdministration) {
			return <><i className="mdi mdi-cash-multiple icon-title mr-2"></i>Revisar Ventas en Administración</>;
		}
		//BACK 157 se agrega this.props.location.pathname
		if (this.props.pageSaleState == PageSaleState.ReadyForPacking /*&& this.props.location.pathname == '/ventasListasParaEmpaquetar'*/) {
			return <><i className="mdi mdi-printer icon-title mr-2"></i>Resumenes y etiquetas</>;
		}
		if (this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli /*&& this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli'*/) {
			return <><i className="mdi mdi-truck-fast icon-title mr-2"></i>Expresos y motos</>;
		}
		if (this.props.pageSaleState == PageSaleState.Packed) {
			return <><i className="mdi mdi-package-variant-closed icon-title mr-2"></i>Revisión de Ventas</>;
		}
	}

	private renderHeader = () => {
		return <Card>
			<Card.Body>
				<h4 className="card-title">
					{this.getIconTitle()}
				</h4>
				<p className="card-description"> Fecha de creación de la venta </p>
				<Form onSubmit={this.onFormSubmit}>
					<Row>
						<Col md={3} className="form-group">
							<Row>
								<Col md={6} >
									<label className="col-form-label">Fecha desde</label>
								</Col>
								<Col md={6}>
									<DatePicker className="form-control w-100"
										selected={this.state.fromDate}
										onChange={(date: Date) => { this.onDateChange('fromDate', date) }}
										dateFormat="dd/MM/yyyy"
									/>
								</Col>
							</Row>
						</Col>
						<Col md={3} className="form-group">
							<Row>
								<Col md={6}>
									<label className="col-form-label">Fecha hasta</label>
								</Col>
								<Col md={6}>
									<DatePicker className="form-control w-100"
										selected={this.state.toDate}
										onChange={(date: Date) => { this.onDateChange('toDate', date) }}
										dateFormat="dd/MM/yyyy"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					{
						/*BACK 157 se agrega this.props.location.pathname == '/ventasListasParaEmpaquetar'*/
						this.props.pageSaleState == PageSaleState.ReadyForPacking ? //&& this.props.location.pathname == '/ventasListasParaEmpaquetar' ?

							<Row className="pt-2 pb-3">
								<Col md={3} className="form-group">
									<label className="col-form-label">Fecha límite para despachar</label>
								</Col>
								<Col md={6} className="form-check">
									<Row>
										<Col md={3}>
											<label className="form-check-label" style={(this.state.limitDispatchDate == 1 ? { "textDecoration": "underline" } : {})}>
												<input type="radio" className="form-check-input"
													name="fldRadio"
													id="fldRadio1"
													value={1}
													onChange={this.handleRadioChangeFLD}
													checked={this.state.limitDispatchDate == 1}
												/>
												<i className="input-helper"></i>
												<span>Demoradas</span>
											</label>
										</Col>
										<Col md={2}>
											<label className="form-check-label" style={(this.state.limitDispatchDate == 2 ? { "textDecoration": "underline" } : {})}>
												<input type="radio" className="form-check-input"
													name="fldRadio"
													id="fldRadio2"
													value={2}
													onChange={this.handleRadioChangeFLD}
													checked={this.state.limitDispatchDate == 2}
												/>
												<i className="input-helper"></i>
												<span>Hoy</span>
											</label>
										</Col>
										<Col md={3}>
											<label className="form-check-label" style={(this.state.limitDispatchDate == 3 ? { "textDecoration": "underline" } : {})}>
												<input type="radio" className="form-check-input"
													name="fldRadio"
													id="fldRadio3"
													value={3}
													onChange={this.handleRadioChangeFLD}
													checked={this.state.limitDispatchDate == 3}
												/>
												<i className="input-helper"></i>
												<span>Mañana</span>
											</label>
										</Col>
										<Col md={3}>
											<label className="form-check-label" style={(this.state.limitDispatchDate == 0 ? { "textDecoration": "underline" } : {})}>
												<input type="radio" className="form-check-input"
													name="fldRadio"
													id="fldRadio4"
													value={0}
													onChange={this.handleRadioChangeFLD}
													checked={this.state.limitDispatchDate == 0}
												/>
												<i className="input-helper"></i>
												<span>Indistinto</span>
											</label>
										</Col>
									</Row>
								</Col>
							</Row> :
							<></>
					}
					<Row>
						<Col className="form-group" md={6}>
							<Label>
								Usuario de ML o Email
                            </Label>
							<Form.Control type="text" className="form-control" id="userName"
								placeholder="Usuario o email"
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ userName: evt.currentTarget.value })}
								value={this.state.userName} />
						</Col>
						<Col className="form-group pt-4" md={6}>
							<div className="form-check">
								<label className="form-check-label text-muted">
									<input type="checkbox" className="form-check-input" checked={this.state.onlyReopenSales} onChange={(evt: ChangeEvent) => this.setState({ onlyReopenSales: !this.state.onlyReopenSales })} />
									<i className="input-helper"></i>
									<span>Ver solo Ventas que fueron reabiertas</span>
								</label>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="form-group" md={6}>
							<Label>
								# Venta
                            </Label>
							<Form.Control type="number" max={2147483647} min={0} className="form-control" id="saleId"
								placeholder="# Venta"
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ saleId: this.getInt(evt.currentTarget.value) })}
								value={this.state.saleId != 0 ? this.state.saleId.toString() : ''} />
						</Col>
						{this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli ?
							<Col className="form-group pt-4" md={6}>
								<div className="form-check">
									<label className="form-check-label text-muted">
										<input type="checkbox" className="form-check-input" checked={this.state.isNotPrinted} onChange={(evt: ChangeEvent) => this.setState({ isNotPrinted: !this.state.isNotPrinted })} />
										<i className="input-helper"></i>
										<span>Resumen sin imprimir</span>
									</label>
								</div>
							</Col>
							: <></>
						}
						{this.props.pageSaleState == PageSaleState.InAdministration ?
							<Col className="form-group pt-4" md={6}>
								<div className="form-check">
									<label className="form-check-label text-muted">
										<input type="checkbox" className="form-check-input" checked={this.state.onlySalesWithoutAdjustments} onChange={(evt: ChangeEvent) => this.setState({ onlySalesWithoutAdjustments: !this.state.onlySalesWithoutAdjustments })} />
										<i className="input-helper"></i>
										<span>Ventas sin modificaciones</span>
									</label>
								</div>
							</Col> :
							<></>}
					</Row>
					<Row>
						<Col className="form-group" md={3}>
							<Label>
								# Órden
                            </Label>
							<Form.Control type="number" className="form-control" id="orderNumber" placeholder="# Órden"
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ orderNumber: this.getInt(evt.currentTarget.value) })}
								value={this.state.orderNumber != 0 ? this.state.orderNumber.toString() : ''} />
						</Col>
						<Col className="form-group" md={3}>
							<Label>
								Forma de entrega
                            </Label>
							{/*BACK 157  se agregan IFs*/
								this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli ? // && this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli' ?
								<select className="form-control"
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ deliveryType: evt.currentTarget.value })}
									value={this.state.deliveryType}>
									<option value="">Seleccione una opción...</option>
									<option value="Expreso">Expreso</option>
									<option value="Moto">Moto</option>
								</select> :
							this.props.pageSaleState == PageSaleState.ReadyForPacking ? //&& this.props.location.pathname == '/ventasListasParaEmpaquetar' ?
								<select className="form-control"
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ deliveryType: evt.currentTarget.value })}
									value={this.state.deliveryType}>
									<option value="">Seleccione una opción...</option>
										<option value="Collect">Colecta</option>
										<option value="Flex">Flex</option>
								</select>
									:
								<select className="form-control"
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ deliveryType: evt.currentTarget.value })}
									value={this.state.deliveryType}>
									<option value="">Seleccione una opción...</option>
									<option value="Collect">Colecta</option>
									<option value="Flex">Flex</option>
									<option value="ToBeAgreed">A Convenir</option>
								</select>
							}
						</Col>
						{
							(this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli) && this.state.deliveryType == "Flex" ?
								<Col className="form-group" md={3}>
									<Label>
										Zona Flex
                                    </Label>

									<select className="form-control"
										onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ flexZone: evt.currentTarget.value })}
										value={this.state.flexZone}>
										<option value="">Seleccione una opción...</option>
										<option value="Norte">Norte</option>
										<option value="Sur">Sur</option>
										<option value="Oeste">Oeste</option>
										<option value="CABA">CABA</option>
									</select>
								</Col> :
								<></>
						}
					</Row>
					<Row>
						<Col className="form-group" md={6}>
							<label>Solo Ventas que contengan</label>
							<div className="form-check">
								<label className="form-check-label text-muted">
									<input type="checkbox" className="form-check-input" checked={this.state.excludeMeLiOrders} onChange={(evt: ChangeEvent) => this.setState({ excludeMeLiOrders: !this.state.excludeMeLiOrders })} />
									<i className="input-helper"></i>
									<span>Órdenes</span>
								</label>
							</div>
							<div className="form-check">
								<label className="form-check-label text-muted">
									<input type="checkbox" className="form-check-input" checked={this.state.excludePaymentLink} onChange={(evt: ChangeEvent) => this.setState({ excludePaymentLink: !this.state.excludePaymentLink })} />
									<i className="input-helper"></i>
									<span>Links de pago</span>
								</label>
							</div>
							<div className="form-check">
								<label className="form-check-label text-muted">
									<input type="checkbox" className="form-check-input" checked={this.state.excludeTransfers} onChange={(evt: ChangeEvent) => this.setState({ excludeTransfers: !this.state.excludeTransfers })} />
									<i className="input-helper"></i>
									<span>Transferencias</span>
								</label>
							</div>
						</Col>
						{this.state.excludeMeLiOrders ? <Col className="form-group" md={6}>
							<div>
								<div className="form-check">
									<label className="form-check-label">
										<input type="radio" className="form-check-input"
											name="hasCardRadio"
											id="hasCardRadio1"
											value={'false'}
											onChange={this.handleRadioChange}
											checked={this.state.ordersWithCard != undefined && !this.state.ordersWithCard} />
										<i className="input-helper"></i>
										<span>Solo órdenes sin carrito</span>
									</label>
								</div>
							</div>
							<div>
								<div className="form-check">
									<label className="form-check-label">
										<input type="radio" className="form-check-input"
											name="hasCardRadio"
											id="hasCardRadio2"
											value={'true'}
											onChange={this.handleRadioChange}
											checked={this.state.ordersWithCard != undefined && this.state.ordersWithCard} />
										<i className="input-helper"></i>
										<span>Solo órdenes con carrito</span>
									</label>
								</div>
							</div>
							<div>
								<div className="form-check">
									<label className="form-check-label">
										<input type="radio" className="form-check-input"
											name="hasCardRadio"
											id="hasCardRadio3"
											value={undefined}
											onChange={this.handleRadioChange}
											checked={this.state.ordersWithCard == undefined} />
										<i className="input-helper"></i>
										<span>Indistinto</span>
									</label>
								</div>
							</div>
						</Col>
							:
							<></>}

					</Row>
					{this.props.pageSaleState == PageSaleState.All ?
						<Row>
							<Col md={12} className="form-group">
								<label>Estado</label>
								<select className="form-control"
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ pageSaleState: evt.currentTarget.value })}
									value={this.state.pageSaleState}>
									{this.getFilterOptions()}
								</select>
							</Col>
						</Row>
						: <></>
					}
					<Row>
						<Col md={12}>
							<button type="submit" className="btn btn-primary mr-2">Buscar</button>
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	}

	private getDeliveryType(sale: Sales) {
		let hasCollect = false;
		let hasFlex = false;
		let hasToBeAgreed = false;

		if (sale.meLiSaleDetails != null) {
			sale.meLiSaleDetails.forEach((meliOrder: MeLiSaleDetailContainer) => {
				if (meliOrder.deliveryType == "Collect") {
					hasCollect = true;
				}
				if (meliOrder.deliveryType == "Flex") {
					hasFlex = true;
				}
				if (meliOrder.deliveryType == "ToBeAgreed") {
					hasToBeAgreed = true;
				}
			});
		}

		if (hasToBeAgreed &&
			!hasCollect &&
			!hasFlex) {
			return "A Convenir";
		}

		if (hasFlex) {
			return "Moto";
		}
		if (hasCollect) {
			return "Colecta";
		}

		return "Fuera de ML";
	}

	private getStringDate(fecha: Date) {
		let options: Intl.DateTimeFormatOptions = {
			weekday: "long", day: "numeric", month: "numeric"
		};

		var fechaDate = new Date(fecha);
		var fechaStr = fechaDate.toLocaleDateString("es-ES", options).replace(",", "");;
		return this.capitalizeFirstLetter(fechaStr);
	}

	private capitalizeFirstLetter(valStr: String) {
		return valStr.charAt(0).toUpperCase() + valStr.slice(1);
	}

	private getDateWithHour(fecha: Date) {
		let options: Intl.DateTimeFormatOptions = {
			day: "numeric", month: "numeric",year:"numeric", hour : "numeric",minute: "numeric"
		};

		var fechaDate = new Date(fecha);
		return fechaDate.toLocaleDateString("es-ES", options).replace(",", "");;
    }

	//BACK 287
	private returnInReviewConfirmation = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showReturnInReviewConfirmation: false })}
			title="Confirmación"
			isOpen={this.state.showReturnInReviewConfirmation}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>La venta { this.state.saleIdInReview } esta en el Deposito y volverá a estar EN REVISION ¿confirma el cambio?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showReturnInReviewConfirmation: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						if (this.state.saleIdInReview != 0) {
							this.props.reopenSaleNoPass(this.state.saleIdInReview);
						}
						this.setState({ showReturnInReviewConfirmation: false});
					}}>Confirmar</Button>
				</div>
			</div>
		</Dialog>
	}

	


	private getDeliveryTypeIcon(sale: Sales) {
		let result = [];
		if (this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli) { // ReadyForPacking && this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli') {
			if (sale!.saleInformation!) {
				if (sale.saleInformation.deliveryMethod ==  "Moto") {
					result.push(<i key={"mb" + sale.id} style={{ color: "#E33A37", fontSize: 20 }} className="mdi mdi-moped mr-2"></i>);
				}
				else
				{
					result.push(<i key={"tk" + sale.id} style={{ fontSize: 20 }} className="mdi mdi-truck-delivery mr-2"></i>);
				}

				if (sale.isPrinted) {
					if (result.length == 1) {
						result.push(<i key={"aa" + sale.id} style={{ color: "#EB9020", fontSize: 20, marginLeft: 28 }}></i>);
					}
					
					result.push(<i key={"pr" + sale.id} style={{ color: "#EB9020", fontSize: 20, cursor: "pointer" }} className="mdi mdi-printer mr-2" onClick={() => this.printOnlySale(sale)}></i>);
				}

				return result;
			}
            else {
				if (sale.isPrinted) {
					if (result.length == 1) {
						result.push(<i key={"aa" + sale.id} style={{ color: "#EB9020", fontSize: 20, marginLeft: 28 }}></i>);
					}
					result.push(<i key={"pr" + sale.id} style={{ color: "#EB9020", fontSize: 20, cursor: "pointer" }} className="mdi mdi-printer mr-2" onClick={() => this.printOnlySale(sale)}></i>);
				}
            }
        }
        else {
			let hasCollect = false;
			let hasFlex = false;
			let hasToBeAgreed = false;

			if (sale.meLiSaleDetails != null) {
				sale.meLiSaleDetails.forEach((meliOrder: MeLiSaleDetailContainer) => {
					if (meliOrder.deliveryType == "Collect") {
						hasCollect = true;
					}
					if (meliOrder.deliveryType == "Flex") {
						hasFlex = true;
					}
					if (meliOrder.deliveryType == "ToBeAgreed") {
						hasToBeAgreed = true;
					}
				});
			}

			if (hasToBeAgreed &&
				!hasCollect &&
				!hasFlex) {
				if (sale.isPrinted && (this.props.pageSaleState == PageSaleState.ReadyForPacking || PageSaleState.ReadyForPackingAcNoMeli)) {
					return <React.Fragment key={"fr" + sale.id}>
						<i key={"ca2" + sale.id} style={{ color: "#018DA8", fontSize: 20 }} className="mdi mdi-comment-account mr-2"></i>
						<i key={"pr2" + sale.id} style={{ color: "#EB9020", fontSize: 20, cursor: "pointer" }} className="mdi mdi-printer mr-2" onClick={() => this.printOnlySale(sale)}></i>
					</React.Fragment>;
				}
				return <i key={"ca" + sale.id} style={{ color: "#018DA8", fontSize: 20 }} className="mdi mdi-comment-account mr-2"></i>;
			}

			
			result.push(<React.Fragment key={"fr" + sale.id}></React.Fragment>);

			if (hasFlex) {
				result.push(<i key={"mb" + sale.id} style={{ color: "#0F8546", fontSize: 20 }} className="mdi mdi-motorbike mr-2"></i>);
			}
			if (hasCollect) {
				result.push(<i key={"tk" + sale.id} style={{ color: "#E33A37", fontSize: 20 }} className="mdi mdi-truck mr-2"></i>);
			}

			if (sale.isPrinted && (this.props.pageSaleState == PageSaleState.ReadyForPacking || PageSaleState.ReadyForPackingAcNoMeli) ) {
				if (result.length == 1) {
					result.push(<i key={"aa" + sale.id} style={{ color: "#EB9020", fontSize: 20, marginLeft: 28 }}></i>);
				}
				result.push(<i key={"pr" + sale.id} style={{ color: "#EB9020", fontSize: 20, cursor: "pointer" }} className="mdi mdi-printer mr-2" onClick={() => this.printOnlySale(sale)}></i>);
			}

			return result;
        }
	}

	private printOnlySale(sale: Sales) {

		let selectedSales = this.state.selectedSales;
		selectedSales = [];
		selectedSales.push(sale);
		this.setState({ selectedSales: selectedSales }, () =>{ this.printSummary(sale.id) } );
		
	}
	//BACK 115
	private printTagAndSummary() {
		this.props.printTagsMassive(this.state.selectedSales.map((sale: Sales) => sale.id));

		this.printSummary();
	}

	private printSummary(saleId?: number) {
		if (document != null) {
			var div = document.getElementById("summaryToPrint");
			if (div != null) {
				var divContents = div.outerHTML;
				var a = window.open('', '', 'height=800, width=1200');
				if (a != null) {
					a.document.write('<html>');
					a.document.write('<head>');
					a.document.write('<style type="text/css" media="print">');
					a.document.write('@page {');
					a.document.write('size: auto;');
					a.document.write('margin: 0mm;');
					a.document.write('margin-left: 5mm;');
					a.document.write('}');
					a.document.write('</style>');
					a.document.write('</head>');
					a.document.write('<body >');
					a.document.write(divContents);
					a.document.write('</body></html>');
					a.document.close();
					a.print();
				}

				if (typeof saleId !== 'undefined') {
					let arrOnlySale:number[] = [saleId];
					this.props.printSales(arrOnlySale);
				}
				else
					this.props.printSales(this.state.selectedSales.map((sale: Sales) => sale.id));
			}
		}
	}

	private printSummaryQR() {
		if (document != null) {
			var div = document.getElementById("printToPDFWithQR");
			if (div != null) {
				var divContents = div.outerHTML;
				var a = window.open('', '', 'height=800, width=1200');
				if (a != null) {
					a.document.write('<html>');
					a.document.write('<head>');
					a.document.write('<style type="text/css" media="print">');
					a.document.write('@page {');
					a.document.write('size: auto;');
					a.document.write('margin: 0mm;');
					a.document.write('margin-left: 5mm;');
					a.document.write('}');
					a.document.write('</style>');
					a.document.write('</head>');
					a.document.write('<body >');
					a.document.write(divContents);
					a.document.write('</body></html>');
					a.document.close();
					a.print();
				}
				this.props.printTags(this.state.selectedSales.map((sale: Sales) => sale.id));
			}
		}
	}

	private getPrintDoc() {
		const style = {
			border: "solid 1px black",
			"border-collapse": "collapse",
			"padding": "5"
		};
		const tableStyle = {
			border: "solid 1px black",
			"border-collapse": "collapse",
			"width": "100%"
		};
		const tdRespStyle = {
			border: "0px",
			"min-width": "80px"
		};
		const tdSales = { border: "1px solid ", padding: "7px" };
		const tdSalesTachado = { border: "1px solid ", padding: "7px", textDecoration: "line-through" };


		var skuSummaryResult: { [k: string]: any } = {};
		const todosPerPage = 11;
		let pageNumbers: number[] = [];


		function addSKU(skuNum: string, saleId: number, quantity: number, totalUnits: number) {
			if (skuSummaryResult[skuNum] == null) {

				skuSummaryResult[skuNum] = {
					totalQuantity: quantity,
					totalUnits: totalUnits,
					salesId: []
				};
			}
			else {
				skuSummaryResult[skuNum].totalQuantity += quantity;
				skuSummaryResult[skuNum].totalUnits += totalUnits;
			}

			if (!(saleId in skuSummaryResult[skuNum].salesId)) {
				skuSummaryResult[skuNum].salesId.push(saleId);
			}

			var lastPage = Math.ceil(Object.keys(skuSummaryResult).length / todosPerPage);
			if (!pageNumbers.includes(lastPage))
				pageNumbers.push(lastPage);

		}

		function addResponsable(packers: Packer[]) {

			let filas: string[][] = [];
			let columnas: Array<string> = [] ;
			let columnaActual: number = 0;
			//			filas += this.props.packers.length % 6 == 0 ? 0 : 1 ;


			Object.values(packers)
				.map((packerObj: Packer, index) => {
					if (index >= 6) {
						var fileNueva = (index-6)  % 7 == 0 ? true : false;
						if (fileNueva == true && index != 7) {
							filas.push(columnas);

							columnaActual = 0;
							columnas = [];
							columnas.push(packerObj.nickname);
						}
						else {

							columnas.push(packerObj.nickname);
						}
						columnaActual++;
					}
				}
			);

			if (columnaActual <= 6)
				for (var i = columnaActual; i <= 6; i++)
					columnas.push("");//relleno con espacios para el total de columnas

			filas.push(columnas); //agrego la ultima fila procesada

		return Object.values(filas)
			.map((fila, index) => {
				return <tr>
					{Object.values(fila)
						.map((columna, index) => {
							return <td style={tdRespStyle}> {columna == '' ? "" : <><input type="checkbox" /></> }  {columna} </td>
						})}
				</tr>
			})

		}

		return <div id="summaryToPrint">
			{this.state.selectedSales.map((sale: Sales) => <div style={{ minHeight: "29.7cm" }}>
				<br/><br/>
				<table style={tableStyle}>
					<tr>
						<td style={{ width: "45px" }}>
							<b>Venta: </b>
	                    </td>
						<td>
							{sale.id}
						</td>
						<td style={{ width: "60px" }}>
							<b> Usuario: </b>
		                </td>
						<td>
							{sale.meLiSaleDetails.length > 0 ? sale.user : sale.client}
						</td>
						{sale.hasMeLiOrder ?
							<>
								<td>
									<b> Tipo:</b>
								</td>
								<td>
									{this.getDeliveryType(sale)}
									{sale.dispatchDate != null ?
										" (" + this.getStringDate(sale.dispatchDate) + ")"
										: ""
									}
								</td>

							</> :
								null
						}
					</tr>
					{sale.hasMeLiOrder ?
						<>
							<tr>
								<td>
									<b>Ordenes:</b>
                                </td>
								<td>
									{sale.meLiSaleDetails.map((value: MeLiSaleDetailContainer) => <> {value.orderNumber} <br /> </>)}
								</td>
								<td>
									<b>Envíos:</b>
								</td>
								<td>
									{sale.meLiSaleDetails.map((value: MeLiSaleDetailContainer) => <> {value.shipmentId == null ? "-" : value.shipmentId} <br /> </>)}
								</td>
								{this.getDeliveryType(sale) == "Moto" && sale.location != null ?
									<>
											<td>
											<b> Zona:</b>
											</td>
											<td>
												{sale.location.zone != "CABA" ? <> {sale.location.zone} - {sale.location.city} </> : <>{sale.location.city}</>}
											</td>
									</>
									: <td colSpan={2}>
										&nbsp;
										</td>
								}
							</tr></>
							: 
							<tr>
							<td>
									<b>Tipo:</b>
									</td>
							<td colSpan={5}>
								Fuera de ML
								</td>
							</tr>
						
					}
				</table>
				<br/>
				<table style={{ border: "none", borderCollapse: "collapse" }}>
					<tr >
						<td style={{ border: "1px solid ", padding:"7px" }}>
							<b>Sku</b>
                        </td>
						<td style={{ border: "1px solid ", padding: "7px" }}>
							<b>Precio Unitario</b>
                        </td>
						<td style={{ border: "1px solid ", padding: "7px" }}>
							<b>Cantidad</b>
                        </td>
						{/*<td style={{ border: "0px" }}>*/}
						{/*	&nbsp;*/}
						{/*                  </td>*/}
					</tr>
					{Object.values(CommonSale.getSummaryElements(sale))
						.sort((a: SaleProduct, b: SaleProduct) => {
							var x = a.sku.toLowerCase();
							var y = b.sku.toLowerCase();
							if (x < y) { return -1; }
							if (x > y) { return 1; }
							return 0;
						})
						.map((saleProduct: SaleProduct, index: number) => <tr key={index} >
							<td style={saleProduct.quantity == 0 ? tdSalesTachado : tdSales}>{saleProduct.sku}</td>
							<td style={saleProduct.quantity == 0 ? tdSalesTachado : tdSales}>${(saleProduct.price).toFixed(2)}</td>
							<td style={saleProduct.quantity == 0 ? tdSalesTachado : tdSales}><b>{saleProduct.quantity} ({coversorNumeroALetras.NumerosALetras(saleProduct.quantity)})</b></td>
							<td style={{borderRight:"0"}}><b>{saleProduct.isPack ? "** " + saleProduct.packQuantity.toString()+ " unidades **" : "" }</b></td>
							{addSKU(saleProduct.sku, sale.id, saleProduct.quantity, saleProduct.isPack ? saleProduct.packQuantity : 0)}
						</tr>)
					}
				</table>
				<br />
				<table style={tableStyle}>
					<tr>
						{Object.values(this.props.packers)
							.map((packerObj: Packer, index) => {
								return (index == 0) ? <>
									<td style={style}>Responsables</td>
									<td style={tdRespStyle}><input type="checkbox" /> {packerObj.nickname}</td>
								</> : (index < 6) ? <><td style={tdRespStyle}><input type="checkbox" /> {packerObj.nickname}</td></> : <></>
							}
							)
						}
					</tr>
					{this.props.packers.length > 6 ? addResponsable(this.props.packers) : null}
					
				</table>
				<br />

				{/*se quita los bultos BACK 294*/}
				{/*{this.getDeliveryType(sale) == "Moto" ?*/}
				{/*	<>*/}
				{/*		<table style={style}>*/}
				{/*			<tr style={style}>*/}
				{/*				<td style={style}>*/}
				{/*					Bultos*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 1*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 2*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 3*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 4*/}
    {/*                            </td>*/}
				{/*			</tr>*/}
				{/*		</table>*/}
				{/*	</>*/}
				{/*	: null*/}
				{/*}*/}

				<br />
				{sale.messages != null && sale.messages.length > 0 ?
					<>
						<table style={{ border: 0 }} >
							<tr>
								<td >
									<b>** NOTAS ** </b>
								</td>
							</tr>
						</table>
					</> : null
				}
				<table style={tableStyle}>
					{sale.messages.map((message: SaleMessage) =>
						<>
							<tr style={style}>
								<td style={style}>
									{message.message}
								</td>
								<td style={style}>
									{message.userName}
								</td>
							</tr>
						</>
					)}
				</table>
			</div>
			)}
			{pageNumbers.map(( paginaActual: number ) => {
				return <div style={{ minHeight: "29.7cm" }}>
					<table style={{ border: "1px solid black" }}>
						<tr>
							<td><b>Fecha de Impresión:</b></td>
							<td>{this.getDateWithHour(new Date())} hs</td>
							<td style={{ width: "250px", textAlign: "right" }}>Página {paginaActual} de {pageNumbers.length}</td>
						</tr>
						<tr>
							<td><b>Total de ventas:</b></td>
							<td colSpan={2} >{this.state.selectedSales.length}</td>
						</tr>
					</table>
					<br />
					<table style={{ border: "none", borderCollapse: "collapse" }}>
						<tr >
							<td style={{ border: "1px solid ", padding: "7px" }}>
								<b>Sku</b>
							</td>
							<td style={{ border: "1px solid ", padding: "7px" }}>
								<b>Cantidad </b>
							</td>
							<td style={{ border: "1px solid ", padding: "7px" }}>
								<b>Ventas</b>
							</td>
							<td style={{ border: "0px" }}>
								&nbsp;
							</td>
						</tr>
						{Object.keys(skuSummaryResult).map(function (key, index) {
							return  ( index >= ((paginaActual-1) * todosPerPage) && index < ((paginaActual-1) * todosPerPage) + todosPerPage) ?
								 <tr >
									<td style={{ border: "1px solid ", padding: "7px" }}>
										{key}
									</td>
									<td style={{ border: "1px solid ", padding: "7px" }}>
										{skuSummaryResult[key].totalQuantity} ({coversorNumeroALetras.NumerosALetras(skuSummaryResult[key].totalQuantity)})
									</td>
									<td style={{ border: "1px solid ", padding: "7px", fontSize: "10pt" }}>
										{skuSummaryResult[key].salesId.join(',')}
									</td>
									<td style={{ borderRight: "0" }}>
										{skuSummaryResult[key].totalUnits > 0 ? "** " + skuSummaryResult[key].totalUnits.toString() + " unidades **" : ""}
									</td>
									</tr>  :  null
						})}
					</table>
				</div>
			}
			)}
		</div>
	}

	private getPrintDocWithQR() {


		return <div id='printToPDFWithQR'>
			{this.state.selectedSales.map((sale: Sales, ix) => {
				//alert(sale.bulks)

				let baseUrlQR = `${configuration.baseApiUrl}/Sales/QR/`;
				let urlQR = `${baseUrlQR}${sale.id}`;

				var currShip = this.state.currentShipments.find(p => p.sale != null && p.sale.id == sale.id)
				let bultos = currShip == null ? 1 : currShip.bulks;

				if (sale!.saleInformation! && sale.saleInformation.deliveryMethod != 'Expreso')
					bultos = 1;

				var arrBultos = [];
				for (var i = 1; i <= bultos; i++) 
					arrBultos.push(i);
				
				var compania = this.props.companies.find(x => x.id == sale!.saleInformation!.companyId);
				
				return <>{
					arrBultos.map((bultoNro, indice) => {
						return <div style={{ minHeight: "29.7cm" }}>
							<table style={{ border: "solid", width: 720, borderBottom: "0", paddingTop: "0px",borderCollapse: "collapse"  }}>
								<tr >
									<td style={{ fontSize: "14pt",paddingLeft: "5px" }}><b>Zetaburgos SRL</b></td>
									<td rowSpan={3} style={{ fontSize: "17pt",borderLeft: "solid", paddingLeft: "5px", verticalAlign:"middle" }}><b>Número de Venta:</b>&nbsp;&nbsp;# {sale.id}</td>
								</tr>
								<tr >
									<td style={{ paddingLeft: "5px" }}><b>Gral. Manuel A. Rodriguez 2528, CABA - CP 1416</b></td>
								</tr>
								<tr >
									<td style={{ paddingLeft: "5px" }}><b>CUIT</b> 30-71152641-9</td>
								</tr>
							</table>
							<table style={{ border: "solid", width: 720, paddingLeft: "4px", borderSpacing:"2px 20px"}} key={ix}>
								<tbody>
								<tr>
									<td style={{ width: 100 }}><b>Cliente:</b></td>
									<td colSpan={2}>{sale.client}</td>
									<td style={{ width: 50 }}><b>DNI/CUIT:</b></td>
									<td style={{ width: 50 }}>{sale!.saleInformation! ? (sale.saleInformation.client.documentNumber == '' ? '-' : sale.saleInformation.client.documentNumber)  :'-'}</td>
									<td style={{ width: 50 }}></td>
										<td rowSpan={5} style={{ width: 128 }}><QRCode value={urlQR} size={128} /></td>
								</tr>
								<tr >
									<td ><b>Dirección:</b></td>
										<td colSpan={4}>{sale!.saleInformation! ? sale.saleInformation.client.address : '-' }</td>
									</tr>
									<tr>
										<td><b>Provincia:</b></td>
										<td colSpan={4}>
											{sale!.saleInformation! ? this.props.provinces.filter(x => x.id == parseInt(sale!.saleInformation!.client.provinceId)).map((provin: Province) => provin.name) : '-'}
										</td>
									</tr>
								<tr style={{ paddingBottom: "5px" }}>
									<td><b>Localidad:</b></td>
									<td colSpan={4} style={{ width: 100 }}>
										{sale!.saleInformation!  ? this.props.cities.filter(x => x.id == parseInt(sale!.saleInformation!.client.locationId)).map((localidad: City) => localidad.name): '-'}
									</td>
									</tr>
									
								<tr style={{ paddingBottom: "5px" }}>
									<td><b>C.P.:</b></td>
									<td colSpan={4} style={{ width: 50 }}>{sale!.saleInformation! ? sale.saleInformation.client.postalCode : ''}</td>
								</tr>
									<tr style={{ paddingBottom: "5px" }}>
										<td><b>Teléfono:</b></td>
									<td colSpan={4}>{sale!.saleInformation! ? sale.saleInformation.client.telephone : ''}</td>
								</tr>
								{
									sale!.saleInformation! && sale.saleInformation!.deliveryMethod == 'Expreso' ?      //sale. es Expreso
										<>
											<tr>
												<td><b>Transporte:</b></td>
												<td colSpan={6}> EXPRESO  {compania!.name}  -  {compania!.address}</td>
											</tr>
										</> :
										<>
										{
											sale!.saleInformation! && sale.saleInformation.deliveryMethod == 'Moto' ?
												<>
													<tr>
													  <td><b>Transporte:</b></td>
													 <td colSpan={7}>MOTO</td>
													</tr>
													</>
													:<></>
										}
										</>
								}
							</tbody>
						</table>
							{sale!.saleInformation! && sale.saleInformation!.deliveryMethod == 'Expreso' ?
								<>
									<table style={{ border: "solid", width: 720, borderTop: "0", paddingBottom: "2px" ,paddingTop:"2px" }}>
										<td style={{ width: 130 }}><b>Bultos Totales:</b></td>
										<td style={{ width: 40 }}>{bultos}</td>
										<td style={{ width: 130 }}><b>Nro. de Bulto:</b></td>
										<td style={{ width: 40 }}>{bultoNro}</td>
										<td >&nbsp;</td>
									</table>
								
								</>:<></>
							}

							{sale.messages.filter(p => p.type == 'EftAdditional' || p.type == 'EftDifference' || p.type == 'EftSale').length > 0 ?
								<>
									<table style={{ border: "solid", marginTop: "10px", width: 720 }}>
								{sale.messages.filter(p => p.type == 'EftAdditional' || p.type == 'EftDifference' || p.type == 'EftSale').map((message: SaleMessage) =>
									<>
										<tr>
											<td>
												<b>{message.message}</b>
											</td>
										</tr>
									</>
								)}
							</table>
								</> : null}

							{sale!.saleInformation! ? (compania != null && compania!.triplicateRemittance ?
								<>
									<table style={{ border: "0", marginTop: "5px", width: 600 }}>
										<tr>
											<td>
												<b>SE ENVÍA REMITO POR TRIPLICADO</b>
											</td>

										</tr>

									</table>
								</>
								: null)
							: null}

							{sale!.saleInformation! ? this.props.provinces.filter(x => x.id == parseInt(sale!.saleInformation!.client.provinceId)).map((provin: Province) => provin.invoiceRequired == true ?
								<>
									<table style={{ border: "0", marginTop: "5px",width: 600 }}>
										<tr>
											<td>
												<b>SE ENVÍA CON FACTURA</b>
											</td>

										</tr>

									</table>
								</>
								: null)
							:null}
						</div>
					})
				} </>;
				
			})}
		</div>
	}

	private translateDeliveryType(deliveryType: string) {
		return deliveryType == 'ToBeAgreed' ? "A convenir"
			: deliveryType == "Flex" ? "Flex" : "Colecta";
	}

	private convertDate = (date: Date) => {
		var delta = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);

		var minute = 60,
			hour = minute * 60,
			day = hour * 24,
			week = day * 7;

		var fuzzy = 'hace más de un día';

		if (delta < 30) {
			fuzzy = 'justo ahora';
		} else if (delta < minute) {
			fuzzy = delta + ' segundos atrás';
		} else if (delta < 2 * minute) {
			fuzzy = 'un minuto atrás'
		} else if (delta < hour) {
			fuzzy = Math.floor(delta / minute) + ' minutos atrás';
		} else if (Math.floor(delta / hour) == 1) {
			fuzzy = '1 hora atrás'
		} else if (delta < day) {
			fuzzy = Math.floor(delta / hour) + ' horas atrás';
		} else if (delta < day * 2) {
			fuzzy = 'hace más de un día';
		}
		return fuzzy;
	}



	private renderZone = (sale: Sales) => {
		if (sale.location != null) {
			let zone = sale.location.zone;
			switch (zone.toUpperCase()) {
				case "NORTE":
					return <div className="badge badge-danger" title="Norte">N</div>;
				case "SUR":
					return <div className="badge badge-primary" title="Sur">S</div>;
				case "OESTE":
					return <div className="badge badge-warning" title="Oeste">O</div>;
				case "CABA":
					return <div className="badge badge-success" title="CABA">C</div>;
			}
		}
		return <></>;
	}

	private confirmationDialog = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showConfirmationModal}
		>
			<div className={Classes.DIALOG_BODY}>
				{//<p>Una o mas ventas seleccionadas tienen cambios y/o agregados.
					//        Confirma las ventas de todos modos?</p>
					//BACK 63
				}
				<p>{ReactHtmlParser(this.state.messagePopup)}</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.props.confirmSales(this.state.selectedSales.map((value: Sales) => value.id));
						this.setState({ showConfirmationModal: false });
					}}>Confirmar</Button>
				</div>
			</div>
		</Dialog>
	}

	private renderBody = () => {
		const sale = this.props.sales;
		return sale.length == 0 ?
			this.props.emptySalesResults ? <Card>
				<Card.Body>
					<h4 className="card-title">
						No se han encontrado resultados para su búsqueda
                    </h4>
					{this.filterSelected() > 0 ?
						<>
							<Link className='nav-link' to="#">
								<i className="mdi mdi-information"></i>
								<span className="menu-title"> Atención: Posee {this.filterSelected()} filtros seleccionados. </span>
								<Button color="success" className="ml-2 mr-2"
									onClick={(evt: any) => this.cleanFilters()}>
									Limpiar Filtros
							</Button>
							</Link>
						</>
						:
						<></>
					}
				</Card.Body>
			</Card>
				: <></>
			:
			<Card>
				<Card.Body>

					{this.filterSelected() > 0 ?
					<>
					  <Link className='nav-link' to="#">
							<i className="mdi mdi-information"></i>
								<span className="menu-title"> Atención: Posee {this.filterSelected()} filtros seleccionados. </span>
							<Button color="success" className="ml-2 mr-2" 
							onClick={(evt: any) => this.cleanFilters()}>
							Limpiar Filtros
							</Button>
						</Link>
					</>
					:
					<></>
					}
					<p className="card-description"> Resultados encontrados {sale.length} </p>
					{(this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli) ?
						<>
							<Link className='link-texto' to="#" onClick={(evt: any) => this.checkCurrentPage()}>
								Marcar página para imprimir
							</Link>
						</>
						: <></>
					}
					
					{this.props.pageSaleState == PageSaleState.InAdministration
						|| (this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli) ? <p className="card-description" style={{ color: "#2196f3" }}> {this.state.selectedSales.length} elementos seleccionados </p>
						: null}
					<Row className="float-right">
						<Button color="success" className="ml-3" onClick={(evt: any) => this.search()}>
							<i className="fa fa-refresh" />
						</Button>
					</Row>
					<Row >
						<Col md={12}>
							<Row className="float-right" >
								<Col md={12}>
							Mostrando {this.state.currentPage} de {Math.ceil(sale.length / this.itemsPerPage())}
							<Button color="success" className="ml-2 mr-2" disabled={this.state.currentPage <= 1}
								onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage - 1) })}>
								{"<"}</Button>
							<Button color="success" className="mr-2" disabled={this.state.currentPage >= ((sale.length / this.itemsPerPage()))}
								onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage + 1) })}>
									{">"}</Button>
								</Col>
							</Row >
						</Col>
					</Row>
					<Row className="table-responsive">
						<Col md={12}>
							<table className="table table-hover">
								<thead>
									<tr>
										{this.props.pageSaleState == PageSaleState.InAdministration
											|| (this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli)  ? <th>
												<div className="form-check">
													<label className="form-check-label text-muted">
														<input type="checkbox" className="form-check-input" checked={this.state.selectedSales.length == this.props.sales.length} onChange={(evt: ChangeEvent) => this.togglerSelectedAllSales()} />
														<i className="input-helper"></i>
													</label>
												</div>
											</th> : <></>}
										<th># Venta</th>

										{(this.props.pageSaleState == PageSaleState.ReadyForPacking /*&& this.props.location.pathname == '/ventasListasParaEmpaquetar'*/) ? <th>Fecha Límite</th> : <th>Fecha</th>}
										<th>Usuario o Email</th>
										<th>Cliente</th>
										{/*BACK 287*/}
										{this.props.pageSaleState == PageSaleState.All ? <th>Estado</th> : <></>}

										<th>Contiene</th>
										{(this.props.pageSaleState == PageSaleState.ReadyForPacking /*&& this.props.location.pathname == '/ventasListasParaEmpaquetar'*/ && (this.state.deliveryType == "Flex" || this.state.deliveryType == "")) ? <th style={{ width: "30px" }}>Zona</th> : <></>}
										{this.props.pageSaleState == PageSaleState.InAdministration ? <th>Ajustes</th> : <></>}
										{this.props.pageSaleState == PageSaleState.InAdministration ? <th>Ajustes</th> : <></>}
										<th></th>
										{this.props.pageSaleState == PageSaleState.InAdministration ? <th>Facturada</th> : (this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli /*&& this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli'*/) ? <th>Bultos</th> : <></>}
										{/*{(this.props.pageSaleState == PageSaleState.ReadyForPacking && this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli') ? <th>Etiqueta</th> : <></>}*/}
										<th></th>
									</tr>
								</thead>
								<tbody>
									{this.currentSales().map((sale: Sales, index: number) => {
										
										var divStyle = { color: 'black', fontWeight: 400 };
										var formatDate = "";
										//BACK 130 - BACK 157
										if (this.props.pageSaleState == PageSaleState.ReadyForPacking /* && this.props.location.pathname == '/ventasListasParaEmpaquetar'*/) {
											const todayDate = new Date();
											var d = todayDate.getDate();
											var dd = "";
											var m = todayDate.getMonth() + 1;
											var mm = "";
											var yyyy = todayDate.getFullYear();
											if (d < 10) {
												dd = '0' + d;
											} else { dd = d.toString(); }
											if (m < 10) {
												mm = '0' + m;
											} else { mm = m.toString(); }
											var formatToday = yyyy + '-' + mm + '-' + dd;

											if (sale.dispatchDate != null) {
												const orderDate = new Date(sale.dispatchDate);
												formatDate = orderDate.getDate() + "/" + (orderDate.getMonth() + 1) + "/" + orderDate.getFullYear();

												var d1 = orderDate.getDate();
												var dd1 = "";
												var m1 = orderDate.getMonth() + 1;
												var mm1 = "";
												var yyyy1 = orderDate.getFullYear();
												if (d1 < 10) {
													dd1 = '0' + d1;
												}
												else { dd1 = d1.toString(); }
												if (m1 < 10) {
													mm1 = '0' + m1;
												}
												else { mm1 = m1.toString(); }
												var formatDateCompare = yyyy1 + '-' + mm1 + '-' + dd1;

												if (Date.parse(formatDateCompare) < Date.parse(formatToday)) {
													divStyle = {
														color: 'red',
														fontWeight: 700
													};
												}
												else if (Date.parse(formatDateCompare) == Date.parse(formatToday)) {
													divStyle = {
														color: 'red',
														fontWeight: 400
													};
												}
											}
											else {
												if (sale.meLiSaleDetails != null && sale.meLiSaleDetails.length > 0) {
													sale.meLiSaleDetails.forEach((meliOrder: MeLiSaleDetailContainer) => {
														if (meliOrder.deliveryType == "ToBeAgreed") {
															formatDate = 'A CONVENIR';
														}
														else
															formatDate = '---';
													});
												}
												else
													formatDate = '---';
											}
										}
										else {
											const orderDate = new Date(sale.date);
											formatDate = orderDate.getDate() + "/" + (orderDate.getMonth() + 1) + "/" + orderDate.getFullYear();
										}

										if (this.props.pageSaleState == PageSaleState.InAdministration) {
											
											if (sale.errorInSaleCharge) {
												divStyle = {
													color: '#E33A37',
													fontWeight: 700
												};
                                            }
                                        }

										return <tr key={index} style={divStyle}>
											{this.props.pageSaleState == PageSaleState.InAdministration
												|| (this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli) ?
												<td>
													<div className="form-check">
														<label className="form-check-label text-muted">
															<input type="checkbox" className="form-check-input" checked={this.state.selectedSales.includes(sale)} onChange={(evt: ChangeEvent) => this.pushSelectedSales(sale)} />
															<i className="input-helper"></i>
														</label>
													</div>
												</td>
												:
												<></>}
											<td>{sale.id}</td>
											{/*<td>{orderDate.getDate() + "/" + (orderDate.getMonth() + 1) + "/" + orderDate.getFullYear()}</td>*/}
											<td style={divStyle}>{formatDate}</td>
											<td>{sale.user}</td>
											<td>{sale.client}</td>
											{/*BACK 287*/}
											{this.props.pageSaleState == PageSaleState.All ? <td>{sale.stateText}</td> : <></>}

											<td>
												{sale.hasMeLiOrder ? <a target="_blank" onClick={() => this.openMeLiOrders(sale.meLiSaleDetails)} > <img src={require("../../assets/images/mercadolibre.png")} alt="MercadoLibre" /> </a> : <></>}
												{sale.hasPaymentLink ? <img src={require("../../assets/images/mercadoPago.png")} alt="MercadoPago" /> : <></>}
												{sale.hastTransfer ? <img src={require("../../assets/images/transfer.png")} alt="Transferencia" /> : <></>}
												{sale.hasCash ? <img src={require("../../assets/images/cash.png")} alt="Efectivo" /> : <></>}
											</td>
											{
												(this.props.pageSaleState == PageSaleState.ReadyForPacking /*&& this.props.location.pathname == '/ventasListasParaEmpaquetar'*/ && (this.state.deliveryType == "Flex" || this.state.deliveryType == "")) ?
													<td style={{ textAlign: "center" }}>
														{this.renderZone(sale)}
													</td> :
													<></>
											}
											{this.props.pageSaleState == PageSaleState.InAdministration ?
												<td>
													{sale.hasAdjustments ? "SI" : "NO"}
												</td> :
												<></>}
											<td>
												{this.getDeliveryTypeIcon(sale)}
											</td>
											{this.props.pageSaleState == PageSaleState.InAdministration ?
												<td>
													<div className="form-check">
														<label className="form-check-label text-muted">
															<input type="checkbox" className="form-check-input"
																checked={sale.isBilled}
															/>
															<i className="input-helper"></i>
														</label>
													</div>
												</td> :
												(this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli /*&& this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli'*/ ) ?
													<td>
													<input type="number"
															className="form-control"
															disabled={sale != null && sale.saleInformation != null && sale.saleInformation.deliveryMethod != null && sale.saleInformation.deliveryMethod != 'Expreso'}
														min={1} max={10}
														placeholder="Cantidad"
														key={"B" + index}
														ref={"B" + index}
														value={
															(this.state.currentShipments[index] != null && this.state.currentShipments[index].bulks > 0) ? this.state.currentShipments[index].bulks.toString() : "1"
														}
														onChange={(evt: React.ChangeEvent<HTMLInputElement>) => this.updateBulks((evt.currentTarget.value ? evt.currentTarget.value : "1"), sale, index) } />
												</td> :
											<></>}
											{/*{(this.props.pageSaleState == PageSaleState.ReadyForPacking && this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli') ? <td><i key={"mb" + sale.id} style={{ color: "#14AA75", fontSize: 20 }} className="mdi mdi-note-text-outline"></i></td> :*/}
											{/*		<></>}*/}
											<td>
												<span>
													<Tooltip content="Ver detalle" position={Position.BOTTOM}>
														<Link className="mr-2" to={`/ventas/${this.props.pageSaleState != PageSaleState.All ? 'detalle' : 'detalleBusqueda'}/${sale.id}`}>
															<i className="fa fa-search"></i>
														</Link>
													</Tooltip>
												</span>
												{sale.isReopen ? <i style={{ color: "#ffcc00" }} className="fa fa-warning"></i> : <></>}
												{/*BACK 287*/}
												{this.props.pageSaleState == PageSaleState.All &&
													sale.state == "ReadyForPacking" && (this.props.user.role == "Admin" || this.props.user.role == "Seller" || this.props.user.role == "SellerLead") ?
													<>&nbsp;<Tooltip content="Pasar a En Revisión" position={Position.BOTTOM}>
															<i style={{ color: "#979797", cursor: "pointer", fontSize: 20 }}
																onClick={(evt: any) => this.setState({ showReturnInReviewConfirmation: true, saleIdInReview: sale.id })}
																className="mdi mdi-briefcase-check menu-icon"></i>
													</Tooltip></>: <></>}
											</td>

										</tr>
									}
									)}
								</tbody>
							</table>
						</Col>
					</Row>
					<Row className="float-right pt-3">
						<label>
							<i style={{ color: "#ffcc00" }} className="fa fa-warning"></i>
                            Ventas que requieren prioridad ya que fueron reabiertas
                        </label>
					</Row>

					{/*BACK*/} 
					{this.props.pageSaleState == PageSaleState.InReview ?
						<Row className="pt-3">
							<Col md={12}>
								<Button
									color="primary"
									onClick={() => this.props.checkMessageAndConfirm(this.props.sales)}
								>Verificar mensajes y confirmar</Button>
							</Col>
						</Row>
						: <></>}


					{this.props.pageSaleState == PageSaleState.InAdministration ?
						<Row className="pt-3">
							<Col md={12}>
								<Button disabled={this.state.selectedSales.length == 0}
									color="success"
									className="mr-2"
									onClick={() => {
										if (this.state.selectedSales.findIndex((sale: Sales) => sale.hasAdjustments) < 0
											//BACK 63
											&& this.state.selectedSales.findIndex((sale: Sales) => sale.errorInSaleCharge) < 0
										) {
											this.props.confirmSales(this.state.selectedSales.map((value: Sales) => value.id));
										}
										else {
											//BACK 63
											this.state.messagePopup = "";
											if (this.state.selectedSales.findIndex((sale: Sales) => sale.hasAdjustments) >= 0)
												this.state.messagePopup += "<p>Una o mas ventas seleccionadas tienen cambios y/o agregados.</p>";
											if( this.state.selectedSales.findIndex((sale: Sales) => sale.errorInSaleCharge) >= 0)
												this.state.messagePopup += "<p>Una o mas ventas seleccionadas tienen incosistencias.</p>";

											this.state.messagePopup += "<p>¿Confirma las ventas de todos modos?.</p>";

											//Original
											this.setState({ showConfirmationModal: true });
										}
									}
									}
								>Confirmar masivamente</Button>
								<Button disabled={this.state.selectedSales.length == 0}
									color="primary"
									onClick={() => this.props.markSalesAsBilled(this.state.selectedSales)}
								>Marcar como facturada</Button>
							</Col>
						</Row>
						: <></>}

					{this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli ?
						<Row className="pt-3">
							<Col md={12}>
								
								{this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli' ?
									<>
										<Button disabled={this.state.selectedSales.length == 0}
											color="primary"
											onClick={() => this.printSummary()}
										>Imprimir Resumen</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button disabled={this.state.selectedSales.length == 0}
											color="primary"
											onClick={() => this.printSummaryQR()}
										>Imprimir Etiqueta</Button>
								</>
									: <Button disabled={this.state.selectedSales.length == 0}
										color="primary"
										onClick={() => this.printTagAndSummary()}
									>Descargar Resúmenes y Etiquetas</Button>
								}
							</Col>
						</Row>
						: <></>}
				</Card.Body>
				{this.returnInReviewConfirmation()}
			</Card>
	}

	private openMeLiOrders(meLiSales: MeLiSaleDetailContainer[]) {
		meLiSales.forEach((meLiSaleDetailContainer: MeLiSaleDetailContainer) => {
			window.open(`https://www.mercadolibre.com.ar/ventas/${meLiSaleDetailContainer.orderNumber}/detalle`, "_blank")
		});
	}

	private filterSelected() {

		var filterSelectedCount = 0;

		if (this.state.fromDate.toDateString() != this.getTwoWeeksAgo().toDateString())
			filterSelectedCount++;

		if (this.state.toDate.toDateString() != new Date().toDateString())
			filterSelectedCount++;

		if (this.state.userName != "")
			filterSelectedCount++;

		if (this.state.deliveryType != "")
			filterSelectedCount++;

		if (this.state.onlyReopenSales == true)
			filterSelectedCount++;

		if (this.state.excludeMeLiOrders == true)
			filterSelectedCount++;

		if (this.state.excludePaymentLink == true)
			filterSelectedCount++;

		if (this.state.excludeTransfers == true)
			filterSelectedCount++;

		if (this.state.onlySalesWithoutAdjustments == true)
			filterSelectedCount++;

		if (this.state.ordersWithCard != undefined)
			filterSelectedCount++;

		if (this.state.saleId != 0)
			filterSelectedCount++;

		if (this.state.orderNumber != 0)
			filterSelectedCount++;

		//if (this.state.pageSaleState != PageSaleState.All)
		//	filterSelectedCount++;

		if (this.state.isNotPrinted == true)
			filterSelectedCount++;

		if (this.state.flexZone != "")
			filterSelectedCount++;

		if (this.state.limitDispatchDate != 0)
			filterSelectedCount++;

		return filterSelectedCount;
	}

	private cleanFilters() {
	
		this.setState({
			fromDate: this.getTwoWeeksAgo(),
			toDate: new Date(),
			userName: "",
			deliveryType: "",
			onlyReopenSales: false,
			excludeMeLiOrders: false,
			excludePaymentLink: false,
			excludeTransfers: false,
			onlySalesWithoutAdjustments: false,
			currentPage: 1,
			ordersWithCard: undefined,
			saleId: 0,
			orderNumber: 0,
			pageSaleState: PageSaleState.All,
			isNotPrinted: false,
			flexZone: "",
			limitDispatchDate: 0
		}, () => {

			this.props.filters[this.props.pageSaleState] = undefined;
			//if (this.props.sales.length > 0) {
				this.search();
			//}
		});
	}

	private checkCurrentPage() {
		//this.itemsPerPage()
		let currPage = () => this.props.sales.slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);


		let selectedSales = this.state.selectedSales;
		selectedSales = [];
		for (var i = 0; i < currPage().length; i++ )
			selectedSales.push(currPage()[i]);

		this.setState({ selectedSales: selectedSales });


		//this.setState({ selectedSales: [...currPage] });
	}

	render() {
		return (
			<>
				<div className="grid-margin">
					{this.renderHeader()}
				</div>
				<div className="grid-margin">
					{this.renderBody()}
				</div>
				{ this.props.pageSaleState == PageSaleState.ReadyForPacking || this.props.pageSaleState == PageSaleState.ReadyForPackingAcNoMeli  ? <div style={{ display: "none" }}>
					{this.getPrintDoc()}
					{this.props.location.pathname == '/ventasListasParaEmpaquetarACNoMeli' ? this.getPrintDocWithQR() : null}
				</div> : null}
				{this.confirmationDialog()}
				{this.popupDialog()}
			</>
		)
	}
}

export default connect(
	(state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
	(dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(ReviewSales as any);