import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, Product, SelectedProduct, AuditLog, MeLiSaleDetailContainer } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import ReactHtmlParser from 'react-html-parser';

type PaymentSaleProps =
    UserState
    & SalesState
    & typeof commonActionCreators.actionCreators
    & typeof salesActionCreators.actionCreators
    & RouteComponentProps<{ idSale?: string }>
    & { isLink: boolean };

class PaymentSale extends React.Component<PaymentSaleProps> {
    state: {
        idSale: number,
        showData: boolean
    } = {
            idSale: 0,
            showData: true
        };

    componentDidMount() { 
        if (this.props.match.params.idSale != undefined) {
            let currentId = parseInt(this.props.match.params.idSale);
            if (this.props.currentSale == null || this.props.currentSale.id != currentId) {
                this.props.getSale(currentId);
                this.props.getHistory(currentId);
            }
        }
    }


    componentWillReceiveProps(nextProps: PaymentSaleProps) {
        if (this.props.currentSale != null
            && this.props.currentSale.id != this.state.idSale) {
            this.setState({ idSale: this.props.currentSale.id });
            this.props.getHistory(this.props.currentSale.id);
        }
    }

    /*BACK 76*/
    private hasMeLiOrder() {
        return this.props.currentSale != null
            && this.props.currentSale.hasMeLiOrder;
    }


    private isAnyOrderFlex = () => {
        if (this.props.currentSale != null
            && (this.props.currentSale.state == 'ReadyForPacking'
                || this.props.currentSale.state == 'Packed'
                || this.props.currentSale.state == 'Dispatched')) {

            return this.props.currentSale.meLiSaleDetails.some((value: MeLiSaleDetailContainer) => value.deliveryType == 'Flex');
        }
        return false;
    }

    private isPacked = () => {
        return this.props.currentSale != null
            && this.props.currentSale.state == 'Packed';
    }
    private isDispatched = () => {
        return this.props.currentSale != null
            && this.props.currentSale.state == 'Dispatched';
    }

    private hasOrderWithClaims() {
        if (this.props.currentSale != null) {
            let claims = this.props.currentSale.meLiSaleDetails.filter((value: MeLiSaleDetailContainer) => value.claimStatus == "ClaimOpen");
            return claims.length > 0;
        }
        return false;
    }
    //-----------------

    private renderHeader() {
        if (!this.state.showData) {
            return <></>;
        }
        let lastDate = '';
        return <div className="box">
            <ul id="first-list">
                
                {this.props.auditLogs.map((auditLog: AuditLog) => 
                    <li className="timecontent">
                        <div className="title">{auditLog.action}</div>
                        <div className="info">{auditLog.description.split("\n").map((item: string) => <> {ReactHtmlParser(item.replace('MiToken', localStorage.getItem('token')! ))}<br /></>)}</div>
                        <div className="name">{auditLog.userName}</div>
                        {/*BACK 76*/}
                        <div className="name"><span>{new Date(auditLog.date).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' }) + ' hs'.toLowerCase()} </span></div>
                        {/*<div className="name">Venta #{auditLog.saleId}</div>*/}
                        {lastDate != new Date(auditLog.date).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) ?
                            <div className="time"><span>{new Date(auditLog.date).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}</span></div>
                            : <></>}
                        <div style={{ display: "none" } }>
                            {lastDate = new Date(auditLog.date).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' })}
                        </div>
                    </li>)}
            </ul>
        </div>
    }

    render() {
        if (this.props.currentSale == null)
            return (<></>);

        return (
            <>
                <h1>
                    Venta #{this.props.currentSale.id} - <span className={this.props.currentSale.state == "Cancelled" || this.props.currentSale.state == "Deleted" ? "text-danger" : ""}> {this.props.currentSale.stateText} </span> {this.hasOrderWithClaims() ? <span className="text-warning"> - Con Reclamos</span> : <></>}
                </h1>

                {/*SE AGREGA A BACK 76*/}
                <Row className="mb-2">
                    <Col md={12}>
                        <i className="mdi mdi-account-card-details"></i> {this.props.currentSale.client}&nbsp;&nbsp;

                            {this.hasMeLiOrder() ?
                            <> <i className="mdi mdi-account"></i>{this.props.currentSale.user}&nbsp;&nbsp;</> :
                            <>
                                <i className="mdi mdi-email"></i>{this.props.currentSale.user}&nbsp;&nbsp;
                                </>
                        }
                        {
                            this.props.currentSale.clientPhone != ""
                                && this.props.currentSale.clientPhone != undefined
                                && this.props.currentSale.clientPhone != null ?
                                <>
                                    <i className="mdi mdi-cellphone-android"></i>{this.props.currentSale.clientPhone}
                                </> :
                                <></>
                        }
                    </Col>
                </Row>
                {(this.isPacked() || this.isDispatched()) && this.isAnyOrderFlex() && this.props.currentSale.driver != null && this.props.currentSale.driver.logistic != null ?
                    <Row className="mb-2">
                        <Col md={12}>
                            <i className="mdi mdi-motorbike"></i> {this.props.currentSale.driver.name}&nbsp;&nbsp;({this.props.currentSale.driver.logistic.name})
							</Col>
                    </Row> :
                    <></>
                }
                <div className="grid-margin">
                    <Row>
                        <Button color="success" className="ml-3" onClick={(evt: any) => window.history.back()}>
                                Atrás
                        </Button>
                    </Row>
                    {this.renderHeader()}
                </div>
            </>
        )
    }
}



export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(PaymentSale as any);

