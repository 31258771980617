import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { UserState, ManageUser } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster"
import { Position, Tooltip, Popover } from '@blueprintjs/core';

declare var require: any;

type EditUserProps =
    UserState
    & typeof commonActionCreators.actionCreators
    & RouteComponentProps<{ idUser?: string }>;


class EditUser extends React.Component<EditUserProps> {
    private itemsPerPage = () => this.props.user.itemsPerPage;

    state: {
        id: number,
        userName: string,
        password: string,
        email: string,
        role: string
    } = {
            id: 0,
            userName: '',
            email: '',
            role: '',
            password: ''
        };

    componentDidMount() {
        if (this.props.match.params.idUser != null) {
            var idUser = parseInt(this.props.match.params.idUser);
            let currentUser = this.props.users.find((value: ManageUser) => value.id == idUser);
            if (currentUser != undefined) {
                this.setState({
                    id: idUser,
                    userName: currentUser.name,
                    email: currentUser.email,
                    role: currentUser.role
                });
            }
        }
    }

    componentWillReceiveProps(nextProps: EditUserProps) {
        if (nextProps.users.length == 0
            && nextProps.user.role == "Admin") {
            this.props.getUsers();
        }

        if (nextProps.createUserSuccess) {
            let message = "Usuario creado exitosamente";
            if (this.props.match.params.idUser != null) {
                message = "Usuario editado exitosamente";
            }
            AppToaster.show({ intent: "success", message: message });
            this.props.dismissCreateUser();
            this.props.history.push('/administrarUsuarios');
        }
    }

    private renderBody = () => {
        const users = this.props.users;
        return <Card>
            <Card.Body>
                <h4 className="card-title">
                    {this.state.id == 0 ? "Crear usuario" : "Editar usuario"}
                </h4>
                <Form onSubmit={this.onFormSubmit}>
                    <Row>
                        <label className="col-md-2 col-form-label">Email</label>
                        <Col md={10}>
                            <Form.Control disabled={this.state.id != 0} type="email" className="form-control" id="userName" value={this.state.email} placeholder="Email" onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ email: evt.currentTarget.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <label className="col-md-2 col-form-label">Nombre</label>
                        <Col md={10}>
                            <Form.Control type="text" className="form-control" id="userName" placeholder="Nombre" value={this.state.userName} onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ userName: evt.currentTarget.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <label className="col-md-2 col-form-label">Contraseña</label>
                        <Col md={10}>
                            <Form.Control type="password" className="form-control" id="password" placeholder="Contraseña" value={this.state.password} onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ password: evt.currentTarget.value })} />
                        </Col>
                    </Row>
                    <Row>
                        <label className="col-md-2 col-form-label">Rol</label>
                        <Col md={10}>
                            <select className="form-control"
                                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ role: evt.currentTarget.value })}>
                                <option value={""} selected={this.state.role == ""}>Seleccione una opción...</option>
                                <option value={"Admin"} selected={ this.state.role == "Admin" }>Administrador</option>
                                <option value={"Seller"} selected={this.state.role == "Seller"}>Vendedor</option>
                                <option value={"Administrative"} selected={this.state.role == "Administrative"}>Administrativo</option>
                                <option value={"Packer"} selected={this.state.role == "Packer"}>Depósito</option>
                                <option value={"SellerLead"} selected={this.state.role == "SellerLead"}>Jefe de ventas online</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <button disabled={this.state.email == '' || this.state.userName == '' || this.state.role == ''}
                                type="submit"
                                className="btn btn-primary mr-2">{this.state.id == 0 ? "Crear" : "Guardar cambios"}
                            </button>
                            <button
                                type="button"
                                color="primary"
                                onClick={() => this.props.history.push('/administrarUsuarios') }
                                className="btn btn-primary mr-2">Cancelar
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    }

    private onFormSubmit = (evt: any) => {
        evt.preventDefault();
        this.props.editUser(this.state.id,
            this.state.email,
            this.state.userName,
            this.state.role,
            this.state.password);
    }

    render() {
        return (
            <>
                <div className="grid-margin">
                    {this.renderBody()}
                </div>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(EditUser as any);

