import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Spinner from './shared/Spinner';
import Dashboard from './dashboard/Dashboard';
import Login from './user-pages/Login';
import BasicElements from './form-elements/BasicElements';
import MLSale from './sales/MLSale';
import History from './sales/History';
import ReviewSales from './sales/ReviewSales';
import PaymentSale from './sales/PaymentSale';
import SaleDetail from './sales/SaleDetail';
import Configuration from './sales/Configuration';
import MLManufacturing from './sales/MLManufacturing';
import { PageSaleState } from '../store/Sales/types';
import NotificationsTable from './sales/NotificationsTable';
import ManageUsers from './users/ManageUsers';
import EditUser from './users/EditUser';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { actionCreators } from '../store/Common/actions';
import { UserState } from '../store/Common/types';
import MassShipments from './sales/MassShipments';
import FulljausSale from './sales/FulljausSale';
//BACK 159
import Reports from './sales/Reports';
//Back 312
import InfoServices from './sales/InfoServices';

class AppRoutes extends React.Component<UserState> {

    render() {
        return <React.Suspense fallback={<Spinner />} >
            <Route exact path="/home" render={props => <ReviewSales {...props} {...{ pageSaleState: PageSaleState.All }} />} />

            <Route path="/login" component={Login} />

            <Route path="/basic" component={BasicElements} />

            <Route exact path="/administrarUsuarios" component={ManageUsers} />

            <Route exact path="/ventas" render={props => 
                this.props.user.role == "Admin"
                || this.props.user.role == "Seller"
                || this.props.user.role == "SellerLead" ?
                    <ReviewSales {...props} {...{ pageSaleState: PageSaleState.InReview }} /> :
                    <Redirect to="/home" />
            } />

            <Route exact path="/buscarVentas" render={props => <ReviewSales {...props} {...{ pageSaleState: PageSaleState.All }} />} />

            <Route exact path="/ventasEnAdministracion" render={props => <ReviewSales {...props} {...{ pageSaleState: PageSaleState.InAdministration }} />} />

            <Route exact path="/reporteFLEX" render={props =>
                this.props.user.role == "Admin"
                    || this.props.user.role == "SellerLead" || this.props.user.role == "Administrative"?
                    <Reports {...props} {...{ pageSaleState: PageSaleState.Report }} /> :
                    <Redirect to="/home" />
            } />

            <Route exact path="/ventasListasParaEmpaquetar" render={props =>
                this.props.user.role == "Admin"
                    || this.props.user.role == "Packer" ?
                    <ReviewSales {...props} {...{ pageSaleState: PageSaleState.ReadyForPacking}} /> :
                    <Redirect to="/home" />
                } />

            {/*//BACK 157*/}
            <Route exact path="/ventasListasParaEmpaquetarACNoMeli" render={props =>
                this.props.user.role == "Admin"
                    || this.props.user.role == "Packer" ?
                    <ReviewSales {...props} {...{ pageSaleState: PageSaleState.ReadyForPackingAcNoMeli}} /> :
                    <Redirect to="/home" />
            } />

            <Route exact path="/ventasEmpaquetadas" render={props =>
                this.props.user.role == "Admin"
                    || this.props.user.role == "Packer" ?
                    <ReviewSales {...props} {...{ pageSaleState: PageSaleState.Packed }} /> :
                    <Redirect to="/home" />
            } />

            <Route exact path="/registrarEnviosMasivos" render={props =>
                this.props.user.role == "Admin"
                    || this.props.user.role == "Packer" ?
                    <MassShipments {...props} {...{ pageSaleState: PageSaleState.Packed }} /> :
                    <Redirect to="/home" />
            } />

            <Route exact path="/servicios" render={props =>
                this.props.user.role == "Admin"
                    || this.props.user.role == "SellerLead" || this.props.user.role == "Administrative" ?
                    <InfoServices {...props} {...{ pageSaleState: PageSaleState.Services }} /> :
                    <Redirect to="/home" />
            } />

            <Route path="/ventas/MercadoLibre/:idSale?" component={MLSale} />

            <Route path="/ventas/Link/:idSale?" render={props => <PaymentSale {...props} {...{ isLink: true }} />} />

            <Route path="/ventas/Transferencia/:idSale?" render={props => <PaymentSale {...props} {...{ isLink: false }} />} />

            <Route path="/ventas/Cash/:idSale?" render={props => <PaymentSale {...props} {...{ isCash: true }} />} />

            <Route path="/ventas/Fulljaus/:idSale?" component={FulljausSale} />

            <Route exact path="/ventas/detalleBusqueda/:idSale" render={props => <SaleDetail {...props} {...{ showNavBar: false }} />} />

            <Route exact path="/ventas/detalle/:idSale" render={props => <SaleDetail {...props} {...{ showNavBar: true }} />} />

            <Route path="/ventas/historia/:idSale?" component={History} />

            <Route path="/notificaciones/:idType" component={NotificationsTable} />

            <Route path="/tiemposElaboracion" component={MLManufacturing} />

            <Route path="/configuracion" component={Configuration} />

            <Route path="/crearUsuario" component={EditUser} />

            <Route path="/editarUsuario/:idUser" component={EditUser} />
            {
                localStorage.getItem('token') === null ?
                    <Redirect to="/login" />
                    : window.location.href.indexOf("/login") > 0 ?
                        <Redirect to="/home" />
                        : <></>
            }

        </React.Suspense>
    }

}



export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(AppRoutes as any);
