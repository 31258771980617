import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UserState } from '../../store/Common/types';
import { actionCreators } from '../../store/Common/actions';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import Notifications from './Notifications';
import { Spinner, Overlay } from '@blueprintjs/core';
declare var require: any;

// At runtime, Redux will merge together...
type NavBarProps =
    UserState // ... state we've requested from the Redux store
    & typeof actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

class Navbar extends React.Component<NavBarProps> {
    state: {
        interval: any
    } = { interval: null }

    componentDidMount() {

        if (this.state.interval != null) {
            clearInterval(this.state.interval);
        }
        else {
            this.props.getUserData();
        }

        const interval = setInterval(() => {
            this.props.getUserData();
        }, 30 * 1000);

        this.setState({ interval: interval });
    }

    toggleOffcanvas() {
        let canvas = document.querySelector('.sidebar-offcanvas');
        if (canvas != null) {
            canvas.classList.toggle('active');
        }
    }

    render() {
        return (
            <>
                {this.props.loading ?
                    <Overlay isOpen={true} canEscapeKeyClose={false}>
                        <Spinner intent={"primary"} size={200} />
                    </Overlay>: <></>}
            <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                    <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}><img src={require("../../assets/images/logo-mini.svg")} alt="logo" /></a>
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                        <i className="mdi mdi-menu"></i>
                    </button>

                    <ul className="navbar-nav navbar-nav-right ml-lg-auto">
                            <li className="nav-item  nav-profile border-0 pl-4">
                                {this.props.user.testHeader}
                            </li>
                            <li className="nav-item  nav-profile border-0 pl-4">
                            <Notifications />
                        </li>
                        <li className="nav-item  nav-profile border-0">
                            <Dropdown alignRight>
                                <Dropdown.Toggle id="4" className="nav-link count-indicator bg-transparent">
                                    <span className="profile-text">{this.props.user.name} </span>
                                    <span className="img-xs rounded-circle" ><i className="fa fa-user-circle-o"></i> </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={(evt: any) => this.props.logout()}>
                                            Cerrar sesión
                                    </Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0">
                                            <span className="profile-text">{this.props.user.versionApp} </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
                </nav>
            </>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(Navbar as any);
