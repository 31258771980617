import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, SaleProduct, MeLiSaleDetailContainer, EditingSaleProduct, SaleDifference, LinkSaleDetailContainer, Sales, TransferSaleDetailContainer, IContainer, Message, SaleMessage, Product, Packer, SaleConfirmationPackage, Driver, CashSaleDetailContainer, Province, City, Company, DeliveryType } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ReactEventHandler, FormEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { Position, Toaster, MenuItem, FileInput, Dialog, Classes, Icon, Tooltip } from "@blueprintjs/core";
import { AppToaster } from "../shared/toaster"
import { Suggest, ItemRenderer, ISuggestProps } from "@blueprintjs/select";
import MLMessage from './MLMessage';
import ReactHtmlParser from 'react-html-parser';
import { isEqual } from 'lodash';

const coversorNumeroALetras = require('numero-a-letras');

type MLSaleProps =
	UserState
	& SalesState
	& typeof commonActionCreators.actionCreators
	& typeof salesActionCreators.actionCreators
	& RouteComponentProps<{ idSale: string }>
	& { showNavBar: boolean }
	;


class SaleDetail extends React.Component<MLSaleProps> {

	state: {
		saleProducts: EditingSaleProduct[],
		showConfirmationModal: boolean,
		confirmationModalText: string,
		confirmationModalOkText: string,
		showEmptyDifferenceConfirmationModal: boolean,
		confirmationAction: Function,
		showPasswordConfirmation: boolean,
		selectedAttachmentToRemove: number,
		selectedAttachmentToRemoveTransferType: string,
		password: string,
		accordionItem: string,
		fileSizeError: boolean,
		fileTypeError: boolean,
		showMessages: boolean,
		newSaleMessage: string,
		currentSaleDetailToSave: IContainer | null,
		currentSaleType: string,
		packers: string[],
		packetCount: { [id: string]: string; },
		originalPacketCount: { [id: string]: string; },
		showAlertPackageChange: boolean,
		showPackagePasswordConfirmation: boolean,
		showReopenPasswordConfirmation: boolean,
		showReturnConfirmation: boolean,
		showConfirmIncompleteOrder: boolean,
		showConfirmOrderWitClaim: boolean,
		showConfirmDeleteSale: boolean,
		showDeleteSaleConfirmationPassword: boolean,
		interval: any,
		intervalMessages: any,
		packageQuantity: number,
		driver: Driver | null,
		showPopUp: boolean,
		messagePopup: string,
		//BACK 145
		salesChannel: string,
		deliveryMethod: string,
		newCompany: boolean,
		address: string,
		provinceId: number,
		companyName: string,
		companyAddress: string,
		companyId: number,
		companyTriplicateRemittance: boolean,
		businessName: string,
		email: string,
		documentType: string,
		cityId: number,
		postalCode: string,
		phone: string,
		cuil: string,
		insurance: string,
		invoice: string,
		//BACK 200
		equalCUIT: boolean,
		//BACK 79
		isbreakdown: boolean,
		breakdownProducts: EditingSaleProduct[],
		productBreakdown: EditingSaleProduct | null,
		ignoreDifference: boolean,
		originalIndex: number,
		breakdownWithDifference: boolean,
		quantityBreakdownPack: number,
		//267
		showGreaterAmountConfirmationModal: boolean,
	} = {
			saleProducts: [],
			confirmationModalText: '',
			confirmationModalOkText: '',
			showConfirmationModal: false,
			confirmationAction: () => { },
			showPasswordConfirmation: false,
			selectedAttachmentToRemove: 0,
			selectedAttachmentToRemoveTransferType: '',
			password: '',
			accordionItem: '',
			fileSizeError: false,
			fileTypeError: false,
			showMessages: false,
			newSaleMessage: '',
			showEmptyDifferenceConfirmationModal: false,
			currentSaleDetailToSave: null,
			currentSaleType: '',
			packers: [],
			packetCount: {},
			originalPacketCount: {},
			showAlertPackageChange: false,
			showPackagePasswordConfirmation: false,
			showReopenPasswordConfirmation: false,
			showReturnConfirmation: false,
			showConfirmIncompleteOrder: false,
			showConfirmOrderWitClaim: false,
			interval: null,
			intervalMessages: null,
			packageQuantity: 1,
			showConfirmDeleteSale: false,
			showDeleteSaleConfirmationPassword: false,
			driver: null,
			showPopUp: false,
			messagePopup: "",
			//BACK 145
			salesChannel: "0",
			deliveryMethod: "0",
			newCompany: false,
			address: "",
			provinceId: 0,
			companyName: "",
			companyAddress: "",
			companyId: 0,
			companyTriplicateRemittance: false,
			businessName: "",
			email: "",
			documentType: "0",
			cityId: 0,
			postalCode: "",
			phone: "",
			cuil: "",
			insurance: "",
			invoice: "",
			//BACK 200
			equalCUIT: false,
			//BACK 79
			isbreakdown: false,
			breakdownProducts: [],
			productBreakdown: null,
			ignoreDifference: false,
			originalIndex: 0,
			breakdownWithDifference: false,
			quantityBreakdownPack: 0,
			//BACK 267
			showGreaterAmountConfirmationModal: false,
		};

	private isCurrentSaleEditable = () => {
		if (this.props.user.role == "Admin"
			|| this.props.user.role == "Seller"
			|| this.props.user.role == "SellerLead") {
			if (this.props.currentSale != null) {
				return this.props.currentSale.state == 'InReview';
			}
		}
		return false;
	}

	//BACK 54
	private isCurrentInReviewInAdministration = () => {
		if (this.props.currentSale != null && (this.props.currentSale.state == 'InReview' || this.props.currentSale.state == 'InAdministration'))
			return true;
		else return false;
	}
	//BACK 145
	// VALIDO SI esta en revisión NO TIENE ORDENES DE MELI MUESTRO FORMULARIO. SI TIENEN ME FIJO QUE NO TENGAN COLECTA NI FLEX
	private isCurrentFlexColecta = () => {
		if (this.props.currentSale != null && (this.props.currentSale.state == 'InReview' || this.props.currentSale.state == 'InAdministration')) {
			if (this.props.currentSale != null && !this.props.currentSale.hasMeLiOrder)
				return false;
			else if (this.props.currentSale != null
				&& (this.props.currentSale.meLiSaleDetails.findIndex(x => x.deliveryType == "Collect") > -1
					|| this.props.currentSale.meLiSaleDetails.findIndex(x => x.deliveryType == "Flex") > -1))
				return true;
			else return false;
		}
		else
			return false;
	}

	private isCurrentFlexColectaReadyForPacking = () => {
		// VALIDO SI es solo colecta
		//if (this.props.currentSale != null && this.props.currentSale.state != 'InReview' && this.props.currentSale.state != 'InAdministration')
		//	return true;
		if (this.props.currentSale != null && this.props.currentSale.state == 'ReadyForPacking') {
			if (this.props.currentSale != null && !this.props.currentSale.hasMeLiOrder)
				return false;
			else if (this.props.currentSale != null
				&& (this.props.currentSale.meLiSaleDetails.findIndex(x => x.deliveryType == "Collect") > -1
					|| this.props.currentSale.meLiSaleDetails.findIndex(x => x.deliveryType == "Flex") > -1))
				return true;
			else return false;
		}
		else
			return false;
	}

	private isCurrentToBeAgreed = () => {

		if (this.props.currentSale != null && (this.props.currentSale.state == 'InReview' || this.props.currentSale.state == 'InAdministration')) {
			if (this.props.currentSale != null && !this.props.currentSale.hasMeLiOrder) {
				return false;
			}
			else if (this.props.currentSale != null
				&& (this.props.currentSale.meLiSaleDetails.findIndex(x => x.deliveryType == "Collect") > -1
					|| this.props.currentSale.meLiSaleDetails.findIndex(x => x.deliveryType == "Flex") > -1)) {
				return false;
			}
			else {
				this.state.salesChannel = "Meli";
				return true;
			}
		}
		else
			return false;
	}

	private isCurrentNotHasMeLiOrder = () => {
		if (this.props.currentSale != null && this.props.currentSale.state != 'InReview' && this.props.currentSale.state != 'InAdministration')
			return false;
		// VALIDO SI esta en revisión NO TIENE ORDENES DE MELI MUESTRO FORMULARIO. SI TIENEN ME FIJO QUE NO TENGAN COLECTA NI FLEX
		if (this.props.currentSale != null && !this.props.currentSale.hasMeLiOrder)
			return true;
		else {
			return false;
		}
	}

	private isEmailValid() {
		if (this.state.email.length == 0) {
			return true;
		}
		if (this.isCurrentToBeAgreed()) {
			return true;
		}
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return regexp.test(this.state.email);
	}

	private telValidate() {
		if (this.state.phone.length == 0) {
			return true;
		}
		//eliminamos todo lo que no es dígito

		var num = this.state.phone.replace(/\D+/, '');

		var ex_regular_phone = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
		//devolver si coincidió con el regex
		if (ex_regular_phone.test(num) == true) {
			return true;
		} else {
			return false;
		}
	}

	private dniValidator() {
		if (this.state.cuil.length == 0) {
			return true;
		}
		var ex_regular_dni = /^\d{8}(?:[-\s]\d{4})?$/;
		if (ex_regular_dni.test(this.state.cuil) == true) {
			return true;
		} else {
			return false;
		}
	}
	//BACK 301
	private cuilValidator(validate: number) {
		switch (validate) {
			case 1:
				if (this.state.cuil.length == 0) {
					return true;
				}
				else if (this.state.cuil.length == 11 || this.state.cuil.length == 13) {
				//else if (this.state.cuil.length !== 11) {
					const [checkDigit, ...rest] = this.state.cuil.replace(/-/g, '')
						.split('')
						.map(Number)
						.reverse();

					const total = rest.reduce(
						(acc, cur, index) => acc + cur * (2 + (index % 6)),
						0,
					);

					const mod11 = 11 - (total % 11);

					if (mod11 === 11) {
						return checkDigit === 0;
					}

					if (mod11 === 10) {
						return false;
					}

					return checkDigit === mod11;
				}
				else {
					return false;
				}
			case 2:
				if (this.state.invoice.length == 0) {
					return true;
				}
				else if (this.state.invoice.length == 11 || this.state.invoice.length == 13) {
					const [checkDigit, ...rest] = this.state.invoice.replace(/-/g, '')
						.split('')
						.map(Number)
						.reverse();

					const total = rest.reduce(
						(acc, cur, index) => acc + cur * (2 + (index % 6)),
						0,
					);

					const mod11 = 11 - (total % 11);

					if (mod11 === 11) {
						return checkDigit === 0;
					}

					if (mod11 === 10) {
						return false;
					}

					return checkDigit === mod11;
				}
				else {
					return false;
				}
			default:
				return false;
		}
	}

	private isUserAbleToEditSale = () => {
		return this.props.user.role == "Admin"
			|| this.props.user.role == "Seller"
			|| this.props.user.role == "SellerLead";
	}

	constructor(props: MLSaleProps) {
		super(props);
	}

	getClaim() {
		if (this.props.currentSale != null) {
			console.log('Current sale:', this.props.currentSale.id);
			this.props.getClaimStatus(this.props.currentSale.id);
		}
	}

	getProducts = (saleContainer: IContainer) => {
		if (saleContainer.differences.length > 0) {
			for (var i = saleContainer.differences.length - 1; i >= 0; i--) {
				let difference = saleContainer.differences[i];
				if (!difference.isDeleted) {
					return difference.saleDetails;
				}
			}
			return saleContainer.saleDetails;
		} else {
			return saleContainer.saleDetails;
		}
	}

	processSumaryElement = (saleContainer: IContainer, result: { [id: string]: SaleProduct }) => {
		let getKey = (saleProduct: SaleProduct) => `${saleProduct.sku}_${saleProduct.price}`
		this.getProducts(saleContainer).forEach((saleProduct: SaleProduct) => {
			let value = result[getKey(saleProduct)];
			if (value != null) {
				value.quantity += saleProduct.quantity;
			}
			else {
				result[getKey(saleProduct)] = { ...saleProduct };
			}
		});
	}

	getSummaryElements = (currentSale: Sales | null) => {
		let result: { [id: string]: SaleProduct } = {};
		if (currentSale != null) {
			currentSale.meLiSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElement(saleContainer, result);
				}
			});
			currentSale.linkSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElement(saleContainer, result);
				}
			});
			currentSale.transferSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElement(saleContainer, result);
				}
			});
			currentSale.cashSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElement(saleContainer, result);
				}
			});
		}

		return result;
	}

	componentWillUnmount() {
		console.log('component will unmount');
		if (this.state.interval != null) {
			clearInterval(this.state.interval);
		}

		if (this.state.intervalMessages != null) {
			clearInterval(this.state.intervalMessages);
		}

		this.setState({
			intervalMessages: null,
			interval: null,
			showEmptyDifferenceConfirmationModal: false,
			currentSaleDetailToSave: null,
			currentSaleType: '',
			//nuevo
			salesChannel: "0",
			deliveryMethod: "0",
			newCompany: false,
			address: "",
			provinceId: 0,
			companyName: "",
			companyAddress: "",
			companyId: 0,
			companyTriplicateRemittance: false,
			businessName: "",
			email: "",
			documentType: "0",
			cityId: 0,
			postalCode: "",
			phone: "",
			cuil: "",
			insurance: "",
			originalIndex: 0,
			breakdownWithDifference: false,
			quantityBreakdownPack: 0,
			//BACK 267
			showGreaterAmountConfirmationModal: false,
		});
	}


	componentDidMount() {
		const intervalMessages = setInterval(() => {
			if (this.props.currentSale != null) {
				this.props.getIternalMessages(this.props.currentSale.id);
			}
		}, 30 * 1000);

		const interval = setInterval(() => this.getClaim(), 10 * 1000);

		this.setState({ intervalMessages: intervalMessages, interval: interval });
		//if (this.state.interval != null) {
		//    clearInterval(this.state.interval);
		//}

		//if (this.state.intervalMessages != null) {
		//    clearInterval(this.state.intervalMessages);
		//}
		if (this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale)) {
			this.props.getSale(parseInt(this.props.match.params.idSale));
		}
		else if (this.props.currentSale != null) {
			this.setState({
				newSaleMessage: ''
			});

			//this.props.getSale(parseInt(this.props.match.params.idSale));

			let sales = this.getMainSale(this.props.currentSale);
			if (sales != null) {
				this.updateEditing(sales, this.getDefaultActiveKey(this.props.currentSale));
			}
			if (this.props.currentSale.state == "ReadyForPacking"
				&& Object.keys(this.state.originalPacketCount).length == 0) {
				this.props.getSale(parseInt(this.props.match.params.idSale));
			}

			//BACK 63
			let currentSale = this.props.currentSale;
			if (currentSale != null) {
				this.state.businessName = this.state.businessName == "" ? currentSale.client : this.state.businessName
				this.state.email = this.state.email == "" ? currentSale.user : this.state.email
				this.state.salesChannel = this.isCurrentToBeAgreed() ? "Meli" : "0"
				this.state.invoice = this.state.invoice == "" ? currentSale.invoice : this.state.invoice
			}

			if (currentSale.saleInformation != null) {
				this.setState({
					salesChannel: currentSale.saleInformation.salesChannel,
					deliveryMethod: currentSale.saleInformation.deliveryMethod,
					companyId: currentSale.saleInformation.companyId
				});

				if (currentSale.saleInformation.client != null) {
					this.setState({
						email: currentSale.saleInformation.client.eMail,
						businessName: currentSale.saleInformation.client.name,
						documentType: currentSale.saleInformation.client.documentType,
						cuil: currentSale.saleInformation.client.documentNumber,
						cityId: parseInt(currentSale.saleInformation.client.locationId),
						postalCode: currentSale.saleInformation.client.postalCode,
						phone: currentSale.saleInformation.client.telephone,
						insurance: currentSale.saleInformation.client.insurance,
						provinceId: parseInt(currentSale.saleInformation.client.provinceId),
						address: currentSale.saleInformation.client.address
					});
				}
			}

			//BACK 200
			if (this.state.equalCUIT) {
				this.setState({ documentType: "CUIT/CUIL" });
				this.setState({ cuit: this.state.invoice });
			}

		}

		if (this.props.products.length == 0) {
			this.props.getProducts();
		}

		if (this.props.drivers.length <= 0) {
			this.props.getDrivers();
		}
		//BACK 145
		if (this.props.provinces.length <= 0) {
			this.props.getProvinces();
		}

		if (this.props.cities.length <= 0) {
			this.props.getCities();
		}
		this.props.getCompanies();
		//if (this.props.companies.length <= 0) {

		//}
	}


	getMainSale(currentSale: Sales) {
		let result: IContainer | null = null;
		currentSale.meLiSaleDetails.forEach((value: MeLiSaleDetailContainer, index: number) => {
			if (value.isMainSale) {
				result = value;
			}
		});

		if (result == null) {
			currentSale.transferSaleDetails.forEach((value: TransferSaleDetailContainer, index: number) => {
				if (value.isMainSale) {
					result = value;
				}
			});
		}

		if (result == null) {
			currentSale.linkSaleDetails.forEach((value: LinkSaleDetailContainer, index: number) => {
				if (value.isMainSale) {
					result = value;
				}
			});
		}
		if (result == null) {
			currentSale.cashSaleDetails.forEach((value: CashSaleDetailContainer, index: number) => {
				if (value.isMainSale) {
					result = value;
				}
			});
		}
		return result;
	}

	getDefaultActiveKey(currentSale: Sales): string {
		let result = "";
		currentSale.meLiSaleDetails.forEach((value: MeLiSaleDetailContainer, index: number) => {
			if (value.isMainSale) {
				result = `meLi${index}`;
			}
		});

		if (result == "") {
			currentSale.transferSaleDetails.forEach((value: TransferSaleDetailContainer, index: number) => {
				if (value.isMainSale) {
					result = `transfer${index}`;
				}
			});
		}

		if (result == "") {
			currentSale.linkSaleDetails.forEach((value: LinkSaleDetailContainer, index: number) => {
				if (value.isMainSale) {
					result = `link${index}`;
				}
			});
		}

		if (result == "") {
			currentSale.cashSaleDetails.forEach((value: CashSaleDetailContainer, index: number) => {
				if (value.isMainSale) {
					result = `cash${index}`;
				}
			});
		}
		console.log(result);
		return result;
	}

	getViewPublication(sku: string) {
		var result = this.props.allProducts.find((product: Product) => product.name == sku);
		if (result == undefined) {
			return <></>
		}

		return <a href={`https://articulo.mercadolibre.com.ar/${result.mla}`} target="_blank"> Ver Publicación</a>;
	}

	componentWillReceiveProps(nextProps: MLSaleProps) {
		if (nextProps.currentSale != null &&
			(this.props.currentSale == null || nextProps.currentSale.id != this.props.currentSale.id)) {
			this.setState({ packers: [], packageQuantity: nextProps.currentSale.packageNumbers.length });
			let sales = this.getMainSale(nextProps.currentSale);

			if (sales != null) {
				this.updateEditing(sales, this.getDefaultActiveKey(nextProps.currentSale));
			}
		}

		if (nextProps.loadedWitouthDifference == true) {
			if (!isEqual(this.props.currentSale, nextProps.currentSale)) {
				//let sales = this.getMainSale(nextProps.currentSale);
				this.getSummaryElementsBreackdown(nextProps.currentSale);
				this.props.dismissloadedWitouthDifference();
            }
			//if (this.props.currentSale.differences[this.props.currentSale.differences.length - 1].saleDetails.length != nextProps.currentSale.differences[nextProps.currentSale.differences.length - 1].saleDetails.length) {
				

			//if (sales != null) {
			//	this.props.getSale(parseInt(nextProps.match.params.idSale));

			//	//this.updateEditing(sales, this.getDefaultActiveKey(nextProps.currentSale));
			//}
        }

		if (nextProps.currentSale != null &&
			nextProps.currentSale.id != parseInt(nextProps.match.params.idSale)
			&& nextProps.match.params.idSale != this.props.match.params.idSale) {
			this.props.getSale(parseInt(nextProps.match.params.idSale));
			this.setState({ showMessages: false });
		}

		if (nextProps.confirmedSuccess && nextProps.currentSale != null) {
			if (nextProps.currentSale.state == 'ReadyForPacking') {
				AppToaster.show({ intent: "success", message: "Venta Enviada a empaquetar" });
				this.props.history.push(`/ventasEnAdministracion`);
			}
			else {
				AppToaster.show({ intent: "success", message: "Venta Envidada a administración" });
				this.props.history.push(`/ventas`);
			}
			this.props.dismissConfirmSuccess();
		}

		if (nextProps.packedSucess) {
			AppToaster.show({ intent: "success", message: "Se registró la información de empaquetamiento" });
			this.props.history.push(`/ventasListasParaEmpaquetar`);
			this.props.dismissPackedSucess();
		}

		if (nextProps.saleShippedSuccess) {
			AppToaster.show({ intent: "success", message: "Venta Marcada como despachada" });
			this.props.history.push(`/ventasEmpaquetadas`);
			this.props.dismissShippedSucess();
		}

		if (nextProps.saleDeletedSuccess) {
			AppToaster.show({ intent: "success", message: "Venta Eliminada correctamente" });
			this.props.history.push(`/ventas`);
			this.props.dismissDeleteSale();
		}

		if (nextProps.confirmedReopen) {
			AppToaster.show({ intent: "success", message: "Venta devuelta al vendedor" });
			if (this.props.currentSale != null && this.props.currentSale.state == "InAdministration") {
				this.props.history.push(`/ventasEnAdministracion`);
			}
			else {

				if (this.isCurrentFlexColectaReadyForPacking()) {
					this.props.history.push(`/ventasListasParaEmpaquetar`);
				}
				else {
					this.props.history.push(`/ventasListasParaEmpaquetarACNoMeli`);
				}
			}

			this.props.dismissReopenSaleSuccess();
		}

		//BACK 142
		if (nextProps.customMessagePopUp.length > 0) {
			this.setState({
				showPopUp: true,
				messagePopup: nextProps.customMessagePopUp
			});
			this.props.dismissCustomMessagePopUp();
		}

		if (nextProps.currentSale != null
			//&& nextProps.currentSale.state == 'ReadyForPacking'
			&& nextProps.packers.length <= 0) {
			this.props.loadPackers(false);
		}

		if (nextProps.currentSale != null
			&& (this.props.currentSale == null
				|| nextProps.currentSale.id != this.props.currentSale.id
				|| nextProps.currentSale.state != this.props.currentSale.state)) {

			let currentSale = nextProps.currentSale;

			let paket: { [id: string]: string; } = {};
			let originalPacketCount: { [id: string]: string; } = {};

			Object.values(this.getSummaryElements(currentSale)).forEach((saleProduct: SaleProduct) => {
				paket[saleProduct.sku] = saleProduct.quantity.toString();
				originalPacketCount[saleProduct.sku] = saleProduct.quantity.toString();
			});

			if (currentSale != null) {
				this.state.businessName = this.state.businessName == "" ? currentSale.client : this.state.businessName
				this.state.email = this.state.email == "" ? currentSale.user : this.state.email
				this.state.salesChannel = this.isCurrentToBeAgreed() ? "Meli" : this.state.salesChannel
				this.state.invoice = this.state.invoice == "" ? currentSale.invoice : this.state.invoice
			}

			this.setState({ packetCount: paket, originalPacketCount: originalPacketCount });
			if (currentSale.saleInformation != null) {
				this.setState({
					salesChannel: currentSale.saleInformation.salesChannel,
					deliveryMethod: currentSale.saleInformation.deliveryMethod,
					companyId: currentSale.saleInformation.companyId
				});

				if (currentSale.saleInformation.client != null) {
					this.setState({
						email: currentSale.saleInformation.client.eMail,
						businessName: currentSale.saleInformation.client.name,
						documentType: currentSale.saleInformation.client.documentType,
						cuil: currentSale.saleInformation.client.documentNumber,
						cityId: parseInt(currentSale.saleInformation.client.locationId),
						postalCode: currentSale.saleInformation.client.postalCode,
						phone: currentSale.saleInformation.client.telephone,
						insurance: currentSale.saleInformation.client.insurance,
						provinceId: parseInt(currentSale.saleInformation.client.provinceId),
						address: currentSale.saleInformation.client.address
					});
				}
			}

			//BACK 200
			if (this.state.equalCUIT) {
				this.setState({ documentType: "CUIT/CUIL" });
				this.setState({ cuit: this.state.invoice });
			}
		}

		let currentSale = this.props.currentSale;
		let nextCurrentSale = nextProps.currentSale;

		if (nextCurrentSale != null &&
			currentSale != null &&
			currentSale.id == nextCurrentSale.id) {
			currentSale.meLiSaleDetails.forEach((meliSale: MeLiSaleDetailContainer) => {
				if (nextCurrentSale != null) {
					let nextSale = nextCurrentSale.meLiSaleDetails.find((nextMeliSale: MeLiSaleDetailContainer) => meliSale.id == nextMeliSale.id);
					//console.log(nextSale.claimStatus, meliSale.claimStatus);
					if (nextSale != undefined && nextSale.claimStatus != meliSale.claimStatus) {
						if (nextSale.claimStatus == 'ClaimOpen') {
							AppToaster.show({
								intent: "danger", message: `La orden #${nextSale.orderNumber} tiene un reclamo abierto`
							});
						}
						else if (nextSale.claimStatus == 'OrderCancelled') {
							AppToaster.show({
								intent: "danger", message: `La orden #${nextSale.orderNumber} ha sido cancelada`
							});
						}
					}
				}
			});
		}
	}

	private enableEdit(saleProduct: EditingSaleProduct, index: number) {
		let saleProducts = [...this.state.saleProducts];

		//Paso todo a false para luego solo habilitar el seleccionado
		saleProducts.forEach((item: EditingSaleProduct) => { item.isEditing = false; })

		saleProducts[index].isEditing = true;
		this.props.refreshProduct(saleProducts[index].sku, saleProducts[index].sku)
		this.setState({
			saleProducts: saleProducts,
			productBreakdown: null,
			isbreakdown: false,
			breakdownProducts: [],
			originalIndex: 0,
			breakdownWithDifference: false,
			quantityBreakdownPack: 0
		});
	}

	//BACK 79
	private saveBreakdown(saleProduct: EditingSaleProduct) {
		
		let breakdownProducts = [...this.state.breakdownProducts];

		var result = breakdownProducts.find((product: EditingSaleProduct) => product.sku == saleProduct.sku);
		if (result != undefined) {
			AppToaster.show({
				intent: "danger", message: `El SKU que se intenta agregar ya existe dentro del desglose`
			});
			return false;
		}

		//Se quita validación por pedido de MATIAS. CASO: un carrito con 2 packs y se quiere cambiar uno x menos cantidad
		////Busco que el sku no se repita en otros productos
		//let saleProducts = [...this.state.saleProducts];
		//var indexProd = saleProducts.findIndex((product: EditingSaleProduct) => product.sku == saleProduct.sku);
		//if (indexProd > -1 && indexProd != this.state.originalIndex) {
		//	AppToaster.show({
		//		intent: "danger", message: `El SKU que se intenta agregar ya existe dentro de esta venta.`
		//	});
		//	return false;
		//}

		const cloneObj = { ...saleProduct };

		let acumQuantity = this.state.quantityBreakdownPack;
		if (this.state.productBreakdown != null  && this.state.productBreakdown.isPack) {
			acumQuantity += this.state.productBreakdown!.packSize * this.state.productBreakdown!.quantity;
        }
        else {
			acumQuantity += this.state.productBreakdown!.quantity;
		}

		this.setState({ quantityBreakdownPack: acumQuantity });
		breakdownProducts.push(cloneObj);

		let newProd = Object.assign({}, saleProduct);
		//this.state.productBreakdown.price = 0;
		//this.state.productBreakdown.quantity = 0;
		//this.state.productBreakdown.sku = '';
		newProd.price = 0;
		newProd.quantity = 0;
		newProd.sku = '';
		this.setState({ productBreakdown: newProd,  breakdownProducts: breakdownProducts });
		//this.setState({ breakdownProducts: breakdownProducts });
	}

	private setFinalBreakdownProducts(type : string, payType : string|null) {
		let saleProducts = [...this.state.saleProducts];
		let breakdownProducts = [...this.state.breakdownProducts];

		let skuOriginal = saleProducts[this.state.originalIndex].sku;
		let isBreakdown = this.state.isbreakdown
		let ignoreDifference = this.state.ignoreDifference;

		//Elimino el producto original que es el que edito o desgloso
		saleProducts.splice(this.state.originalIndex, 1);

		//y agregar los nuevos
		breakdownProducts.forEach((breakProd: EditingSaleProduct) => {
			saleProducts.push({ ...breakProd });
		});

		this.setState({
			saleProducts: saleProducts,
			showEmptyDifferenceConfirmationModal: false,
			//currentSaleDetailToSave: this.state.saleDetail,
			isbreakdown: false,
			breakdownProducts: [],
			originalIndex: 0,
			breakdownWithDifference: false,
			ignoreDifference: false,
			quantityBreakdownPack: 0,
			//BACK 267
			showGreaterAmountConfirmationModal: false
		});

		this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, this.state.currentSaleDetailToSave != null ? this.state.currentSaleDetailToSave.id : 0, type, saleProducts, payType, skuOriginal, isBreakdown, ignoreDifference);

    }

	private delBreakdown(index: number) {
		let breakdownProducts = [...this.state.breakdownProducts];

		let quantity = this.state.quantityBreakdownPack;
		if (breakdownProducts[index].isPack) {
			quantity = quantity - (breakdownProducts[index].packSize * breakdownProducts[index].quantity);
        }
        else {
			quantity = quantity - breakdownProducts[index].quantity;
        }


		breakdownProducts.splice(index, 1);
		this.setState({ breakdownProducts: breakdownProducts, quantityBreakdownPack: quantity });
	}

	private disableEdit(saleProduct: EditingSaleProduct, index: number, saleDetail: IContainer, type: string) {
		let saleProducts = [...this.state.saleProducts];
		for (var i = 0; i < saleDetail.saleDetails.length; i++) {
			if (saleProduct.sku == saleDetail.saleDetails.sort((a: SaleProduct, b: SaleProduct) => {
				var x = a.sku.toLowerCase();
				var y = b.sku.toLowerCase();
				if (x < y) { return -1; }
				if (x > y) { return 1; }
				return 0;
			})[i].sku && index != i) {
				AppToaster.show({
					intent: "danger", message: `No es posible guardar los cambios. El SKU ingresado ya existe en esta orden`
				});
				return false;
			}
        }

		saleProducts[index].isEditing = false;

		if (this.getTotalDifference(saleDetail) == 0) {

			this.setState({
				saleProducts: saleProducts,
				showEmptyDifferenceConfirmationModal: true,
				currentSaleDetailToSave: saleDetail,
				currentSaleType: type
			});
		}
		else {
			AppToaster.show({ intent: "success", message: "Elija la forma de pago para la diferencia a pagar" });
			this.setState({ saleProducts: saleProducts });
		}
	}

	//BACK 79
	private disableEditDesglose(index: number) {
		let saleProducts = [...this.state.saleProducts];
		saleProducts[index].isEditing = false;
		//asignamos el producto a editar..

		/*{ ...saleProducts[index] }*/
		//let newProd: Object.assign({}, this.state.productBreakdown);
		let newProd = Object.assign({}, saleProducts[index]);
		newProd.price = 0;
		newProd.quantity = 0;
		newProd.sku = '';
		newProd.isPack = false;
		newProd.packQuantity = 0;
		newProd.packSize = 0;
		this.setState({ productBreakdown: newProd , saleProducts: saleProducts, isbreakdown: !this.state.isbreakdown, originalIndex: index });
	}

	private endBreakdown(saleDetail: IContainer, type: string) {

		let originalProd = this.state.saleProducts[this.state.originalIndex];
		if (originalProd.isPack) {
			//BACK 272 a. Si el desglose es menor a al tamaño del pack, bloquear avanzar con el toaster rojo actual.
			if (this.state.quantityBreakdownPack < originalProd.packQuantity ) {
				AppToaster.show({
					intent: "danger", message: `La cantidad de unidades no puede ser menor a ` + originalProd.packQuantity
				});
				return false;
			}
			//b. Si el desglose es igual al tamaño del pack, permitir avanzar
			//No se hace nada
			//c.Si el desglose es mayor al tamaño del pack, alertar al usuario para que confirme:
			else if (this.state.quantityBreakdownPack > originalProd.packQuantity) {
				//"La cantidad de unidades desglosadas supera el tamaño del pack, desea continuar igualmente?"
				this.setState({
					showGreaterAmountConfirmationModal: true,
					currentSaleDetailToSave: saleDetail,
					currentSaleType: type,
					breakdownWithDifference: true
				});

				if (this.getTotalDifferenceBreakdown(saleDetail.saleDetails) == 0 || this.state.ignoreDifference) { 
					this.setState({
						breakdownWithDifference: false
					});
				}
                
				return false;
            }
        }

		if (this.getTotalDifferenceBreakdown(saleDetail.saleDetails) == 0 || this.state.ignoreDifference) {

			this.setState({
				showEmptyDifferenceConfirmationModal: true,
				currentSaleDetailToSave: saleDetail,
				currentSaleType: type,
				breakdownWithDifference : false
			});
		}
		else if (this.getTotalDifferenceBreakdown(saleDetail.saleDetails) < 0 && !this.state.ignoreDifference) {
			AppToaster.show({
				intent: "danger", message: `La diferencia a pagar es negativa, ignore la diferencia a pagar.`
			});
			return false;
		}
		else {
			this.setState({
				breakdownWithDifference: true,
				currentSaleDetailToSave: saleDetail
			});
			//deshabilito la posibilidad de ignorar diferencia de pago
			AppToaster.show({ intent: "success", message: "Elija la forma de pago para la diferencia a pagar" });
			return false;
		}
	}

	private setPriceBreakdown(price: string) {
		let productBreakdown = Object.assign({}, this.state.productBreakdown);  //{ ...this.state.productBreakdown }!;

		let value = 0;
		if (price != '' && price != undefined) {
			value = parseFloat(price);
		}	
		if (value < 0) {
			value = 0;
		}
		productBreakdown!.price = value;
		this.setState({ productBreakdown: productBreakdown });
	}

	private setPrice(price: string, index: number) {
		let saleProducts = [...this.state.saleProducts];
		let value = 0;
		if (price != '' && price != undefined) {
			value = parseFloat(price);
		}
		if (value < 0) {
			value = 0;
		}
		saleProducts[index].price = value;
		this.setState({ saleProducts: saleProducts });
	}

	private setProduct(productSku: string, index: number) {
		let saleProducts = [...this.state.saleProducts];

		let product = this.props.products.find((product: Product) => product.name == productSku);

		if (product != null) {
			saleProducts[index].price = product.price;
			saleProducts[index].sku = product.name;
		}
		else {
			saleProducts[index].sku = productSku;
		}


		this.setState({ saleProducts: saleProducts });
	}

	private setQuantity(quantity: string, index: number) {
		let saleProducts = [...this.state.saleProducts];

		let value = 0;
		if (quantity != '' && quantity != undefined) {
			value = parseInt(quantity);
		}
		if (value < 0) {
			value = 0;
		}
		saleProducts[index].quantity = value;
		this.setState({ saleProducts: saleProducts });
	}

	private setQuantityBreakdown(quantity: string) {
		
		let productBreakdown = Object.assign({}, this.state.productBreakdown); //{ ...this.state.productBreakdown }!;

		let value = 0;
		if (quantity != '' && quantity != undefined) {
			value = parseInt(quantity);
		}
		if (value < 0) {
			value = 0;
		}
		productBreakdown!.quantity = value;
		this.setState({ productBreakdown: productBreakdown });
	}
	
	//
	getSummaryElementsBreackdown = (currentSale: Sales | null) => {
		let result: { [id: string]: SaleProduct } = {};
		if (currentSale != null) {
			currentSale.meLiSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElementBreackdown(saleContainer, result);
				}
			});
			currentSale.linkSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElement(saleContainer, result);
				}
			});
			currentSale.transferSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElementBreackdown(saleContainer, result);
				}
			});
			currentSale.cashSaleDetails.forEach((saleContainer: IContainer) => {
				if (!saleContainer.isDeleted) {
					this.processSumaryElementBreackdown(saleContainer, result);
				}
			});
		}

		return result;
	}

	processSumaryElementBreackdown = (saleContainer: IContainer, result: { [id: string]: SaleProduct }) => {
		let saleProducts = this.getProducts(saleContainer);
		let accordionItem = null;
		if (this.props.currentSale != null) {
			accordionItem = this.getDefaultActiveKey(this.props.currentSale);
        } else {
			accordionItem = this.state.accordionItem;
        }
		

		//let accordionItem = this.getDefaultActiveKey(this.props.currentSale);

		this.setState({
			saleProducts: saleProducts.map((saleProduct: SaleProduct) => ({
				sku: saleProduct.sku,
				price: saleProduct.price,
				quantity: saleProduct.quantity,
				isEditing: false,
				isPack: saleProduct.isPack,
				packQuantity: saleProduct.packQuantity
			})),
			accordionItem: accordionItem
		})

	}

	private updateEditing(sale: IContainer, accordionItem: string) {
		let saleProducts = this.getProducts(sale);

		this.setState({
			saleProducts: saleProducts.map((saleProduct: SaleProduct) => ({
				sku: saleProduct.sku,
				price: saleProduct.price,
				quantity: saleProduct.quantity,
				isEditing: false,
				isPack: saleProduct.isPack,
				packQuantity: saleProduct.packQuantity
			})),
			accordionItem: accordionItem
		})
	}

	private updateEditingAccordion(accordionItem: string) {
		this.setState({
			accordionItem: accordionItem
		})
	}

	private getTotal = (saleDetail: IContainer) => {
		let total = 0;
		saleDetail.saleDetails.forEach((item: SaleProduct) => { total += (item.price * item.quantity); })
		return total.toFixed(2);

	}

	private getTotalProducts = (sales: SaleProduct[]) => {
		let total = 0;
		sales.forEach((item: SaleProduct) => { total += (item.price * item.quantity); })
		return total;
	}

	private getTotalSale = (saleProducts: SaleProduct[]) => {
		let total = 0;
		saleProducts.forEach((saleProduct: SaleProduct) => { total += saleProduct.price * saleProduct.quantity })
		return total;
	}

	//BACK 79
	private getTotalProductsBreakdown = (sales: EditingSaleProduct[]) => {
		let total = 0;
		sales.forEach((item: EditingSaleProduct) => { total += (item.price * item.quantity); })
		return total;
	}

	private getTotalSaleBreakdown = () => {
		let total = 0;
		
		this.state.breakdownProducts.forEach((saleProduct: EditingSaleProduct) => { total += saleProduct.price * saleProduct.quantity })
		return total;
	}

	private getTotalDifference = (sale: IContainer) => {
		let total = 0;
		let saleDetails = this.getProducts(sale);
		let resto = 0;
		saleDetails.forEach((saleProduct: SaleProduct, index: number) => { resto += ((saleProduct.price * saleProduct.quantity)); })
		this.state.saleProducts.forEach((saleProduct: EditingSaleProduct, index: number) => { total += ((saleProduct.price * saleProduct.quantity) ); })
		return total - resto;
	}

	//BACK 79
	private getTotalDifferenceBreakdown = (saleProducts: SaleProduct[]) => {
		if (this.state.breakdownProducts == null || this.state.breakdownProducts.length == 0) {
			return 0;
        }

		//Tengo que obtener si hay mas de un rpoducto el total menos el producto en edición
		let restoSaleProducts = [...this.state.saleProducts];
		let resto = 0;
		restoSaleProducts.splice(this.state.originalIndex,1);
		restoSaleProducts.forEach((saleProduct: EditingSaleProduct, index: number) => { resto += ((saleProduct.price * saleProduct.quantity)); })

		let totalBreackdown = this.getTotalSaleBreakdown() + resto;

		let totalSale = this.getTotalProductsBreakdown(this.state.saleProducts);
		return (totalBreackdown - totalSale);
	}

	//BACK-339
	private getTotalOrdenActive = () => {
		let total = 0;
		if (this.props.currentSale != null) {
			this.props.currentSale.meLiSaleDetails.forEach((item: MeLiSaleDetailContainer) => { if (!item.isDeleted) total++; });
			this.props.currentSale.linkSaleDetails.forEach((item: LinkSaleDetailContainer) => { if (!item.isDeleted) total++; });
			this.props.currentSale.transferSaleDetails.forEach((item: TransferSaleDetailContainer) => { if (!item.isDeleted) total++; });
			this.props.currentSale.cashSaleDetails.forEach((item: CashSaleDetailContainer) => { if (!item.isDeleted) total++; });
			this.props.currentSale.differences.forEach((item: SaleDifference) => { if (!item.isDeleted) total++; });

        }

		return total;
	}

	private removeMeLiSaleDetail(saleDetail: MeLiSaleDetailContainer) {
		if (saleDetail.hasActiveChilds) {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden y todas sus diferencias',
					confirmationModalOkText: 'Desestimar todo',
					confirmationAction: () => { this.props.toggleMeLiOrder(saleDetail.id) }
				});
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden, todas sus diferencias y se cancelará la venta ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleMeLiOrder(saleDetail.id) }
				});
			}
		}
		else {
			//BACK-339
			if (this.getTotalOrdenActive() > 1)
				this.props.toggleMeLiOrder(saleDetail.id);
            else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Atención: al desestimar este ítem se cancelará toda la venta. ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleMeLiOrder(saleDetail.id) }
				});
            }
		}
	}

	private removeLinkSaleDetail(saleDetail: LinkSaleDetailContainer) {
		if (saleDetail.hasActiveChilds) {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden y todas sus diferencias',
					confirmationModalOkText: 'Desestimar todo',
					confirmationAction: () => { this.props.toggleLink(saleDetail.id) }
				});
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden, todas sus diferencias y se cancelará la venta ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleLink(saleDetail.id) }
				});
			}
		}
		else {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				//BACK 142
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Si continua, el link de pago quedará CANCELADO y no se podrá revertir. ¿Desea proceder?',
					confirmationModalOkText: 'Continuar',
					confirmationAction: () => { this.props.toggleLink(saleDetail.id) }
				});
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Atención: al desestimar este ítem se cancelará toda la venta. ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleLink(saleDetail.id) }
				});
			}
		}
	}

	private removeLinkDifferenceCard(saleDifference: SaleDifference) {
		//BACK-339
		if (this.getTotalOrdenActive() > 1) {
			//BACK 142
			this.setState({
				showConfirmationModal: true,
				confirmationModalText: 'Si continua, el link de pago quedará CANCELADO y no se podrá revertir. ¿Desea proceder?',
				confirmationModalOkText: 'Continuar',
				confirmationAction: () => { this.props.toggleDifference(saleDifference.id) }
			});
		}
		else {
			this.setState({
				showConfirmationModal: true,
				confirmationModalText: 'Atención: al desestimar este ítem se cancelará toda la venta. ¿Estás seguro?',
				confirmationModalOkText: 'Desestimar',
				confirmationAction: () => { this.props.toggleDifference(saleDifference.id) }
			});
		}
	}

	private removeDifferenceCard(saleDifference: SaleDifference) {
		//BACK-339
		if (this.getTotalOrdenActive() > 1) {
			this.props.toggleDifference(saleDifference.id);
		}
		else {
			this.setState({
				showConfirmationModal: true,
				confirmationModalText: 'Atención: al desestimar este ítem se cancelará toda la venta. ¿Estás seguro?',
				confirmationModalOkText: 'Desestimar',
				confirmationAction: () => { this.props.toggleDifference(saleDifference.id) }
			});
		}
	}

	private removeTransferSaleDetail(saleDetail: TransferSaleDetailContainer) {
		if (saleDetail.hasActiveChilds) {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden y todas sus diferencias',
					confirmationModalOkText: 'Desestimar todo',
					confirmationAction: () => { this.props.toggleTransfer(saleDetail.id) }
				});
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden, todas sus diferencias y se cancelará la venta ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleTransfer(saleDetail.id) }
				});
			}
		}
		else {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				this.props.toggleTransfer(saleDetail.id);
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Atención: al desestimar este ítem se cancelará toda la venta. ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleTransfer(saleDetail.id) }
				});
			}
			
		}

	}

	private removeCashSaleDetail(saleDetail: CashSaleDetailContainer) {
		if (saleDetail.hasActiveChilds) {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden y todas sus diferencias',
					confirmationModalOkText: 'Desestimar todo',
					confirmationAction: () => { this.props.toggleCash(saleDetail.id) }
				});
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta Orden contiene Diferencias a pagar. Si procede se desestimarán la Orden, todas sus diferencias y se cancelará la venta ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleCash(saleDetail.id) }
				});
			}
		}
		else {
			//BACK-339
			if (this.getTotalOrdenActive() > 1) {
				this.props.toggleCash(saleDetail.id);
			}
			else {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Atención: al desestimar este ítem se cancelará toda la venta. ¿Estás seguro?',
					confirmationModalOkText: 'Desestimar',
					confirmationAction: () => { this.props.toggleCash(saleDetail.id) }
				});
			}
		}

	}

	private handleCloseConfirmation = () => {
		this.setState(
			{
				showConfirmationModal: false,
				confirmationAction: () => { }
			});
	}

	private handleEmptyDifferenceCloseConfirmation = () => {
		this.setState(
			{
				showEmptyDifferenceConfirmationModal: false
			});
	}

	//BACK 267
	private handleGreaterAmountCloseConfirmation = () => {
		this.setState(
			{
				showGreaterAmountConfirmationModal: false,
				breakdownWithDifference: false
			});
	}

	private handleGreaterAmountCloseAcceptConfirmation = () => {
		this.setState(
			{
				showGreaterAmountConfirmationModal: false,
				breakdownWithDifference: true
			});
	}

	private handleClosePasswordConfirmation = () => {
		this.setState(
			{
				showPasswordConfirmation: false,
				password: ''
			});
	}

	private handleClosePackagePasswordConfirmation = () => {
		this.setState(
			{
				showPackagePasswordConfirmation: false,
				password: ''
			});
	}


	private handleCloseReopenPasswordConfirmation = () => {
		this.setState(
			{
				showReopenPasswordConfirmation: false,
				password: ''
			});
	}

	private confirmAction = () => {
		this.state.confirmationAction();
		this.handleCloseConfirmation();
	}

	private removeAttachment = (saleDetailId: number, transferType: string) => {
		this.setState({
			showPasswordConfirmation: true,
			selectedAttachmentToRemove: saleDetailId,
			selectedAttachmentToRemoveTransferType: transferType
		});
	}

	private confirmationDialog = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showConfirmationModal}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>{this.state.confirmationModalText}</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => this.confirmAction()}>{this.state.confirmationModalOkText}</Button>
				</div>
			</div>
		</Dialog>
	}


	private emptyDifferenceConfirmationModal = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleEmptyDifferenceCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showEmptyDifferenceConfirmationModal}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Este cambio no generará una diferencia. Desea generar el cambio?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleEmptyDifferenceCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						if (!this.state.isbreakdown) {
							this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, this.state.currentSaleDetailToSave != null ? this.state.currentSaleDetailToSave.id : 0, this.state.currentSaleType, this.state.saleProducts, null, "", false, false);
							this.handleEmptyDifferenceCloseConfirmation();
						} else {
							this.setFinalBreakdownProducts(this.state.currentSaleType,null);
                        }
					}
					}
					>Aceptar cambios</Button>
				</div>
			</div>
		</Dialog>
	}

	//BACK 272
	private greaterAmountConfirmationModal = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleGreaterAmountCloseConfirmation}
			title="Confirmación"
			isOpen={this.state.showGreaterAmountConfirmationModal}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>La cantidad de unidades desglosadas supera el tamaño del pack, desea continuar igualmente?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleGreaterAmountCloseConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						if (!this.state.isbreakdown) {
							this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, this.state.currentSaleDetailToSave != null ? this.state.currentSaleDetailToSave.id : 0, this.state.currentSaleType, this.state.saleProducts, null, "", false, false);
							this.handleGreaterAmountCloseConfirmation();
						} else {
							if (this.state.breakdownWithDifference) {
								//deshabilito la posibilidad de ignorar diferencia de pago
								AppToaster.show({ intent: "success", message: "Elija la forma de pago para la diferencia a pagar" });
								this.handleGreaterAmountCloseAcceptConfirmation();
								return false;
                            }
                            else {
								this.setFinalBreakdownProducts(this.state.currentSaleType, null);
                            }
						}
					}
					}
					>Aceptar cambios</Button>
				</div>
			</div>
		</Dialog>
	}

	private messageDialog = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showMessages: false })}
			title="Confirmación"
			isOpen={this.state.showMessages}
			style={{ minWidth: 1000 }}
		>
			<div className={Classes.DIALOG_BODY}>
				<MLMessage {...this.props} />
			</div>
		</Dialog>
	}

	private renderProduct: ItemRenderer<Product> = (product, { handleClick, modifiers, query }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}
		const text = product.name;
		return (
			<MenuItem
				active={false}
				disabled={modifiers.disabled}
				label={`${product.stock}`}
				key={product.id}
				onClick={handleClick}
				text={this.highlightText(text, query)}
			/>
		);
	};

	private escapeRegExpChars(text: string) {
		return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}

	private highlightText(text: string, query: string) {
		let lastIndex = 0;
		const words = query
			.split(/\s+/)
			.filter(word => word.length > 0)
			.map(this.escapeRegExpChars);
		if (words.length === 0) {
			return [text];
		}
		const regexp = new RegExp(words.join("|"), "gi");
		const tokens: React.ReactNode[] = [];
		while (true) {
			const match = regexp.exec(text);
			if (!match) {
				break;
			}
			const length = match[0].length;
			const before = text.slice(lastIndex, regexp.lastIndex - length);
			if (before.length > 0) {
				tokens.push(before);
			}
			lastIndex = regexp.lastIndex;
			tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
		}
		const rest = text.slice(lastIndex);
		if (rest.length > 0) {
			tokens.push(rest);
		}
		return tokens;
	}

	private isInPackaging = () => {
		return this.props.currentSale != null
			&& this.props.currentSale.state == 'ReadyForPacking';
	}

	private isInPacked = () => {
		return this.props.currentSale != null
			&& this.props.currentSale.state == 'Packed';
	}

	private isAnyOrderFlex = () => {
		if (this.props.currentSale != null
			&& (this.props.currentSale.state == 'ReadyForPacking'
				|| this.props.currentSale.state == 'Packed'
				|| this.props.currentSale.state == 'Dispatched')) {

			return this.props.currentSale.meLiSaleDetails.some((value: MeLiSaleDetailContainer) => value.deliveryType == 'Flex');
		}
		return false;
	}

	private isAnyOrderCollect = () => {
		if (this.props.currentSale != null
			&& (this.props.currentSale.state == 'ReadyForPacking'
				|| this.props.currentSale.state == 'Packed'
				|| this.props.currentSale.state == 'Dispatched')) {

			return this.props.currentSale.meLiSaleDetails.some((value: MeLiSaleDetailContainer) => value.deliveryType == 'Collect');
		}
		return false;
	}

	private isPacked = () => {
		return this.props.currentSale != null
			&& this.props.currentSale.state == 'Packed';
	}
	private isDispatched = () => {
		return this.props.currentSale != null
			&& this.props.currentSale.state == 'Dispatched';
	}

	private isCancelled = () => {
		return this.props.currentSale != null
			&& this.props.currentSale.state == 'Cancelled';
	}

	private renderCreateItem = (query: string,
		active: boolean,
		handleClick: React.MouseEventHandler<HTMLElement>) => {
		return <MenuItem
			icon="add"
			text={`Agregar "${query}"`}
			onClick={handleClick}
			shouldDismissPopover={false}
		/>
	}

	private createProduct = (title: string): Product => {
		return { name: title, id: '', price: 0, stock: -1, mla: '', isActive: true, isPack : false, packSize: 0 };
	}


	private passwordConfirmationDialog = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleClosePasswordConfirmation}
			title="Confirmación"
			isOpen={this.state.showPasswordConfirmation}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Ingrese su contraseña para confirmar la eliminación</p>
				<Form.Group className="d-flex search-field">
					<Form.Control type="password" placeholder="Password" size="lg" className={this.props.invalidUserOrPassword ? "h-auto is-invalid" : "h-auto"} onChange={(e: any) => this.setState({ password: e.target.value })} value={this.state.password} />
				</Form.Group>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleClosePasswordConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.props.removeAttachment(this.state.selectedAttachmentToRemove, this.state.password, this.state.selectedAttachmentToRemoveTransferType);
						this.handleClosePasswordConfirmation();
					}}>Eliminar comprobante</Button>
				</div>
			</div>
		</Dialog>
	}

	private passwordConfirmationDialogForPackageChange = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleClosePackagePasswordConfirmation}
			title="Confirmación"
			isOpen={this.state.showPackagePasswordConfirmation}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Para poder continuar debe ingresar su contraseña</p>
				<Form.Group className="d-flex search-field">
					<Form.Control type="password" placeholder="Password" size="lg" className={this.props.invalidUserOrPassword ? "h-auto is-invalid" : "h-auto"} onChange={(e: any) => this.setState({ password: e.target.value })} value={this.state.password} />
				</Form.Group>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleClosePackagePasswordConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						if (this.props.currentSale != null) {
							this.props.markAsPacked(this.props.currentSale.id, this.state.packers, this.state.packetCount, true, this.state.password, this.state.driver);
						}
						this.handleClosePackagePasswordConfirmation();
					}}>Marcar como empaquetado</Button>
				</div>
			</div>
		</Dialog>
	}

	private passwordConfirmationDialogForReopenSale = () => {
		return <Dialog
			icon="info-sign"
			onClose={this.handleCloseReopenPasswordConfirmation}
			title="Confirmación"
			isOpen={this.state.showReopenPasswordConfirmation}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Ingrese su contraseña para confirmar la reapertura de la venta</p>
				<Form.Group className="d-flex search-field">
					<Form.Control type="password" placeholder="Password" size="lg" className={this.props.invalidUserOrPassword ? "h-auto is-invalid" : "h-auto"} onChange={(e: any) => this.setState({ password: e.target.value })} value={this.state.password} />
				</Form.Group>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={this.handleCloseReopenPasswordConfirmation}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						if (this.props.currentSale != null) {
							this.props.reopenSale(this.props.currentSale.id, this.state.password);
						}
						this.handleCloseReopenPasswordConfirmation();
					}}>Enviar al vendedor</Button>
				</div>
			</div>
		</Dialog>
	}


	private returnSaleConfirmation = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showReturnConfirmation: false })}
			title="Confirmación"
			isOpen={this.state.showReturnConfirmation}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>¿Está seguro que desea devolver la venta al vendedor?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showReturnConfirmation: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.setState({ showReturnConfirmation: false, showReopenPasswordConfirmation: true });
					}}>Enviar al vendedor</Button>
				</div>
			</div>
		</Dialog>
	}

	private confirmIncompleteOrder = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showConfirmIncompleteOrder: false })}
			title="Confirmación"
			isOpen={this.state.showConfirmIncompleteOrder}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>¿Está seguro que desea empaquetar la orden incompleta?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showConfirmIncompleteOrder: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.setState({ showConfirmIncompleteOrder: false, showPackagePasswordConfirmation: true });
					}}>Confirmar</Button>
				</div>
			</div>
		</Dialog>
	}

	private confirmDeleteSale = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showConfirmDeleteSale: false })}
			title="Confirmación"
			isOpen={this.state.showConfirmDeleteSale}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>¿Está seguro que desea eliminar la venta?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showConfirmDeleteSale: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.setState({ showConfirmDeleteSale: false, showDeleteSaleConfirmationPassword: true });
					}}>Confirmar</Button>
				</div>
			</div>
		</Dialog>
	}

	private passwordConfirmationDialogForDeleteSale = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showDeleteSaleConfirmationPassword: false })}
			title="Confirmación"
			isOpen={this.state.showDeleteSaleConfirmationPassword}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Ingrese su contraseña para eliminar la venta</p>
				<Form.Group className="d-flex search-field">
					<Form.Control type="password" placeholder="Password" size="lg" className={this.props.invalidUserOrPassword ? "h-auto is-invalid" : "h-auto"} onChange={(e: any) => this.setState({ password: e.target.value })} value={this.state.password} />
				</Form.Group>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showDeleteSaleConfirmationPassword: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						if (this.props.currentSale != null) {
							this.props.deleteSale(this.props.currentSale.id, this.state.password);
						}
						this.setState({ showDeleteSaleConfirmationPassword: false });
					}}>Aceptar</Button>
				</div>
			</div>
		</Dialog>
	}

	private confirmOrderWithClaim = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showConfirmOrderWitClaim: false })}
			title="Confirmación"
			isOpen={this.state.showConfirmOrderWitClaim}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>¿Está seguro que desea empaquetar la orden con un reclamo abierto?</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showConfirmOrderWitClaim: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.setState({ showConfirmOrderWitClaim: false, showPackagePasswordConfirmation: true });
					}}>Confirmar</Button>
				</div>
			</div>
		</Dialog>
	}
	//


	private getDetailProductCardTable = (saleProducts: SaleProduct[]) => {
		return <>
			<label>DETALLE:</label>
			<table className="table table-hover">
				<thead>
					<tr>
						<th>SKU</th>
						<th>Precio $</th>
						<th>Cantidad</th>
						<th>Subtotal</th>
					</tr>
				</thead>
				<tbody>
					{saleProducts.map((saleProduct: SaleProduct, index: number) => {
						return <tr key={index}>
							<td style={{ maxWidth: 200 }}>{saleProduct.sku}</td>
							<td>{saleProduct.price}</td>
							<td>{saleProduct.quantity}</td>
							<td>${(saleProduct.price * saleProduct.quantity).toFixed(2)}</td>
						</tr>
					}
					)}
				</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td></td>
						<td>Total</td>
						<td>$ {this.getTotalProducts(saleProducts).toFixed(2)}</td>
						<td></td>
					</tr>
				</tfoot>
			</table>
		</>
	}

	private renderStock = (saleProduct: EditingSaleProduct) => {
		{
			let currentProduct = this.props.products.find((product: Product) => product.name == saleProduct.sku);
			return currentProduct == null || !saleProduct.isEditing ? <></>
				: currentProduct.stock != -1 ? <Label>
					<strong> Stock en ML:</strong> {currentProduct.stock}
				</Label> : <Label>
								SKU no existe en ML. Buscar por título en ML o verificar en sistema POS
                           </Label>
		}
	}

	//BACK 79
	private renderBreakdown = (saleProduct: EditingSaleProduct, saleDetail: IContainer, type: string, index: number) => {
		{
			//|| this.state.productBreakdown.sku != saleProduct.sku
			let currentProduct = this.props.products.find((product: Product) => product.name == saleProduct.sku);

			if (currentProduct == null)
				currentProduct = this.createProduct(saleProduct.sku);

			let productOriginal = Object.values(this.getSummaryElements(this.props.currentSale)).find((product: SaleProduct) => product.sku == saleProduct.sku)

			return currentProduct == null || this.state.productBreakdown == null ? <></>
				: this.state.isbreakdown && index == this.state.originalIndex ?
					<>
						<table className="table table-hover" >
						<thead>
							<tr>
								<th>SKU</th>
								<th>Precio $</th>
								<th>Cantidad</th>
								<th style={{ textAlign: "center" }} >{saleProduct.isPack ? this.state.quantityBreakdownPack + " de " + saleProduct.packQuantity + " unidades" : ""  }</th>
							</tr>
						</thead>
							<tbody>
									<tr >
										<td className="table-sku-container-desglose">
											<Suggest
											items={this.props.products}
											popoverProps={{ minimal: true }}
											noResults={<MenuItem disabled={true} text="Producto inexistente" />}
											onItemSelect={(value: Product) => {
												
												let product = this.props.products.find((product: Product) => product.name == value.name);
												let auxProdBreak = Object.assign({}, this.state.productBreakdown);//{ ...this.state.productBreakdown }

												if (product != null) {
													auxProdBreak.price = product.price;
													auxProdBreak.sku = product.name;
													auxProdBreak.quantity = 0;
													auxProdBreak.isPack = product.isPack;
													auxProdBreak.packSize = product.packSize;
													this.setState({ productBreakdown: auxProdBreak });
												}
												else {
													auxProdBreak.price = 0;
													auxProdBreak.sku = value.name;
													auxProdBreak.quantity = 0;
													auxProdBreak.isPack = false;
													auxProdBreak.packSize = 0;
													this.setState({ productBreakdown: auxProdBreak });
												}
											}
											}
											itemsEqual={(iteamA: Product, itemB: Product) => iteamA.id == itemB.id}
											inputValueRenderer={(value: Product) => value.name}
											itemRenderer={this.renderProduct}
											itemPredicate={(query, product, _index, exactMatch) => { return product.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
											inputProps={{ placeholder: "Seleccione un producto" }}
											//selectedItem={this.props.products.find((product: Product) => { return this.state.productBreakdown.sku == "" ? null : product.name == this.state.productBreakdown.sku })}
											createNewItemFromQuery={this.createProduct}
											createNewItemRenderer={this.renderCreateItem}
											/>
										</td>
										<td><Form.Control type="number"
											className="form-control"
											value={this.state.productBreakdown != null && this.state.productBreakdown.price != undefined ? this.state.productBreakdown.price.toString() : saleProduct.price.toString()}
											onChange={(evt: React.ChangeEvent<FormControlProps>) => evt.currentTarget.value != undefined ? this.setPriceBreakdown(evt.currentTarget.value) : {}} />
										</td>
										<td><Form.Control type="number"
											className="form-control"
											value={this.state.productBreakdown != null && this.state.productBreakdown.quantity != undefined ? this.state.productBreakdown.quantity.toString() : saleProduct.quantity.toString()}
											onChange={(evt: React.ChangeEvent<FormControlProps>) => evt.currentTarget.value != undefined ? this.setQuantityBreakdown(evt.currentTarget.value) : {}} /></td>
										{this.isCurrentSaleEditable() ? <td>
										<Button className="mr-2" color="primary"
											disabled={this.state.breakdownWithDifference == true || this.state.productBreakdown.sku == '' || this.state.productBreakdown.quantity <= 0 || this.state.productBreakdown.price <= 0}
												onClick={(evt: React.ChangeEvent<FormControlProps>) => { this.state.productBreakdown == null ? this.saveBreakdown(saleProduct ) : this.saveBreakdown(this.state.productBreakdown) }}>
												<i className="fa fa-plus"></i>
											</Button>
											<Button className="mr-2" color="primary"
											disabled={this.state.breakdownWithDifference == true || (this.state.breakdownProducts == null || this.state.breakdownProducts.length == 0)}//|| this.getTotalDifferenceBreakdown(saleDetail.saleDetails) < 0
											onClick={(evt: React.ChangeEvent<FormControlProps>) => { this.endBreakdown(saleDetail, type) }}>
												<i className="fa fa-save"></i>
											</Button>
									</td> : <td></td>}   
									</tr>
						</tbody>
					</table>
						{ this.renderBreakdownDetails() }
					</>

					: <></>
		}
	}


	private getDetailCardTable = (saleDetail: IContainer, type: string, differenceEventKey: string) => {
		return <>
			<Row>
				<Col md={12}>
					{this.getDetailProductCardTable(saleDetail.saleDetails)}
				</Col>
				<Col md={12}>

					<Accordion>
						{!saleDetail.isMainSale && (type == "PaymentLink" || type == "Transfer" || type == "Cash") ? <></> :
							<Accordion.Toggle
								as={Button}
								eventKey={differenceEventKey}>
								CAMBIOS DE VARIANTES
                    </Accordion.Toggle>}
						<Accordion.Collapse eventKey={differenceEventKey}>
							<Card.Body>

								<table className="table table-hover">
									<thead>
										<tr>
											<th></th>
											<th>SKU</th>
											<th>Precio $</th>
											<th style={{width:"80px" }} >Cantidad</th>
											{/*<th>Diferencia</th>*/}
											<th>Total</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{this.state.saleProducts.sort((a: EditingSaleProduct, b: EditingSaleProduct) => {
											var x = a.sku.toLowerCase();
											var y = b.sku.toLowerCase();
											if (x < y) { return -1; }
											if (x > y) { return 1; }
											return 0;
										}).map((saleProduct: EditingSaleProduct, index: number) => {
											return <>
												<tr key={index}
													style={(!saleProduct.isEditing && this.state.isbreakdown && (this.state.productBreakdown != null && this.state.originalIndex == index)) ? { borderTop: "2px #44AC4A solid", borderRight: "2px #44AC4A solid", borderLeft: "2px #44AC4A solid" } : {}}													>
													<td>
														<div className="form-check">
															<label className="form-check-label text-muted">
																<input type="checkbox" className="form-check-input" style={{ cursor: !saleProduct.isEditing ? "default" : 'pointer' }}
																	disabled={!saleProduct.isEditing }
																	checked={!saleProduct.isEditing && this.state.isbreakdown && (this.state.productBreakdown != null && this.state.originalIndex == index)}//(this.state.productBreakdown != null && this.state.originalIndex == index ) }
																	onChange={(evt: ChangeEvent) => { this.disableEditDesglose(index) }} />
																<i className="input-helper"></i>
																<span style={(!saleProduct.isEditing && this.state.isbreakdown && (this.state.productBreakdown != null && this.state.originalIndex == index)) ? { color: "#44AC4A" } : {}   } >Desglosar</span>
															</label>
														</div>
													</td>
													<td className="table-sku-container-desglose">
														<Suggest
															items={this.props.products}
															disabled={!saleProduct.isEditing}
															popoverProps={{ minimal: true }}
															noResults={<MenuItem disabled={true} text="Producto inexistente" />}
															onItemSelect={(value: Product) => {
																this.props.refreshProduct(value.id, value.name);
																this.setProduct(value.name, index);}
															}
															itemsEqual={(iteamA: Product, itemB: Product) => iteamA.id == itemB.id}
															inputValueRenderer={(value: Product) => value.name}
															itemRenderer={this.renderProduct}
															itemPredicate={(query, product, _index, exactMatch) => { return product.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
															inputProps={{ placeholder: "Seleccione un producto" }}
															selectedItem={this.getSelProduct(saleProduct.sku)} //{this.props.products.find((product: Product) => { return product.name == saleProduct.sku; })}
															createNewItemFromQuery={this.createProduct}
															createNewItemRenderer={this.renderCreateItem}
														/>
													</td>
													<td><Form.Control type="number"
														disabled={!saleProduct.isEditing}
														className="form-control"
														value={saleProduct.price != undefined ? saleProduct.price.toString() : ""}
														onChange={(evt: React.ChangeEvent<FormControlProps>) => evt.currentTarget.value != undefined ? this.setPrice(evt.currentTarget.value, index) : {}} />
													</td>
													<td><Form.Control type="number"
														disabled={!saleProduct.isEditing}
														className="form-control"
														value={saleProduct.quantity != undefined ? saleProduct.quantity.toString() : ""}
														onChange={(evt: React.ChangeEvent<FormControlProps>) => evt.currentTarget.value != undefined ? this.setQuantity(evt.currentTarget.value, index) : {}} /></td>
													<td>${(saleProduct.price * saleProduct.quantity).toFixed(2)
														//((saleProduct.price * saleProduct.quantity) - (saleDetail.saleDetails[index].price * saleDetail.saleDetails[index].quantity)).toFixed(2)
													}</td>
													{this.isCurrentSaleEditable() ? <td>
														<Button className="mr-2" color="primary"
															disabled={saleProduct.isEditing || !this.isCurrentSaleEditable()}
															onClick={(evt: React.ChangeEvent<FormControlProps>) => { this.enableEdit(saleProduct, index) }}>
															<i className="fa fa-pencil"></i>
														</Button>
														<Button className="mr-2" color="primary"
															disabled={!saleProduct.isEditing || this.getTotalDifference(saleDetail) < 0}
															onClick={(evt: React.ChangeEvent<FormControlProps>) => { this.disableEdit(saleProduct, index, saleDetail, type) }}>
															<i className="fa fa-save"></i>
														</Button>
													</td> : <td></td>}
												</tr>
												<tr style={(!saleProduct.isEditing && this.state.isbreakdown && (this.state.productBreakdown != null && this.state.originalIndex == index)) ? { borderRight: "2px #44AC4A solid", borderLeft: "2px #44AC4A solid" } : {}}>
													<td colSpan={2} className="borderNone">
														{ this.renderStock(saleProduct)}
													</td>
												</tr>
												
												<tr>
													<td colSpan={6} style={(!saleProduct.isEditing && this.state.isbreakdown && (this.state.productBreakdown != null && this.state.originalIndex == index)) ? { borderBottom: "2px #44AC4A solid", borderRight: "2px #44AC4A solid", borderLeft: "2px #44AC4A solid" } : {}}	 className="borderNone">
														{this.renderBreakdown(saleProduct, saleDetail, type, index)}
													</td>
												</tr> 
											</>
										}
										)}
									</tbody>
									<tfoot>
										<tr>
											<td colSpan={2} className={this.state.ignoreDifference ? 'tachado-rojo' : '' } style={{ textAlign: 'right' }}>DIFERENCIA A PAGAR:</td>
											<td className={this.state.ignoreDifference ? 'tachado-rojo' : ''}>$ {
												!this.state.isbreakdown
												? this.getTotalDifference(saleDetail).toFixed(2)
													: this.getTotalDifferenceBreakdown(saleDetail.saleDetails).toFixed(2)
													}</td>
											<td colSpan={3}>
												{this.state.isbreakdown && this.getTotalDifferenceBreakdown(saleDetail.saleDetails) != 0 ?
													<div className="form-check">
														<label className="form-check-label text-muted">
															<input type="checkbox" className="form-check-input"
																disabled={this.state.breakdownWithDifference}
																checked={this.state.ignoreDifference}
																onChange={(evt: ChangeEvent) => { this.setState({ ignoreDifference: !this.state.ignoreDifference })}} />
															<i className="input-helper"></i>
															<span className=""> Ignorar diferencia a pagar</span>
														</label>
													</div>
												: <></>
												}
											</td>
										</tr>
									</tfoot>
								</table>
								{this.props.currentSale != null && this.props.currentSale.state == "InReview" ?
									<div className="float-right" style={{ marginTop: 10, marginRight: 300 }} >
										<div className="float-left" style={{ marginTop: -15, marginLeft: -4 }}>
											<Tooltip content="Enviar link de pago" position={Position.TOP}>
												{(this.getTotalDifference(saleDetail) == 0 || this.state.saleProducts.some((value: EditingSaleProduct) => value.isEditing))
													&& (this.state.breakdownWithDifference == false)
													? <a><img style={{ width: 50, height: 50, cursor: 'default', opacity: '0.7' }} className="mr-2" src={require("../../assets/images/mercadoPago.png")} alt="MercadoPago" /></a>
													: <a onClick={(evt: any) => {
														if (this.state.breakdownWithDifference == true) {
															this.setFinalBreakdownProducts(type, 'L');
															//this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'L');
														}
														else
															this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'L', "", false, false)
													}}
													><img style={{ width: 50, height: 50 }} className="mr-2" src={require("../../assets/images/mercadoPago.png")} alt="MercadoPago" /></a>
												}
											</Tooltip>
										</div>

										<div className="float-left" style={{ marginTop: -10 }}>
											<Tooltip content="Solicitar transferencia" position={Position.TOP}>
												{(this.getTotalDifference(saleDetail) == 0 || this.state.saleProducts.some((value: EditingSaleProduct) => value.isEditing))
													&& (this.state.breakdownWithDifference == false)
													? <a><img style={{ width: 40, height: 40, borderRadius: "100%", cursor: 'default', opacity: '0.7' }} className="mr-2" src={require("../../assets/images/transfer.png")} alt="Transferencia" /></a>
													: <a onClick={(evt: any) => {
														if (this.state.breakdownWithDifference == true) {
															this.setFinalBreakdownProducts(type,'T');
															//this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'T');
														}
														else
															this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'T', "", false, false)
													}}
													><img style={{ width: 40, height: 40, borderRadius: "100%" }} className="mr-2" src={require("../../assets/images/transfer.png")} alt="Transferencia" /></a>
												}
											</Tooltip>
										</div>
										<div className="float-left" style={{ marginTop: -8, marginLeft: 1 }}>
											<Tooltip content="Solicitar efectivo" position={Position.TOP}>
												{(this.getTotalDifference(saleDetail) == 0 || this.state.saleProducts.some((value: EditingSaleProduct) => value.isEditing))
													&& (this.state.breakdownWithDifference == false)
													? <a><img style={{ width: 38, height: 38, borderRadius: "100%", cursor: 'default', opacity: '0.7' }} className="mr-2" src={require("../../assets/images/cash.png")} alt="Efectivo" /></a>
													: <a onClick={(evt: any) => {
														if (this.state.breakdownWithDifference == true) {
															this.setFinalBreakdownProducts(type, 'C');
															//this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'C');
														}
														else
															this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'C', "", false, false)
													}}
													><img style={{ width: 38, height: 38, borderRadius: "100%" }} className="mr-2" src={require("../../assets/images/cash.png")} alt="Efectivo" /></a>
												}
											</Tooltip>
										</div>

										{/*<Button className="ml-2 mr-2" color="primary" disabled={this.getTotalDifference(saleDetail) == 0 || this.state.saleProducts.some((value: EditingSaleProduct) => value.isEditing)}*/}
										{/*	onClick={(evt: any) => { this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'L') }}*/}
										{/*>Enviar link de pago</Button>*/}
										{/*<Button className="mr-2" color="primary" disabled={this.getTotalDifference(saleDetail) == 0 || this.state.saleProducts.some((value: EditingSaleProduct) => value.isEditing)}*/}
										{/*	onClick={(evt: any) => { this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'T') }}>Solicitar transferencia</Button>*/}
										{/*<Button className="mr-2" color="primary" disabled={this.getTotalDifference(saleDetail) == 0 || this.state.saleProducts.some((value: EditingSaleProduct) => value.isEditing)}*/}
										{/*	onClick={(evt: any) => { this.props.addDifference(this.props.currentSale != null ? this.props.currentSale.id : 0, saleDetail.id, type, this.state.saleProducts, 'C') }}>Solicitar efectivo</Button>*/}
									</div>
									: <></>
								}
							</Card.Body>
						</Accordion.Collapse>
					</Accordion>
				</Col>
			</Row>
		</>
	}

	private getAccordionColor(saleDetail: MeLiSaleDetailContainer, eventKey: string) {
		if (saleDetail.claimStatus == "OrderCancelled") {
			if (this.state.accordionItem == eventKey) {
				return "order-cancelled";
			}
			return "order-cancelled-soft";
		}
		if (saleDetail.claimStatus == "ClaimOpen") {
			if (this.state.accordionItem == eventKey) {
				return "order-with-claim";
			}
			return "order-with-claim-soft";
		}
		if (this.state.accordionItem == eventKey) {
			return "open";
		}
		return "closed";
	}

	public getSelProduct(value : string) : Product | undefined {

		var prod = this.props.products.find((product: Product) => { return product.name == value; });

		if (prod === undefined)
			return this.createProduct(value);
		else
			return prod;
	}

	private getMLCard = (saleDetail: MeLiSaleDetailContainer, index: number) => {
		let eventKey = `meLi${index}`;
		return <Card>
			<Accordion.Toggle onClick={() => this.updateEditing(saleDetail, eventKey)}
				as={Card.Header}
				className={this.getAccordionColor(saleDetail, eventKey)}
				style={saleDetail.isDeleted ? { "text-decoration": "line-through" } : {}}
				variant="link"
				eventKey={eventKey}>
				Orden ML #{saleDetail.orderNumber} x ${this.getTotal(saleDetail)}
				{!this.isCurrentSaleEditable() ? <></>
					:
					saleDetail.isDeleted ? <Button className="ml-2 mr-2" color="primary"
						onClick={(evt: any) => { this.props.toggleMeLiOrder(saleDetail.id) }}
					>Habilitar</Button>
						:
						<Button className="ml-2 mr-2" color="primary"
							disabled={!saleDetail.canBeDeleted || !this.isCurrentSaleEditable()}
							onClick={(evt: any) => { this.removeMeLiSaleDetail(saleDetail) }}
						>Desestimar</Button>}
				<a className="mr-2" target="_blank" href={`https://www.mercadolibre.com.ar/ventas/${saleDetail.orderNumber}/detalle`} >
					<Button className="ml-2 mr-2" color="primary"
					>Ver Detalle</Button>                </a>
				{saleDetail.claimStatus == "OrderCancelled" ? "CANCELADA" : ""}
				{saleDetail.claimStatus == "ClaimOpen" ? "CON RECLAMO" : ""}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={`meLi${index}`}>
				<Card.Body>
					<Row>
						<Col>
							<label><strong>Usuario:</strong> {saleDetail.userName}</label>
						</Col>
					</Row>
					<Row>
						<Col>
							<label><strong>Forma de entrega:</strong> {this.translateDeliveryType(saleDetail.deliveryType)}</label>
						</Col>
					</Row>
					{this.state.accordionItem != eventKey ? <></> :
						this.getDetailCardTable(saleDetail, 'MeLiOrder', `meLiDifference${index}`)
					}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	}

	private translateDeliveryType(deliveryType: string) {
		return deliveryType == 'ToBeAgreed' ? "A convenir"
			: deliveryType == "Flex" ? "Flex" : "Colecta";
	}

	private getTransferHeaderCard = (comments: string, id: number, hasAttachment: boolean, user: string, transferType: string, saleDetails: SaleProduct[]) => {
		return <>
			<Row>
				<Col>
					{this.props.currentSale != null &&
						this.props.currentSale.hasMeLiOrder ?
						<><label>Cliente :</label> {user}</>
						:
						<><label>Email cliente :</label> {user}</>
					}

				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<label>Comentarios :</label> {comments}
				</Col>
				{this.props.user.role == "Admin"
					|| this.props.user.role == "Seller"
					|| this.props.user.role == "SellerLead"
					|| this.props.user.role == "Administrative" ?

					<Col md={6}>
						<label>Comprobante </label>
						{hasAttachment ? <>
							<a href={`/api/Sales/File/${transferType}/${id}?access_token=${localStorage.getItem('token')}`}>Descargar</a>
							<Button color="success" className="ml-3" onClick={(evt: any) => this.removeAttachment(id, transferType)}>
								<i className="fa fa-trash-o" />
							</Button>
						</> :
							<>
								<FileInput title={"file"} text={"fileName"}
									inputProps={{ accept: "image/png, image/jpeg, .pdf" }}
									buttonText="Buscar"
									onChange={(e: any) => {
										if (e.target.files != null
											&& this.props.currentSale != null) {
											if (e.target.files[0].size > this.props.user.maxFileSize * 1024 * 1024) {
												this.setState({ fileSizeError: true });
											}
											else if (!e.target.files[0].name.toLowerCase().endsWith(".png") &&
												!e.target.files[0].name.toLowerCase().endsWith(".jpg") &&
												!e.target.files[0].name.toLowerCase().endsWith(".jpeg") &&
												!e.target.files[0].name.toLowerCase().endsWith(".pdf")) {
												this.setState({ fileTypeError: true });
											}
											else {
												this.setState({ fileSizeError: false, fileTypeError: false });
												this.props.addVoucher(this.props.currentSale.id,
													id,
													e.target.files[0],
													transferType
												);
											}
										}
									}}
								/>
								{
									this.state.fileSizeError ? (
										<div className="mt-3">
											<small id="passwordHelp" className="text-danger">
												El archivo de debe pesar menos de 2 MB
                                                </small>
										</div>)
										: <></>
								}
								{
									this.state.fileTypeError ? (
										<div className="mt-3">
											<small id="passwordHelp" className="text-danger">
												El archivo de debe ser png, jpeg o pdf
                                                </small>
										</div>)
										: <></>
								}
							</>
						}

					</Col> : <></>
				}
			</Row>
			<Row style={{ textAlign: "left" }}>
				<Col>
					Estado: {hasAttachment ? <label className="badge badge-success">Pagado</label> : <label className="badge badge-warning">Aguardando comprobante</label>}
				</Col>
			</Row>
			{saleDetails.length > 0 ?
				<Row>
					<Col md={6}>
						{this.getDetailProductCardTable(saleDetails)}
					</Col>
				</Row>
				: <></>}
		</>
	}

	private getTransferCard = (saleDetail: TransferSaleDetailContainer, user: string, index: number) => {
		let eventKey = `transfer${index}`;
		return <Card>
			<Accordion.Toggle as={Card.Header}
				className={this.state.accordionItem == eventKey ? "open" : "closed"}
				onClick={() => this.updateEditing(saleDetail, eventKey)}
				variant="link"
				eventKey={eventKey}
				style={saleDetail.isDeleted ? { "text-decoration": "line-through" } : {}} >
				{saleDetail.isMainSale ? "" : "Adicional - "} Transferencia/Depósito x ${this.getTotalProducts(saleDetail.saleDetails).toFixed(2)}
				{!this.isCurrentSaleEditable() ? <></>
					:
					saleDetail.isDeleted ? <Button className="ml-2 mr-2" color="primary"
						onClick={(evt: any) => { this.props.toggleTransfer(saleDetail.id) }}
					>Habilitar</Button>
						:
						<Button className="ml-2 mr-2" color="primary"
							disabled={!saleDetail.canBeDeleted || !this.isCurrentSaleEditable()}
							onClick={(evt: any) => { this.removeTransferSaleDetail(saleDetail) }}
						>Desestimar</Button>}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={`transfer${index}`}>
				<Card.Body>
					{this.getTransferHeaderCard(saleDetail.comments, saleDetail.id,
						saleDetail.hasAttachment,
						user,
						"transfer",
						[])}
					{this.state.accordionItem != eventKey ? <></> :
						this.getDetailCardTable(saleDetail, 'Transfer', `transferDifference${index}`)
					}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	}

	private getCashHeaderCard = (comments: string, id: number, user: string, saleDetails: SaleProduct[]) => {
		return <>
			<Row>
				<Col>
					{this.props.currentSale != null &&
						this.props.currentSale.hasMeLiOrder ?
						<><label>Cliente :</label> {user}</>
						:
						<><label>Email cliente :</label> {user}</>
					}

				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<label>Comentarios :</label> {comments}
				</Col>
			</Row>
			{saleDetails.length > 0 ?
				<Row>
					<Col md={6}>
						{this.getDetailProductCardTable(saleDetails)}
					</Col>
				</Row>
				: <></>}
		</>
	}

	private getCashCard = (saleDetail: CashSaleDetailContainer, user: string, index: number) => {
		let eventKey = `cash${index}`;
		return <Card>
			<Accordion.Toggle as={Card.Header}
				className={this.state.accordionItem == eventKey ? "open" : "closed"}
				onClick={() => this.updateEditing(saleDetail, eventKey)}
				variant="link"
				eventKey={eventKey}
				style={saleDetail.isDeleted ? { "text-decoration": "line-through" } : {}} >
				{saleDetail.isMainSale ? "" : "Adicional - "} Pago en Efectivo x ${this.getTotalProducts(saleDetail.saleDetails).toFixed(2)}
				{!this.isCurrentSaleEditable() ? <></>
					:
					saleDetail.isDeleted ? <Button className="ml-2 mr-2" color="primary"
						onClick={(evt: any) => { this.props.toggleCash(saleDetail.id) }}
					>Habilitar</Button>
						:
						<Button className="ml-2 mr-2" color="primary"
							disabled={!saleDetail.canBeDeleted || !this.isCurrentSaleEditable()}
							onClick={(evt: any) => { this.removeCashSaleDetail(saleDetail) }}
						>Desestimar</Button>}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={`cash${index}`}>
				<Card.Body>
					{this.getCashHeaderCard(saleDetail.comments, saleDetail.id,
						user,
						[])}
					{this.state.accordionItem != eventKey ? <></> :
						this.getDetailCardTable(saleDetail, 'Cash', `cashDifference${index}`)
					}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	}

	private getDifferenceCard = (saleDifference: SaleDifference, index: number) => {
		let eventKey = `difference${index}`;
		if (saleDifference.price == 0) {
			
			//return <></>;
			//BACK-346
			return <Card>
				<Accordion.Toggle as={Card.Header}
					onClick={() => this.updateEditingAccordion(eventKey)}
					className={this.state.accordionItem == eventKey ? (saleDifference.isDeleted && saleDifference.paymentLinkId != null) ? "opendeleted" : "open" : (saleDifference.isDeleted && saleDifference.paymentLinkId != null) ? "opendeleted" : "closed"}
					variant="link"
					eventKey={eventKey}
					style={saleDifference.isDeleted ? { "text-decoration": "line-through" } : {}}>
					Cambios de SKUs
					{!this.isCurrentSaleEditable() ? <></>
						:
						saleDifference.isDeleted ? <Button className="ml-2 mr-2" color="primary"
							onClick={(evt: any) => { this.props.toggleDifference(saleDifference.id) }}
						>Habilitar</Button>
							:
							<Button className="ml-2 mr-2" color="primary"
								disabled={!saleDifference.canBeDeleted || !this.isCurrentSaleEditable()}
								onClick={(evt: any) => { this.removeDifferenceCard(saleDifference) }}
							>Desestimar</Button>}
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={`difference${index}`}>
					<Card.Body>
						{	<>
								<Row>
									<Col>
										<label><strong>Estado:</strong> Sin diferencias a pagar</label>
									</Col>
							</Row>
							<Row>
								<Col md={6}>
									{this.getDetailProductCardTable(saleDifference.saleDetails)}
								</Col>
							</Row>
							</>
						}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		}

		return <Card>
			<Accordion.Toggle as={Card.Header}
				onClick={() => this.updateEditingAccordion(eventKey)}
				className={this.state.accordionItem == eventKey ? (saleDifference.isDeleted && saleDifference.paymentLinkId != null) ? "opendeleted" : "open" : (saleDifference.isDeleted && saleDifference.paymentLinkId != null) ? "opendeleted" : "closed"}
				variant="link"
				eventKey={eventKey}
				style={saleDifference.isDeleted ? { "text-decoration": "line-through" } : {}}>
				Diferencia a pagar - {saleDifference.paymentLinkId == null ? (saleDifference.type == "C" ? "Pago en efvo" : "Transferencia") : "Link de pago"} x ${saleDifference.price}
				{!this.isCurrentSaleEditable() ? <></>
					:
					//BACK 142
					//A partir de este requerimiento, los links de pago que queden desestimados no podrán volver a habilitarse. En otras palabras, el botón HABILITAR deberá mostrarse inhabilitado.
					saleDifference.isDeleted ? <Button className="ml-2 mr-2" color="primary"
						disabled={saleDifference.isDeleted ? saleDifference.isDeleted : !saleDifference.canBeEnabled}
						onClick={(evt: any) => { this.props.toggleDifference(saleDifference.id) }}
					>Habilitar</Button>
						:
						<Button className="ml-2 mr-2" color="primary"
							disabled={!saleDifference.canBeDeleted || !this.isCurrentSaleEditable()}
							onClick={(evt: any) => {
								saleDifference.paymentLinkId == null ? this.props.toggleDifference(saleDifference.id)
									:
									this.removeLinkDifferenceCard(saleDifference)
							}}
						>Desestimar</Button>}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={`difference${index}`}>
				<Card.Body>
					{saleDifference.paymentLinkId == null ?
						(
							saleDifference.type == "C" ?
								this.getCashHeaderCard(saleDifference.description, saleDifference.id,
									saleDifference.email,
									saleDifference.saleDetails)
								:
								this.getTransferHeaderCard(saleDifference.description, saleDifference.id,
									saleDifference.isPayed,
									saleDifference.email,
									"difference",
									saleDifference.saleDetails)
						)
						:
						<>
							<Row>
								<Col>
									Descripción: {saleDifference.description}
								</Col>
							</Row>
							<Row>
								<Col>
									Usuario: {saleDifference.email}
								</Col>
							</Row>
							<Row>
								<Col>
									Monto: ${saleDifference.price}
								</Col>
							</Row>
							<Row>
								<Col>
									{/*BACK 142*/}
									{/*el estado del link deberá mostrarse en el mismo color y con el lablel “Cancelado”*/}
									Estado: {saleDifference.isPayed ? <label className="badge badge-success">Pagado</label> : saleDifference.isDeleted ? <label className="badge badge-danger">Cancelado</label> : <> <label className="badge badge-warning">Pago pendiente</label><Button className="ml-2 mr-2" color="primary"
										onClick={(evt: any) => { this.props.refreshPaymentStatus(saleDifference.id, 'difference', saleDifference.paymentLinkId != null ? saleDifference.paymentLinkId : '') }}
									><i className="fa fa-refresh" /></Button></>}
									<Button className="ml-2 mr-2" color="primary"
										onClick={(evt: any) => {
											if (saleDifference.paymentLinkId != null)
												this.copyToClipboard(saleDifference.paymentLinkId);
										}}
									><i className="mdi mdi-content-copy" /></Button>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									{this.getDetailProductCardTable(saleDifference.saleDetails)}
								</Col>
							</Row>
						</>
					}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	}

	private getLinkCard = (saleDetail: LinkSaleDetailContainer, user: string, index: number) => {
		let eventKey = `link${index}`;
		return <Card>
			<Accordion.Toggle as={Card.Header}
				className={this.state.accordionItem == eventKey ? saleDetail.isDeleted ? "opendeleted" : "open" : saleDetail.isDeleted ? "opendeleted" : "closed"}
				onClick={() => this.updateEditing(saleDetail, eventKey)}
				variant="link" style={saleDetail.isDeleted ? { "text-decoration": "line-through" } : {}}
				eventKey={eventKey}>
				{saleDetail.isMainSale ? "" : "Adicional - "} Link de Pago x ${this.getTotalProducts(saleDetail.saleDetails).toFixed(2)}
				{!this.isCurrentSaleEditable() ? <></> :
					//BACK 142
					//A partir de este requerimiento, los links de pago que queden desestimados no podrán volver a habilitarse. En otras palabras, el botón HABILITAR deberá mostrarse inhabilitado.
					saleDetail.isDeleted ? <Button className="ml-2 mr-2" color="primary" disabled={saleDetail.isDeleted}
						onClick={(evt: any) => { this.props.toggleLink(saleDetail.id) }}
					>Habilitar</Button>
						:
						<Button className="ml-2 mr-2" color="primary"
							disabled={!saleDetail.canBeDeleted || !this.isCurrentSaleEditable()}
							onClick={(evt: any) => { this.removeLinkSaleDetail(saleDetail) }}
						>Desestimar</Button>}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={`link${index}`}>
				<Card.Body>
					<Row>
						<Col>
							{this.props.currentSale != null &&
								this.props.currentSale.hasMeLiOrder ?
								<><label>Cliente :</label> {user}</>
								:
								<><label>Email cliente :</label> {user}</>
							}
						</Col>
					</Row>
					<Row>
						<Col>
							<label>Monto :</label> ${this.getTotalProducts(saleDetail.saleDetails).toFixed(2)}
						</Col>
					</Row>
					<Row>
						<Col>
							{/*BACK 149*/}
							{/*el estado del link deberá mostrarse en el mismo color y con el lablel “Cancelado”*/}
							Estado: {saleDetail.isPayed ? <label className="badge badge-success">Pagado</label> : saleDetail.isDeleted ? <label className="badge badge-danger">Cancelado</label> : <><label className="badge badge-warning">Pago pendiente</label><Button className="ml-2 mr-2" color="primary"
								onClick={(evt: any) => { this.props.refreshPaymentStatus(saleDetail.id, 'paymentLink', saleDetail.paymentLinkId) }}
							><i className="fa fa-refresh" /></Button></>}
							<Button className="ml-2 mr-2" color="primary"
								onClick={(evt: any) => { this.copyToClipboard(saleDetail.paymentLinkId) }}
							><i className="mdi mdi-content-copy" /></Button>
						</Col>
					</Row>
					{this.state.accordionItem != eventKey ? <></> :
						this.getDetailCardTable(saleDetail, 'PaymentLink', `PaymentDifference${index}`)
					}
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	}

	private copyToClipboard(text: string) {
		const el = document.createElement('textarea');
		el.value = "https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=" + text;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		AppToaster.show({ intent: "success", message: "Link copiado exitosamente" });
	}


	//BACK 142
	private handleClosePopup = () => {
		this.setState({ showPopUp: false, messagePopup: "" });
	}

	private popupDialog = () => {
		return <Dialog
			//icon="info-sign"
			onClose={this.handleClosePopup}
			//title="Confirmación"
			isOpen={this.state.showPopUp}
			className="naranja"
		>
			<div className={Classes.DIALOG_BODY}>
				<p>{ReactHtmlParser(this.state.messagePopup)}</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ justifyContent: "center" }}>
					<Button onClick={this.handleClosePopup} color="primary">Aceptar</Button>
				</div>
			</div>
		</Dialog>
	}

	private renderHeader = () => {
		let currentId = 0;
		if (this.props.currentSale != null) {
			currentId = this.props.currentSale.id;
		}
		return this.props.currentSale == null ||
			this.props.currentSale.id != parseInt(this.props.match.params.idSale) ||
			this.props.currentSale.state == 'ReadyForPacking' ||
			this.props.currentSale.state == 'Packed' ||
			this.props.currentSale.state == 'Dispatched' ||
			this.props.currentSale.state == 'Cancelled'
			? <></>
			: <Card>
				<Card.Body>
					<h4 className="card-title">
						{this.isCurrentSaleEditable() ? <>
							<div className="float-left">
								Agregar adicionales:
                            </div>
							<div className="float-left ml-2" style={{ marginTop: -15 }}>
								<Tooltip content="Agregar Orden ML" position={Position.TOP}>
									<a onClick={() => this.props.history.push(`/ventas/MercadoLibre/${currentId}`)} > <img style={{ width: 50, height: 50 }} className="mr-2" src={require("../../assets/images/mercadolibre.png")} alt="MercadoLibre" /> </a>
								</Tooltip>
							</div>
							<div className="float-left" style={{ marginTop: -15, marginLeft: -4 }}>
								<Tooltip content="Agregar Link de Pago" position={Position.TOP}>
									<a onClick={() => this.props.history.push(`/ventas/Link/${currentId}`)} ><img style={{ width: 50, height: 50 }} className="mr-2" src={require("../../assets/images/mercadoPago.png")} alt="MercadoPago" /></a>
								</Tooltip>
							</div>

							<div className="float-left" style={{ marginTop: -10 }}>
								<Tooltip content="Agregar una Transferencia" position={Position.TOP}>
									<a onClick={() => this.props.history.push(`/ventas/Transferencia/${currentId}`)} ><img style={{ width: 40, height: 40, borderRadius: "100%" }} className="mr-2" src={require("../../assets/images/transfer.png")} alt="Transferencia" /></a>
								</Tooltip>
							</div>
							<div className="float-left" style={{ marginTop: -8, marginLeft: 1 }}>
								<Tooltip content="Agregar un Pago en Efectivo" position={Position.TOP}>
									<a onClick={() => this.props.history.push(`/ventas/Cash/${currentId}`)} ><img style={{ width: 38, height: 38, borderRadius: "100%" }} className="mr-2" src={require("../../assets/images/cash.png")} alt="Efectivo" /></a>
								</Tooltip>
							</div>
						</> : <></>}
					</h4>
					<div style={{ clear: "both" }}></div>
					<Accordion defaultActiveKey={this.state.accordionItem != '' ? this.state.accordionItem : this.getDefaultActiveKey(this.props.currentSale)}>
						{this.props.currentSale.meLiSaleDetails.map((saleDetail: MeLiSaleDetailContainer, index: number) => this.getMLCard(saleDetail, index))}
						{this.props.currentSale.linkSaleDetails.map((saleDetail: LinkSaleDetailContainer, index: number) => this.getLinkCard(saleDetail, this.props.currentSale != null ? this.props.currentSale.user : "", index))}
						{this.props.currentSale.transferSaleDetails.map((saleDetail: TransferSaleDetailContainer, index: number) => this.getTransferCard(saleDetail, this.props.currentSale != null ? this.props.currentSale.user : "", index))}
						{this.props.currentSale.cashSaleDetails.map((saleDetail: CashSaleDetailContainer, index: number) => this.getCashCard(saleDetail, this.props.currentSale != null ? this.props.currentSale.user : "", index))}
						{this.props.currentSale.differences.map((saleDifference: SaleDifference, index: number) => this.getDifferenceCard(saleDifference, index))}
					</Accordion>
				</Card.Body>
			</Card>
	}

	private getOptions(sku: string, quantity: number) {
		let result = Array();
		for (let i = 0; i < quantity; i++) {
			result.push(<option value={quantity - i}>{quantity - i}</option>)
		}
		result.push(<option value={0}>NO EMPAQUETAR</option>)

		return result;
	}

	private updatePacketCount(sku: string, quantity: string) {
		let packetCount = { ...this.state.packetCount };
		packetCount[sku] = quantity;

		let showAlertPackageChange = this.state.showAlertPackageChange;
		if (packetCount[sku] != this.state.originalPacketCount[sku]) {
			showAlertPackageChange = true;
		}

		this.setState({ packetCount: packetCount, showAlertPackageChange: showAlertPackageChange });
	}

	private areAllProductNotToBePacked() {
		let areAllProductNotToBePacked = true;
		Object.values(this.getSummaryElements(this.props.currentSale)).forEach((saleProduct: SaleProduct) => {
			if (this.state.packetCount[saleProduct.sku] != '0') {
				areAllProductNotToBePacked = false;
			}
		});
		return areAllProductNotToBePacked;
	}

	private hasValueChange() {
		let hasChange = false;

		let packetCount: { [id: string]: string; } = {};

		let packetEmpty = false;

		if (Object.keys(this.state.packetCount).length == 0) {
			packetEmpty = true;
		}

		if (this.props.currentSale != null) {
			Object.values(this.getSummaryElements(this.props.currentSale)).forEach((saleProduct: SaleProduct) => {
				packetCount[saleProduct.sku] = saleProduct.quantity.toString();
				if (packetEmpty) {
					this.setState({ packetCount: { ...packetCount } });
				}
			});
		}

		if (!packetEmpty) {
			Object.keys(packetCount).forEach((id: string) => { if (packetCount[id] != this.state.packetCount[id]) { hasChange = true } });
		}
		return hasChange;
	}

	private renderBottom = () => {
		return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ? <></>
			: <Card>
				<Card.Body>
					<p>
						Detalle actualizado
                    </p>
					<table id="summaryTable" className="table table-hover">
						<thead>
							<tr>
								<th>SKU</th>
								<th>Precio $</th>
								<th>Cantidad</th>
								<th>Subtotal</th>
								{this.props.currentSale.state == "ReadyForPacking" ? <th></th> : <></>}
							</tr>
						</thead>
						<tbody>
							{Object.values(this.getSummaryElements(this.props.currentSale))
								.sort((a: SaleProduct, b: SaleProduct) => {
									var x = a.sku.toLowerCase();
									var y = b.sku.toLowerCase();
									if (x < y) { return -1; }
									if (x > y) { return 1; }
									return 0;
								})
								.map((saleProduct: SaleProduct, index: number) => <tr key={index}>
									<td style={{ maxWidth: 200 }} className={saleProduct.quantity == 0 ? 'tachado-rojo' : '' }>{saleProduct.sku}</td>
									<td className={saleProduct.quantity == 0 ? 'tachado-rojo' : ''}>{saleProduct.price}</td>
									<td className={saleProduct.quantity == 0 ? 'tachado-rojo' : ''}>{
										this.props.currentSale != null &&
											this.props.currentSale.state == "ReadyForPacking" ? <>
											<select className="form-control select-focus"
												onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.updatePacketCount(saleProduct.sku, evt.currentTarget.value)}>
												{this.getOptions(saleProduct.sku, saleProduct.quantity)}
											</select>
										</> :
											<>{saleProduct.quantity}</>
									}
									</td>
									<td className={saleProduct.quantity == 0 ? 'tachado-rojo' : ''}>${(saleProduct.price * saleProduct.quantity).toFixed(2)}</td>
									{this.props.currentSale != null &&
										this.props.currentSale.state == "ReadyForPacking" ? <td>
										{this.getViewPublication(saleProduct.sku)}
									</td> :
										<></>}
								</tr>)
							}
						</tbody>
						<tfoot>
							<tr>
								<td></td>
								<td></td>
								<td>Total</td>
								<td>$ {this.getTotalSale(Object.values(this.getSummaryElements(this.props.currentSale))).toFixed(2)}</td>
							</tr>
						</tfoot>
					</table>

					{//BACK 54
						(this.isCurrentInReviewInAdministration()) ? this.renderSaleinvoice() : <></>
					}
					{//BACK 145
						(!this.isCurrentFlexColecta() && ((this.props.currentSale != null && (this.props.currentSale.state == 'InReview' || this.props.currentSale.state == 'InAdministration')))) ? this.renderSaleInfo() : <></>
					}


					{this.props.currentSale != null &&
						(this.props.currentSale.state == "ReadyForPacking"
							|| this.props.currentSale.state == "Packed"
							|| this.props.currentSale.state == "Dispatched") ? <></> : this.renderInternalMessages()}
					<div>
						{this.getConfirmationButton()}
						{this.getPrintButtons()}
						{<>
							<div style={{ display: "none" }}>
								{this.getPrintDoc()}
							</div>
						</>}
					</div>
				</Card.Body>
			</Card>
	}

	//BACK 79
	private renderBreakdownDetails = () => {
		return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ||
			this.state.breakdownProducts == null || this.state.breakdownProducts.length == 0 ? <></>
			: <Card>
				<Card.Body>
					<table id="summaryTable" className="table table-hover">
						<thead>
							<tr>
								<th>SKU</th>
								<th>Precio $</th>
								<th>Cantidad</th>
								<th>Subtotal</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{Object.values(this.state.breakdownProducts)
								.map((saleProduct: EditingSaleProduct, index: number) => <tr key={index}>
									<td style={{ maxWidth: 200 }}>{saleProduct.sku}</td>
									<td>{saleProduct.price}</td>
									<td>{
										this.props.currentSale != null &&
											this.props.currentSale.state == "ReadyForPacking" ? <>
											<select className="form-control select-focus"
												onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.updatePacketCount(saleProduct.sku, evt.currentTarget.value)}>
												{this.getOptions(saleProduct.sku, saleProduct.quantity)}
											</select>
										</> :
											<>{saleProduct.quantity}</>
									}
									</td>
									<td>${(saleProduct.price * saleProduct.quantity).toFixed(2)}</td>
									{this.isCurrentSaleEditable() ? <td>
										<Button className="mr-2" color="primary"
											disabled={this.state.breakdownWithDifference == true}
											onClick={(evt: React.ChangeEvent<FormControlProps>) => { this.delBreakdown(index) }}>
											<i className="fa fa-minus"></i>
										</Button>
									</td> : <td></td>}
								</tr>)
							}
						</tbody>
						<tfoot>
							<tr>
								<td></td>
								<td></td>
								<td>Total</td>
								<td>$ {this.getTotalSaleBreakdown().toFixed(2)}</td>
							</tr>
						</tfoot>
					</table>
				</Card.Body>
			</Card>
	}


	private renderPacked = () => {
		return this.props.currentSale == null ||
			this.props.currentSale.id != parseInt(this.props.match.params.idSale) ||
			this.props.currentSale.saleConfirmationPackages.length == 0 ? <></>
			: <Card>
				<Card.Body>
					<p>
						Detalle empaquetado
                    </p>
					<table id="summaryTable" className="table table-hover">
						<thead>
							<tr>
								<th>SKU</th>
								<th>Cantidad</th>
							</tr>
						</thead>
						<tbody>
							{this.props.currentSale.saleConfirmationPackages
								.map((saleProduct: SaleConfirmationPackage, index: number) => <tr key={index}>
									<td style={{ maxWidth: 200 }}>{saleProduct.sku}</td>
									<td>{saleProduct.quantity}</td>
								</tr>)
							}
						</tbody>
					</table>
				</Card.Body>
			</Card>
	}

	private renderMeLiHeader = () => {
		return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale)
			|| !this.props.currentSale.hasMeLiOrder ? <></>
			: <Card>
				<Card.Body>
					<p>
						Órdenes MercadoLibre
                    </p>
					<ul>
						{this.props.currentSale.meLiSaleDetails.map((saleDetail: MeLiSaleDetailContainer) => <li>
							Órden #{saleDetail.orderNumber} {saleDetail.deliveryType == 'ToBeAgreed' ? "a convenir"
								: saleDetail.deliveryType == "Flex" ? "Flex" : "Colecta"}
						</li>)}
					</ul>
				</Card.Body>
			</Card>
	}

	private updateDriver(item: Driver) {
		this.setState({ driver: item })
	}

	private renderProductDrivers: ItemRenderer<Driver> = (item, { handleClick, modifiers, query }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}
		const text = item.name;
		const maxLength = 15;
		return (
			<MenuItem
				active={false}
				disabled={modifiers.disabled}
				label={`${item.logistic != null ? (item.logistic.name.length > (maxLength + 1) ? item.logistic.name.substring(0, maxLength) + "..." : item.logistic.name) : ""}`}
				key={"dri" + item.id}
				labelClassName="labelSuggestMS driver"
				onClick={handleClick}
				text={this.highlightText(text, query)}
			/>
		);
	};

	private renderPackingCard = () => {
		return this.props.currentSale == null ||
			this.props.currentSale.id != parseInt(this.props.match.params.idSale)
			? <></>
			:
			<>
				{this.props.user.role == "Admin" || this.props.user.role == "Packer" ?
					<div className="grid-margin">
						<Card>
							<Card.Body>
								<p>
									Responsables empaquetamiento
									</p>
								{this.props.packers.map((packer: Packer) => <div className="form-check">
									<label className="form-check-label text-muted">
										<input type="checkbox"
											className="form-check-input"
											checked={this.state.packers.some((name: string) => name == packer.name)}
											onChange={(evt: ChangeEvent) => {
												let newPackers = [...this.state.packers];
												if (this.state.packers.some((name: string) => name == packer.name)) {
													newPackers = this.state.packers.filter((name: string) => name != packer.name);
												}
												else {
													newPackers.push(packer.name);
												}
												this.setState({ packers: newPackers });
											}} />
										<i className="input-helper"></i>
										{packer.nickname}
									</label>
								</div>)}
							</Card.Body>
						</Card>
					</div> :
					<></>
				}
				{(this.props.user.role == "Admin" || this.props.user.role == "Packer") ?
					<div className="grid-margin">
						<Card>
							<Card.Body>
								<Col md={4}>
									<p>
										Conductor
									</p>
									<Suggest
										disabled={!(this.isAnyOrderFlex())}
										items={this.props.drivers}
										popoverProps={{ minimal: true }}
										noResults={<MenuItem disabled={true} text="Conductor inexistente" />}
										onItemSelect={(value: Driver) => {
											this.updateDriver(value);
										}
										}
										itemsEqual={(iteamA: Driver, itemB: Driver) => iteamA.id == itemB.id}
										inputValueRenderer={(value: Driver) => value.name}
										itemRenderer={this.renderProductDrivers}
										itemPredicate={(query, item, _index, exactMatch) => { return item.name.toString().toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
										inputProps={{ placeholder: "Seleccione un conductor" }}
										selectedItem={this.isAnyOrderFlex() && this.state.driver != null ? this.state.driver : null}
										resetOnClose={true}
									/>
								</Col>
							</Card.Body>
						</Card>
					</div> :
					<></>}
				<div className="grid-margin">
					<Card>
						<Card.Body>
							{this.renderInternalMessages()}
							{this.props.user.role == "Admin" || this.props.user.role == "Packer" ? <>
								<Button color="success"
									className="mr-2"
									disabled={(this.props.currentSale != null &&
										this.props.currentSale.claimStatus != '' &&
										this.props.currentSale.claimStatus != null) ||
										this.state.packers.length == 0 ||
										this.areAllProductNotToBePacked() ||
										(this.isAnyOrderFlex() && this.props.currentSale.packageNumbers.length == 0) ||
										(this.isAnyOrderFlex() && this.state.driver == null)
									}
									onClick={() => this.markAsPacked()}
								>
									Registrar Venta como empaquetada
									</Button>
								
							</> : <></>}
						</Card.Body>
					</Card>
				</div>
			</>
	}

	private markAsPacked() {
		if (this.props.currentSale != null) {
			if (this.hasValueChange()) {
				this.setState({ showConfirmIncompleteOrder: true });
			}
			else if (this.props.currentSale.meLiSaleDetails.filter((meLiSaleDetail: MeLiSaleDetailContainer) => meLiSaleDetail.claimStatus == "ClaimOpen").length > 0) {
				this.setState({ showConfirmOrderWitClaim: true });
			}
			else {
				this.props.markAsPacked(this.props.currentSale.id, this.state.packers, this.state.packetCount, false, '', this.state.driver);
			}
		}
	}

	private renderFlexCard = () => {
		if (this.isInPackaging()) {
			return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ? <></>
				: <Card>
					<Card.Body>
						<p>
							Bultos Flex
                    </p>

						<Label>
							Ingrese cantidad de bultos
                    </Label>
						<Form.Control type="number"
							className="form-control" placeholder="Precio"
							value={this.state.packageQuantity.toString()}
							onChange={(evt: React.ChangeEvent<FormControlProps>) => {
								if (evt.currentTarget.value != undefined) {
									let currentValue = parseInt(evt.currentTarget.value);
									if (currentValue > 5 || currentValue < 1) {
										return;
									}
									this.setState({
										packageQuantity: currentValue
									});
								}
							}} />
						{
							this.props.currentSale.packageNumbers.length > 0 ? <>
								<label>
									Números de paquete generados:
                            </label>
								<ul>
									{this.props.currentSale.packageNumbers.map((packageNumer: number) => <li>{packageNumer}</li>)}
								</ul>
							</>
								: <></>
						}


						<Button color="success"
							className="mr-2"
							onClick={() => this.props.currentSale != null ? this.props.generatePackageNumbers(this.props.currentSale.id, this.state.packageQuantity) : {}}
							disabled={this.props.currentSale.packageNumbers.length == this.state.packageQuantity}
						>
							{this.props.currentSale.packageQuantity != 0 ? "Generar Números de Paquete" : "Renerar Números de Paquete"}
						</Button>
					</Card.Body>
				</Card>
		}
		else {
			return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ? <></>
				: <Card>
					<Card.Body>
						<p>
							Bultos Flex
                            </p>
						{this.props.currentSale.packageNumbers.length > 0 ? <>
							<label>
								Números de paquete generados:
                                    </label>
							<ul>
								{this.props.currentSale.packageNumbers.map((packageNumer: number) => <li>{packageNumer}</li>)}
							</ul>
						</>
							: <></>
						}
					</Card.Body>
				</Card>
		}
	}

	private renderPackers = () => {
		return this.props.currentSale == null ||
			this.props.currentSale.id != parseInt(this.props.match.params.idSale) ?
			/* || (this.props.user.role != "Admin" && this.props.user.role != "Packer") */
			this.isPacked() ? <Card>
				<Card.Body>
					{this.renderInternalMessages()}
				</Card.Body>
			</Card>
				: <></>
			: <Card>
				<Card.Body>
					<p>
						Responsables empaquetamiento
                    </p>
					<ul>
						{this.props.currentSale.packers.map((packer: string) =>
							<li>
								{packer}
							</li>
						)}
					</ul>
					{this.isPacked() ?
						<>
							{this.renderInternalMessages()}
							{this.isAnyOrderFlex() || this.isAnyOrderCollect() ? <></> :
								<Button color="success"
									className="mr-2"
									onClick={() => this.props.currentSale != null ? this.props.markAsShipped(this.props.currentSale.id) : {}}
								>
									Marcar venta como despachada
                                </Button>
							}
						</>
						:
						<></>}
				</Card.Body>
			</Card>
	}

	private getDeliveryType(sale: Sales) {
		let hasCollect = false;
		let hasFlex = false;
		let hasToBeAgreed = false;

		if (sale.meLiSaleDetails != null) {
			sale.meLiSaleDetails.forEach((meliOrder: MeLiSaleDetailContainer) => {
				if (meliOrder.deliveryType == "Collect") {
					hasCollect = true;
				}
				if (meliOrder.deliveryType == "Flex") {
					hasFlex = true;
				}
				if (meliOrder.deliveryType == "ToBeAgreed") {
					hasToBeAgreed = true;
				}
			});
		}

		if (hasToBeAgreed &&
			!hasCollect &&
			!hasFlex) {
			return "A Convenir";
		}

		if (hasFlex) {
			return "Moto";
		}
		if (hasCollect) {
			return "Colecta";
		}

		return "Fuera de ML";
	}
	private getStringDate(fecha: Date) {
		let options: Intl.DateTimeFormatOptions = {
			weekday: "long", day: "numeric", month: "numeric"
		};

		var fechaDate = new Date(fecha);
		var fechaStr = fechaDate.toLocaleDateString("es-ES", options).replace(",", "");;
		return this.capitalizeFirstLetter(fechaStr);
	}

	private capitalizeFirstLetter(valStr: String) {
		return valStr.charAt(0).toUpperCase() + valStr.slice(1);
	}

	private renderEmptyComments() {
		return <Card>
			<Card.Body>
				{this.renderInternalMessages()}
			</Card.Body>
		</Card>
	}

	private addResponsable(packers: Packer[]) {

		const tdRespStyle = {
			border: "0px",
			"min-width": "80px"
		};

		let filas: string[][] = [];
		let columnas: Array<string> = [];
		let columnaActual: number = 0;
		//			filas += this.props.packers.length % 6 == 0 ? 0 : 1 ;


		Object.values(packers)
			.map((packerObj: Packer, index) => {
				if (index >= 6) {
					var fileNueva = (index - 6) % 7 == 0 ? true : false;
					if (fileNueva == true && index != 7) {
						filas.push(columnas);

						columnaActual = 0;
						columnas = [];
						columnas.push(packerObj.nickname);
					}
					else {

						columnas.push(packerObj.nickname);
					}
					columnaActual++;
				}
			}
			);

		if (columnaActual <= 6)
			for (var i = columnaActual; i <= 6; i++)
				columnas.push("");//relleno con espacios para el total de columnas

		filas.push(columnas); //agrego la ultima fila procesada

		return Object.values(filas)
			.map((fila, index) => {
				return <tr>
					{Object.values(fila)
						.map((columna, index) => {
							return <td style={tdRespStyle}> {columna == '' ? "" : <><input type="checkbox" /></>}  {columna} </td>
						})}
				</tr>
			})

	}

	private getPrintDoc() {

		if (this.props.currentSale != null) {
			let sale = this.props.currentSale;
			const style = {
				border: "solid 1px black",
				"border-collapse": "collapse",
				"padding": "5"
			};
			const tableStyle = {
				border: "solid 1px black",
				"border-collapse": "collapse",
				"width": "100%"
			};
			const tdRespStyle = {
				border: "0px",
				"min-width": "80px"
			};

			const tdSales = { border: "1px solid ", padding: "7px" };
			const tdSalesTachado = { border: "1px solid ", padding: "7px", textDecoration: "line-through" };


			return <div id="summaryToPrint">
				<br /><br />
				<table style={tableStyle}>
					<tr>
						<td style={{ width: "45px" }}>
							<b>Venta: </b>
						</td>
						<td>
							{sale.id}
						</td>
						<td style={{ width: "60px" }}>
							<b> Usuario: </b>
						</td>
						<td>
							{sale.meLiSaleDetails.length > 0 ? sale.user : sale.client}
						</td>
						{sale.hasMeLiOrder ?
							<>
								<td>
									<b> Tipo:</b>
								</td>
								<td>
									{this.getDeliveryType(sale)}
									{sale.dispatchDate != null ?
										" (" + this.getStringDate(sale.dispatchDate) + ")"
										: ""
									}
								</td>

							</> :
								null
						}
					</tr>
					{sale.hasMeLiOrder ?
						<>
							<tr>
								<td>
									<b>Ordenes:</b>
								</td>
								<td>
									{sale.meLiSaleDetails.map((value: MeLiSaleDetailContainer) => <> {value.orderNumber} <br /> </>)}
								</td>
								<td>
									<b>Envíos:</b>
								</td>
								<td>
									{sale.meLiSaleDetails.map((value: MeLiSaleDetailContainer) => <> {value.shipmentId == null ? "-" : value.shipmentId} <br /> </>)}
								</td>
								{this.getDeliveryType(sale) == "Moto" && sale.location != null ?
									<>
										<td>
											<b> Zona:</b>
										</td>
										<td>
											{sale.location.zone != "CABA" ? <> {sale.location.zone} - {sale.location.city} </> : <>{sale.location.city}</>}
										</td>
									</>
									: <td colSpan={2}>
										&nbsp;
										</td>
								}
							</tr></>
						:
						<tr>
							<td>
								<b>Tipo:</b>
							</td>
							<td colSpan={5}>
								Fuera de ML
								</td>
						</tr>

					}
				</table>
				<br />
				<table style={{ border: "none", borderCollapse: "collapse" }}>
					<tr >
						<td style={{ border: "1px solid ", padding: "7px" }}>
							<b>Sku</b>
						</td>
						<td style={{ border: "1px solid ", padding: "7px" }}>
							<b>Precio Unitario</b>
						</td>
						<td style={{ border: "1px solid ", padding: "7px" }}>
							<b>Cantidad</b>
						</td>
					</tr>
					{Object.values(this.getSummaryElements(this.props.currentSale))
						.sort((a: SaleProduct, b: SaleProduct) => {
							var x = a.sku.toLowerCase();
							var y = b.sku.toLowerCase();
							if (x < y) { return -1; }
							if (x > y) { return 1; }
							return 0;
						})
						.map((saleProduct: SaleProduct, index: number) => <tr key={index} >
							<td style={saleProduct.quantity == 0 ? tdSalesTachado : tdSales }>{saleProduct.sku}</td>
							<td style={saleProduct.quantity == 0 ? tdSalesTachado : tdSales}>${(saleProduct.price).toFixed(2)}</td>
							<td style={saleProduct.quantity == 0 ? tdSalesTachado : tdSales}><b>{saleProduct.quantity} ({coversorNumeroALetras.NumerosALetras(saleProduct.quantity)})</b></td>
							<td style={{ borderRight: "0" }}><b>{saleProduct.isPack ? "** " + saleProduct.packQuantity.toString() + " unidades **" : ""}</b></td>
						</tr>)
					}
				</table>
				<br />
				<table style={tableStyle}>
					<tr>
						{Object.values(this.props.packers)
							.map((packerObj: Packer, index) => {
								return (index == 0) ? <>
									<td style={style}>Responsables</td>
									<td style={tdRespStyle}><input type="checkbox" /> {packerObj.nickname}</td>
								</> : (index < 6) ? <><td style={tdRespStyle}><input type="checkbox" /> {packerObj.nickname}</td></> : <></>
							}
							)
						}
					</tr>
					{this.props.packers.length > 6 ? this.addResponsable(this.props.packers) : null}

				</table>
				<br />

				{/*se quita los bultos BACK 294*/}
				{/*{this.getDeliveryType(sale) == "Moto" ?*/}
				{/*	<>*/}
				{/*		<table style={style}>*/}
				{/*			<tr style={style}>*/}
				{/*				<td style={style}>*/}
				{/*					Bultos*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 1*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 2*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 3*/}
    {/*                            </td>*/}
				{/*				<td style={tdRespStyle}>*/}
				{/*					<input type="checkbox" /> 4*/}
    {/*                            </td>*/}
				{/*			</tr>*/}
				{/*		</table>*/}
				{/*	</>*/}
				{/*	: null*/}
				{/*}*/}
				<br />
				{this.props.currentSale.messages != null && this.props.currentSale.messages.length > 0 ?
					<>
						<table style={{ border: 0 }} >
							<tr>
								<td >
									<b>** NOTAS ** </b>
								</td>
							</tr>
						</table>
					</> : null
				}
				<table style={tableStyle}>
					{this.props.currentSale.messages.map((message: SaleMessage) =>
						!message.isDeleted ?
							<>
								<tr style={style}>
									<td style={style}>
										{message.message}
									</td>
									<td style={style}>
										{message.userName}
									</td>
								</tr>
							</> : null
					)}
				</table>

			</div>
		}
		return <></>;
	}

	private convertDate = (date: Date) => {
		var delta = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);

		var minute = 60,
			hour = minute * 60,
			day = hour * 24,
			week = day * 7;

		var fuzzy = 'hace más de un día';

		if (delta < 30) {
			fuzzy = 'justo ahora';
		} else if (delta < minute) {
			fuzzy = delta + ' segundos atrás';
		} else if (delta < 2 * minute) {
			fuzzy = 'un minuto atrás'
		} else if (delta < hour) {
			fuzzy = Math.floor(delta / minute) + ' minutos atrás';
		} else if (Math.floor(delta / hour) == 1) {
			fuzzy = '1 hora atrás'
		} else if (delta < day) {
			fuzzy = Math.floor(delta / hour) + ' horas atrás';
		} else if (delta < day * 2) {
			fuzzy = 'hace más de un día';
		}
		return fuzzy;
	}

	//BACK 54
	private renderSaleinvoice = () => {
		return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ? <></>
			:
			<Row style={{ marginTop: '15px' }} >
				<Col className="form-group" md={4} style={{ marginBottom: '0px' }}>
					<Row>

						<Col className="form-group" md={6} style={{ marginBottom: '0px' }}>
							<label>Factura A</label>
							<Form.Control type="text" className="form-control" id="invoice"
								placeholder="CUIT"
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ invoice: evt.currentTarget.value })}
								value={this.state.invoice} />
							{!this.cuilValidator(2) ? (
								<div className="mb-3">
									<small id="cuilHelp" className="text-danger">Ingrese un cuit válido</small>
								</div>)
								: <></>
							}
						</Col>
					</Row>
				</Col>
			</Row>
	}

	//BACK 145
	private renderSaleInfo = () => {
		//if (this.props.currentSale != null) {
		//	this.state.businessName = this.state.businessName == "" ? this.props.currentSale.client : this.state.businessName
		//	this.state.salesChannel = this.isCurrentToBeAgreed() ? "Meli" : this.state.salesChannel
		//  }

		return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ? <></>
			:
			<Row style={{ marginTop: '15px' }} >
				<Col className="" md={12}>
					<p style={{ textDecorationLine: 'underline' }}>
						Datos de la Venta
                    </p>
				</Col>
				<Col className="form-group" md={12}>
					<Row>

						<Col className="form-group" md={6}>
							<Form.Control type="text" className="form-control" id="address" disabled={this.isCurrentToBeAgreed()}
								placeholder="Nombre, Apellido / Razón Social (*)" maxLength={50}
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ businessName: evt.currentTarget.value })}
								value={this.state.businessName} />
						</Col>
						<Col className="form-group" md={6}>
							<Form.Control type="text" className="form-control" id="email" disabled={this.isCurrentToBeAgreed()}
								placeholder={this.state.deliveryMethod == "Expreso" ? "Email (*)" : "Email"} maxLength={75}
								value={this.state.email}
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ email: evt.currentTarget.value })} />

							{!this.isEmailValid() ? (
								<div className="mb-3">
									<small id="emailHelp" className="text-danger">
										Ingrese un email válido
                                                    </small>
								</div>)
								: <></>}
						</Col>


						<Col className="form-group" md={6}>
							<select className="form-control" disabled={this.isCurrentToBeAgreed()}
								onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ salesChannel: evt.currentTarget.value })}
								value={this.state.salesChannel}>
								{this.isCurrentToBeAgreed() ? <option value="Meli">MercadoLibre</option> :
									<>
										<option value="0">Seleccione un canal de venta (*)...</option>
										<option value="Local">Local</option>
										<option value="Email">Email</option>
										<option value="Whatsapp">Whatsapp</option>
										<option value="RedesSociales">Redes Sociales</option>
										<option value="Telefono">Teléfono</option> </>}
							</select>
						</Col>
						{/*<Col className="form-group pt-4" md={6}>*/}
						{/*	{this.isCurrentToBeAgreed() ? <></> : <Label> Local, Email, Whatsapp, Redes Sociales, Teléfono </Label>}*/}
						{/*	<Label> </Label>*/}
						{/*</Col>*/}

						<Col className="form-group" md={6}>
							<select className="form-control"
								onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ deliveryMethod: evt.currentTarget.value })}
								value={this.state.deliveryMethod}>
								<option value="0">Seleccione una forma de entrega (*)...</option>
								<option value="Local">Local</option>
								<option value="Rodriguez">Rodriguez</option>
								<option value="Moto">Moto</option>
								<option value="Expreso">Expreso</option>
							</select>
						</Col>
						{this.state.deliveryMethod == "Expreso" ?
							<>
								<Col className="form-group" md={4}>
									<select className="form-control" disabled={this.state.newCompany}
										onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ companyId: parseInt(evt.currentTarget.value) })}
										value={this.state.companyId}>
										{this.state.newCompany ? <option value="0">Seleccione una empresa...</option> : <>
											<option value="0">Seleccione una empresa (*)...</option>
											{this.props.companies.map((company: Company) => (
												<option key={company.id} value={company.id}>
													{company.name}
												</option>
											))}
										</>}
									</select>
								</Col>
								<Col className="form-group" md={3}>
									<div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" checked={this.state.newCompany} onChange={(evt: ChangeEvent) => this.setState({ newCompany: !this.state.newCompany })} />
											<i className="input-helper"></i>
											<span>Agregar empresa</span>
										</label>
									</div>
								</Col>
							</>
							:
							<></>
						}
					</Row>
				</Col>
				{this.state.deliveryMethod == "Expreso" && this.state.newCompany ?
					<>
						<Col className="" md={12}>
							<p style={{ textDecorationLine: 'underline' }}>
								Datos de la Empresa
						</p>
						</Col>
						<Col className="form-group" md={12}>
							<Row>
								<Col className="form-group" md={4}>
									<Form.Control type="text" className="form-control" id="companyName"
										placeholder="Nombre de la Empresa (*)" maxLength={50}
										onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ companyName: evt.currentTarget.value })}
										value={this.state.companyName} />
								</Col>
								<Col className="form-group" md={6}>
									<Form.Control type="text" className="form-control" id="companyAddress"
										placeholder="Dirección (*)" maxLength={100}
										onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ companyAddress: evt.currentTarget.value })}
										value={this.state.companyAddress} />
								</Col>
								<Col className="form-group" md={2}>
									<div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" checked={this.state.companyTriplicateRemittance} onChange={(evt: ChangeEvent) => this.setState({ companyTriplicateRemittance: !this.state.companyTriplicateRemittance })} />
											<i className="input-helper"></i>
											<span>Exige remito triplicado</span>
										</label>
									</div>
								</Col>
							</Row>
						</Col>
					</> : <></>}
				{this.state.deliveryMethod == "Expreso" || this.state.deliveryMethod == "Moto" ? <>
					<Col className="" md={12}>
						<p style={{ textDecorationLine: 'underline' }}>
							Información para el Despacho
                    </p>
					</Col>
					<Col className="form-group" md={12}>
						<Row>
							<Col className="form-group" md={2}>
								<select className="form-control" disabled={this.state.equalCUIT && this.state.invoice != ""}
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ documentType: evt.currentTarget.value })}
									value={this.state.documentType}>
									{(this.state.equalCUIT && this.state.invoice != "")?
										<option value="CUIT/CUIL">CUIT / CUIL</option> :
										<>
											<option value="0">{this.state.deliveryMethod == "Expreso" ? "Tipo documento (*)... " : "Tipo documento..."}</option>
											<option value="DNI">DNI</option>
											<option value="CUIT/CUIL">CUIT / CUIL</option>
										</>
									}
								</select>
							</Col>
							<Col className="form-group" md={4}>
								<Form.Control type="text" className="form-control" id="cuit" disabled={this.state.documentType == "0" || (this.state.equalCUIT && this.state.invoice != "")}
									placeholder={this.state.deliveryMethod == "Expreso" ? "CUIT / CUIL (*)" : "CUIT / CUIL"}
									onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ cuil: evt.currentTarget.value })}
									value={this.state.equalCUIT ? this.state.invoice : this.state.cuil} />
								{this.state.documentType == "DNI" ?
									!this.dniValidator() ? (
										<div className="mb-3">
											<small id="cuilHelp" className="text-danger">
												Ingrese un DNI válido
														</small>
										</div>)
										: <></>
									: !this.cuilValidator(1) ? (
										<div className="mb-3">
											<small id="cuilHelp" className="text-danger">
												Ingrese un cuit/cuil válido
														</small>
										</div>)
										: <></>}
							</Col>

							<Col className="form-group" md={3}>
								<Form.Control type="text" className="form-control" id="telephone"
									placeholder={this.state.deliveryMethod == "Expreso" ? "Teléfono (*)" : "Teléfono"}
									onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ phone: evt.currentTarget.value })}
									value={this.state.phone} />
							</Col>

							<Col className="form-group" md={3}>
								<Form.Control type="text" className="form-control" id="sure"
									placeholder="Seguro"
									onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ insurance: evt.currentTarget.value })}
									value={this.state.insurance} />
							</Col>


							{/*BACK 200*/}
							{this.state.invoice != "" ?
								<Col className="form-group" md={12}>
									<div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" checked={this.state.equalCUIT} onChange={(evt: ChangeEvent) =>
												!this.state.equalCUIT ?
													this.setState({
														documentType: "CUIT/CUIL",
														cuil: this.state.invoice,
														equalCUIT: !this.state.equalCUIT
													}
													)
													:
													this.setState({ equalCUIT: !this.state.equalCUIT })
											} />
											<i className="input-helper"></i>
											<span>Usar el CUIT de facturación</span>
										</label>
									</div>
								</Col> : <>
								</>
							}

							<Col className="form-group" md={4}>
								<Form.Control type="text" className="form-control" id="address"
									placeholder="Dirección Destino (*)"
									onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ address: evt.currentTarget.value })}
									value={this.state.address} />
							</Col>
							<Col className="form-group" md={3}>
								<select className="form-control"
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ provinceId: parseInt(evt.currentTarget.value) })}
									value={this.state.provinceId}>
									<option value="0">Seleccione una provincia (*)...</option>
									{this.props.provinces.map((province: Province) => (
										<option key={province.id} value={province.id}>
											{province.name}
										</option>
									))}

								</select>
							</Col>
							<Col className="form-group" md={3}>
								<select className="form-control" disabled={this.state.provinceId == 0}
									onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ cityId: parseInt(evt.currentTarget.value) })}
									value={this.state.cityId}>
									<option value="0">Seleccione una localidad (*)...</option>
									{this.state.provinceId == 0 ?
										<></> :
										this.props.cities.filter(x => x.province.id == this.state.provinceId).map((city: City) => (
											<option key={city.id} value={city.id}>
												{city.name}
											</option>
										))}
								</select>
							</Col>
							<Col className="form-group" md={2}>
								<Form.Control type="text" className="form-control" id="postalCode"
									placeholder="Cod. Postal (*)"
									onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ postalCode: evt.currentTarget.value })}
									value={this.state.postalCode} />
							</Col>


						</Row>
					</Col> </>
					: <></>
				}
			</Row>
	}

	private renderInternalMessages = () => {
		return this.props.currentSale == null || this.props.currentSale.id != parseInt(this.props.match.params.idSale) ? <></>
			: <div  style={{ marginTop: '0.1rem !important'}}>
				<div className="messageContainer mb-3" style={{ marginTop: '10px' }}>
					{
						this.props.currentSale.messages.map((message: SaleMessage) =>
							(!message.isDeleted) ?
								<div className="incoming_msg">
									<div className="received_msg">
										<div className="received_withd_msg">
											<p>{message.message}</p>
											<span className="time_date">{message.userName} - {this.convertDate(message.date)}</span></div>
									</div>
								</div>
								: null

						)
					}
				</div>
				<Row>
					<Col md={9}>
						<textarea
							style={{ width: '100%' }}
							placeholder="Agregar una nota"
							value={this.state.newSaleMessage}
							onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => this.setState({ newSaleMessage: evt.currentTarget.value })}
						/>
					</Col>
					<Col md={3}>
						<Button color="primary" className="ml-2" onClick={() => this.sendNote()}
							disabled={this.state.newSaleMessage.length <= 0}>Agregar nota</Button>
					</Col>
				</Row>
			</div>
	}

	private sendNote() {
		if (this.props.currentSale != null) {
			let message = this.state.newSaleMessage;
			this.props.sendSaleMessage(this.props.currentSale.id, message);
			this.setState({ newSaleMessage: '' });
		}
	}

	private areAllTransfersConfirmed() {
		if (this.props.currentSale != null) {
			return this.props.currentSale
				.transferSaleDetails
				.every((transfer: TransferSaleDetailContainer) => !transfer.isDeleted && transfer.hasAttachment)
				&&
				this.props.currentSale
					.differences
					.every((difference: SaleDifference) => !difference.isDeleted && difference.isPayed)
		}
		return false;
	}

	private getConfirmationButton = () => {
		if (this.props.currentSale != null) {
			if (this.props.currentSale.state == 'InReview'
				&& this.isUserAbleToEditSale() && this.isCurrentFlexColecta()) {
				return <Button color="success"
					className="mr-2"
					onClick={() => this.props.currentSale != null ? this.props.confirmSale(this.props.currentSale.id, this.state.invoice) : null}
					disabled={this.props.currentSale
						.linkSaleDetails
						.find((linkSale: LinkSaleDetailContainer) => !linkSale.isDeleted && !linkSale.isPayed) != null
						|| this.props.currentSale
							.differences
							.find((saleDifference: SaleDifference) => !saleDifference.isDeleted && !saleDifference.isPayed && saleDifference.paymentLinkId != null) != null
						|| (this.props.currentSale.linkSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0
							&& this.props.currentSale.transferSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0
							&& this.props.currentSale.cashSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0
							&& this.props.currentSale.meLiSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0)
						|| !this.cuilValidator(2)}
				>
					Confirmar
                                </Button>
			}
			//BACK 145
			else if (this.props.currentSale.state == 'InReview'
				&& this.isUserAbleToEditSale() && !this.isCurrentFlexColecta()) {
				return <Button color="success"
					className="mr-2"
					onClick={() => this.props.currentSale != null ? this.props.confirmSaleInformation(this.props.currentSale.id,
						this.state.salesChannel, this.state.deliveryMethod, this.state.companyId, this.state.newCompany,
						this.state.companyName, this.state.companyAddress, this.state.companyTriplicateRemittance,
						0, this.state.businessName, this.state.address, this.state.provinceId, this.state.cityId,
						this.state.postalCode, this.state.email, this.state.documentType, this.state.cuil,
						this.state.insurance, this.state.phone, this.state.invoice) : null}
					disabled={this.props.currentSale
						.linkSaleDetails
						.find((linkSale: LinkSaleDetailContainer) => !linkSale.isDeleted && !linkSale.isPayed) != null
						|| this.props.currentSale
							.differences
							.find((saleDifference: SaleDifference) => !saleDifference.isDeleted && !saleDifference.isPayed && saleDifference.paymentLinkId != null) != null
						|| (this.props.currentSale.linkSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0
							&& this.props.currentSale.transferSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0
							&& this.props.currentSale.cashSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0
							&& this.props.currentSale.meLiSaleDetails.filter((sale: IContainer) => !sale.isDeleted).length == 0)
						//SE AGREGAN VALIDACION DEL BACK 145
						|| (
							// ||
							this.state.deliveryMethod == "0" || //Forma de entrega
							this.state.businessName == "" || this.state.businessName.length == 0
						)
						|| (!this.isCurrentToBeAgreed() ? (this.state.salesChannel == "0" || this.state.salesChannel == "Meli") : false)
						|| (
							(this.state.deliveryMethod == "Expreso") ?
								this.state.address.length == 0 || this.state.address == "" || //direccion
								this.state.provinceId == 0 || //provincia
								this.state.cityId == 0 || //localidad 
								this.state.postalCode == "" || this.state.postalCode.length == 0 ||//codigo postal 
								this.state.documentType == "0" || this.state.cuil == "" || this.state.phone == ""
								|| ((this.state.documentType == "DNI") ? !this.dniValidator() : false)
								|| ((this.state.documentType == "CUIT/CUIL") ? !this.cuilValidator(1) : false)
								|| (!this.state.newCompany ? this.state.companyId == 0 :
									this.state.companyName == "" || this.state.companyName.length == 0
									|| this.state.companyAddress == "" || this.state.companyAddress.length == 0
								)
								: false)
						|| (
							(this.state.deliveryMethod == "Moto") ?
								this.state.address.length == 0 || this.state.address == "" || //direccion
								this.state.provinceId == 0 || //provincia
								this.state.cityId == 0 || //localidad 
								this.state.postalCode == "" || this.state.postalCode.length == 0 //codigo postal 
								|| ((this.state.documentType == "DNI") ? !this.dniValidator() : false)
								|| ((this.state.documentType == "CUIT/CUIL") ? !this.cuilValidator(1) : false)
								: false)
						|| (this.isCurrentNotHasMeLiOrder() ? (this.state.email == "" || !this.isEmailValid()) : false)
						|| !this.cuilValidator(2)}
				>
					Confirmar
                                </Button>
			}
			else if (this.props.currentSale.state == 'Confirmed') {
				return <Button color="success"
					className="mr-2"
					onClick={() => this.props.currentSale != null ? this.props.confirmSale(this.props.currentSale.id, this.state.invoice) : null}
					disabled={this.props.currentSale
						.linkSaleDetails
						.find((linkSale: LinkSaleDetailContainer) => !linkSale.isDeleted && !linkSale.isPayed) != null}
				>
					Marcar como facturada
                                </Button>
			}
			else if (this.props.currentSale.state == 'InAdministration'
				&& (this.props.user.role == "Admin" || this.props.user.role == "Administrative") && this.isCurrentFlexColecta()) {
				return <>
					<div className="form-check">
						<label className="form-check-label text-muted">
							<input type="checkbox" className="form-check-input"
								checked={this.props.currentSale.isBilled}
								onChange={(evt: ChangeEvent) => this.props.currentSale != null ? this.props.markAsBilled(this.props.currentSale.id) : null} />
							<i className="input-helper"></i>
                                    Marcar como facturada
                                </label>
					</div>
					<Button color="success"
						className="mr-2"
						onClick={() => this.confirmSale()}
						disabled={!this.areAllTransfersConfirmed() || !this.cuilValidator(2)}
					>
						Confirmar
                                </Button>
				</>
			}
			else if (this.props.currentSale.state == 'InAdministration'
				&& (this.props.user.role == "Admin" || this.props.user.role == "Administrative") && !this.isCurrentFlexColecta()) {
				return <>
					<div className="form-check">
						<label className="form-check-label text-muted">
							<input type="checkbox" className="form-check-input"
								checked={this.props.currentSale.isBilled}
								onChange={(evt: ChangeEvent) => this.props.currentSale != null ? this.props.markAsBilled(this.props.currentSale.id) : null} />
							<i className="input-helper"></i>
                                    Marcar como facturada
                                </label>
					</div>
					<Button color="success"
						className="mr-2"
						onClick={() => this.props.currentSale != null ? this.props.confirmSaleInformation(this.props.currentSale.id,
							this.state.salesChannel, this.state.deliveryMethod, this.state.companyId, this.state.newCompany,
							this.state.companyName, this.state.companyAddress, this.state.companyTriplicateRemittance,
							0, this.state.businessName, this.state.address, this.state.provinceId, this.state.cityId,
							this.state.postalCode, this.state.email, this.state.documentType, this.state.cuil,
							this.state.insurance, this.state.phone, this.state.invoice) : null}
						disabled={!this.areAllTransfersConfirmed()
							//SE AGREGAN VALIDACION DEL BACK 145
							|| (
								// ||
								this.state.deliveryMethod == "0" || //Forma de entrega
								this.state.businessName == "" || this.state.businessName.length == 0
							)
							|| (!this.isCurrentToBeAgreed() ? (this.state.salesChannel == "0" || this.state.salesChannel == "Meli") : false)
							|| (
								(this.state.deliveryMethod == "Expreso") ?
									this.state.address.length == 0 || this.state.address == "" || //direccion
									this.state.provinceId == 0 || //provincia
									this.state.cityId == 0 || //localidad 
									this.state.postalCode == "" || this.state.postalCode.length == 0 ||//codigo postal 
									this.state.documentType == "0" || this.state.cuil == "" || this.state.phone == ""
									|| ((this.state.documentType == "DNI") ? !this.dniValidator() : false)
									|| ((this.state.documentType == "CUIT/CUIL") ? !this.cuilValidator(1) : false)
									|| (!this.state.newCompany ? this.state.companyId == 0 :
										this.state.companyName == "" || this.state.companyName.length == 0
										|| this.state.companyAddress == "" || this.state.companyAddress.length == 0
									)
									: false)
							|| (
								(this.state.deliveryMethod == "Moto") ?
									this.state.address.length == 0 || this.state.address == "" || //direccion
									this.state.provinceId == 0 || //provincia
									this.state.cityId == 0 || //localidad 
									this.state.postalCode == "" || this.state.postalCode.length == 0 //codigo postal 
									|| ((this.state.documentType == "DNI") ? !this.dniValidator() : false)
									|| ((this.state.documentType == "CUIT/CUIL") ? !this.cuilValidator(1) : false)
									: false)
							|| (this.isCurrentNotHasMeLiOrder() ? (this.state.email == "" || !this.isEmailValid()) : false)
							|| !this.cuilValidator(2)}
					>
						Confirmar
                                </Button>
				</>
			}
		}
		return <></>
	}

	private confirmSale() {
		if (this.props.currentSale != null) {
			let sale = this.props.currentSale;
			if (this.hasOrderWithClaims()) {
				this.setState({
					showConfirmationModal: true,
					confirmationModalText: 'Esta venta contiene Órdenes con reclamos abiertos, desea continuar de todos modos?',
					confirmationModalOkText: 'Confirmar',
					confirmationAction: () => { this.props.confirmSale(sale.id, this.state.invoice) }
				});
			}
			else {
				this.props.confirmSale(this.props.currentSale.id, this.state.invoice);
			}
		}
	}

	private getPrintableLabel(orderNumber: number) {
		console.log('Print', orderNumber);
		this.props.getPrintableLabel(orderNumber);
	}

	private getPrintableLabel2(orderNumber: number) {
		console.log('Print', orderNumber);
		this.props.getPrintableLabel2(orderNumber);
	}

	private printPdf(name: string) {
		let doc = document.getElementById(name) as any;
		//Wait until PDF is ready to print 
		if (doc != null) {
			if (typeof doc.contentWindow.print === 'undefined') {
				setTimeout(() => { this.printPdf(name); }, 1000);
			} else {
				doc.focus();
				doc.contentWindow.print();
			}
		}
	}

	private printPdf2(name: string) {
		let doc = document.getElementById(name) as any;
		//Wait until PDF is ready to print 
		if (doc != null) {
			if (typeof doc.contentWindow.print === 'undefined') {
				setTimeout(() => { this.printPdf2(name); }, 1000);
			} else {
				doc.focus();
				doc.contentWindow.print();
			}
		}
	}

	private printSummary() {
		if (document != null) {
			var div = document.getElementById("summaryToPrint");
			if (div != null) {
				var divContents = div.outerHTML;
				var a = window.open('', '', 'height=800, width=1200');
				if (a != null) {
					a.document.write('<html>');
					a.document.write('<body >');
					a.document.write(divContents);
					a.document.write('</body></html>');
					a.document.close();
					a.print();
				}
				if (this.props.currentSale != null) {
					var sales: number[] = [this.props.currentSale.id];
					this.props.printSales(sales);
				}

			}
		}
	}

	private getPrintButtons() {
		if (this.props.currentSale != null) {

			if (this.props.currentSale.state == 'ReadyForPacking'
				&& (this.props.user.role == "Admin" || this.props.user.role == "Packer")) {
				var rePrintButAdded = false;

				let links = this.props.currentSale.meLiSaleDetails.map((meliSale: MeLiSaleDetailContainer, index:number) =>
					meliSale.deliveryType != "" ?
						<Row className="mt-2">
							{/*//BACK 206 - se agrega Label2*/}
							{this.props.user.printInPdf ? <iframe
								style={{ display: "none" }}
								src={`/api/MeLi/Label/${meliSale.orderNumber}?access_token=${localStorage.getItem('token')}`}
								id={`pdfDocument${meliSale.orderNumber}`}
								width="0"
								height="0" /> : null}
							{/*//BACK 206 - se quita botom viejo*/}
							{/*<Button disabled={meliSale.claimStatus == 'OrderCancelled'*/}
							{/*	|| meliSale.deliveryType == 'ToBeAgreed'}*/}
							{/*	color="success"*/}
							{/*	onClick={() => this.props.user.printInPdf ? this.printPdf(`pdfDocument${meliSale.orderNumber}`) : this.getPrintableLabel(meliSale.orderNumber)}>*/}
							{/*	{meliSale.deliveryType == 'ToBeAgreed' ? 'Orden' : 'Imprimir orden '}*/}
							{/*#{meliSale.orderNumber}*/}
							{/*</Button>*/}
							{/*<span>&nbsp;&nbsp;</span>*/}
							{/*BACK 233*/}



							{this.props.currentSale != null && this.props.currentSale.labelReadyToPrint ?
								<Button disabled={meliSale.claimStatus == 'OrderCancelled' || meliSale.deliveryType == 'ToBeAgreed' }
									color="warning"
									onClick={() => this.props.user.printInPdf ? this.printPdf2(`pdfDocument${meliSale.orderNumber}`) : this.getPrintableLabel2(meliSale.orderNumber)}>
									{meliSale.deliveryType == 'ToBeAgreed' ? 'Orden' : 'Imprimir orden '}
									#{meliSale.orderNumber}
								</Button> :

								meliSale.deliveryType == 'ToBeAgreed' ?
									<Button disabled={true}
										color="warning"
										onClick={() => this.props.user.printInPdf ? this.printPdf2(`pdfDocument${meliSale.orderNumber}`) : this.getPrintableLabel2(meliSale.orderNumber)}>
										{meliSale.deliveryType == 'ToBeAgreed' ? 'Orden' : 'Imprimir orden '} #{meliSale.orderNumber}
									</Button>
								:
								<>
									<i style={{ color: "#ffcc00" }} className="fa fa-warning ml-2"></i>
									<p >MercadoLibre liberará la etiqueta a partir del día {this.props.currentSale != null && this.props.currentSale.dispatchDate != null ? this.getStringDate(this.props.currentSale.dispatchDate) : ''}</p>
								</>}

							{meliSale.deliveryType == 'ToBeAgreed' ? <>
								<p>A Convenir</p>
							</> : <></>}
							{meliSale.claimStatus == "ClaimOpen" ?
								<>
									<i style={{ color: "#ffcc00" }} className="fa fa-warning ml-2"></i>
									<p>Nuevo reclamo abierto</p>
								</> :
								meliSale.claimStatus == "OrderCancelled" ?
									<>
										<i style={{ color: "#ffcc00" }} className="fa fa-warning ml-2"></i>
										<p >Venta Cancelada</p>
									</> :
									<></>}
							{(index == 0 && this.props.currentSale != null && this.props.currentSale.canRePrint == true) ?
								<> &nbsp;&nbsp;<Button disabled={this.props.currentSale != null && this.props.currentSale.claimStatus != '' && this.props.currentSale.claimStatus != null} color="primary" onClick={() => this.printSummary()}>
									Reimprimir Resumen</Button></> :
								<></>
							}
							
						</Row> : <></>);

				if (!this.props.currentSale.hasMeLiOrder) {
					links.push(<p style={{ color: "red" }}>
						Esta venta no contiene órdenes de mercadolibre 
                    </p>);
				}

				links.unshift(
					<Row className="mt-2">
						<div className="form-check ml-4">
							<label className="form-check-label text-muted">
								<input type="checkbox" className="form-check-input" checked={this.props.currentSale != null && this.props.currentSale.isPrinted} onChange={(evt: ChangeEvent) => this.props.currentSale != null ? this.props.printSalesChk([this.props.currentSale.id]) : {}} />
								<i className="input-helper"></i>
				                                    Marcar resumen como impreso
				                            </label>
						</div>
					</Row>);

				links.push(
					<div className="mt-2 row">
						<Button color="primary" onClick={() => this.setState({ showReturnConfirmation: true })}>
							Devolver al vendedor</Button>
					</div>);

				if (this.props.currentSale != null  && !this.props.currentSale.hasMeLiOrder && this.props.currentSale.canRePrint == true) {
					links.push(<div className="mt-2 row"><Button disabled={this.props.currentSale != null && this.props.currentSale.claimStatus != '' && this.props.currentSale.claimStatus != null} color="primary" onClick={() => this.printSummary()}>
						Reimprimir Resumen</Button></div> );
				}


				if (this.props.currentSale.hasDistinctAddress) {
					links.push(<><br /><b><p style={{ color: "red" }}>
						(*) ATENCIÓN: Etiquetas con diferentes direcciones de destino.
                    </p></b></>);
				}

				return links;
			}
			else if (this.props.currentSale.state == 'InAdministration'
				&& (this.props.user.role == "Admin" || this.props.user.role == "Administrative")) {
				return <div className="mt-2">
					<Button color="success" onClick={() => this.setState({ showReturnConfirmation: true })}>
						Devolver al vendedor</Button>
				</div>;
			}
			else if (this.props.currentSale.state == 'InReview'
				&& this.props.currentSale.canRePrint == true) {
				return <Button disabled={this.props.currentSale != null && this.props.currentSale.claimStatus != '' && this.props.currentSale.claimStatus != null} color="primary" onClick={() => this.printSummary()}>
						Reimprimir Resumen</Button>;
			}

		}
		return <></>;
			
	}

	private hasMeLiOrder() {
		return this.props.currentSale != null
			&& this.props.currentSale.hasMeLiOrder;
	}

	private isMessageEnable() {
		return this.hasMeLiOrder() &&
			(this.props.user.role == "Admin"
				|| this.props.user.role == "Seller"
				|| this.props.user.role == "SellerLead"
				|| this.props.user.role == "Administrative");
	}

	private hasOrderWithClaims() {
		if (this.props.currentSale != null) {
			let claims = this.props.currentSale.meLiSaleDetails.filter((value: MeLiSaleDetailContainer) => value.claimStatus == "ClaimOpen");
			return claims.length > 0;
		}
		return false;
	}

	private hasCanceledOrders() {
		if (this.props.currentSale != null) {
			let claims = this.props.currentSale.meLiSaleDetails.filter((value: MeLiSaleDetailContainer) => value.claimStatus == "OrderCancelled");
			return claims.length > 0;
		}
		return false;
	}

	render() {
		return (
			<>
				{this.props.currentSale != null ? <><h1>
					Venta #{this.props.currentSale.id} - <span className={this.props.currentSale.state == "Cancelled" || this.props.currentSale.state == "Deleted" ? "text-danger" : ""}> {this.props.currentSale.stateText} </span> {this.hasOrderWithClaims() ? <span className="text-warning"> - Con Reclamos</span> : <></>}
				</h1>
					<Row className="mb-2">
						<Col md={12}>
							<i className="mdi mdi-account-card-details"></i> {this.props.currentSale.client}&nbsp;&nbsp;

                            {this.hasMeLiOrder() ?
								<> <i className="mdi mdi-account"></i>{this.props.currentSale.user}&nbsp;&nbsp;</> :
								<>
									<i className="mdi mdi-email"></i>{this.props.currentSale.user}&nbsp;&nbsp;
                                </>
							}
							{
								this.props.currentSale.clientPhone != ""
									&& this.props.currentSale.clientPhone != undefined
									&& this.props.currentSale.clientPhone != null ?
									<>
										<i className="mdi mdi-cellphone-android"></i>{this.props.currentSale.clientPhone}
									</> :
									<></>
							}
						</Col>
					</Row>
					{(this.isPacked() || this.isDispatched()) && this.isAnyOrderFlex() && this.props.currentSale.driver != null && this.props.currentSale.driver.logistic != null ?
						<Row className="mb-2">
							<Col md={12}>
								<i className="mdi mdi-motorbike"></i> {this.props.currentSale.driver.name}&nbsp;&nbsp;({this.props.currentSale.driver.logistic.name})
							</Col>
						</Row> :
						<></>
					}
					<Row>
						<div style={{ textAlign: 'left' }}>
							<Button color="success" className="ml-2" onClick={() => {
								this.props.getHistory(this.props.currentSale != null ? this.props.currentSale.id : 0);
								this.props.history.push(`/ventas/historia/${this.props.currentSale != null ? this.props.currentSale.id : 0}`);
							}}>Historial</Button>
							{this.props.currentSale.state == "InReview"
								&& (this.props.user.role == "Admin"
									|| this.props.user.role == "Seller"
									|| this.props.user.role == "SellerLead"
								) ? <Button color="danger" className="ml-2" onClick={() => {
									this.setState({ showConfirmDeleteSale: true });
								}}>Eliminar venta</Button>
								: <></>
							}
						</div>
					</Row>
					{this.props.showNavBar ? <div style={{ textAlign: 'right' }}>
						{this.props.currentSale.previousId != 0 ? <> <Link className="mr-2" to={`/ventas/detalle/${this.props.currentSale.previousId}`}>
							Anterior
                    </Link> / </>
							: <>Anterior</>}
						{this.props.currentSale.nextId != 0 ? <Link className="mr-2" to={`/ventas/detalle/${this.props.currentSale.nextId}`}>
							Siguiente
                    </Link>
							: <>Siguiente</>}
					</div> : <><br /></>}
				</> : <><br /></>}
				{this.state.showMessages ?
					<></>
					:
					<div style={{
						width: this.isMessageEnable() ? "95%" : "100%",
						float: "left"
					}}>
						{
							this.isInPacked() || this.isDispatched() ? <div className="grid-margin">
								{this.renderMeLiHeader()}
							</div>
								: <></>
						}
						<div className="grid-margin">
							{this.renderHeader()}
						</div>
						<div className="grid-margin">
							{this.renderBottom()}
						</div>
						<div className="grid-margin">
							{this.renderPacked()}
						</div>
						{(this.isInPackaging() || this.isPacked() || this.isDispatched()) && this.isAnyOrderFlex() ? <div className="grid-margin">
							{this.isAnyOrderFlex() ? this.renderFlexCard() : <></>}
						</div>
							:
							<></>
						}
						{this.isInPackaging() ? <div className="grid-margin">
							{this.renderPackingCard()}
						</div>
							:
							<></>
						}

						{this.isPacked() || this.isDispatched() || this.isCancelled() ? <div className="grid-margin">
							{this.renderPackers()}
						</div>
							:
							<></>
						}
						{this.isDispatched() ? <div className="grid-margin">
							{this.renderEmptyComments()}
						</div> :
							<></>}
					</div>}
				{this.isMessageEnable() ?
					<div style={{
						width: "5%",
						backgroundColor: "#0c7cd5",
						float: "left",
						height: "100%",
						color: "#fff",
						textAlign: "center"
					}}
						className="pt-3">
						<button style={{ color: "#fff" }}
							className="navbar-toggler navbar-toggler align-self-center"
							type="button"
							onClick={() => this.setState({ showMessages: !this.state.showMessages })}>
							<i className="mdi mdi-menu"></i>
						</button>
					</div>
					:
					<></>}
				{this.state.showMessages ?
					<div style={{ width: "95%", float: "left" }}>
						<MLMessage {...this.props} {...{ showMessages: this.state.showMessages }} />
					</div>
					:
					<></>}
				{this.confirmationDialog()}
				{this.passwordConfirmationDialog()}
				{this.emptyDifferenceConfirmationModal()}
				{this.passwordConfirmationDialogForPackageChange()}
				{this.passwordConfirmationDialogForReopenSale()}
				{this.returnSaleConfirmation()}
				{this.confirmIncompleteOrder()}
				{this.confirmOrderWithClaim()}
				{this.passwordConfirmationDialogForDeleteSale()}
				{this.confirmDeleteSale()}
				{this.popupDialog()}
				{this.greaterAmountConfirmationModal()}
			</>
		)
	}
}

export default connect(
	(state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
	(dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(SaleDetail as any);

