import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { Notification as UserNotification } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster"
import { Position, Tooltip, Popover, Classes, Dialog } from '@blueprintjs/core';

declare var require: any;

type ReviewSalesProps =
    UserState
    & SalesState
    & typeof commonActionCreators.actionCreators
    & typeof salesActionCreators.actionCreators
    & RouteComponentProps<{ idType: string }>
    & { pageSaleState: PageSaleState };


class NotificationsTable extends React.Component<ReviewSalesProps> {
    private getTwoWeeksAgo = () => {
        const date = new Date();
        date.setDate(date.getDate() - 15);
        return date;
    }

    private itemsPerPage = () => this.props.user.itemsPerPage;

    state: {
        currentPage: number,
        selectedNotifications: UserNotification[],
        showConfirmationModal: boolean
    } = {
        currentPage: 1,
            selectedNotifications: [],
            showConfirmationModal: false
        };

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps: ReviewSalesProps) {

        
        if (nextProps.user.notifications.filter(x => x.type == parseInt(this.props.match.params.idType)).length > 0) {
            console.log('currentPage',this.state.currentPage);
            console.log('total pages', ((nextProps.user.notifications.filter(x => x.type == parseInt(this.props.match.params.idType)).length / this.itemsPerPage())));
            if (this.state.currentPage >= ((nextProps.user.notifications.filter(x => x.type == parseInt(this.props.match.params.idType)).length / this.itemsPerPage())) + 1) {
                this.setState({ currentPage: this.state.currentPage - 1 });
            }
        }
    }

    private pushSelectedNotifications(notification: UserNotification) {
        let selectedNotifications = this.state.selectedNotifications;
        if (selectedNotifications.includes(notification)) {
            selectedNotifications = selectedNotifications.filter((value: UserNotification) => notification != value);
        }
        else {
            selectedNotifications.push(notification);
        }

        this.setState({ selectedNotifications: selectedNotifications });
    }

    private togglerSelectedAllSales() {
        if (this.state.selectedNotifications.length == this.currentSales().length) {
            this.setState({ selectedNotifications: [] });
        }
        else {
            this.setState({ selectedNotifications: this.currentSales() });
        }
    }

    private currentSales = () => this.props.user.notifications.filter(x => x.type == parseInt(this.props.match.params.idType)).slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);


    private renderBody = () => {
        const sale = this.props.user.notifications.filter(x => x.type == parseInt(this.props.match.params.idType));
        return sale.length == 0 ?
            <Card>
                <Card.Body>
                    <h4 className="card-title">
                        No posee notificaciones
                    </h4>
                </Card.Body>
            </Card>
            :
            <Card>
                <Card.Body>
                    <h4 className="card-title">
                        Notificaciones
                </h4>
                    <p className="card-description"> Resultados encontrados {sale.length} </p>
                    <Row className="float-right">
                        <Col md={12}>
                            Mostrando {this.state.currentPage} de {Math.ceil(sale.length / this.itemsPerPage())}
                            <Button color="success" className="ml-2 mr-2" disabled={this.state.currentPage <= 1}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage - 1), selectedSales: [] })}>
                                {"<"}</Button>
                            <Button color="success" className="mr-2" disabled={this.state.currentPage >= ((sale.length / this.itemsPerPage()))}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage + 1), selectedSales: [] })}>
                                {">"}</Button>
                        </Col>
                    </Row>
                    <Row className="table-responsive">
                        <Col md={12}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input type="checkbox" className="form-check-input" checked={this.state.selectedNotifications.length == this.currentSales().length} onChange={(evt: ChangeEvent) => this.togglerSelectedAllSales()} />
                                                    <i className="input-helper"></i>
                                                </label>
                                            </div>
                                        </th>
                                        <th>Fecha</th>
                                        <th>Mensajes</th>
                                        <th>Leído</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    {this.currentSales().map((notification: UserNotification, index: number) => {
                                        const orderDate = new Date(notification.dateTime);
                                        return <tr key={index}>
                                                <td>
                                                    <div className="form-check">
                                                        <label className="form-check-label text-muted">
                                                            <input type="checkbox" className="form-check-input" checked={this.state.selectedNotifications.includes(notification)} onChange={(evt: ChangeEvent) => this.pushSelectedNotifications(notification)} />
                                                            <i className="input-helper"></i>
                                                        </label>
                                                    </div>
                                                </td>
                                            <td className={notification.isRead ? "font-weight-normal" : "font-weight-bold"}>{orderDate.getDate() + "/" + (orderDate.getMonth() + 1) + "/" + orderDate.getFullYear()}</td>
                                            <td className={ notification.isRead ? "font-weight-normal" : "font-weight-bold" }>
                                                {notification.body}
                                            </td>
                                            <td>
                                                <div className="form-check">
                                                    <label className="form-check-label text-muted">
                                                        <input type="checkbox" className="form-check-input" checked={notification.isRead} onChange={(evt: ChangeEvent) => this.props.toggleNotificationReadness(notification.id) } />
                                                        <i className="input-helper"></i>
                                                    </label>
                                                </div></td>
                                            
                                        </tr>
                                    }
                                    )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col md={12}>
                            <Button disabled={this.state.selectedNotifications.length == 0}
                                color="primary"
                                onClick={() => this.setState({ showConfirmationModal: true})}
                            >Eliminar notificaciones</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
    }

    private handleCloseConfirmation = () => {
        this.setState(
            {
                showConfirmationModal: false
            });
    }

    private confirmationDialog = () => {
        return <Dialog
            icon="info-sign"
            onClose={this.handleCloseConfirmation}
            title="Confirmación"
            isOpen={this.state.showConfirmationModal}
        >
            <div className={Classes.DIALOG_BODY}>
                <p>¿Desea eliminar { this.state.selectedNotifications.length } notification/es?</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={this.handleCloseConfirmation}>Cancelar</Button>
                    <Button color="primary" className="ml-2" onClick={() => {
                        this.props.removeNotifications(this.state.selectedNotifications);
                        this.handleCloseConfirmation();
                        this.setState({ selectedNotifications: [] });
                    }
                    }>
                        Confirmar</Button>
                </div>
            </div>
        </Dialog>
    }

    render() {
        return (
            <>
                <div className="grid-margin">
                </div>
                <div className="grid-margin">
                    {this.renderBody()}
                </div>
                {this.confirmationDialog()}
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(NotificationsTable as any);

