import * as Common from './Common/types';
import * as CommonReducer from './Common/reducers';
import * as Sales from './Sales/types';
import * as SalesReducer from './Sales/reducers';

// The top-level state object
export interface ApplicationState {
    userState: Common.UserState | undefined;
    salesState: Sales.SalesState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    userState: CommonReducer.reducer,
    salesState: SalesReducer.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
