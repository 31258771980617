import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, SaleProduct, MeLiSaleDetailContainer, EditingSaleProduct, SaleDifference, LinkSaleDetailContainer, Sales, TransferSaleDetailContainer, IContainer, Message, SaleMessage, Product, MessageConfiguration } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ReactEventHandler, FormEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { Position, Toaster, MenuItem, FileInput, Dialog, Classes, Icon } from "@blueprintjs/core";
import { AppToaster } from "../shared/toaster"
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import MLMessage from './MLMessage';

type MLSaleProps =
    UserState
    & SalesState
    & typeof commonActionCreators.actionCreators
    & typeof salesActionCreators.actionCreators
    & RouteComponentProps<{ idSale: string }>;


class Configuration extends React.Component<MLSaleProps> {

    state: {
        file: any,
        accordionItem: string,
        currentMessage: MessageConfiguration | undefined
    } = {
            file: null,
            accordionItem: 'CSV',
            currentMessage: undefined
        };

    componentWillReceiveProps(nextProps: MLSaleProps) {
        if (nextProps.messageConfigurationUpdateSuccess) {
            AppToaster.show({ intent: "success", message: "Mensaje actualizado exitosamente" });
            //this.setState({ currentMessage: undefined });
            this.props.dismissMessageConfigurationSuccess();
        }
    }

    componentDidMount() {
        if (this.props.messageConfigurations.length == 0
            && this.props.user.role == "Admin") {
            this.props.getMessageConfigurations();
        }
    }

    renderCsvCard() {
        return <Card>
            <Accordion.Toggle
                as={Card.Header}
                onClick={() => this.setState({ accordionItem: "CSV" }) }
                className={this.state.accordionItem == "CSV" ? "open" : ""}
                variant="link"
                eventKey={"CSV"}>
                CSV
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"CSV"}>
                <Card.Body>
                    <Row>
                        <FileInput title={"file"} text={"fileName"}
                            inputProps={{ accept: ".csv" }}
                            buttonText="Buscar"
                            onChange={(e: any) => {
                                if (e.target.files != null) {
                                    if (!e.target.files[0].name.toLowerCase().endsWith(".csv")) {
                                        this.setState({ fileTypeError: true });
                                    }
                                    else {
                                        this.setState({ file: e.target.files[0] });
                                    }
                                }
                            }}
                        />
                    </Row>
                    <Row>
                        <Button color="success" onClick={(evt: any) => this.props.getCsv(this.state.file)}>
                            Procesar CSV
                                </Button>

                    </Row>
                    <Row>
                        <ul>
                            {Object.keys(this.props.csvResult).map((key: string) =>
                                <li>
                                    <strong>{key} </strong>: {this.props.csvResult[key].map((value: string) => <> {value.split('|').map((splittedValue: string) => <> { splittedValue }<br /></>)}</>)}
                                </li>
                            )}
                        </ul>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>;
    }

    renderMessagesCard() {
        if (this.props.user.role == "Admin") {
            return <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    onClick={() => this.setState({ accordionItem: "Messages" })}
                    className={this.state.accordionItem == "Messages" ? "open" : ""}
                    variant="link"
                    eventKey={"Messages"}>
                    Mensajes
            </Accordion.Toggle>
                <Accordion.Collapse eventKey={"Messages"}>
                    <Card.Body>
                        <Row>
                            <select className="form-control"
                                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ currentMessage: this.props.messageConfigurations.find((message: MessageConfiguration) => message.messageId == evt.currentTarget.value) })}>
                                <option value="">Seleccione una opción...</option>
                                {this.props.messageConfigurations.map((messageConfiguration: MessageConfiguration) =>
                                    <option value={messageConfiguration.messageId}>{messageConfiguration.translatedName}</option>)}
                            </select>
                        </Row>
                        {this.state.currentMessage == undefined ? <></> :
                            <>
                                <Row>
                                    <div className="form-check">
                                        <label className="form-check-label text-muted">
                                            <input type="checkbox" className="form-check-input"
                                                checked={this.state.currentMessage != undefined ?
                                                    this.state.currentMessage.isActive : false}
                                                onChange={(evt: ChangeEvent) => {
                                                    if (this.state.currentMessage != undefined) {
                                                        let newMessage = { ...this.state.currentMessage };
                                                        newMessage.isActive = !newMessage.isActive;
                                                        this.setState({ currentMessage: newMessage });
                                                    }
                                                }} />
                                            <i className="input-helper"></i>
                                Activo
                            </label>
                                    </div>
                                </Row>
                                <Row>
                                    <Label>
                                        Asunto
                        </Label>
                                    <Form.Control type="text" className="form-control"
                                        placeholder="Asunto"
                                        value={this.state.currentMessage != undefined ?
                                            this.state.currentMessage.subject : ""}
                                        onChange={(evt: React.ChangeEvent<FormControlProps>) => {
                                            if (this.state.currentMessage != undefined && evt.currentTarget.value != undefined) {
                                                let newMessage = { ...this.state.currentMessage };
                                                newMessage.subject = evt.currentTarget.value;
                                                this.setState({ currentMessage: newMessage });
                                            }
                                        }} />
                                </Row>
                                <Row className="mt-2">
                                    <Label>
                                        Texto
                                </Label>
                                    <textarea
                                        className="form-control form-control"
                                        style={{ width: '100%', height: '100px' }}
                                        placeholder="Texto"
                                        value={this.state.currentMessage != undefined ?
                                            this.state.currentMessage.messageText : ""}
                                        onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
                                            if (this.state.currentMessage != undefined && evt.currentTarget.value != undefined) {
                                                let newMessage = { ...this.state.currentMessage };
                                                newMessage.messageText = evt.currentTarget.value;
                                                this.setState({ currentMessage: newMessage });
                                            }
                                        }}
                                    />
                                </Row>
                                <Row className="mt-2">
                                    <Label>
                                        Descripción
                        </Label>
                                    <textarea
                                        className="form-control form-control"
                                        style={{ width: '100%', height: '100px' }}
                                        placeholder="Descripción"
                                        value={this.state.currentMessage != undefined ?
                                            this.state.currentMessage.description == null ? "" : this.state.currentMessage.description
                                            : ""}
                                        onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
                                            console.log('desc');
                                            if (this.state.currentMessage != undefined && evt.currentTarget.value != undefined) {
                                                let newMessage = { ...this.state.currentMessage };
                                                newMessage.description = evt.currentTarget.value;
                                                this.setState({ currentMessage: newMessage });
                                            }
                                        }}
                                    />
                                </Row>
                                <Row className="mt-2">
                                    <Label>
                                        Nombre Adjunto
                        </Label>
                                    <Form.Control type="text" className="form-control"
                                        placeholder="Adjunto"
                                        value={this.state.currentMessage != undefined ?
                                            this.state.currentMessage.attachment == null ? "" : this.state.currentMessage.attachment
                                            : ""}
                                        onChange={(evt: React.ChangeEvent<FormControlProps>) => {
                                            if (this.state.currentMessage != undefined && evt.currentTarget.value != undefined) {
                                                let newMessage = { ...this.state.currentMessage };
                                                newMessage.attachment = evt.currentTarget.value;
                                                this.setState({ currentMessage: newMessage });
                                            }
                                        }} />
                                </Row>
                                <Row className="float-right mt-2 mb-2">
                                    <Button color="success" className="ml-3" onClick={(evt: any) => this.state.currentMessage != undefined ? this.props.saveMessageConfiguration(this.state.currentMessage) : {}}>
                                        Guardar cambios
                        </Button>
                                </Row>
                            </>
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>;
        }
        return <></>;
    }

    renderRefreshProduct() {
        return <Card>
            <Accordion.Toggle
                as={Card.Header}
                onClick={() => this.setState({ accordionItem: "Productos" })}
                className={this.state.accordionItem == "Productos" ? "open" : ""}
                variant="link"
                eventKey={"Productos"}>
                Productos
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"Productos"}>
                <Card.Body>
                    <Row>
                        <Button color="primary" type="button"
                            className="mr-2"
                            onClick={(evt: any) => this.props.refreshAllProducts()} >
                            Refrescar Productos</Button>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>;
    }

    renderRefreshHolidays() {
        return <Card>
            <Accordion.Toggle
                as={Card.Header}
                onClick={() => this.setState({ accordionItem: "Feriados" })}
                className={this.state.accordionItem == "Feriados" ? "open" : ""}
                variant="link"
                eventKey={"Feriados"}>
                Feriados
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"Feriados"}>
                <Card.Body>
                    <Row>
                        <Button color="primary" type="button"
                            className="mr-2"
                            onClick={(evt: any) => this.props.refreshHolidays()} >
                            Refrescar Feriados</Button>
                    </Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>;
    }

    render() {
        return (
            <>
                <h1>
                    <i className="mdi mdi-settings menu-icon"></i>Configuración
                </h1>
                <div className="grid-margin">
                    <Accordion defaultActiveKey={"CSV"}>
                        {this.renderCsvCard()}
                        {this.renderMessagesCard()}
                        {this.renderRefreshProduct()}
                        {this.renderRefreshHolidays()}
                    </Accordion>
                </div>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(Configuration as any);

