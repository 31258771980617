import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../.';
import { UserState, KnownAction, User, Notification as UserNotification, ManageUser, Services } from '../types';
import { configuration } from '../../configureStore';
import { handleError, requestHelper } from '../../common';

const getUserData = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
        fetch(`${configuration.baseApiUrl}/User`,
            {
                method: 'GET',
                cache: 'no-cache',
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            })
            .then(response => {
                if (response.ok) {
                    return response.json() as Promise<User>;
                }
                else {
                    handleError(dispatch, response);
                    return null;
                }
            })
            .then(user => {
                if (user != null) {
                    dispatch({ type: 'RECEIVE_USER_DATA', user: user });
                }
            })
            .catch((errorMessage: string) => { dispatch({ type: 'SERVER_ERROR', errorMessage: errorMessage }) });
    }
}

const getUsers = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
        let url = `${configuration.baseApiUrl}/User/All`;


        let onSuccess = (response: Response) => {
            {
                (response.json() as Promise<ManageUser[]>)
                    .then(data => {
                        if (data != null) {
                            dispatch({ type: "RECEIVE_ALL_USERS", users: data });
                        }
                    })
            }
        };

        requestHelper(url, 'GET', null, onSuccess, dispatch);
    }
};

const getServices = (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
        let url = `${configuration.baseApiUrl}/Service/All`;


        let onSuccess = (response: Response) => {
            {
                (response.json() as Promise<Services[]>)
                    .then(data => {
                        if (data != null) {
                            dispatch({ type: "RECEIVE_ALL_SERVICES", services: data });
                        }
                    })
            }
        };

        requestHelper(url, 'GET', null, onSuccess, dispatch);
    }
};
export const actionCreators = {
    login: (userName: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            dispatch({ type: "BEGIN_REQUEST" });
            fetch(`${configuration.baseApiUrl}/Account`,
                {
                    method: 'POST',
                    cache: 'no-cache',
                    body: JSON.stringify({ userName: userName, password: password }),
                    headers: { 'Content-Type': 'application/json'}
                })
                .then(response => {
                    dispatch({ type: "END_REQUEST" });
                    if (response.ok) {
                        return response.json() as Promise<any>;
                    }
                    else {
                        if (response.status != 400) {
                            handleError(dispatch, response);
                        }
                        else {
                            dispatch({ type: 'INVALID_USER_PASSWORD' })
                        }
                        return null;
                    }
                })
                .then(data => {
                    if (data != null) {
                        localStorage.setItem('token', data.token);
                        dispatch({ type: 'RECEIVE_USER_LOGGED', userName: userName });
                    }
                })
                .catch((errorMessage: string) => { dispatch({ type: 'SERVER_ERROR', errorMessage: errorMessage }) });
        }
    },
    getUserData: getUserData,
    toggleNotificationReadness: (notificationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let url = `${configuration.baseApiUrl}/User/ToggleNotification/${notificationId}`;

            let onSuccess = (response: Response) => {
                {
                    const untypedDispatch = <any>dispatch;
                    untypedDispatch(getUserData());
                }
            };

            requestHelper(url, 'POST', null, onSuccess, dispatch);
        }
    },
    removeNotifications: (notifications: UserNotification[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let url = `${configuration.baseApiUrl}/User/Notifications`;

            let objectToSend = {
                notificationIds: notifications.map((notification: UserNotification) => notification.id)
            };

            let onSuccess = (response: Response) => {
                {
                    const untypedDispatch = <any>dispatch;
                    untypedDispatch(getUserData());
                }
            };

            requestHelper(url, 'DELETE', objectToSend, onSuccess, dispatch);
        }
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        localStorage.clear();
        dispatch({ type: 'CLEAN_USER_DATA' })
    },
    getUsers: getUsers,
    recoverPassword: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let url = `${configuration.baseApiUrl}/User/Password/${userId}`;

            let onSuccess = (response: Response) => {
                dispatch({ type: "RECOVER_PASSWORD_SUCCESS" });
            };

            requestHelper(url, 'POST', null, onSuccess, dispatch);
        }
    },
    dismissRecoverPassword: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "DISMISS_RECOVER_PASSWORD_SUCCESS" });
    },
    editUser: (userId: number, email: string, userName: string, role: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let url = `${configuration.baseApiUrl}/User`;

            let objectToSend = {
                id: userId,
                email: email,
                userName: userName,
                role: role,
                password: password
            };

            let onSuccess = (response: Response) => {
                const untypedDispatch = <any>dispatch;
                untypedDispatch(getUsers());
                dispatch({ type: "CREATE_USER_SUCCESS" });
            };

            requestHelper(url, userId == 0 ? 'POST' : 'PUT', objectToSend, onSuccess, dispatch);
        }
    },
    dismissCreateUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "DISMISS_CREATE_USER_SUCCESS" });
    },
    deleteUser: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let url = `${configuration.baseApiUrl}/User/${userId}`;

            let onSuccess = (response: Response) => {
                const untypedDispatch = <any>dispatch;
                untypedDispatch(getUsers());
                dispatch({ type: "DELETE_USER_SUCCESS" });
            };

            requestHelper(url, 'DELETE', null, onSuccess, dispatch);
        }
    },
    dismissDeleteUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "DISMISS_DELETE_USER_SUCCESS" });
    },
    getServices: getServices,
    //BACK 331
    changeStateService: (cod: string, enviroment: string, active: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState) {
            let url = `${configuration.baseApiUrl}/Service/ActiveService`;
            let objectToSend = {
                Cod: cod,
                Enviroment: enviroment,
                Active: active
            };

            let onSuccess = (response: Response) => {
                const untypedDispatch = <any>dispatch;
                dispatch({ type: "RECEIVE_SERVICE_ACTIVE" });
                untypedDispatch(getServices());
            };

            requestHelper(url, 'POST', objectToSend, onSuccess, dispatch);
        }
    },
};
