import * as React from 'react';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Common/actions';
import { ApplicationState } from '../store';
import { UserState } from '../store/Common/types';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Next } from 'react-bootstrap/PageItem';

type AppProps =
    UserState
    & RouteComponentProps<{ idSale: string }>;

class App extends React.Component<AppProps> {
    state: { isFullPageLayout: boolean, location: string } = { isFullPageLayout: false, location: "" };

    render() {
        let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
        let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
        let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
        return (
                <div className="container-scroller">
                    {navbarComponent}
                    <div className="container-fluid page-body-wrapper">
                        {sidebarComponent}
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <AppRoutes />
                            </div>
                            {footerComponent}
                        </div>
                    </div>
                </div>
        );
    }

    componentDidMount() {
        this.onRouteChanged();
    }

    componentWillReceiveProps(nextProps: AppProps) {
        console.log(nextProps, this.props);
        if (nextProps.isLogged && !this.props.isLogged) {
            //this.setState({
            //    isFullPageLayout: true
            //});
            this.onRouteChanged();
        }
        else if (!nextProps.isLogged && this.props.isLogged) {
            //this.setState({
            //    isFullPageLayout: false
            //});
            this.onRouteChanged();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== this.state.location) {
            this.setState({ location: this.props.location.pathname });
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        window.scrollTo(0, 0);
        const fullPageLayoutRoutes = ['/login', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
        let bodyWrapper = document.querySelector('.page-body-wrapper')

        this.setState({
            isFullPageLayout: false
        });

        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
                this.setState({
                    isFullPageLayout: true
                });
                if (bodyWrapper != null) {
                    bodyWrapper.classList.add('full-page-wrapper');
                }
                break;
            } else {
                if (bodyWrapper != null) {
                    bodyWrapper.classList.remove('full-page-wrapper');
                }
            }
        }
    }

}

export default connect(
    (state: any) => { return { ...state.userState, ...state.router } }, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(App as any);
