import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { UserState, ManageUser } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster"
import { Position, Tooltip, Popover, Classes, Dialog } from '@blueprintjs/core';

declare var require: any;

type ManageUsersProps =
    UserState
    & typeof commonActionCreators.actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;


class ManageUsers extends React.Component<ManageUsersProps> {
    private itemsPerPage = () => this.props.user.itemsPerPage;

    state: {
        currentPage: number,
        showConfirmationModal: boolean,
        selectedUser: number
    } = {
            currentPage: 1,
            showConfirmationModal: false,
            selectedUser: 0
        };

    componentDidMount() {
        if (this.props.user.role == "Admin") {
            this.search();
        }
    }

    componentWillReceiveProps(nextProps: ManageUsersProps) {
        if (nextProps.users.length == 0
            && nextProps.user.role == "Admin") {
            this.search();
        }

        if (nextProps.recoverPasswordSucess) {
            AppToaster.show({ intent: "success", message: "Correo enviado exitosamente exitosamente" });
            this.props.dismissRecoverPassword();
        }

        if (nextProps.deleteUserSuccess) {
            AppToaster.show({ intent: "success", message: "Usuario eliminado exitosamente" });
            this.props.dismissDeleteUser();
        }
    }

    private currentUsers = () => this.props.users.slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);

    private search() {
        this.props.getUsers();
    }

    private confirmationDialog = () => {
        return <Dialog
            icon="info-sign"
            onClose={() => this.setState({ showConfirmationModal: false, selectedUser: 0})}
            title="Confirmación"
            isOpen={this.state.showConfirmationModal}
        >
            <div className={Classes.DIALOG_BODY}>
                <p>¿Desea eliminar el usuario?</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={() => this.setState({ showConfirmationModal: false, selectedUser: 0 })}>Cancelar</Button>
                    <Button color="primary" className="ml-2" onClick={() => {
                        this.props.deleteUser(this.state.selectedUser);
                        this.setState({ showConfirmationModal: false, selectedUser: 0 });
                    }}>Eliminar</Button>
                </div>
            </div>
        </Dialog>
    }

    private renderBody = () => {
        const users = this.props.users;
        return  <Card>
                <Card.Body>
                    <h4 className="card-title">
                    <i className="mdi mdi-account-settings icon-title"></i>Administrar usuarios
                </h4>
                    <p className="card-description"> Resultados encontrados {users.length} </p>
                    <Row className="float-right">
                        <Col md={12}>
                            Mostrando {this.state.currentPage} de {Math.ceil(users.length / this.itemsPerPage())}
                            <Button color="success" className="ml-2 mr-2" disabled={this.state.currentPage <= 1}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage - 1), selectedSales: [] })}>
                                {"<"}</Button>
                            <Button color="success" className="mr-2" disabled={this.state.currentPage >= ((users.length / this.itemsPerPage()))}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage + 1), selectedSales: [] })}>
                                {">"}</Button>
                        </Col>
                    </Row>
                    <Row className="table-responsive">
                        <Col md={12}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th># Usuario</th>
                                        <th>Nombre</th>
                                        <th>Usuario o Email</th>
                                        <th>Rol</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.currentUsers().map((user: ManageUser, index: number) => {
                                        //const orderDate = new Date(sale.date);
                                        return <tr key={index}>
                                            <td>{user.id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.roleName}</td>
                                            <td>
                                                <Tooltip content="Editar" position={Position.BOTTOM}>
                                                    <Link className="mr-2" to={`/editarUsuario/${user.id}`}>
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                </Tooltip>

                                                <Tooltip content="Eliminar" position={Position.BOTTOM}>
                                                    <i className="fa fa-trash" onClick={() => this.setState({ selectedUser: user.id, showConfirmationModal: true }) }></i>
                                                </Tooltip>
                                            </td>
                                            { /* 
                                             * <td>
                                                <Tooltip content="Recuperar password" position={Position.BOTTOM}>
                                                    <i className="fa fa-key" onClick={ () => this.props.recoverPassword(user.id) }></i>
                                                </Tooltip>
                                            </td>
                                            */
                                            }
                                            
                                        </tr>
                                    }
                                    )}
                                </tbody>
                            </table>
                        </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <button type="button"
                            onClick={() => this.props.history.push(`/crearUsuario`) }
                            className="btn btn-primary mr-2">Crear usuario
                        </button>
                    </Col>
                </Row>
                </Card.Body>
            </Card>
    }

    render() {
        return (
            <>
                <div className="grid-margin">
                    {this.renderBody()}
                    {this.confirmationDialog()}
                </div>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(ManageUsers as any);

