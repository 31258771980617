import { Action, Reducer } from 'redux';
import { UserState, KnownAction, User, ManageUser } from '../types';

const unloadedState: UserState = {
    errorMessage: "",
    hasError: false,
    invalidUserOrPassword: false,
    isLogged: false,
    userName: "",
    user: <User>{ email: "", name: "", role: "", notifications: [], messageRefreshTime: 15, itemsPerPage: 3, maxFileSize: 2, roleName: '', printInPdf: true, massShipmentsCount: 20, versionApp: '' , testHeader: ''},
    loading: false,
    users: [],
    recoverPasswordSucess: false,
    createUserSuccess: false,
    deleteUserSuccess: false,
    filters: new Array(15),
    services : [],
};

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_USER_LOGGED':
            return { ...state, isLogged: true, userName: action.userName };
        case 'INVALID_USER_PASSWORD':
            return { ...state, invalidUserOrPassword: true };
        case 'RECEIVE_USER_DATA':
            return { ...state, user: action.user };
        case 'SERVER_ERROR':
            return { ...state, hasError: true, errorMessage: action.errorMessage };
        case 'CLEAN_USER_DATA':
            return { ...state, user: <User>{ email: "", name: "", role: "", notifications: [], itemsPerPage: 3, maxFileSize: 2, messageRefreshTime: 15, roleName: '', printInPdf: true, massShipmentsCount: 20, versionApp: '', testHeader: '' }, userName: "" };
        case 'BEGIN_REQUEST':
            return { ...state, loading: true };
        case 'END_REQUEST':
            return { ...state, loading: false };
        case 'RECEIVE_ALL_USERS':
            return { ...state, users: action.users };
        case 'RECEIVE_ALL_SERVICES':
            return { ...state, services: action.services};
        case 'RECOVER_PASSWORD_SUCCESS':
            return { ...state, recoverPasswordSucess: true };
        case 'DISMISS_RECOVER_PASSWORD_SUCCESS':
            return { ...state, recoverPasswordSucess: false };
        case 'CREATE_USER_SUCCESS':
            return { ...state, createUserSuccess: true };
        case 'DISMISS_CREATE_USER_SUCCESS':
            return { ...state, createUserSuccess: false };
        case 'DELETE_USER_SUCCESS':
            return { ...state, deleteUserSuccess: true };
        case 'DISMISS_DELETE_USER_SUCCESS':
            return { ...state, deleteUserSuccess: false };
        case 'UPDATE_FILTERS':
            let filters = [...state.filters];
            filters[action.pageSaleState] = action.filters;
            return { ...state, filters: filters };
        case 'RECEIVE_SERVICE_ACTIVE':
            return { ...state };
        default:
            return state;
    }
};
