import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { ReportsFilter } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState, MeLiSaleDetailContainer, SaleProduct, SaleMessage, Packer, Logistic } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import { Position, Tooltip, MenuItem, Popover, Dialog, Classes } from '@blueprintjs/core';
import CommonSale from './commonSale';
import { debug } from 'console';
import { UserState } from '../../store/Common/types';

declare var require: any;

type ReportsProps =
	UserState
	& SalesState
	& typeof commonActionCreators.actionCreators
	& typeof salesActionCreators.actionCreators
	& RouteComponentProps<{ startDateIndex: string }>
	& { pageSaleState: PageSaleState };


class Reports extends React.Component<ReportsProps> {
	private get90DaysAgo = () => {
		const date = new Date();
		date.setDate(date.getDate() - 90);
		return date;
	}

	state: {
		fromDate: Date,
		toDate: Date,
		reportType: string,
		pageSaleState: PageSaleState,
		//BACK-293
		logisticId: number
	} = {
			fromDate: new Date(),
			toDate: new Date(),
			reportType: "MotoFLEX",
			pageSaleState: PageSaleState.All,
			logisticId : 0
		};

	componentDidMount() {
		let filters = this.props.filters[this.props.pageSaleState] as ReportsFilter;

		if (filters != undefined) {
			this.setState({
				fromDate: filters.fromDate,
				toDate: filters.toDate,
				reportType: filters.reportType,
				logisticId: filters.logisticId
				//pageSaleState: filters.pageSaleState
			}, () => {

				//if (this.props.sales.length > 0) {
				//	this.search();
				//}
			});
		}
		
		//BACK-293
		if (this.props.logistics.length <= 0)
			this.props.getLogistics();
	}

	componentWillReceiveProps(nextProps: ReportsProps) {
		if (nextProps.salesCreatedSuccessfully) {
			AppToaster.show({ intent: "success", message: "Venta/s creada/s exitosamente" });
			this.props.dismissSuccessfullyCreated();
		}

		if (nextProps.salesBilledSuccessfully) {
			AppToaster.show({ intent: "success", message: `Se han marcado ${nextProps.amountSalesBilled} venta/s como facturadas` });
			this.props.dismissSuccessfullyBilled();
		}

		if (nextProps.confirmedSuccess) {
			AppToaster.show({ intent: "success", message: "Ventas Envidadas a empaquetar" });
			this.props.dismissConfirmSuccess();
			this.search();
		}
	}

	private onFormSubmit = (evt: any) => {
		evt.preventDefault();
		this.search();
	}

	private search() {
		this.setState({ selectedSales: [], currentPage: 1 });
		let isInSearch = this.props.pageSaleState == PageSaleState.All;
		let state = this.state.pageSaleState;
		//if (!isInSearch) {
		//	state = this.props.pageSaleState;
		//}

		this.props.getReport(this.state.fromDate,
			this.state.toDate,
			this.state.reportType,
			this.state.logisticId,
			state,
			isInSearch
		);
	}

	private onDateChange = (input: string, date: Date) => {
		this.setState({ [input]: date })
	}

	getInt(valueString?: string) {
		if (valueString != undefined && valueString != null) {

			let value = valueString == "" ? 0 : parseInt(valueString);

			if (isNaN(value) || value < 0) {
				value = 0;
			}
			return value;
		}
		return 0;
	}

	private getIconTitle() {
			return <><i className="mdi mdi-file-import icon-title mr-2"></i>Reportes</>;
	}

	private renderHeader = () => {
		return <Card>
			<Card.Body>
				<h4 className="card-title">
					{this.getIconTitle()}
				</h4>
				
				<Form onSubmit={this.onFormSubmit}>

					<Row>
						<Col className="form-group" md={3}>
							<p className="card-description"> Tipo de Reporte </p>

							<select className="form-control"
								onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ reportType: evt.currentTarget.value })}
								value={this.state.reportType}	>
								<option value="MotoFLEX">Reporte de moto FLEX</option>
							</select>
						</Col>
					</Row>
					<Row>
						<Col md={3} className="form-group">
							<Row>
								<Col md={6} >
									<label className="col-form-label">Fecha desde</label>
								</Col>
								<Col md={6}>
									<DatePicker className="form-control w-100"
										selected={this.state.fromDate}
										onChange={(date: Date) => { this.onDateChange('fromDate', date) }}
										dateFormat="dd/MM/yyyy"
										minDate={this.get90DaysAgo()}
										maxDate={new Date()}
									/>
								</Col>
							</Row>
						</Col>
						<Col md={3} className="form-group">
							<Row>
								<Col md={6}>
									<label className="col-form-label">Fecha hasta</label>
								</Col>
								<Col md={6}>
									<DatePicker className="form-control w-100"
										selected={this.state.toDate}
										onChange={(date: Date) => { this.onDateChange('toDate', date) }}
										dateFormat="dd/MM/yyyy"
										minDate={this.get90DaysAgo()}
										maxDate={new Date()}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col md={4}>
							<p className="col-form-label"> Logística </p>
									<select className="form-control"
										onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ logisticId: parseInt(evt.currentTarget.value) })}
										value={this.state.logisticId}>
										{	
													<><option value="0">-- TODAS --</option>
													{this.props.logistics.map((logistic: Logistic) => (
														<option key={logistic.id} value={logistic.id}>
															{logistic.name}
														</option>
													))}
													</>}
									</select>
						</Col>
					</Row>
					<br />
					<br />
					<Row>
						<Col md={12}>
							<button type="submit" className="btn btn-primary mr-2">Generar</button>
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	}

	private getStringDate(fecha: Date) {
		let options: Intl.DateTimeFormatOptions = {
			weekday: "long", day: "numeric", month: "numeric"
		};

		var fechaDate = new Date(fecha);
		var fechaStr = fechaDate.toLocaleDateString("es-ES", options).replace(",", "");;
		return this.capitalizeFirstLetter(fechaStr);
	}

	private capitalizeFirstLetter(valStr: String) {
		return valStr.charAt(0).toUpperCase() + valStr.slice(1);
	}
	

	render() {
		return (
			<>
				<div className="grid-margin">
					{this.renderHeader()}
				</div>
			</>
		)
	}
}

export default connect(
	(state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
	(dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(Reports as any);

