import { KnownAction } from "./Common/types";
import { Toaster, Position } from "@blueprintjs/core";
import * as React from 'react';

export const handleError = (dispatch: (action: any) => void, response: Response) => {
    if (response.status == 500) {
        response.text().then((text: string) => {
            dispatch({ type: 'SERVER_ERROR', errorMessage: text })

            Toaster.create({
                className: "recipe-toaster",
                position: Position.TOP
            }).show({
                message: text,
                intent: "danger"
            });
        })
    }
    if (response.status == 401) {
        let header = response.headers.get('www-authenticate');
        if (header != null && header.lastIndexOf('invalid_token') > 0) {
            localStorage.clear();
            dispatch({ type: 'CLEAN_USER_DATA' });
        }
        else {
            Toaster.create({
                className: "recipe-toaster",
                position: Position.TOP
            }).show({
                message: "No Posee permisos para realizar esta acción",
                intent: "danger"
            });
        }
    }

    if (response.status == 400) {
        response.json().then((data: any) => {
            dispatch({ type: 'SERVER_ERROR', errorMessage: data.message });
            let result = data.message;
            Toaster.create({
                className: "recipe-toaster",
                position: Position.TOP
            }).show({
                message: result,
                intent: "warning"
            });
        })
    }
}

export const requestHelper = (url: string, method: string, ordersToSend: any, onSuccess: any, dispatch: (action: any) => void, showSpinner :boolean = true) => {
    let authorization = `Bearer ${localStorage.getItem('token')}`;
    if (showSpinner) {
        dispatch({ type: "BEGIN_REQUEST" });
    }

    let fetchHeader =
        ordersToSend != null && ordersToSend != undefined ?
            fetch(url,
            {
                method: method,
                cache: 'no-cache',
                body: JSON.stringify(ordersToSend),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization
                }
                }) :
            fetch(url,
                {
                    method: method,
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': authorization
                    }
                });

    fetchHeader
        .then(response => {
            if (showSpinner) {
                dispatch({ type: "END_REQUEST" });
            }
            if (response.ok) {
                onSuccess(response);
            }
            else {
                handleError(dispatch, response);
            }
        })
        .catch(() => {
            if (showSpinner) {
                dispatch({ type: "END_REQUEST" });
            }

            Toaster.create({
                className: "recipe-toaster",
                position: Position.TOP
            }).show({
                message: "Se ha producido un error de comunicación. Por favor vuelva a intentar",
                intent: "danger"
            });
        }
    );
}