import { IContainer, SaleProduct, Sales } from "../../store/Sales/types";

class CommonSale {
    private getProducts = (saleContainer: IContainer) => {
        if (saleContainer.differences.length > 0) {
            for (var i = saleContainer.differences.length - 1; i >= 0; i--) {
                let difference = saleContainer.differences[i];
                if (!difference.isDeleted) {
                    return difference.saleDetails;
                }
            }
            return saleContainer.saleDetails;
        } else {
            return saleContainer.saleDetails;
        }
    }

    private processSumaryElement = (saleContainer: IContainer, result: { [id: string]: SaleProduct }) => {
        let getKey = (saleProduct: SaleProduct) => `${saleProduct.sku}_${saleProduct.price}`
        this.getProducts(saleContainer).forEach((saleProduct: SaleProduct) => {
            let value = result[getKey(saleProduct)];
            if (value != null) {
                value.quantity += saleProduct.quantity;
                if (saleProduct.packQuantity != null )
                    value.packQuantity += saleProduct.packQuantity ;
            }
            else {
                result[getKey(saleProduct)] = { ...saleProduct };
            }
        });
    }

    public getSummaryElements = (currentSale: Sales | null) => {
        let result: { [id: string]: SaleProduct } = {};
        if (currentSale != null) {
            currentSale.meLiSaleDetails.forEach((saleContainer: IContainer) => {
                if (!saleContainer.isDeleted) {
                    this.processSumaryElement(saleContainer, result);
                }
            });
            currentSale.linkSaleDetails.forEach((saleContainer: IContainer) => {
                if (!saleContainer.isDeleted) {
                    this.processSumaryElement(saleContainer, result);
                }
            });
            currentSale.transferSaleDetails.forEach((saleContainer: IContainer) => {
                if (!saleContainer.isDeleted) {
                    this.processSumaryElement(saleContainer, result);
                }
            });
            currentSale.cashSaleDetails.forEach((saleContainer: IContainer) => {
                if (!saleContainer.isDeleted) {
                    this.processSumaryElement(saleContainer, result);
                }
            });
        }

        return result;
    }
}

export default new CommonSale();