import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, SaleProduct } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ReactEventHandler, FormEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { Position, Toaster, MenuItem, Tooltip, Dialog, Classes } from "@blueprintjs/core";
import { AppToaster } from "../shared/toaster"
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import moment from 'moment';



type MLSaleProps =
	UserState
	& SalesState
	& typeof commonActionCreators.actionCreators
	& typeof salesActionCreators.actionCreators;


class MLManufacturing extends React.Component<MLSaleProps> {
	private get90DaysAgo = () => {
		const date = new Date();
		date.setDate(date.getDate() - 90);
		return date;
	}

	private getTwoWeeksAgo = () => {
		const date = new Date();
		date.setDate(date.getDate() - 15);
		return date;
	}

	private itemsPerPage = () => this.props.user.itemsPerPage;

	state: {
		fromDate: Date,
		toDate: Date,
		userName: string,
		deliveryType: string,
		hasCard: boolean | undefined,
		seletedOrders: MeLiOrder[],
		currentPage: number,
		canEditUserName: boolean,
		showConfirmationModal: false,
		orderNumber: number
	} = {
			fromDate: this.getTwoWeeksAgo(),
			toDate: new Date(),
			userName: "",
			deliveryType: "",
			hasCard: undefined,
			seletedOrders: [],
			currentPage: 1,
			canEditUserName: true,
			showConfirmationModal: false,
			orderNumber: 0
		};

	getInt(valueString?: string) {
		if (valueString != undefined && valueString != null) {

			let value = valueString == "" ? 0 : parseInt(valueString);

			if (isNaN(value) || value < 0) {
				value = 0;
			}
			return value;
		}
		return 0;
	}

	private confirmationDialog = () => {
		return <Dialog
			icon="info-sign"
			onClose={() => this.setState({ showConfirmationModal: false })}
			title="Confirmación"
			isOpen={this.state.showConfirmationModal}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>Las órdenes seleccionadas quedarán a disposición para avanzar en el flujo de ventas</p>
				<br />
				<br />
				<p>Si confirma, se abrirán estas ventas en Mercadolibre para indicar allí que ya tenemos el producto</p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => this.setState({ showConfirmationModal: false })}>Cancelar</Button>
					<Button color="primary" className="ml-2" onClick={() => {
						this.props.confirmStock(this.state.seletedOrders);
						this.setState({ showConfirmationModal: false });
					}}>Confirmar</Button>
				</div>
			</div>
		</Dialog>
	}

	componentDidMount() {
		this.props.cleanOrders();
	}

	componentWillReceiveProps(nextProps: MLSaleProps) {
		if (nextProps.messageStockConfirmedSuccess) {
			AppToaster.show({ intent: "success", message: "Stock confirmado exitosamente. Por favor recuerde confirmar stock en MercadoLibre" });

			let selectedOrders = [...this.state.seletedOrders];

			setTimeout(() => {
				selectedOrders.forEach((value: MeLiOrder) => {
					window.open(`https://www.mercadolibre.com.ar/ventas/${value.orderNumber}/detalle`);
				});
			}, 5000);

			this.setState({ seletedOrders: [] });

			this.props.dismissMessageStockConfirmed();
		}
	}

	componentWillUnmount() {
		this.setState({ userName: '', canEditUserName: true, seletedOrders: [] });
	}


	private onFormSubmit = (evt: any) => {
		evt.preventDefault();
		this.search();
	}

	private search() {
		AppToaster.show({ intent: "success", message: "Se buscarán nuevas órdenes en Mercadolibre. Este proceso puede tardar unos segundos" });
		this.setState({ seletedOrders: [], currentPage: 1 });
		this.props.getOrders(this.state.fromDate,
			this.state.toDate,
			this.state.userName,
			this.state.deliveryType,
			this.state.hasCard,
			this.state.orderNumber,
			true);
	}

	private onDateChange = (input: string, date: Date) => {
		this.setState({ [input]: date })
	}

	private orderSelectedFromSameUsers = () => {
		if (this.state.seletedOrders.length == 0) {
			return false;
		}
		let userName = this.state.seletedOrders[0].userName;
		let differentUser = this.state.seletedOrders.filter((order: MeLiOrder) => order.userName != userName);
		return differentUser.length == 0;
	}

	private pushSelectedOrder(order: MeLiOrder) {
		let selectedOrders = this.state.seletedOrders;
		if (selectedOrders.includes(order)) {
			selectedOrders = selectedOrders.filter((value: MeLiOrder) => order != value);
		}
		else {
			selectedOrders.push(order);
		}

		this.setState({ seletedOrders: selectedOrders });
	}

	private currentOrders = () => this.props.orders.slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);

	private togglerSelectedAllOrders() {
		if (this.state.seletedOrders.length == this.props.orders.length) {
			this.setState({ seletedOrders: [] });
		}
		else {
			this.setState({ seletedOrders: [...this.props.orders] });
		}
	}

	private escapeRegExpChars(text: string) {
		return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}

	private highlightText(text: string, query: string) {
		let lastIndex = 0;
		const words = query
			.split(/\s+/)
			.filter(word => word.length > 0)
			.map(this.escapeRegExpChars);
		if (words.length === 0) {
			return [text];
		}
		const regexp = new RegExp(words.join("|"), "gi");
		const tokens: React.ReactNode[] = [];
		while (true) {
			const match = regexp.exec(text);
			if (!match) {
				break;
			}
			const length = match[0].length;
			const before = text.slice(lastIndex, regexp.lastIndex - length);
			if (before.length > 0) {
				tokens.push(before);
			}
			lastIndex = regexp.lastIndex;
			tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
		}
		const rest = text.slice(lastIndex);
		if (rest.length > 0) {
			tokens.push(rest);
		}
		return tokens;
	}

	private renderUser: ItemRenderer<string> = (user, { handleClick, modifiers, query }) => {
		if (!modifiers.matchesPredicate) {
			return null;
		}
		const text = user;
		return (
			<MenuItem
				active={modifiers.active}
				disabled={modifiers.disabled}
				label={user}
				key={user}
				onClick={handleClick}
				text={this.highlightText(text, query)}
			/>
		);
	};

	private handleRadioChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		if (changeEvent.target.value == undefined || changeEvent.target.value == "") {
			this.setState({
				hasCard: undefined
			});
		}
		else {
			this.setState({
				hasCard: changeEvent.target.value == "true"
			});
		}

	}

	private renderHeader = () => {
		return <Card>
			<Card.Body>
				<h4 className="card-title">
					<i className="mdi mdi-factory icon-title mr-2"></i>Revisar ventas con tiempos de elaboración
                </h4>
				<p className="card-description"> Seleccione fechas, usuario y forma de entrega antes de buscar </p>
				<Form onSubmit={this.onFormSubmit}>
					<Row>
						<Col className="form-group" md={3}>
							<Row>
								<label className="col-md-4 col-form-label">Fecha desde</label>
								<Col md={8}>
									<DatePicker className="form-control w-100"
										selected={this.state.fromDate}
										onChange={(date: Date) => { this.onDateChange('fromDate', date) }}
										dateFormat="dd/MM/yyyy"
										minDate={this.get90DaysAgo()}
										maxDate={new Date()}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="form-group" md={3}>
							<Row>
								<label className="col-md-4 col-form-label">Fecha hasta</label>
								<Col md={8}>
									<DatePicker className="form-control w-100"
										selected={this.state.toDate}
										onChange={(date: Date) => { this.onDateChange('toDate', date) }}
										dateFormat="dd/MM/yyyy"
										minDate={this.get90DaysAgo()}
										maxDate={new Date()}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col className="form-group" md={6}>
							<Label>
								Usuario de ML
                            </Label>

							<Form.Group className="d-flex search-field">
								<Form.Control type="text" placeholder="Usuario" size="lg"
									disabled={!this.state.canEditUserName}
									onChange={(e: any) => this.setState({ userName: e.target.value })}
									value={this.state.userName} />
							</Form.Group>
						</Col>
						<Col className="form-group" md={6}>
							<Label>
								Forma de entrega
                            </Label>
							<select className="form-control"
								onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => this.setState({ deliveryType: evt.currentTarget.value })}
								value={this.state.deliveryType}>
								<option value="">Seleccione una opción...</option>
								<option value="Collect">Colecta</option>
								<option value="Flex">Flex</option>
								<option value="ToBeAgreed">A Convenir</option>
							</select>
						</Col>
					</Row>
					<Row>
						<Col className="form-group" md={3}>
							<Label>
								# Órden
                            </Label>
							<Form.Control type="number" className="form-control" id="orderNumber" placeholder="# Órden"
								onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ orderNumber: this.getInt(evt.currentTarget.value) })}
								value={this.state.orderNumber != 0 ? this.state.orderNumber.toString() : ''}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="form-group" md={12}>
							<div className="col-sm-4">
								<div className="form-check">
									<label className="form-check-label">
										<input type="radio" className="form-check-input"
											name="hasCardRadio"
											id="hasCardRadio1"
											value={'false'}
											onChange={this.handleRadioChange}
											checked={this.state.hasCard != undefined && !this.state.hasCard} /> Solo órdenes sin carrito
                                            <i className="input-helper"></i>
									</label>
								</div>
							</div>
							<div className="col-sm-5">
								<div className="form-check">
									<label className="form-check-label">
										<input type="radio" className="form-check-input"
											name="hasCardRadio"
											id="hasCardRadio2"
											value={'true'}
											onChange={this.handleRadioChange}
											checked={this.state.hasCard != undefined && this.state.hasCard} /> Solo órdenes con carrito
                            <i className="input-helper"></i>
									</label>
								</div>
							</div>
							<div className="col-sm-5">
								<div className="form-check">
									<label className="form-check-label">
										<input type="radio" className="form-check-input"
											name="hasCardRadio"
											id="hasCardRadio3"
											value={undefined}
											onChange={this.handleRadioChange}
											checked={this.state.hasCard == undefined} /> Indistinto
                            <i className="input-helper"></i>
									</label>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<button type="submit" className="btn btn-primary mr-2">Buscar</button>
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	}

	private getTotal = (order: MeLiOrder) => {
		let total = 0;
		order.details.forEach((item: SaleProduct) => { total += (item.price * item.quantity); })
		return total;
	}

	private convertDeliveryType = (deliveryType: string) => {
		if (deliveryType == "0") {
			return "Colecta";
		}
		else if (deliveryType == "1") {
			return "Flex";
		}
		else {
			return "A Convenir";
		}
	}

	private filterSelected() {

		var filterSelectedCount = 0;

		if (this.state.fromDate.toDateString() != this.getTwoWeeksAgo().toDateString())
			filterSelectedCount++;

		if (this.state.toDate.toDateString() != new Date().toDateString())
			filterSelectedCount++;

		if (this.state.userName != "")
			filterSelectedCount++;

		if (this.state.deliveryType != "")
			filterSelectedCount++;

		if (this.state.hasCard != undefined)
			filterSelectedCount++;

		if (this.state.orderNumber != 0)
			filterSelectedCount++;

		return filterSelectedCount;
	}

	private cleanFilters() {

		this.setState({
			fromDate: this.getTwoWeeksAgo(),
			toDate: new Date(),
			userName: "",
			deliveryType: "",
			orderNumber: 0,
			hasCard: undefined
		}, () => {
			this.search();
		});
	}

	private renderBody = () => {
		const orders = this.props.orders;
		return orders.length == 0 ?
			this.props.emptyResults ? <Card>
				<Card.Body>
					<h4 className="card-title">
						No se han encontrado resultados para su búsqueda
                    </h4>
					{this.filterSelected() > 0 ?
						<>
							<Link className='nav-link' to="#">
								<i className="mdi mdi-information"></i>
								<span className="menu-title"> Atención: Posee {this.filterSelected()} filtros seleccionados. </span>
								<Button color="success" className="ml-2 mr-2"
									onClick={(evt: any) => this.cleanFilters()}>
									Limpiar Filtros
							</Button>
							</Link>
						</>
						:
						<></>
					}
				</Card.Body>
			</Card>
				:
				<></>
			:
			<Card>
				<Card.Body>
					{this.filterSelected() > 0 ?
						<>
							<Link className='nav-link' to="#">
								<i className="mdi mdi-information"></i>
								<span className="menu-title"> Atención: Posee {this.filterSelected()} filtros seleccionados. </span>
								<Button color="success" className="ml-2 mr-2"
									onClick={(evt: any) => this.cleanFilters()}>
									Limpiar Filtros
							</Button>
							</Link>
						</>
						:
						<></>
					}
					<h4 className="card-title">
						Ordenes de MercadoLibre / MercadoShops
                </h4>
					<p className="card-description"> Resultados encontrados {orders.length} </p>
					<p className="card-description" style={{ color: "#2196f3" }}> {this.state.seletedOrders.length} elementos seleccionados </p>

					<Row className="float-right">
						<Button color="success" className="ml-3" onClick={(evt: any) => this.search()}>
							<i className="fa fa-refresh" />
						</Button>
					</Row>
					<Row className="float-right">
						<Col md={12}>
							Mostrando {this.state.currentPage} de {Math.ceil(orders.length / this.itemsPerPage())}
							<Button color="success" className="ml-2 mr-2" disabled={this.state.currentPage <= 1}
								onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage - 1) })}>
								{"<"}</Button>
							<Button color="success" className="mr-2" disabled={this.state.currentPage >= ((orders.length / this.itemsPerPage()))}
								onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage + 1) })}>
								{">"}</Button>
						</Col>
					</Row>
					<Row className="table-responsive">
						<Col md={12}>
							<table className="table table-hover">
								<thead>
									<tr>
										<th>
											<div className="form-check">
												<label className="form-check-label text-muted">
													<input type="checkbox" className="form-check-input" checked={this.state.seletedOrders.length == this.props.orders.length} onChange={(evt: ChangeEvent) => this.togglerSelectedAllOrders()} />
													<i className="input-helper"></i>
												</label>
											</div>
										</th>
										<th>Fecha</th>
										<th>Usuario-comprador</th>
										<th>Nro. Orden</th>
										<th>Forma de entrega</th>
										<th>Total</th>
										<th>Notificaciones</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{this.currentOrders().map((order: MeLiOrder, index: number) => {
										let orderDate = new Date(order.date);
										orderDate = moment(orderDate).add(orderDate.getTimezoneOffset(), 'm').toDate();
										return <tr key={index}>
											<td>
												<div className="form-check">
													<label className="form-check-label text-muted">
														<input type="checkbox" className="form-check-input" checked={this.state.seletedOrders.includes(order)} onChange={(evt: ChangeEvent) => this.pushSelectedOrder(order)} />
														<i className="input-helper"></i>
													</label>
												</div>
											</td>
											<td>{orderDate.getDate() + "/" + (orderDate.getMonth() + 1) + "/" + orderDate.getFullYear()}</td>
											<td>{order.userName}</td>
											<td>{order.orderNumber}</td>
											<td>{this.convertDeliveryType(order.deliveryType)}</td>
											<td>${this.getTotal(order).toFixed(2)}</td>
											<td>
												<div className="custom-control custom-switch">
													<input type="checkbox" disabled={this.props.user.role != "Admin"} className="custom-control-input" checked={!order.hasNotificationDisabled} onChange={(evt: ChangeEvent) => this.props.toggleOrderNotification(order.orderNumber)} id={`customSwitch${order.orderNumber}`} />
													<label className="custom-control-label" htmlFor={`customSwitch${order.orderNumber}`}></label>
												</div>
											</td>
											<td>
												<span>
													<Tooltip content="Ver detalle" position={Position.TOP}>
														<a className="mr-2" target="_blank" href={`https://www.mercadolibre.com.ar/ventas/${order.orderNumber}/detalle`} >
															<i className="fa fa-search"></i>
														</a>
													</Tooltip>
												</span></td>
										</tr>
									}
									)}
								</tbody>
							</table>
						</Col>
					</Row>
					<Row className="float-left pt-3">
						<Col md={12}>
							<Button onClick={(evt: any) => this.setState({ showConfirmationModal: true })} color="primary" className="btn-primary mr-2" >Confirmar stock</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
	}

	render() {
		return (
			<>
				<div className="grid-margin">
					{this.renderHeader()}
				</div>
				<div className="grid-margin">
					{this.renderBody()}
				</div>
				{this.confirmationDialog()}
			</>
		)
	}
}

export default connect(
	(state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
	(dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(MLManufacturing as any);

