import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Common/actions';
import { ApplicationState } from '../../store';
import { Button } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import { Overlay, Spinner } from '@blueprintjs/core';
declare var require: any;

type LoginProps =
    UserState
    & typeof actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;


class Login extends React.Component<LoginProps> {

    componentWillReceiveProps(nextProps: LoginProps) {
        if (!this.props.isLogged && nextProps.isLogged) {
            this.props.history.push("home")
        }
    }

    private onFormSubmit = (evt: any) => {
        evt.preventDefault();
        this.loginAction();
    }

    public state: { userName: string, password: string } = { userName: "", password: "" };

    private loginAction = () => { this.props.login(this.state.userName, this.state.password) };

    render() {
        return (
            <div>
                {this.props.loading ?
                    <Overlay isOpen={true} canEscapeKeyClose={false}>
                        <Spinner intent={"primary"} size={200} />
                    </Overlay> : <></>}
                <div className="d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src={require("../../assets/images/logo.png")} alt="logo" />
                                </div>
                                <h4>Acceso al Backoffice</h4>
                                <h6 className="font-weight-light">Ingrese usuario y password para avanzar.</h6>
                                <Form className="pt-3" onSubmit={this.onFormSubmit}>
                                    <Form.Group className="d-flex search-field">
                                        <Form.Control type="email" placeholder="Usuario" size="lg" className={this.props.invalidUserOrPassword ? "h-auto is-invalid" : "h-auto"} onChange={(e: any) => this.setState({ userName: e.target.value })} value={this.state.userName} />
                                    </Form.Group>
                                    <Form.Group className="d-flex search-field">
                                        <Form.Control type="password" placeholder="Password" size="lg" className={this.props.invalidUserOrPassword ? "h-auto is-invalid" : "h-auto"} onChange={(e: any) => this.setState({ password: e.target.value })} value={this.state.password} />
                                    </Form.Group>
                                    {
                                        this.props.invalidUserOrPassword ? (
                                            <div className="mt-3">
                                                <small id="passwordHelp" className="text-danger">
                                                    Nombre de usuario o password equivocado
                                                </small>
                                            </div>)
                                            : <></>
                                    }
                                    <div className="mt-3">
                                        <Button disabled={this.state.userName == "" || this.state.password == ""} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" >Ingresar</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(Login as any);

