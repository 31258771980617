import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, SaleProduct, MeLiSaleDetailContainer, IContainer, Message, Attachment } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ReactEventHandler, FormEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { Position, Toaster, MenuItem, FileInput } from "@blueprintjs/core";
import { AppToaster } from "../shared/toaster"
import { Suggest, ItemRenderer } from "@blueprintjs/select";



type MLMessageProps =
    UserState
    & SalesState
    & typeof commonActionCreators.actionCreators
    & typeof salesActionCreators.actionCreators
    & RouteComponentProps<{ idSale?: string }>
    & { showMessages: boolean };


class MLMessage extends React.Component<MLMessageProps> {
    private convertDate = (date: Date) => {
        var delta = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);

        var minute = 60,
            hour = minute * 60,
            day = hour * 24,
            week = day * 7;

        var fuzzy = 'hace más de un día';

        if (delta < 30) {
            fuzzy = 'justo ahora';
        } else if (delta < minute) {
            fuzzy = delta + ' segundos atrás';
        } else if (delta < 2 * minute) {
            fuzzy = 'un minuto atrás'
        } else if (delta < hour) {
            fuzzy = Math.floor(delta / minute) + ' minutos atrás';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = '1 hora atrás'
        } else if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' horas atrás';
        } else if (delta < day * 2) {
            fuzzy = 'hace más de un día';
        }
        return fuzzy;
    }

    state: {
        accordionItem: string,
        newMessage: string,
        file: any
        interval: any
        orderChanged: boolean,
        currentIndex: number
    } = {
            accordionItem: '',
            newMessage: '',
            interval: null,
            file: null,
            orderChanged: true,
            currentIndex: 0
        };

    componentWillReceiveProps(nextProps: MLMessageProps) {
        if (nextProps.messages.length != this.props.messages.length) {
            let isLastFromMe = nextProps.messages[nextProps.messages.length - 1].isFrom;
            if (isLastFromMe) {
                this.setState({ orderChanged: true });
            }

            if (!this.state.orderChanged && !isLastFromMe) {
                AppToaster.show({ intent: "success", message: "Tiene nuevos mensajes" });
            }
            else {
                this.scrollToBottom();
                this.setState({ newMessage: '', file: null });
            }
        }

        if (nextProps.showMessages &&
            nextProps.showMessages != this.props.showMessages
            && nextProps.currentSale != null) {
            this.showMessage(nextProps.currentSale.meLiSaleDetails[0], 0);
        }

        if (nextProps.cleanMessage) {
            this.setState({ newMessage: '', file: null});
            this.props.dismissMessageSent();
        }
    }

    componentWillUnmount() {
        if (this.state.interval != null) {
            clearInterval(this.state.interval);
            this.setState({ interval: null });
        }
    }

    scrollToBottom = () => {
        let currentContainer = this.messageContainer[this.state.currentIndex];
        if (currentContainer != undefined) {
            currentContainer.scrollTop = currentContainer.scrollHeight;
        }
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps: MLMessageProps) {
        if (this.state.orderChanged
            && this.messageContainer[this.state.currentIndex] != undefined
            && this.messageContainer[this.state.currentIndex].scrollHeight != 0) {
            this.scrollToBottom();
            this.setState({ orderChanged: false });
        }
    }

    private getTotal = (saleDetail: IContainer) => {
        let total = 0;
        saleDetail.saleDetails.forEach((item: SaleProduct) => { total += (item.price * item.quantity); })
        return total.toFixed(2);

    }

    private showMessage = (saleDetail: MeLiSaleDetailContainer, index: number) => {
        if (this.state.accordionItem != `meLi${index}`) {
            this.props.getMessages(saleDetail.id);
            if (this.state.interval != null) {
                clearInterval(this.state.interval);
            }

            const interval = setInterval(() => {
                if (this.state.accordionItem == `meLi${index}`) {
                    this.props.getMessages(saleDetail.id);
                }
            }, this.props.user.messageRefreshTime * 1000);

            this.setState({ accordionItem: `meLi${index}`, interval: interval, orderChanged: true, currentIndex: index });
        }
    }

    private getMessageDetail = (message: Message) => {
        if (message.isFrom) {
            return <>
                {message.message.length == 0 ? <></> :
                    <div className="outgoing_msg">
                        <div className="sent_msg">
                            <p>{ message.message.split("\n").map((item: string) => <> {item}<br /></>)
                                }</p>
                            <span className="time_date"> {this.convertDate(message.date)}</span>
                        </div>
                    </div>
                }
                {message.attachment.map((value: Attachment) => <div className="outgoing_msg">
                    <div className="sent_msg">
                        <p>
                            <i className="mdi mdi-file"></i>
                            <a target="_blank" href={`/api/Message/File/${value.id}?access_token=${localStorage.getItem('token')}`}>{value.fileName}</a>
                        </p>
                        <span className="time_date"> {this.convertDate(message.date)}</span>
                    </div>
                </div>)
                }
            </>
        }
        else {
            return <>
                {message.message.length == 0 ? <></> :
                    <div className="incoming_msg">
                        <div className="received_msg">
                            <div className="received_withd_msg">
                                <p>{message.message}</p>
                                <span className="time_date"> {this.convertDate(message.date)}</span>
                            </div>
                        </div>
                    </div>
                }
                {message.attachment.map((value: Attachment) => <div className="incoming_msg">
                        <div className="received_msg">
                            <p>
                                <i className="mdi mdi-file"></i>
                            <a target="_blank" href={`/api/Message/File/${value.id}?access_token=${localStorage.getItem('token')}`}>{value.fileName}</a>
                            </p>
                            <span className="time_date"> {this.convertDate(message.date)}</span>
                        </div>
                    </div>)
                }
            </>
        }
    }

    private hiddenFileInput = React.createRef<any>();
    private messageContainer : any[] = [];

    private getMessageCard = (saleDetail: MeLiSaleDetailContainer, index: number) => {
        let eventKey = `meLi${index}`;
        return <Card>
            <Accordion.Toggle onClick={() => this.showMessage(saleDetail, index)}
                as={Card.Header}
                className={this.state.accordionItem == eventKey ? "open" : ""}
                variant="link"
                eventKey={eventKey}>
                Orden ML #{saleDetail.orderNumber} x ${this.getTotal(saleDetail)}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`meLi${index}`}>
                <Card.Body>
                    <div ref={accordionContent => this.messageContainer[index] = accordionContent} className="messageContainer">
                        {this.props.messages.map((message: Message) => this.getMessageDetail(message))}
                    </div>
                    {this.props.user.role == "Admin"
                        || this.props.user.role == "Seller"
                        || this.props.user.role == "SellerLead" ? <Row>
                            <Col md={1}>
                                <Button color="primary" className="ml-2" onClick={() => {
                                    let hidden = this.hiddenFileInput.current;
                                    if (hidden != null) {
                                        hidden.click();
                                    }
                                }}>
                                    <i className="mdi mdi-attachment"></i>
                                </Button>
                                <input
                                    type="file"
                                    ref={this.hiddenFileInput}
                                    style={{ display: "none" }}
                                    accept={"image/png, image/jpeg, .pdf"}
                                    onChange={(e: any) => {
                                        if (e.target.files != null) {
                                            this.setState({ file: e.target.files[0] });
                                        }
                                    }
                                    }
                                />
                            </Col>
                            <Col md={8}>
                                <textarea
                                    style={{ width: '100%' }}
                                    placeholder="Escribile al comprador"
                                    value={this.state.newMessage}
                                    onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => this.setState({ newMessage: evt.currentTarget.value })}
                                />
                                {this.state.file != null ? <div>
                                    <i className="mdi mdi-file"></i>
                                    {this.state.file.name}
                                </div> : <></>}
                            </Col>
                            <Col md={3}>
                                <Button color="primary"
                                    className="ml-2"
                                    disabled={this.state.newMessage.length == 0 && this.state.file == null}
                                    onClick={() => {
                                        this.props.sendMessages(saleDetail.id,
                                            this.state.newMessage, this.state.file)
                                    }}>Enviar mensaje</Button>
                            </Col>
                        </Row>
                        :
                        <></>
                        }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    }

    private renderBody = () => {
        let currentId = this.props.currentSale != null ? this.props.currentSale.id : 0;
        return this.props.currentSale == null ? <></>
            : <Card>
                <Card.Body>
                    {this.props.currentSale.state == 'InReview' ?
                        <h4 className="card-title">
                            Agregar adicionales:
                        <Button className="ml-2 mr-2" color="primary" onClick={() => this.props.history.push(`/ventas/MercadoLibre/${currentId}`)}>+Órden ML</Button>
                            <Button className="mr-2" color="primary" onClick={() => this.props.history.push(`/ventas/Link/${currentId}`)}>+Link MP</Button>
                            <Button className="mr-2" color="primary" onClick={() => this.props.history.push(`/ventas/Transferencia/${currentId}`)}>+Transferencia</Button>
                        </h4>
                        : <></>
                        }
                    <Accordion>
                        {this.props.currentSale.meLiSaleDetails.map((saleDetail: MeLiSaleDetailContainer, index: number) => this.getMessageCard(saleDetail, index))}
                    </Accordion>
                </Card.Body>
            </Card>
    }

    render() {
        return (
            <>
                <div className="grid-margin">
                    {this.renderBody()}
                </div>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(MLMessage as any);

