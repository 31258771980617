import { ReviewFilter, ReportsFilter} from "../../Common/types";

interface ReceiveMeLiOrders {
    type: 'RECEIVE_MELI_ORDER';
    orders: MeLiOrder[];
}

interface ReceiveSalesCreated {
    type: 'RECEIVE_SALES_CREATED';
    amountSalesCreated: number;
    saleId: number;
    //BACK 89
    containsmessages: number;
    loadedWitouthDifference: boolean;
    duplicatesales: number;
}

interface ReceiveStockConfirmed {
    type: 'RECEIVE_STOCK_CONFIRMED';
    amountOrderConfirmed: number;
}

interface DismissStockConfirmed {
    type: 'DISMISS_STOCK_CONFIRMED';
}

interface ReceiveSalesOrderAdded {
    type: 'RECEIVE_SALES_ORDER_ADDED';
    amountSalesCreated: number;
}

interface CleanOrders {
    type: 'CLEAN_ORDERS';
}

interface CleanCurrentSale {
    type: 'CLEAN_CURRENT_SALE';
}

interface DismissSalesCreated {
    type: 'DISMISS_SALES_CREATED';
}

interface ReceiveSales {
    type: 'RECEIVE_SALES';
    sales: Sales[];
}

interface ReceiveMessages {
    type: 'RECEIVE_MESSAGES';
    messages: Message[];
}

interface ReceiveProducts {
    type: 'RECEIVE_PRODUCTS';
    products: Product[];
}

interface ReceiveProductRefresh {
    type: 'RECEIVE_PRODUCT_REFRESH';
    product: Product;
}

interface ReceiveSale {
    type: 'RECEIVE_SALE';
    sale: Sales;
}

interface ReceiveInternalSaleMessages {
    type: 'RECEIVE_INTERNAL_SALE_MESSAGES';
    messages: SaleMessage[];
}

interface DismissProductRefresh {
    type: 'DISMISS_PRODUCT_REFRESH';
}

interface ReceiveConfirmedSuccess {
    type: 'RECEIVE_CONFIRMED_SUCCESS';
    newState: string;
}

interface DismissConfirmedSuccess {
    type: 'DISMISS_CONFIRMED_SUCCESS';
}

//BACK 287
interface ReceiveConfirmBackReviewSuccess {
    type: 'RECEIVE_CONFIRMED_BACK_REVIEW_SUCCESS';
}

interface DismissConfirmBackReviewSuccess {
    type: 'DISMISS_CONFIRMED_BACK_REVIEW_SUCCESS';
}

interface ReceiveSalesBilled {
    type: 'RECEIVE_SALES_BILLED';
    amountSalesBilled: number;
}


interface ReceiveConfirmedSalesWithOutMessage {
    type: 'RECEIVE_CONFIRMED_SALES_WITHOUT_MESSAGE';
    countConfirmedSalesWithOutMessage: number;
}


interface DismissReceiveConfirmedSalesWithOutMessage {
    type: 'DISMISS_CONFIRMED_SALES_WITHOUT_MESSAGE';
}

interface DismissSalesBilled {
    type: 'DISMISS_SALES_BILLED';
}

interface RefreshPaymentStatus {
    type: 'REFRESH_PAYMENT_STATUS';
    id: number,
    paymentType: string
}

interface ReceiveMessageSent {
    type: 'RECEIVE_MESSAGE_SENT';
}

interface DismissMessageSent {
    type: 'DISMISS_MESSAGE_SENT';
}

interface ReceiveCsvResult {
    type: 'RECEIVE_CSV_RESULT';
    result: { [id: string]: string[]; };
}

interface BeginRequest {
    type: 'BEGIN_REQUEST';
}

interface EndRequest {
    type: 'END_REQUEST';
}

interface ReceivePackers {
    type: 'RECEIVE_PACKERS';
    packers: Packer[];
}

interface ReceiveDrivers {
    type: 'RECEIVE_DRIVERS';
    drivers: Driver[];
}

interface ReceiveLogistics {
    type: 'RECEIVE_LOGISTICS';
    logistics: Logistic[];
}

interface DissMissLoadedWithoutDiff {
    type: 'DISMISS_LOADED_WITHOUT_DIFF';
}


//BACK 145
interface ReceiveProvinces {
    type: 'RECEIVE_PROVINCES';
    provinces: Province[];
}

interface ReceiveCities {
    type: 'RECEIVE_CITIES';
    cities: City[];
}

interface ReceiveCompanies {
    type: 'RECEIVE_COMPANIES';
    companies: Company[];
}

interface ReceiveAuditLogs {
    type: 'RECEIVE_AUDIT_LOGS';
    auditLogs: AuditLog[];
}

interface ReceivePackedSuccess {
    type: 'RECEIVE_PACKED_SUCCESS';
}

interface ReceiveReopenSuccess {
    type: 'RECEIVE_REOPEN_SUCCESS';
}

interface DismissReopenSuccess {
    type: 'DISMISS_REOPEN_SUCCESS';
}

interface DismissReceivePackedSuccess {
    type: 'DISMISS_RECEIVE_PACKED_SUCCESS';
}

interface ReceiveShippedSuccess {
    type: 'RECEIVE_SHIPPED_SUCCESS';
}

interface DismissShippedSuccess {
    type: 'DISMISS_SHIPPED_SUCCESS';
}

interface ReceiveClaimOrder {
    type: 'RECEIVE_CLAIM_ORDER';
    orders: ClaimStatus
}

interface ReceiveMessageConfigurations {
    type: 'RECEIVE_MESSAGE_CONFIGURATIONS';
    messageConfigurations: MessageConfiguration[]
}

interface ReceiveMessageConfigurationUpdate {
    type: 'RECEIVE_MESSAGE_CONFIGURATION_UPDATE';
}

interface DismissMessageConfigurationUpdate {
    type: 'DISMISS_MESSAGE_CONFIGURATION_UPDATE';
}

interface OrderNotificationToggle {
    type: 'ORDER_NOTIFICATION_TOGGLE';
    orderId: number;
}

interface ReceiveDeleteSaleSucess {
    type: 'RECEIVE_DELETE_SUCCESS';
}

interface ReceiveDeleteSaleDismiss {
    type: 'RECEIVE_DELETE_DISMISS';
}

interface ShowCustomMessage {
    type: 'SHOW_CUSTOM_MESSAGE';
    text: string;
}

interface DismissCustomMessage {
    type: 'DISMISS_CUSTOM_MESSAGE';
}

interface ShowCustomMessagePopUp {
    type: 'SHOW_CUSTOM_MESSAGE_POPUP';
    text: string;
}

interface DismissCustomMessagePopUp {
    type: 'DISMISS_CUSTOM_MESSAGE_POPUP';
}


interface SalesPrinted {
    type: 'SALES_PRINTED';
    sales: number[];
}

interface SalesPrintedChk {
    type: 'SALES_PRINTED_CHK';
    sales: number[];
}

interface SaveMassShipment {
    type: 'SAVE_MASS_SHIPMENT';
}

interface UpdateFilters {
    type: 'UPDATE_FILTERS';
    pageSaleState: PageSaleState;
    filters: ReviewFilter | ReportsFilter;
}

//BACK 111
interface ReceiveHolidays {
    type: 'RECEIVE_HOLIDAYS';
    holidays: Holidays[];
}

//BACK 159
interface ReportFLEX {
    type: 'REPORT_FLEX';
}

interface PrintPDF {
    type: 'PRINT_PDF';
    Sales: Sales[];
    urlBaseQR: string;
}

interface SalesTagPrinted {
    type: 'SALES_TAG_PRINTED';
    printOk : boolean
}


interface ReceiveFulljausOrders {
    type: 'RECEIVE_FULLJAUS_ORDER';
    orders: MeLiOrder[];
}

export enum PageSaleState {
    InReview,
    Confirmed,
    InAdministration,
    ReOpen,
    ReadyForPacking,
    Packed,
    Dispatched,
    Cancelled,
    All,
    MLSale,
    Report,
    ReadyForPackingAcNoMeli,
    Services,
    FJSale,
}

export enum DeliveryType {
    Collect,
    Flex,
    ToBeAgreed,
    Full 
}

export enum SaleState {
    Collect,
    Flex,
    ToBeAgreed,
    Full
}

export enum DeliveryMethod {
    Local,
    Rodriguez,
    Moto,
    Expreso
}

export type KnownAction = ReceiveMeLiOrders | ReceiveSalesCreated | DismissSalesCreated
    | ReceiveSales | ReceiveProducts | ReceiveSale | ReceiveSalesOrderAdded | CleanOrders | CleanCurrentSale
    | ReceiveMessages | ReceiveProductRefresh | DismissProductRefresh | ReceiveConfirmedSuccess
    | DismissConfirmedSuccess | ReceiveSalesBilled | DismissSalesBilled | RefreshPaymentStatus
    | ReceiveMessageSent | DismissMessageSent | ReceiveCsvResult | BeginRequest | EndRequest
    | ReceivePackers | ReceiveDrivers | ReceiveProvinces | ReceiveCities | ReceiveCompanies | ReceivePackedSuccess | DismissReceivePackedSuccess | ReceiveClaimOrder
    | ReceiveReopenSuccess | DismissReopenSuccess | ReceiveMessageConfigurations
    | ReceiveMessageConfigurationUpdate | DismissMessageConfigurationUpdate
    | ReceiveShippedSuccess | DismissShippedSuccess | ReceiveAuditLogs | ReceiveStockConfirmed
    | DismissStockConfirmed | OrderNotificationToggle | ReceiveInternalSaleMessages
    | ReceiveDeleteSaleSucess | ReceiveDeleteSaleDismiss
    | ShowCustomMessage | DismissCustomMessage | SaveMassShipment | UpdateFilters | SalesPrinted | SalesPrintedChk | ReceiveHolidays | ShowCustomMessagePopUp
    | DismissCustomMessagePopUp | ReportFLEX | PrintPDF | SalesTagPrinted | DissMissLoadedWithoutDiff | DismissConfirmBackReviewSuccess | ReceiveConfirmBackReviewSuccess | ReceiveLogistics
    | ReceiveFulljausOrders | ReceiveConfirmedSalesWithOutMessage | DismissReceiveConfirmedSalesWithOutMessage;

export interface MeLiOrder {
    date: Date,
    userName: string,
    userFullName: string,
    userPhone: string,
    orderNumber: number,
    deliveryType: string,
    hasCard: boolean,
    details: SaleProduct[],
    hasNotificationDisabled: boolean,
    //solo para Fulljaus
    referenceOrder: string
}

export interface Message {
    date: Date,
    message: string,
    isFrom: boolean,
    attachment: Attachment[]
}

export interface Attachment {
    fileName: string,
    id: string
}

export interface SaleMessage {
    date: Date,
    message: string,
    userName: string,
    isDeleted: boolean,
    type : string
}

export interface Sales {
    id: number,
    date: Date,
    user: string,
    clientPhone: string,
    client: string,
    type: string[],
    isReopen: boolean,
    hasMeLiOrder: boolean,
    hasPaymentLink: boolean,
    hastTransfer: boolean,
    hasCash: boolean,
    meLiSaleDetails: MeLiSaleDetailContainer[],
    linkSaleDetails: LinkSaleDetailContainer[],
    transferSaleDetails: TransferSaleDetailContainer[],
    cashSaleDetails: CashSaleDetailContainer[],
    differences: SaleDifference[],
    messages: SaleMessage[],
    previousId: number,
    nextId: number,
    state: string,
    stateText: string,
    isBilled: boolean,
    claimStatus: string,
    orderClaim: number,
    sellers: string[],
    packers: string[],
    packageQuantity: number,
    packageNumbers: number[],
    saleConfirmationPackages: SaleConfirmationPackage[],
    isPrinted: boolean,
    hasAdjustments: boolean,
    location: Locations,
    idDriver: number,
    driver: Driver | null,
    dispatchDate: Date,
    //BACK 83
    errorInSaleCharge: boolean,
    //Back 145
    saleInformation: SalesInformation,
    //BACK 54
    invoice: string,
    //BACK 233
    labelReadyToPrint: boolean
    //BACK 256
    canRePrint: boolean
    //BACK 202
    hasDistinctAddress: boolean

}

export interface SalesInformation {
    salesChannel: string,
    deliveryMethod: string,
    saleId: number,
    companyId: number,
    client: Client
}

export interface Client {
    id: number,
    eMail: string,
    name: string
	address: string,
	provinceId: string,
    locationId: string,
    postalCode: string,
    telephone: string,
    documentType: string,
    documentNumber: string,
    insurance: string
}


export interface SaleConfirmationPackage {
    sku: string,
    quantity: number
}

export interface MessageConfiguration {
    messageId: string,
    messageText: string,
    subject: string,
    attachment: string,
    description: string,
    isActive: boolean,
    translatedName: string
}

export interface IContainer {
    id: number,
    saleDetails: SaleProduct[],
    differences: SaleDifference[],
    isDeleted: boolean,
    canBeDeleted: boolean,
    isMainSale: boolean
}

export interface MeLiSaleDetailContainer extends IContainer {
    orderNumber: number,
    hasActiveChilds: boolean,
    userName: string,
    deliveryType: string,
    claimStatus: string,
    shipmentId: number | null
}


export interface LinkSaleDetailContainer extends IContainer {
    paymentLinkId: string,
    isPayed: boolean,
    hasActiveChilds: boolean
}

export interface TransferSaleDetailContainer extends IContainer {
    hasAttachment: boolean,
    attachmentFile: string,
    hasActiveChilds: boolean,
    comments: string
}

export interface CashSaleDetailContainer extends IContainer {
    hasActiveChilds: boolean,
    comments: string
}

export interface Product {
    id: string,
    name: string,
    price: number,
    stock: number,
    mla: string,
    isActive: boolean,
    isPack: boolean,
    packSize: number
}

export interface SelectedProduct {
    product: Product,
    quantity: number
}


export interface SaleProduct {
    sku: string,
    price: number,
    quantity: number,
    isPack: boolean,
    packSize: number,
    packQuantity: number
}

export interface EditingSaleProduct {
    sku: string,
    price: number,
    quantity: number,
    isEditing: boolean,
    //BACK 79
    isPack: boolean,
    packSize: number,
    packQuantity: number
}

export interface Packer {
    name: string;
    nickname: string;
    visible: string;
    appearanceOrder: string;
}

export interface AuditLog {
    date: Date,
    description: string,
    userName: string,
    saleId: number,
    action: string
}

export interface SalesState {
    orders: MeLiOrder[];
    sales: Sales[];
    products: Product[];
    allProducts: Product[];
    auditLogs: AuditLog[];
    salesCreatedSuccessfully: boolean;
    salesBilledSuccessfully: boolean;
    amountSalesCreated: number;
    amountSalesBilled: number;
    emptyResults: boolean;
    currentSale: Sales | null;
    lastSaleCreated: number;
    emptySalesResults: boolean;
    messages: Message[];
    productRefreshed: boolean;
    confirmedSuccess: boolean;
    cleanMessage: boolean;
    csvResult: { [id: string]: string[]; };
    packers: Packer[];
    packedSucess: boolean;
    confirmedReopen: boolean;
    messageConfigurations: MessageConfiguration[];
    messageConfigurationUpdateSuccess: boolean;
    saleShippedSuccess: boolean;
    amountOrderConfirmed: number;
    messageStockConfirmedSuccess: boolean;
    saleDeletedSuccess: boolean;
    customMessage: string;
    drivers: Driver[];
    customMessagePopUp: string;
    //BACK 89
    ContainsMessages: number;
    salesToPrint: Sales[] | null;
    //BACK 145
    provinces: Province[];
    cities: City[];
    companies: Company[];
    printOk: boolean;
    loadedWitouthDifference: boolean;
    //BACK 287
    confirmedBackReviewSuccess: boolean;
    DuplicateSales: number;
    //BACK-193
    logistics: Logistic[];
    //BACK
    receiveSalesSuccessfully: boolean;
    countConfirmedSalesWithOutMessage: number;
}

export interface SaleDifference {
    id: number,
    paymentLinkId: string | null,
    price: number,
    isPayed: boolean,
    description: string,
    email: string,
    isDeleted: boolean,
    canBeDeleted: boolean,
    saleDetails: SaleProduct[],
    canBeEnabled: boolean,
    type : string | null
}

export interface ClaimStatus {
    saleState: string,
    stateText: string,
    orderStatus: OrderStatus[]
}

export interface OrderStatus {
    orderId: number,
    claimStatus: string
}

export interface Shipment {
    sale: Sales,
    bulks: number,
    packers: Array<Packer>,
    driver: Driver
}

export interface Logistic {
    id: number,
    name: string
}

export interface Driver {
    id: number,
    name: string,
    logistic?: Logistic
}

export interface Locations {
    id: number,
    zipCode: number,
    province: string,
    city: string,
    zone: string,
    costo: number,
    cost: number
}

export interface Holidays {
    year: number,
    month: number,
    day: number,
    reason: string,
    type: string
}

//BACK 145
export interface Province {
    id: number,
    name: string,
    invoiceRequired : boolean
}

export interface City {
    id: number,
    name: string,
    province: Province
}

export interface Company {
    id: number,
    name: string,
    address: string,
    city: City,
    triplicateRemittance: boolean
}