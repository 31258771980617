import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label } from 'reactstrap';
import { UserState} from '../../store/Common/types';
import {  Services } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, Sales, PageSaleState } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ChangeEvent } from 'react';
import { AppToaster } from "../shared/toaster"
import { Position, Tooltip, Popover, Classes, Dialog } from '@blueprintjs/core';
import moment from 'moment';

declare var require: any;

type InfoServicesProps =
    UserState
    & typeof commonActionCreators.actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;


class InfoServices extends React.Component<InfoServicesProps> {
    private itemsPerPage = () => this.props.user.itemsPerPage;

    state: {
        currentPage: number,
        showConfirmationModal: boolean
    } = {
            currentPage: 1,
            showConfirmationModal: false
        };

    componentDidMount() {
        if (this.props.user.role == "Admin") {
            this.search();
        }
    }

    componentWillReceiveProps(nextProps: InfoServicesProps) {
        //if ((nextProps.services == undefined || nextProps.services.length == 0)
        //    && nextProps.user.role == "Admin") {
        //    this.search();
        //}

        //if (nextProps.recoverPasswordSucess) {
        //    AppToaster.show({ intent: "success", message: "Correo enviado exitosamente exitosamente" });
        //    this.props.dismissRecoverPassword();
        //}

        //if (nextProps.deleteUserSuccess) {
        //    AppToaster.show({ intent: "success", message: "Usuario eliminado exitosamente" });
        //    this.props.dismissDeleteUser();
        //}
    }

    private currentServices = () => this.props.services.slice(this.itemsPerPage() * (this.state.currentPage - 1), this.itemsPerPage() * this.state.currentPage);

    private search() {
        this.props.getServices();
    }

    private addZero = (i: string) => {
        if (parseInt(i) < 10) { i = "0" + i }
        return i;
    }

    private confirmationDialog = () => {
        //return <Dialog
        //    icon="info-sign"
        //    onClose={() => this.setState({ showConfirmationModal: false, selectedUser: 0})}
        //    title="Confirmación"
        //    isOpen={this.state.showConfirmationModal}
        //>
        //    <div className={Classes.DIALOG_BODY}>
        //        <p>¿Desea eliminar el usuario?</p>
        //    </div>
        //    <div className={Classes.DIALOG_FOOTER}>
        //        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        //            <Button onClick={() => this.setState({ showConfirmationModal: false, selectedUser: 0 })}>Cancelar</Button>
        //            <Button color="primary" className="ml-2" onClick={() => {
        //                this.props.deleteUser(this.state.selectedUser);
        //                this.setState({ showConfirmationModal: false, selectedUser: 0 });
        //            }}>Eliminar</Button>
        //        </div>
        //    </div>
        //</Dialog>
    }

    private renderBody = () => {
        const services = this.props.services;
        const tdFilaMarcada = { border: "1px solid ", color: "red" };
        let enviroment = "";
        return  <Card>
                <Card.Body>
                    <h4 className="card-title">
                    <i className="mdi mdi-apps icon-title"></i>Servicios
                </h4>
                <p className="card-description"> Total Servicios {services.length} </p>
                    <Row className="float-right">
                        <Col md={12}>
                        Mostrando {this.state.currentPage} de {Math.ceil(services.length / this.itemsPerPage())}
                            <Button color="success" className="ml-2 mr-2" disabled={this.state.currentPage <= 1}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage - 1), selectedSales: [] })}>
                                {"<"}</Button>
                        <Button color="success" className="mr-2" disabled={this.state.currentPage >= ((services.length / this.itemsPerPage()))}
                                onClick={(evt: any) => this.setState({ currentPage: (this.state.currentPage + 1), selectedSales: [] })}>
                                {">"}</Button>
                        </Col>
                    </Row>
                    <Row className="table-responsive">
                        <Col md={12}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Nombre (Código)</th>
                                        {/*<th>Descripciön</th>*/}
                                        <th>F. Ultima ejecución</th>
                                        <th>F. Próxima ejecución</th>
                                        {/*<th>Comentario</th>*/}
                                        <th>En Ejecución</th>
                                        <th>Ultimo Error</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.currentServices().map((service: Services, index: number) => {
                                        //const orderDate = new Date(sale.date);
                                    let orderDatelastRunArgentina = new Date(service.lastRunArgentina);
                                    orderDatelastRunArgentina = moment(orderDatelastRunArgentina).add(orderDatelastRunArgentina.getTimezoneOffset(), 'm').toDate();

                                    let orderDatenextRunArgentina = new Date(service.nextRunArgentina);
                                    orderDatenextRunArgentina = moment(orderDatenextRunArgentina).add(orderDatenextRunArgentina.getTimezoneOffset(), 'm').toDate();
                                    enviroment = service.environment;
                                    return <tr key={index}>
                                            <td style={service.run == false ? tdFilaMarcada : undefined}>{service.name + " (" + service.cod + ")"}</td>
                                            {/*<td style={service.run == false ? tdFilaMarcada : undefined}>{}</td>*/}
                                            {/*<td style={service.run == false ? tdFilaMarcada : undefined}>{service.description}</td>*/}
                                        <td style={service.run == false ? tdFilaMarcada : undefined}>{orderDatelastRunArgentina.getDate() + "/" + (orderDatelastRunArgentina.getMonth() + 1) + "/" + orderDatelastRunArgentina.getFullYear() + " " + this.addZero(orderDatelastRunArgentina.getHours().toString()) + ":" + this.addZero(orderDatelastRunArgentina.getMinutes().toString()) }</td>
                                        <td style={service.run == false ? tdFilaMarcada : undefined}>{orderDatenextRunArgentina.getDate() + "/" + (orderDatenextRunArgentina.getMonth() + 1) + "/" + orderDatenextRunArgentina.getFullYear() + " " + this.addZero(orderDatenextRunArgentina.getHours().toString()) + ":" + this.addZero(orderDatenextRunArgentina.getMinutes().toString()) }</td>
                                            {/*<td style={service.run == false ? tdFilaMarcada : undefined}>{service.nextRunArgentina}</td>*/}
                                            {/*<td><div style={{ height:"40px", overflow:"hidden"}}>{service.comment}</div></td>*/}
                                            <td style={service.run == false ? tdFilaMarcada : undefined}>{service.run == true ? "Si" : "No"}</td>
                                        <td style={service.run == false ? tdFilaMarcada : undefined}>{service.error}</td>
                                       
                                            {service.environment == "https://backoffice.tiendaobjetos.com.ar" ?
                                                <>
                                                    <td style={service.run == false ? tdFilaMarcada : undefined}>
                                                        {!service.runBackoffice ?
                                                            <>
                                                                <Button color="success" className="ml-2 mr-2"
                                                                    onClick={(evt: any) => this.props.changeStateService(service.cod, service.environment, true)}>
                                                                    Correr
							                                </Button>
                                                            </>
                                                            :
                                                            <>
                                                                <Button color="danger" className="ml-2 mr-2"
                                                                    onClick={(evt: any) => this.props.changeStateService(service.cod, service.environment, false)}>
                                                                    Frenar
							                                </Button>
                                                            </>
                                                        }
                                                    </td>
                                                </>
                                                :
                                                service.environment == "https://backoffice2.tiendaobjetos.com.ar" ?
                                                    <>
                                                        <td style={service.run == false ? tdFilaMarcada : undefined}>
                                                            {!service.runBackoffice2 ?
                                                                <>
                                                                    <Button color="success" className="ml-2 mr-2"
                                                                        onClick={(evt: any) => this.props.changeStateService(service.cod, service.environment, true)}>
                                                                        Correr 2
							                                </Button>
                                                                </>
                                                                :
                                                                <>
                                                                    <Button color="danger" className="ml-2 mr-2"
                                                                        onClick={(evt: any) => this.props.changeStateService(service.cod, service.environment, false)}>
                                                                        Frenar 2
							                                </Button>
                                                                </>
                                                            }
                                                        </td>
                                                    </> :
                                                <>
                                                        <td style={service.run == false ? tdFilaMarcada : undefined}>
                                                        {!service.runBackofficeQA ?
                                                                <>
                                                                    <Button color="success" className="ml-2 mr-2"
                                                                        onClick={(evt: any) => this.props.changeStateService(service.cod, service.environment, true)}>
                                                                        Correr QA
							                                </Button>
                                                                </>
                                                                :
                                                                <>
                                                                    <Button color="danger" className="ml-2 mr-2"
                                                                        onClick={(evt: any) => this.props.changeStateService(service.cod, service.environment, false)}>
                                                                        Frenar QA
							                                </Button>
                                                                </>
                                                            }
                                                        </td>

                                                    </>
                                            }
                                        </tr>
                                    }
                                    )}
                                </tbody>
                        </table>  

                        </Col>
                </Row>

                <Row className="float-right pt-3">
                    <Col md={12}>
                        <Button color="success" className="ml-2 mr-2"
                            onClick={(evt: any) => this.props.changeStateService("TODOS", enviroment, true)}>
                            Corren Todos
							</Button>

                        <Button color="danger" className="ml-2 mr-2"
                            onClick={(evt: any) => this.props.changeStateService("TODOS", enviroment, false)}>
                            Paran Todos
							</Button>

                    </Col>
                </Row>

                </Card.Body>
            </Card>
    }

    render() {
        return (
            <>
                <div className="grid-margin">
                    {this.renderBody()}
                    
                </div>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(InfoServices as any);

