import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, Row, Col, FormControl, FormControlProps, Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as commonActionCreators from '../../store/Common/actions';
import * as salesActionCreators from '../../store/Sales/actions';
import { ApplicationState } from '../../store';
import { Button, Label, Input } from 'reactstrap';
import { UserState } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
import DatePicker from "react-datepicker";
import { SalesState, MeLiOrder, Product, SelectedProduct } from '../../store/Sales/types';
import { bindActionCreators } from 'redux';
import { ReactEventHandler, FormEventHandler, ChangeEventHandler, ChangeEvent } from 'react';
import { Position, Toaster, MenuItem } from "@blueprintjs/core";
import { AppToaster } from "../shared/toaster"
import { Suggest, ItemRenderer } from "@blueprintjs/select";


type PaymentSaleProps =
    UserState
    & SalesState
    & typeof commonActionCreators.actionCreators
    & typeof salesActionCreators.actionCreators
    & RouteComponentProps<{ idSale?: string }>
    & { isLink: boolean, isCash: boolean };

class PaymentSale extends React.Component<PaymentSaleProps> {
    state: {
        userName: string,
        fullName: string,
        detail: string,
        currentProduct: Product | null,
        editingIndex: number,
        currentQuantity: number,
        selectedProducts: SelectedProduct[]
    } = {
            userName: "",
            fullName: "",
            detail: "",
            currentProduct: null,
            currentQuantity: 0,
            selectedProducts: [],
            editingIndex: -1
        };

    private isInSale = () => this.props.match.params.idSale != undefined;
    componentDidMount() {
        if (this.props.products.length == 0) {
            this.props.getProducts();
        }
        if (this.props.match.params.idSale != undefined) {
            let currentId = parseInt(this.props.match.params.idSale);
            if (this.props.currentSale == null || this.props.currentSale.id != currentId) {
                this.props.getSale(currentId);
            }
        }
        else if (this.props.currentSale != null) {
            this.props.cleanSale();
        }
    }

    handleCancelClick() {
        if (this.isInSale() && this.props.currentSale != null) {
            this.props.history.push(`/ventas/detalle/${this.props.currentSale.id}`);
        }
        else {
            this.props.history.push(`/ventas`);
        }
    }


    componentWillReceiveProps(nextProps: PaymentSaleProps) {
        if (nextProps.salesCreatedSuccessfully) {
            if (this.isInSale()) {
                AppToaster.show({ intent: "success", message: "Venta actualizada exitosamente" });
                if (this.props.currentSale != null) {
                    this.props.history.push(`/ventas/detalle/${this.props.match.params.idSale}`);
                }
            }
            else {
                AppToaster.show({ intent: "success", message: "Venta/s creada/s exitosamente" });
                this.props.history.push(`/ventas/detalle/${nextProps.lastSaleCreated}`);
            }
            this.props.dismissSuccessfullyCreated();
        }

        if (this.props.productRefreshed) {
            if (this.state.currentProduct != null) {
                let currentProduct = this.state.currentProduct;
                let newProduct = this.props.products.find((product: Product) => product.id == currentProduct.id);
                this.setState({ currentProduct: newProduct });
            }
        }

        if (nextProps.productRefreshed) {
            this.props.dismissProductRefreshed();
        }

        if (this.props.match.params.idSale != nextProps.match.params.idSale) {
            this.setState({
                userName: "",
                fullName: "",
                detail: "",
                currentProduct: null,
                currentQuantity: 0,
                selectedProducts: [],
                editingIndex: -1
            });
        }
    }

    private onFormSubmit = (evt: any) => {
        evt.preventDefault();
        this.sendLink();
    }

    private sendLink() {
        this.setState({ seletedOrders: [] });
        if (this.props.match.params.idSale != undefined) {
            this.props.addOrderLink(parseInt(this.props.match.params.idSale),
                this.state.selectedProducts,
                this.state.userName,
                this.state.detail,
                this.props.isLink,
                this.props.isCash
            );
        }
        else {
            this.props.createLinkSale(this.state.selectedProducts,
                this.state.userName,
                this.state.detail,
                this.props.isLink,
                this.state.fullName,
                this.props.isCash
            );
        }
    }

    private escapeRegExpChars(text: string) {
        return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    }

    private highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(this.escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    private saveProduct() {
        if (this.state.currentProduct == null) return;

        let newSelectedProducts = [...this.state.selectedProducts];

        if (this.state.editingIndex < 0) {
            newSelectedProducts = this.state.selectedProducts
                .filter((value: SelectedProduct) => this.state.currentProduct != value.product);

            newSelectedProducts.push({
                product: this.state.currentProduct,
                quantity: this.state.currentQuantity
            });
        }
        else {
            newSelectedProducts[this.state.editingIndex].product = this.state.currentProduct;
            newSelectedProducts[this.state.editingIndex].quantity = this.state.currentQuantity;
        }

        this.setState({
            selectedProducts: newSelectedProducts,
            currentProduct: null,
            currentQuantity: 0,
            editingIndex: -1
        })
    }

    private editProduct(selectedProduct: SelectedProduct, editingProductIndex: number) {
        this.setState({
            currentProduct: selectedProduct.product,
            currentQuantity: selectedProduct.quantity,
            editingIndex: editingProductIndex
        })
    }

    private removeProduct(selectedProduct: SelectedProduct) {
        let newSelectedProducts = this.state.selectedProducts
            .filter((value: SelectedProduct) => selectedProduct != value);
        this.setState({ selectedProducts: newSelectedProducts })
    }

    private getTotal() {
        let sum = 0;
        this.state.selectedProducts.forEach((value: SelectedProduct) => {
            sum += value.product.price * value.quantity;
        })
        return sum;
    }

    private renderProduct: ItemRenderer<Product> = (product, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = product.name;
        return (
            <MenuItem
                active={false}
                disabled={modifiers.disabled}
                label={`${product.stock}`}
                key={product.id}
                onClick={handleClick}
                text={this.highlightText(text, query)}
            />
        );
    };

    private renderCreateItem = (query: string,
        active: boolean,
        handleClick: React.MouseEventHandler<HTMLElement>) => {
        return <MenuItem
            icon="add"
            text={`Agregar "${query}"`}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    }

    private createProduct = (title: string): Product => {
        return { name: title, id: '', price: 0, stock: -1, mla: '', isActive: true, isPack: false, packSize: 0};
    }

    private getTitle() {
        if (this.props.isLink) {
            if (this.isInSale()) {
                return "Agregar Link de pago a esta venta";
            }
            return <><i className="mdi mdi-link icon-title mr-2"></i>Crear Venta A Partir De Un Link De Pago</>;
        }
        else {
            if (this.props.isCash) {
                if (this.isInSale()) {
                    return "Agregar Efectivo a esta venta";
                }
                return <><i className="mdi mdi-cash-usd icon-title mr-2"></i>Crear Venta A Partir De Un Pago En Efectivo</>;
            }
            else {
                if (this.isInSale()) {
                    return "Agregar Transferencia a esta venta";
                }
                return <><i className="mdi mdi-bank icon-title mr-2"></i>Crear Venta A Partir De Una Transferencia</>;
            }
        }
    }

    getFloat(valueString?: string) {
        if (valueString != undefined && valueString != null) {

            let value = valueString == "" ? 0 : parseFloat(valueString);

            if (isNaN(value) || value < 0) {
                value = 0;
            }
            return value;
        }
        return 0;
    }

    getInt(valueString?: string) {
        if (valueString != undefined && valueString != null) {

            let value = valueString == "" ? 0 : parseInt(valueString);

            if (isNaN(value) || value < 0) {
                value = 0;
            }
            return value;
        }
        return 0;
    }

    private renderHeader = () => {
        return <Card>
            <Card.Body>
                <h4 className="card-title">
                    {this.getTitle()}
                </h4>
                <p className="pt-2 card-description"> Detalle de productos </p>
                <Form onSubmit={this.onFormSubmit}>
                    <Row>
                        <Col className="form-group" md={4}>
                            <Label>
                                SKU
                            </Label>
                            <Suggest
                                items={this.props.products}
                                popoverProps={{ minimal: true }}
                                noResults={<MenuItem disabled={true} text="Producto inexistente" />}
                                onItemSelect={(value: Product) => {
                                    this.props.refreshProduct(value.id, value.name);
                                    this.setState({ currentProduct: value });
                                }
                                }
                                itemsEqual={(iteamA: Product, itemB: Product) => iteamA.id == itemB.id}
                                inputValueRenderer={(value: Product) => value.name}
                                itemRenderer={this.renderProduct}
                                itemPredicate={(query, product, _index, exactMatch) => { return product.name.toLowerCase().indexOf(query.toLowerCase()) >= 0 }}
                                inputProps={{ placeholder: "Seleccione un producto" }}
                                selectedItem={this.state.currentProduct}
                                createNewItemFromQuery={this.createProduct}
                                createNewItemRenderer={this.renderCreateItem}
                            />
                        </Col>
                        <Col className="form-group" md={2}>
                            <Label>
                                Precio
                            </Label>
                            <Form.Control type="number" disabled={this.state.currentProduct == null}
                                className="form-control" placeholder="Precio"
                                value={this.state.currentProduct == null ? "" : this.state.currentProduct.price.toString()}
                                onChange={(evt: React.ChangeEvent<FormControlProps>) => {
                                    this.setState({
                                        currentProduct: { ...this.state.currentProduct, price: this.getFloat(evt.currentTarget.value) }
                                    });
                                }} />
                            {this.state.currentProduct == null ? <></>
                                : this.state.currentProduct.stock != -1 ? <Label style={{ marginTop: 5 }}>
                                    <strong> Stock en ML:</strong> {this.state.currentProduct.stock}
                                </Label>
                                    : <Label style={{ marginTop: 5 }}>
                                        SKU no existe en ML. Buscar por título en ML o verificar en sistema POS
                                    </Label>}

                        </Col>
                        <Col className="form-group" md={4}>
                            <Label>
                                Cant.
                            </Label>
                            <Form.Control type="number" disabled={this.state.currentProduct == null} className="form-control"
                                placeholder="Precio"
                                value={this.state.currentQuantity.toString()}
                                onChange={(evt: React.ChangeEvent<FormControlProps>) => { if (evt.currentTarget.value != undefined) this.setState({ currentQuantity: this.getInt(evt.currentTarget.value) }) }} />
                        </Col>
                        <Col className="form-group pt-4" md={2}>
                            <Button color="primary" type="button" disabled={this.state.currentQuantity == 0 || this.state.currentProduct == null || this.state.currentProduct.price <= 0} className="mr-2" onClick={(evt: any) => this.saveProduct()} >Guardar</Button>
                        </Col>
                    </Row>
                    <Row className="table-responsive">
                        <Col md={12}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Precio</th>
                                        <th>Cantidad</th>
                                        <th>Subtotal</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selectedProducts.map((selectedProduct: SelectedProduct, index: number) => {
                                        return <tr key={index}>
                                            <td>{selectedProduct.product.name}</td>
                                            <td>{selectedProduct.product.price}</td>
                                            <td>{selectedProduct.quantity}</td>
                                            <td>{(selectedProduct.quantity * selectedProduct.product.price).toFixed(2)}</td>
                                            <td>
                                                <Button color="primary" type="button" className="mr-2" onClick={(evt: any) => this.editProduct(selectedProduct, index)} >Editar</Button>
                                                <Button disabled={this.state.currentProduct != null} color="primary" type="button" className="mr-2" onClick={(evt: any) => this.removeProduct(selectedProduct)} >Quitar</Button>
                                            </td>
                                        </tr>
                                    }
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>$ {this.getTotal().toFixed(2)}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </Col>
                    </Row>
                    {this.props.isLink ? this.renderLinkBottom() : this.renderTransferCashBottom()}
                </Form>

                <form action="https://www.mi-sitio.com/procesar-pago" method="POST">

                </form>
            </Card.Body>
        </Card>
    }

    private isEmailValid() {
        if (this.state.userName.length == 0) {
            return true;
        }

        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regexp.test(this.state.userName);
    }

    private renderLinkBottom = () => {
        return <>
            <Row>
                <p className="card-description"> Datos para el link </p>
            </Row>
            <Row className="mb-2">
                {this.props.currentSale == null
                    /*|| !this.props.currentSale.hasMeLiOrder*/
                    ? <>
                    <Col className="form-group" md={2}>
                        <Label>Email</Label>
                    </Col>
                        <Col className="form-group" md={4}>
                        <Row> <Form.Control type="text" disabled={this.state.selectedProducts.length == 0}
                            className="form-control"
                            placeholder="Email"
                            value={this.state.userName}
                            onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ userName: evt.currentTarget.value })} />

                            {!this.isEmailValid() ? (
                                <div className="mb-3">
                                    <small id="passwordHelp" className="text-danger">
                                        Ingrese un email válido
                                                    </small>
                                </div>)
                                : <></>}
                        </Row>
                    </Col>
                </>
                    : <></>
                }
                <Col className="form-group" md={4}>
                    <Label> Monto en $ {this.getTotal().toFixed(2)}</Label>
                </Col>
            </Row>
            {this.props.currentSale == null ? <Row className="mb-2">
                <Col className="form-group" md={2}>
                    <Label>Nombre Cliente</Label>
                </Col>
                <Col className="form-group" md={4}>
                    <Row> <Form.Control type="text" disabled={this.state.selectedProducts.length == 0}
                        className="form-control"
                        placeholder="Nombre de usuario"
                        value={this.state.fullName}
                        onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ fullName: evt.currentTarget.value })} />
                    </Row>
                </Col>
            </Row> :
                <></>}
            <Row className="mb-2">
                <Col className="form-group" md={2}>
                    <Label>
                        Detalle (hasta 100 caracteres)
                            </Label>
                </Col>
                <Col className="form-group" md={4}>
                    <Row>
                        <Form.Control type="text" disabled={this.state.selectedProducts.length == 0}
                            className="form-control"
                            placeholder="Detalle"
                            value={this.state.detail}
                            onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ detail: evt.currentTarget.value })} />

                    </Row>
                </Col>
            </Row>
            <Row className="float-right">
                <Button color="success"
                    type="submit"
                    className="ml-3"
                    disabled={this.props.currentSale != null && this.props.currentSale.state == "InReview" ?
                        (this.state.selectedProducts.length == 0 || this.state.detail.length == 0 ) :
                        this.state.selectedProducts.length == 0
                        || (this.state.userName.length == 0 && this.props.currentSale == null)
                        || (this.state.userName.length == 0 && this.props.currentSale != null && !this.props.currentSale.hasMeLiOrder)
                        || this.state.detail.length == 0
                        || !this.isEmailValid()
                        || this.state.fullName.length == 0
                    }>
                    Enviar Link de Pago
                        </Button>

                {this.isInSale() && this.props.currentSale != null ?
                    <Button color="secondary" className="ml-3" onClick={() => this.handleCancelClick()}>
                        Cancelar
                        </Button>
                    :
                    <></>
                }
            </Row>
        </>;
    }

    private renderTransferCashBottom = () => {
        return <>
            <Row>
                <p className="card-description"> {this.props.isCash ? "Información para el pago en efectivo" : "Información para la transferencia"}</p>
            </Row>
            <Row className="mb-2">

                {this.props.currentSale == null
                    /*|| !this.props.currentSale.hasMeLiOrder*/
                    ? <>
                        <Col className="form-group" md={2}>
                        <Label>Email</Label>
                    </Col>
                        <Col className="form-group" md={4}>
                        <Row>
                            <Form.Control type="text" disabled={this.state.selectedProducts.length == 0}
                                className="form-control"
                                placeholder="Email"
                                value={this.state.userName}
                                onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ userName: evt.currentTarget.value })} />
                                {!this.isEmailValid() ? (
                                    <div className="mb-3">
                                        <small id="passwordHelp" className="text-danger">
                                            Ingrese un email válido
                                        </small>
                                    </div>)
                                : <></>}
                        </Row>
                    </Col>
                </> : <></>
                }
                <Col className="form-group" md={4}>
                    <Label>Monto en $ {this.getTotal().toFixed(2)}</Label>
                </Col>
            </Row>
            {this.props.currentSale == null ? <Row className="mb-2">
                <Col className="form-group" md={2}>
                    <Label>Nombre Cliente</Label>
                </Col>
                <Col className="form-group" md={4}>
                    <Row> <Form.Control type="text" disabled={this.state.selectedProducts.length == 0}
                        className="form-control"
                        placeholder="Nombre de usuario"
                        value={this.state.fullName}
                        onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ fullName: evt.currentTarget.value })} />
                    </Row>
                </Col>
            </Row> :
                <></>}
            <Row>
                <Col className="form-group" md={12}>
                    <Label>
                        Comentarios
                    </Label>
                    <textarea disabled={this.state.selectedProducts.length == 0}
                        className="form-control min-vh-10 mb-3"
                        placeholder="Detalle"
                        value={this.state.detail}
                        onChange={(evt: React.ChangeEvent<FormControlProps>) => this.setState({ detail: evt.currentTarget.value })} />
                </Col>
            </Row>
            <Row className="float-right">
                <Button color="success"
                    type="submit"
                    className="ml-3"
                    disabled={(this.props.currentSale != null && this.props.currentSale.state == "InReview") ?
                              (this.state.selectedProducts.length == 0) :
                              (this.state.selectedProducts.length == 0
                            || (this.state.userName.length == 0 && this.props.currentSale != null && !this.props.currentSale.hasMeLiOrder)
                            || (this.state.userName.length == 0 || !this.isEmailValid())
                            || this.state.fullName.length == 0)
                    }>

                    {this.props.isCash ? "Enviar detalle para pago en efectivo" : "Enviar Solicitud de Pago por Transferencia"}
                </Button>
                <Button color="secondary" className="ml-3" onClick={() => this.handleCancelClick()}>
                    Cancelar
                        </Button>
            </Row>
        </>;
    }
    render() {
        return (
            <>
                {this.isInSale() && this.props.currentSale != null ? <h1>
                    Venta #<Link className="mr-2" to={`/ventas/detalle/${this.props.currentSale.id}`}>
                        {this.props.currentSale.id}
                    </Link>
                </h1> : <></>}
                <div className="grid-margin">
                    {this.renderHeader()}
                </div>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.userState, ...state.salesState } },
    (dispatch: any) => bindActionCreators({ ...commonActionCreators.actionCreators, ...salesActionCreators.actionCreators }, dispatch)
)(PaymentSale as any);

