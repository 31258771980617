import * as React from 'react';
import { Link } from 'react-router-dom';
import { Form, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Common/actions';
import { ApplicationState } from '../../store';
import { Button } from 'reactstrap';
import { UserState, Notification } from '../../store/Common/types';
import { RouteComponentProps } from 'react-router';
declare var require: any;

type NotificationProps =
    UserState
    & typeof actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;


class Notifications extends React.Component<NotificationProps> {

    state : {
        hassNewMessages: boolean,
        hassNewMessagesNDispachedND: boolean
    } =
        {
            hassNewMessages: false,
            hassNewMessagesNDispachedND: false
        }

    private convertDate = (date: Date) => {
        var delta = Math.round((new Date().getTime() - new Date(date).getTime()) / 1000);

        var minute = 60,
            hour = minute * 60,
            day = hour * 24,
            week = day * 7;
        
        var fuzzy = 'hace más de un día';

        if (delta < 30) {
            fuzzy = 'justo ahora';
        } else if (delta < minute) {
            fuzzy = delta + ' segundos atrás';
        } else if (delta < 2 * minute) {
            fuzzy = 'un minuto atrás'
        } else if (delta < hour) {
            fuzzy = Math.floor(delta / minute) + ' minutos atrás';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = '1 hora atrás'
        } else if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' horas atrás';
        } else if (delta < day * 2) {
            fuzzy = 'hace más de un día';
        }
        return fuzzy;
    }

    componentWillReceiveProps(nextProps: NotificationProps) {
        if (this.props.user.email != "" &&
            nextProps.user.notifications.filter(notification => !notification.isRead && notification.type == 1).length >
        this.props.user.notifications.filter(notification => !notification.isRead && notification.type == 1).length) {
            this.setState({ hassNewMessages: true });
        }
        else if (this.state.hassNewMessages &&
            nextProps.user.notifications.filter(notification => !notification.isRead && notification.type == 1).length
        != this.props.user.notifications.filter(notification => !notification.isRead && notification.type == 1).length) {
            this.setState({ hassNewMessages: false });
        }
        //BACK 78
        if (this.props.user.email != "" &&
            nextProps.user.notifications.filter(notification => !notification.isRead && notification.type == 2).length >
        this.props.user.notifications.filter(notification => !notification.isRead && notification.type == 2).length) {
            this.setState({ hassNewMessagesNDispachedND: true });
        }
        else if (this.state.hassNewMessages &&
            nextProps.user.notifications.filter(notification => !notification.isRead && notification.type == 2).length
        != this.props.user.notifications.filter(notification => !notification.isRead && notification.type == 2).length) {
            this.setState({ hassNewMessagesNDispachedND: false });
        }
    }

    render() {
        //BACK 78 se agrega && notification.type == 1 para traer las de tipo sistema
        let messageLength = this.props.user.notifications.filter(notification => !notification.isRead && notification.type == 1).length;

        let messageLengthNFlex = this.props.user.notifications.filter(notification => !notification.isRead && notification.type == 2).length;
        return (
            <>
                <Dropdown alignRight>
                    <Dropdown.Toggle id="2" className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                        <div>
                            <i className="mdi mdi-bell-outline"></i>
                            {
                                messageLength > 0 ? 
                                    <span className={`count ${this.state.hassNewMessages ? "bg-danger": "bg-success"}`}>{messageLength}</span>
                                : <></>
                            }
                        
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="navbar-dropdown preview-list">
                        <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center">
                            <Link className="preview-item-content py-2 w-100 h-100"
                                to="/notificaciones/1">
                                Ver todos los mensajes
                            </Link>
                        </Dropdown.Item>
                        <div className="dropdown-divider"></div>
                        {this.props.user.notifications.filter(notification => notification.type == 1).map((notification: Notification, index: number) => {
                            if (index > 7)
                                return <React.Fragment key={ "frNot" + index}></React.Fragment>;
                            return (<React.Fragment key={"frNot" + index}>
                                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center"
                                    key={"ddiNot" + index}
                                    disabled={notification.isRead}
                                    href="!#"
                                    onClick={(evt: any) => {
                                        evt.preventDefault();
                                        this.props.toggleNotificationReadness(notification.id);
                                    }}>
                                        <div className="preview-thumbnail">
                                            <i className="mdi mdi-alert m-auto text-primary"></i>
                                    </div>
                                        <div className="preview-item-content py-2">
                                        <h6 className={`preview-subject text-dark mb-1 ${notification.isRead ? "font-weight-normal" : "font-weight-bold"}`}>{notification.body}</h6>
                                        <p className="font-weight-light small-text mb-0"> {this.convertDate(notification.dateTime) } </p>
                                        </div>
                                    </Dropdown.Item>
                                <div className="dropdown-divider"></div>
                            </React.Fragment>)
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                {this.props.user.role == "Packer" || this.props.user.role == "Seller" || this.props.user.role == "SellerLead" ?
                    <Dropdown style={{ marginLeft: "25px" }} alignRight >
                        <Dropdown.Toggle id="3" className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                            <div>
                                <i className="mdi mdi-clock-fast"></i>
                                {
                                    messageLengthNFlex > 0 ?
                                        <span className={`count ${this.state.hassNewMessagesNDispachedND ? "bg-danger" : "bg-success"}`}>{messageLengthNFlex}</span>
                                        : <></>
                                }

                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="navbar-dropdown preview-list">
                            <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center">
                                <Link className="preview-item-content py-2 w-100 h-100"
                                    to="/notificaciones/2">
                                    Ver todos los mensajes
                            </Link>
                            </Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            {this.props.user.notifications.filter(notification => notification.type == 2).map((notification: Notification, index: number) => {
                                if (index > 7)
                                    return <React.Fragment key={"frNot" + index}></React.Fragment>;
                                return (<React.Fragment key={"frNot" + index}>
                                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center"
                                        key={"ddiNot" + index}
                                        disabled={notification.isRead}
                                        href="!#"
                                        onClick={(evt: any) => {
                                            evt.preventDefault();
                                            this.props.toggleNotificationReadness(notification.id);
                                        }}>
                                        <div className="preview-thumbnail">
                                            <i className="mdi mdi-alert m-auto text-primary"></i>
                                        </div>
                                        <div className="preview-item-content py-2">
                                            <h6 className={`preview-subject text-dark mb-1 ${notification.isRead ? "font-weight-normal" : "font-weight-bold"}`}>{notification.body}</h6>
                                            <p className="font-weight-light small-text mb-0"> {this.convertDate(notification.dateTime)} </p>
                                        </div>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                </React.Fragment>)
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    : <></>}
            </>
        )
    }
}


export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(Notifications as any);

